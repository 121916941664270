import React, {useState} from 'react'
import moment from 'moment'
import { useNavigate, useLocation } from "react-router-dom"
import Axios from "axios"
import {toast} from "react-hot-toast"

import { useAuth } from '../../context/auth';
import { useGdate } from '../../context/date'
import { useMenuType } from '../../context/menu';

import {Text} from '../../Components/Inputs/InputFields'

export default function Register() {
  //hook
  const navigate = useNavigate()
  const location = useLocation()

  const [auth, setAuth] = useAuth()
  const [gdate, setGdate] = useGdate()
  const [menuType, setMenuType] = useMenuType()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const [submitDisable, setSubmitDisable] = useState(false)
  const setMenu = (role) => {
    const x = role
    switch (true) {
      case x < 2:
        return {...menuType, value: "General"}
      case 2 <= x:
        return {...menuType, value: "Gas"}
      default:
        return {...menuType, value: "Gas"}
    }
  }

  const loadCashFlowsToBeVerify = async() => {
    try {
        const {data} = await Axios.post(`/cashflows-verifiedlist`)
        if (data?.error) {
            console.log(data?.error)
        } else {
          await setGdate({...gdate, 
            date: data?.last ? moment(data?.date).add(1, 'day').format('YYYY-MM-DD') : data?.date, 
            reportStartDate: data?.last ? moment(data?.date).add(1, 'day').startOf('month').format('YYYY-MM-DD') : moment(data?.date).startOf('month').format('YYYY-MM-DD'), 
            reportEndDate: data?.last ? moment(data?.date).add(1, 'day').endOf('month').format('YYYY-MM-DD') : moment(data?.date).endOf('month').format('YYYY-MM-DD'),
            cashflowDate: data?.last ? moment(data?.date).add(1, 'day').format('YYYY-MM-DD') : data?.date,
            cashflowStatus: data?.last
          })
          await localStorage.setItem( "globalDates", JSON.stringify({...gdate, 
            date: data?.last ? moment(data?.date).add(1, 'day').format('YYYY-MM-DD') : data?.date, 
            reportStartDate: data?.last ? moment(data?.date).add(1, 'day').startOf('month').format('YYYY-MM-DD') : moment(data?.date).startOf('month').format('YYYY-MM-DD'), 
            reportEndDate: data?.last ? moment(data?.date).add(1, 'day').endOf('month').format('YYYY-MM-DD') : moment(data?.date).endOf('month').format('YYYY-MM-DD'),
            cashflowDate: data?.last ? moment(data?.date).add(1, 'day').format('YYYY-MM-DD') : data?.date,
            cashflowStatus: data?.last
          }))
        }
    } catch (err) {
        console.log(err)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post(`/login`, {
        email: email?.toLowerCase(),
        password,
      });
      if(data?.error) {
        toast.error(data.error);
        setSubmitDisable(false)
      } else {
        loadCashFlowsToBeVerify()
        localStorage.setItem( "auth", JSON.stringify(data))
        setAuth({...auth, token: data?.token, employee: data?.employee})
        setMenuType(setMenu(data?.employee?.role))
        toast.success("Logged In Sucessfully");
        navigate(location.state || `/dashboard/${data?.employee?.role < 2 ? 'user/quick-panel' : ''}`);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong, Try Again!")
      
    }
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-xxl-4 col-lg-6 col-md-8 col-sm-12 shadow p-5' style={{borderRadius: '5px', backgroundColor: 'rgba(100,100,100,0.25)'}}>
        <h1 className='text-center'>LOGIN</h1>
        <form onSubmit={handleSubmit}>
          <Text
            label="Email / Username / NIC No"
            value = {email}
            onChange={(e) => setEmail(e.target.value)} 
          />

          <Text
            label="Password"
            type = 'password'
            value = {password}
            onChange={(e) => setPassword(e.target.value)} 
          />

          <div class="d-grid gap-2">
            <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
              LOGIN
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
