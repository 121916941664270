import Axios from 'axios';
import {toast} from 'react-hot-toast'

export async function sendMessage ({numbers, message}) { 
    try {
        const {data} = await Axios.post(`/messagelist`)
        if (data?.error) {
            toast.error(data.error)
        } else if (Number(data?.reduce((a,v) => a = a + (v.messageCount), 0)) > 0) {
            const {data} = await Axios.post(`/sendMessage`, {numbers, message})
            if (data === 1) {
                return ({success: 'SMS Sent Successfully'})
            } else {
                return ({error: 'SMS Service Failed!, Try Again'})
            }
        } else {
            return ({error: 'Recharge SMS Service'})
        }
    } catch (err) {
        console.log(err)
        return ({error: "Something went wrong, Try Again!"})
    }
}