import React, {useState,useEffect} from 'react'
import { useNavigate, createSearchParams } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useAuth } from '../../context/auth'

import {currency} from '../../Functions/NoFomats'

import { Add, Edit, Receipt, Check, Clear } from '@mui/icons-material/'

import {SwitchInput} from '../../Components/Inputs/InputFields'

import MaterialTable from '../../Components/Table/MaterialTable'
import ModalBasic from '../../Components/Modal/ModalBasic'

import CreateSupplier from './CreateSupplier'
import UpdateSupplier from './UpdateSupplier'

import AccountsFrom from '../Accounts/DoubleEntires/AccountsForm'

export default function ListSuppliers() {

  const [auth] = useAuth()

  const navigate = useNavigate()

  const [listSuppliers, setListSuppliers] = useState('')
  const [listBalanceDetails, setListBalanceDetails] = useState('')
  const [listGivenChequeSupplier, setListGivenChequeSupplier] = useState()
  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  const [visible1, setVisible1] = useState(false)
  const [selected1, setSelected1] = useState(null)

  const [visibleCredit, setVisibleCredit] = useState(false)
  const [visibleDebit, setVisibleDebit] = useState(false)

  const [submitDisable, setSubmitDisable] = useState(false)

  const [checked, setChecked] = useState(true)

  useEffect(() => {
      loadBalanceDetails()
      loadGivenChequesSupplier()
  }, [])
  
  useEffect(() => {
    listBalanceDetails && loadSuppliers()
  },[listBalanceDetails, listGivenChequeSupplier])

  const loadBalanceDetails = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/suppliertotal`, {  $expr : { $eq: [ '$accounting' , { $toObjectId: '64acdf13722d07654e219f89' } ] }, "detail.supplier" : { $exists: true} })
      if (data?.error) {
          toast.error(data.error)
      } else {
        setListBalanceDetails(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadGivenChequesSupplier = async() => {
    try {
      const {data} = await Axios.post(`/cheques/given-supplier`, { status: 'debit', chqDeposit: false})
      if (data?.error) {
          toast.error(data.error)
      } else {
        setListGivenChequeSupplier(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadSuppliers = async() => {
      try {
          const {data} = await Axios.post('/suppliers')
          if(data?.error){
              toast.error(data.error)
          } else {
              setListSuppliers(data?.map((i) => 
                ({...i,
                  balance: (listBalanceDetails?.find((c) => c?._id?._id === i?._id) ? listBalanceDetails?.find((c) => c?._id?._id === i?._id)?.total  : 0),
                  totalBusiness: (listBalanceDetails?.find((c) => c?._id?._id === i?._id) ? listBalanceDetails?.find((c) => c?._id?._id === i?._id)?.sumCreditAmount: 0),
                  chequeTotal: (listGivenChequeSupplier?.find((g) => i?._id === g?._id?._id)?.total ? listGivenChequeSupplier?.find((g) => i?._id === g?._id?._id)?.total : 0)

                })
              ))
          }
      } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
      }
  }

  const handleDeleteSupplier = async(id) => {
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.delete(`/supplier/${id}`)
      if (data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        toast.success('supplier Successfully Deleted')
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const columns = [
    { accessorKey: 'name', header: 'supplier Name', size: 100},
    { accessorKey: 'nic', header: 'NIC No', size: 100},
    { accessorKey: 'company', header: 'Company', size: 100},
    { accessorKey: 'address', header: 'Address', size: 100},
    { accessorKey: 'phoneNumber', header: 'Phone No', size: 100},
    { accessorKey: 'active', header: 'Active', size: 100, Cell : ({row: {original}}) => original.active ? <Check color='success' /> : <Clear color='error'/>},
    { accessorKey: 'balance', header: 'Payable Balance', size: 170, Cell : ({row: {original}}) =>  currency(original.balance), export: (i) => currency(i)},
    { accessorKey: 'totalBusiness', header: 'Total Credit Business', size: 170, Cell : ({row: {original}}) => currency(original.totalBusiness), export: (i) => currency(i)},
    { accessorKey: 'chequeTotal', header: 'Cheque Total', size: 100, 
      Cell: ({row: {original}}) => currency(original.chequeTotal),
      export: (i) => currency(i)
    }
  ]

  const rowAction = ({row: {original}}) => [
    <div className='d-flex justify-content-center gap-2'>
      <Receipt
        onClick={(e) => {
          navigate({
            pathname: `/dashboard/user/supplier-accounts/${original._id}`,
            search: `?${createSearchParams({
              name: original?.name,
              chequeTotal : original?.chequeTotal
            })}`
          })
        }}
      />
      <Edit
        onClick={() => {
          setVisible(true)
          setSelected(original)
        }}
      />
      {/* <DeleteForever
        onClick={() => {
          setVisible1(true)
          setSelected1(original)
        }}
      /> */}
    </div>
  ]

  return (
    <div className='row justify-content-center'>
      <div className='col-12 mb-3'>
        <div className='hstack gap-3 mb-2'>
            <button className = 'btn btn-outline-primary' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
            <button className = 'btn btn-outline-success ms-auto' onClick={() => {setVisibleDebit(true)}}><Add/> Debit</button> 
            <button className = 'btn btn-outline-danger' onClick={() => {setVisibleCredit(true)}}><Add/> Credit</button>
        </div>

        <div className='d-flex justify-content-end'>
          <SwitchInput
            endLabel = "Active"
            checked = {checked}
            color = 'primary'
            onChange = {(e) => {
              setChecked(preVal => !preVal)
            }}
          />
        </div>

        <div>
          <MaterialTable
            title = "List of Suppliers"
            col = {columns}
            columnVisibility = {{nic: false, address: false}}
            row = {listSuppliers && checked ? listSuppliers?.filter((f) => f?.active === true) : listSuppliers}
            rowAction = {rowAction}
          />
        </div>

        <ModalBasic
          open = {visibleAdd}
          onClose = {()=> {
          setVisibleAdd(false)
          }}
          content = {<CreateSupplier/>}
        />

        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {<UpdateSupplier supplier = {selected}/>}
        />

        <ModalBasic
          open = {visible1}
          onClose = {()=> {
          setVisible1(false)
          }}
          content = {
            auth?.employee?.role === 0 ?
              <div className='d-flex justify-content-between gap-2'>
                <p>Are You sure want to? On deletation selected Customer data will be erased</p>
                <div>
                  <button className='btn btn-outline-danger' disabled={submitDisable} onClick={() => {
                    handleDeleteSupplier(selected1._id)
                    }}>Delete
                  </button>
                </div>
              </div>
              :
              <div>
                <p className='text-center'>You don't have Permission to Delete</p>
                <p className='text-center fs-4'>Login as Admin</p>
              </div>
              
          }
        />

        <ModalBasic
          size = '80%'
          open = {visibleCredit}
          onClose = {()=> {
          setVisibleCredit(false)
          }}
          content = {<AccountsFrom data= {{inputAccounting2: '64acdf13722d07654e219f89'}}/>}
        />

        <ModalBasic
          size = '80%'
          open = {visibleDebit}
          onClose = {()=> {
          setVisibleDebit(false)
          }}
          content = {<AccountsFrom data= {{inputAccounting: '64acdf13722d07654e219f89'}}/>}
        />
      </div>
    </div>
    )
}
