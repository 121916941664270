import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import MaterialTable from '../../../Components/Table/MaterialTable'
import ModalBasic from '../../../Components/Modal/ModalBasic'

import {Add, Edit} from '@mui/icons-material/'

import CreateBrand from './CreateBrand'
import UpdateBrand from './UpdateBrand'

export default function ListBrand() {

  const [listBrand, setListBrand] = useState('')
  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  useEffect(() => {
      loadBrands()
  }, [])
  

  const loadBrands = async() => {
      try {
          const {data} = await Axios.post('/brands')
          if(data?.error){
              toast.error(data.error)
          } else {
              setListBrand(data)
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  const columns = [
    { accessorKey: 'name', header: 'Brand'},
  ]

  const rowAction = ({row}) => [
    <div className='d-flex justify-content-center'>
      <Edit
        onClick={() => {
          setVisible(true)
          setSelected(row.original)
        }}
      />
    </div>
  ]

  return (
    <div className='row justify-content-center'>
      <div className='col-xl-6 col-lg-8 col-12 mb-3'>
        <div className='d-flex gap-2 mb-2'>
          <button className = 'btn btn-outline-primary' onClick={() => {setVisibleAdd(true)}}><Add/> ADD NEW</button>
        </div>
        <MaterialTable
          title = 'List Brand'
          col = {columns}
          row = {listBrand}
          rowAction = {rowAction}
          exportDisable = {true}
        />
        <ModalBasic
          open = {visibleAdd}
          onClose = {()=> {
          setVisibleAdd(false)
          }}
          content = {<CreateBrand/>}
        />

        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {<UpdateBrand data = {selected}/>}
        />

      </div>
    </div>
    )
}
