import React, {useState, useEffect} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import { useAuth } from '../../context/auth'

import {Text, SelectAutoComplete} from '../../Components/Inputs/InputFields'
import {Check, Clear} from '@mui/icons-material'

export default function UpdateEmployee(props) {
    const empData = props.employee

    const [auth] = useAuth()
    const [roleArray, setRoleArray] = useState([{id: 2, role:"Employee"}])

    const [name, setName] = useState(empData?.name)
    const [email, setEmail] = useState(empData?.email)
    const [nic, setNic] = useState(empData?.nic)
    const [role, setRole] = useState(empData?.role)
    const [designation, setDesignation] = useState(empData?.designation)
    const [active, setActive] = useState(empData?.active)
    const [address, setAddress] = useState(empData?.address)
    const [phoneNumber, setPhoneNumber] = useState(empData?.phoneNumber)
    

    const [password, setPassword] = useState('')
    const [comparePassword, setComparePassword] = useState('')

    const [type, setType] = useState(empData?.payment?.type)
    const [amount, setAmount] = useState(empData?.payment?.amount)

    const [submitDisable, setSubmitDisable] = useState(false)

    useEffect(() => {
        setRoleArray(roles)
        setRole([{id: 0, role:"Admin"},{id: 1, role:"User"},{id: 2, role:"Employee"}]?.find((i) => i?.id === empData?.role))
    }, [auth?.employee])
    
    const roles = () => {
        switch(auth?.employee?.role) {
            case 0 :
                return [{id: 0, role:"Admin"},{id: 1, role:"User"},{id: 2, role:"Employee"}]
            default:
                return [{id: 2, role:"Employee"}]
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await setSubmitDisable(true)
            if (password.trim() && password === comparePassword) {
                const {data} = await Axios.put(`/employee/${empData._id}`, {name, email, nic, role, designation, active, address, phoneNumber, payment: {type, amount: Number(amount)?.toFixed(2)}, password})
                if(data?.error) {
                    toast.error(data.error)
                    setSubmitDisable(true)
                } else {
                    toast.success("Employee sucessfully Updated");
                    window.location.reload()
                }
            } else {
                const {data} = await Axios.put(`/employee/${empData._id}`, {name, email, nic, role, designation, active, address, phoneNumber, payment: {type, amount : Number(amount)?.toFixed(2)}})
                if(data?.error) {
                    toast.error(data.error)
                    setSubmitDisable(true)
                } else {
                    toast.success("Employee sucessfully Updated");
                    window.location.reload()
                }
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    return (
        <div className='p-3'>
            <h4 className='text-center mb-3'>Employee : {empData?.name}</h4>
            <form onSubmit={handleSubmit}>
                <Text
                    label="Name"
                    value = {name}
                    onChange={(e) => setName(e.target.value)} 
                />
                <Text
                    label="Email"
                    value = {email}
                    onChange={(e) => setEmail(e.target.value)} 
                />

                <SelectAutoComplete
                    label = "Role"
                    optionsList={roleArray}
                    option = {(i) => i?.role}
                    value={role}
                    onChange={(event, value) => {
                        setRole(value.id)
                    }}
                />

                <Text
                    label="Designation"
                    value = {designation}
                    onChange={(e) => setDesignation(e.target.value)}
                />

                <SelectAutoComplete
                    label = "Active"
                    optionsList={[true, false]}
                    option = {(i) => (i ? 'TRUE' : 'FALSE')}
                    value={active}
                    onChange={(event, value) => {
                        setActive(value)
                    }}
                />

                <Text
                    label="Address"
                    value = {address}
                    onChange={(e) => setAddress(e.target.value)} 
                />
                <Text
                    label="NIC Number"
                    value = {nic}
                    onChange={(e) => setNic(e.target.value)}
                    end = {nic?.length > 0 ? (nic.length === 10 || nic.length === 12 ? <Check color='success' /> : <Clear color='error'/>) : ''}
                />
                <Text
                    label="Phone Number"
                    type ="Number"
                    value = {phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    end = {phoneNumber?.length > 0 ? (phoneNumber.length === 10 ? <Check color='success' /> : <Clear color='error'/>) : ''}
                />
                <Text
                    label="Password"
                    type= "password"
                    value = {password}
                    onChange={(e) => setPassword(e.target.value)} 
                />
                <Text
                    label="Confirm Password"
                    type = "password"
                    value = {comparePassword}
                    onChange={(e) => setComparePassword(e.target.value)} 
                />

                <SelectAutoComplete
                    label = "Payment Type"
                    optionsList={['hours', 'day', 'month']}
                    option = {(i) => i}
                    value={type}
                    onChange={(event, value) => {
                        setType(value)
                    }}
                />

                <Text
                    label="Payment"
                    type= "Number"
                    value = {amount}
                    onChange={(e) => setAmount(e.target.value)}
                    end = 'LKR'
                />

                <div class="d-grid gap-2">
                    <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
                        UPDATE EMPLOYEE
                    </button>
                </div>
            </form>
        </div>
    )
}
