import React, {useState, useEffect, useRef} from 'react'
import Axios from 'axios'
import { toast } from 'react-hot-toast'
import moment from 'moment'

import {useSettings} from '../../../context/settings'
import {useGdate} from '../../../context/date'

import { GeneralNo, currency } from '../../../Functions/NoFomats'

import { SelectAutoComplete, Text, SwitchInput } from '../../../Components/Inputs/InputFields'
import {TextField, Autocomplete, Button, Paper} from '@mui/material';
import {Clear} from '@mui/icons-material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCartPlus, faBarcode} from '@fortawesome/free-solid-svg-icons'

import MaterialTable from '../../../Components/Table/MaterialTable'
import ModalBasic from '../../../Components/Modal/ModalBasic'

import Scanner from '../../../Components/Scan-Qr/Scanner'
import SalesReceipt from './Receipts/SalesReceipt'
import SalesReceiptV2 from './Receipts/SalesReceiptV2'

export default function SalesForm() {
  const [settings] = useSettings()
  const [gdate] = useGdate()

  // LOAD DATA
  const [ListCustomers, setListCustomers] = useState('')
  const [listBanks, setListBanks] = useState('')
  const [listGovOrders, setListGovOrders] = useState('')

  const [listWareHouses, setListWareHouses] = useState('')
  const [listAllStocks, setListAllStocks] = useState('')
  const [listWarehouseStocks, setListWarehouseStocks] = useState('')
  const [listPurchaseDetails, setListPurchaseDetails] = useState('')

  const [orderId, setOrderId] = useState('')
  const [invoiceNo, setInvoiceNo] = useState(0)
  const [wareHouse, setWareHouse] = useState('')

  const [inputValue, setInputValue] = useState('') 
  const [selectProductStock, setSelectProductStock] = useState('')

  const [selectedCustomer, setSelectedCustomer] = useState()

  const [bank, setBank] = useState()

  const [checked, setChecked] = useState(false)

  const [quantity, setQuantity] = useState()
  const [productDiscount, setProductDiscount] = useState('')
  const [finalPrice, setFinalPrice] = useState('')

  const [FIFOList, setFIFOList] = useState('')

    //Mark Date should be morethan cashflow verified date
    const [checkCashflow, setCheckCashflow] = useState(false)

  const [orderDate] = useState(gdate?.date)
  const [items, setItems] = useState([])
  const [subTotal, setSubTotal] = useState()
  const [total, setTotal] = useState()
  const [payment, setPayment] = useState()
  const [received, setReceived] = useState('')

  //GOVERNMENT SALES
  const [govOrder, setGovOrder] = useState()
  const [comment, setComment] = useState()

  const [scannerVisible, setScannerVisible] = useState(false)
  const [visible, setVisible] = useState(false)

  const paymentMethod = [{_id:'64952f578188d2c8b9c26603', name: 'Cash'}, {_id:'63e31a93f029ceab39dcd281', name: 'Bank / Card Pays'}, {_id:'63e31abdf029ceab39dcd283', name: 'Credit'}]
  const productFocusRef = useRef()

  const [submitDisable, setSubmitDisable] = useState(false)

  //Load Events on Open Form
  useEffect(() => {
    loadWareHouses()
    loadCustomers()
    loadBanks()
    loadWarehouseStocks()
    loadPurchaseDetails()
    setQuantity(1)
    setProductDiscount('0')
    setCheckCashflow(() => moment(gdate?.cashflowDate) > moment(orderDate) ? true : false)
  },[])

  //Changes on Warehouse Update
  useEffect(() => {
    setInputValue('')
    setSelectProductStock('')    
    listAllStocks && setListWarehouseStocks(listAllStocks?.filter((i) => i?.wareHouse?._id === wareHouse?._id))
  },[wareHouse, listAllStocks])

  //Auto Select Product Codes on Scan
  useEffect(() => {
    if (selectProductStock?.productCode?.productCode !== inputValue.split('|')[0].trim()) {
        listWarehouseStocks && setSelectProductStock(listWarehouseStocks?.find((i) => i.productCode?.productCode === inputValue) ? listWarehouseStocks?.find((i) => i.productCode?.productCode === inputValue) : '')
    }
  }, [inputValue])

  //FIFO effect on selectProductStock && Quantity
  useEffect(() => {
    selectProductStock && quantity && FIFO(selectProductStock?.productCode?._id)
  }, [selectProductStock, quantity])

  //Calculate SubTotal, Total
  useEffect(() => {
    setSubTotal(items?.reduce((t,i) => t = t + Number(i?.quantity * i.productStock?.productCode?.price), 0)?.toFixed(2))
    setTotal(items?.reduce((t,i) => t = t + Number((i?.productStock?.productCode?.price - i?.unitDiscount) * i?.quantity) , 0)?.toFixed(2))
  },[items])

  //Payment Selection
  useEffect(() => {
    setSelectedCustomer()
    setBank()
    setGovOrder()
  },[payment])


  const loadWareHouses = async() => {
    try {
      const {data} = await Axios.post('/warehouses')
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListWareHouses(data)
        setWareHouse(data?.find((i) => i?._id === '63f48039710db4fab3a62abb'))
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadCustomers = async() => {
    try {
      const {data} = await Axios.post(`/customers`)
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListCustomers(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadBanks = async() => {
    try {
      const {data} = await Axios.post(`/bank-accounts`)
      if(data?.error){
        toast.error(data?.error)
      } else {
        setListBanks(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadGovOrders = async(id) => {
    try {
      const {data} = await Axios.post(`/govorders`, {customer: id, status: 'receive'})
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListGovOrders(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  // Load warehouse stock BY _id !==  Fuel Category
  const loadWarehouseStocks = async() => {
    try {
      const {data} = await Axios.post(`/warehousestocks/filtercategory`, {_id: {"$ne" : '63e65efc2f840202604fbb7f'}})
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListAllStocks(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  // Load Purchase Productlines BY _id !== drop Fuel Category in Reverse order
  const loadPurchaseDetails = async() => {
    try {
        const {data} = await Axios.post(`/purchaseproductlines`)
        if (data?.error) {
            toast.error(data.error)
        } else {
          setListPurchaseDetails(data?.filter((i) => i?.productCode?.product?.category?._id !== '63e65efc2f840202604fbb7f')?.reverse())
        }
    } catch (err) {
      toast.error("Something went wrong, Try Again!")
      console.log(err)
    }
  }

  const FIFO = async(selectedProductCode) => {
    try {
      // Filter purchase productlines of selected productcode in Reverse
      const productLines = await listPurchaseDetails?.filter((i) => i?.productCode?._id === selectedProductCode)
      //Filter by productcode and get total of all stocks 
      const totalStock = await listAllStocks?.filter((i) => i?.productCode?._id === selectedProductCode)?.reduce((a,v) => a = a + v?.stock, 0)
      var SBS = totalStock
      var SAS = totalStock - quantity
      var sumBSQty = 0
      var sumASQty = 0
      var PBSindex = ''
      var PASindex = ''

      //FIND SAS Index
      for (var i=0; i<productLines?.length; i++) {
        sumASQty += productLines[i]?.quantity
        if (sumASQty >= SAS) {
          PASindex = i
          SAS -= (sumASQty - productLines[i]?.quantity)
          break
        }
      }
      
      // FIND SBS Index
      for (var i=0; i<productLines?.length; i++) {
        // Go through purchase lines until reach start of product Stock
        sumBSQty += productLines[i]?.quantity
        if (sumBSQty >= SBS) {
          // PBS is index of 1st stock purchase product line
          PBSindex = i
          //Subtract Balance amount (Sold) of stock in 1st purchase product line
          SBS = sumBSQty - SBS
          break
        }
      }
      
      const arr = []

      await productLines?.slice(PASindex, PBSindex + 1)?.reverse().map((pl, index) => {
        const uc = (pl?.itemPrice / pl?.quantity)?.toFixed(2)
        // First Element of Array
        if(index === 0) {
          if ((pl?.quantity - SBS) >= quantity) {
            arr.push({uc, qty: quantity, check: "Same"})
          } else {
            arr.push({uc, qty: (pl?.quantity - SBS), check: `${index}`})
          }
          // Last Element of Array
        } else if (index === (PBSindex - PASindex)) {
          arr.push({uc, qty: (pl?.quantity - SAS), check: `last`})
        } else {
          // Balance Elements of Array
          arr.push({uc, qty: (pl?.quantity), check: `${index}`})
        }
      })

      var result = [];
      arr.reduce(function(a, v) {
        if (!a[v.uc]) {
          a[v.uc] = { uc: v.uc, qty: 0 };
          result.push(a[v.uc])
        }
        a[v.uc].qty += v.qty;
        return a;
      }, {});
      
      setFIFOList(result)

    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const getBarcodeData = (data) => {
    setInputValue(data.result.decodedText)
  }

  const productAdd = async(e) => {
    e.preventDefault()
    try {
      if(quantity <= selectProductStock?.stock && quantity > 0) {
        const newItems = FIFOList?.map((i) => {
          return {productStock: selectProductStock, quantity: Number(i?.qty), unitDiscount: `${checked ? (selectProductStock?.productCode?.price - (finalPrice / quantity))?.toFixed(2) : ((productDiscount / quantity)).toFixed(2)}`, unitCost: i?.uc}
        })
        await setItems([...items, ...newItems])
        setQuantity(1)
        setInputValue('')
        setSelectProductStock('')
        setProductDiscount('0')
        setFinalPrice('')
        productFocusRef?.current?.firstChild?.focus();
      }
      else {
        toast.error(`${GeneralNo(selectProductStock?.stock) + ' ' + selectProductStock?.productCode?.product?.unit} Available / Minimum 1 Qty`)
        setQuantity(1)
      }

    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const orderSubmit = async(e) => {
    e.preventDefault()
    try {
      await setSubmitDisable(true)
      if (payment === '63e31abdf029ceab39dcd283' && !selectedCustomer) {
        toast.error("Customer Required");
        setSubmitDisable(false)
      } else if (payment === '63e31ae2f029ceab39dcd28d' && !bank) {
        toast.error("Bank Required");
        setSubmitDisable(false)
      } else {
        const {data} = await Axios.post(`/createorder`, {govOrder, customer: selectedCustomer?._id, orderDate, items, total, payment, comment})
        if (data?.error) {
          toast.error(data.error)
          setSubmitDisable(false)
        } else {
          await setOrderId(data?.order?._id)
          await setInvoiceNo(data?.orders?.invoiceNo)
          const data1 = await Axios.post(`/accdatabulk`, [
            {date: moment(orderDate).format("YYYY-MM-DD"), empStatus: {status: false}, detail:{description: `Sales Order No: ${data?.orders?.invoiceNo}`}, amount: Number(total).toFixed(2) , accounting: "63eb82f1661aeb9f06368651", refId: `sales-${data?.orders?.invoiceNo}-${moment(orderDate).format("YYYY-MM-DD")}-${selectedCustomer?._id ? selectedCustomer?._id : ''}`},
            {date: moment(orderDate).format("YYYY-MM-DD"), empStatus: {status: false}, detail:{description: `Sales Order No: ${data?.orders?.invoiceNo}`, customer: selectedCustomer?._id, bank, govOrder, invoiceNo: data?.orders?.invoiceNo}, amount: Number(total).toFixed(2) , accounting: payment,  refId: `sales-${data?.orders?.invoiceNo}-${moment(orderDate).format("YYYY-MM-DD")}-${selectedCustomer?._id ? selectedCustomer?._id : ''}`}
          ])
          if(data1?.data?.error){
            toast.error(data.error)
            setSubmitDisable(false)
          } else {
            toast.success("Sales Recorded Sucessfully")
            setVisible(true)
          }
        }
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong!")
    }
  }

  const columns = [
    {accessorKey: 'productStock.productCode.productCode', header: 'Code', size: 100},
    {accessorKey: 'productStock.productCode.product.name', header: 'Item', size: 100},
    {accessorKey: 'quantity', header: 'Qty', size: 100, Cell: ({row : {original}}) => GeneralNo(original.quantity)},
    {accessorKey: 'unitPrice', header: 'Unit Price', Cell: ({row : {original}}) => currency(original.productStock?.productCode?.price)},
    {accessorKey: 'discount', header: 'Discount', Cell: ({row : {original}}) => currency(original.unitDiscount ? (original.unitDiscount * original?.quantity) : 0.00)},
    {accessorKey: 'price', header: 'Price', Cell: ({row : {original}}) => currency((original.productStock?.productCode?.price - original.unitDiscount) * original?.quantity)},
  ]

  const rowAction = ({row}) => [
    <div className='d-flex justify-content-center'>
      <Clear
        onClick={() => {
          setItems(items?.filter(c => c?.productStock?.productCode?._id !== row.original.productStock?.productCode?._id))
          toast.success("Product Removed")
          productFocusRef?.current?.firstChild?.focus();
        }}
      />
    </div>
  ]

  // UI COMPONENTS
  return (
    <div className='row'>
      {/* COLUMN 1 */}
      <div className='col-xxl-8 col-12'>
        {/* WAREHOUSE */}
        <div className='col-12 mb-2'>
          <SelectAutoComplete
            label = "Warehouse"
            optionsList={listWareHouses}
            option = {(i) => i.name}
            value={wareHouse}
            onChange={(event, value) => {
                setWareHouse(value)
            }}
          />
        </div>
        {/* PRODUCTS */}
        <form className='row mb-2' onSubmit={productAdd}>
          <div className='col-md-6 col-12 mb-2'>
          {scannerVisible ? <Scanner result = {getBarcodeData}/> : <button className = 'd-flex btn btn-outline-primary' onClick={() => {setScannerVisible(true)}}><FontAwesomeIcon icon={faBarcode}/></button>}
          </div>

          <div className='col-md-6 col-12'>
            <div className='col-12'>
              <div className='col-12'>
                {listWarehouseStocks && <Autocomplete
                  size='small'
                  className='mb-2'
                  value={selectProductStock?.productCode}
                  onChange={(event, value) => {
                    setSelectProductStock(value)
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue)
                  }}
                  freeSolo
                  filterOptions={items?.productStock}
                  options={listWarehouseStocks}
                  renderInput={(params) => <TextField {...params} label="Product" autoFocus ref={productFocusRef}/>}
                  getOptionLabel = {(params) => `${params?.productCode?.productCode + ' | '+ params.productCode?.product?.brand?.name + ' ' + params.productCode?.product?.name + ' | '+ new Intl.NumberFormat().format(params?.productCode?.price)}`}
                  noOptionsText = "No Product Found"
                />}
              </div>

              <div className='col-12'>
                <Text
                  label = "Qty"
                  type = "Number"
                  value = {quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </div>

              <div>
                <SwitchInput
                  startLabel = "Discount"
                  endLabel = "Final Price"
                  checked = {checked}
                  onChange = {(e) => {
                    setChecked(preVal => !preVal)
                  }}
                />
              </div>

              <div className='col-12'>
                <Text
                  label={checked ? "Final Price" : "Product Discount"}
                  type="Number"
                  value = {checked ? finalPrice : productDiscount}
                  onChange={(e) => {
                    checked ? setFinalPrice(e.target.value) : setProductDiscount(e.target.value)
                  }}
                  end = "LKR"
                />
              </div>

              <div>{ checked ? 
                  <dd className='fw-bold'>Unit Discount : {selectProductStock && finalPrice && new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(selectProductStock?.productCode?.price - (finalPrice / quantity))}</dd>
                  :
                  <dd className='fw-bold'>Final Price : {selectProductStock && productDiscount && new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format((selectProductStock?.productCode?.price * quantity) - Number(productDiscount))}</dd>
              }</div>

              <div className='col-12 d-grid'>
                {selectProductStock ? <button style={{maxHeight: '40px'}} className='btn btn-primary' type='submit'><FontAwesomeIcon icon={faCartPlus}/></button> : <button  style={{maxHeight: '40px'}} className='btn btn-dark' type='submit' disabled><FontAwesomeIcon icon={faCartPlus}/></button>}
              </div>

            </div>
          </div>
        </form>

        {/*PRODUCTS ADDED TABLE*/}
        <div className='row mb-3'>
          <div className='col-12'>
            <MaterialTable
              title = 'Sales Product'
              col = {columns}
              row = {items}
              rowAction = {rowAction}
              exportDisable = {true}
            />
          </div>
        </div>
      </div>

      {/* COLUMN 2 */}
      <div className='col-xxl-4 col-12 d-flex justify-content-center align-items-start'>
        <Paper elevation={5} className='p-5' style={{minWidth: 400}}>
          <h4 className='text-center pb-3'>Sales Summary</h4>
          <div className='d-flex justify-content-between'><p>Date : </p> <p>{orderDate}</p></div>
          <div className='d-flex justify-content-between'><p>No of Items : </p> <p>{items.length}</p></div>
          <div className='d-flex justify-content-between'><p>Sub Total : </p> <p>{new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(items.length > 0 ? subTotal : 0)}</p></div>
          <div className='d-flex justify-content-between gap-3'><p>Total Discount :</p> <p>{new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(items.length > 0 ? items?.reduce((t,i) => t = t + Number(i?.unitDiscount * i?.quantity), 0) : 0)}</p></div>
          <hr style={{borderStyle: 'double'}}></hr>
          <div className='d-flex justify-content-between'><h5>Total : </h5> <h5>{new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(items.length > 0 ? total : 0)}</h5></div>
          <div className='d-flex justify-content-between gap-3 mt-2'>
            <p>Payment Method :</p>

            <SelectAutoComplete
              label = ""
              optionsList={paymentMethod}
              option = {(i) => i?.name}
              value={payment}
              onChange={(event, value) => {
                  setPayment(value._id)
              }}
            />
          </div>

          {(payment === "63e31a93f029ceab39dcd281") &&
            // Card Payment Or Bank
            <SelectAutoComplete
              label = "Bank"
              optionsList={listBanks}
              option = {(i) => i?.bank + ' | ' + i?.accountNo}
              value={bank}
              onChange={(event, value) => {
                  setBank(value)
              }}
            />
          }

          {/* Credit Payment */}
          {payment === '63e31abdf029ceab39dcd283' &&
            <SelectAutoComplete
              label = "Customer"
              optionsList={ListCustomers}
              option = {(i) => i?.name + ' | '+ i.phoneNumber}
              value={selectedCustomer}
              onChange={(event, value) => {
                  setSelectedCustomer(value)
                  value?.type === 'government' && loadGovOrders(value?._id)
              }}
            />
          }

          
          {selectedCustomer?.type === 'government' &&
          <SelectAutoComplete
            label = "Government Order (Opt.)"
            optionsList={listGovOrders}
            option = {(i) => i.govOrder + ' | '+ i.receiveDate}
            value={govOrder}
            onChange={(event, value) => {
              value ? setGovOrder(value) : setGovOrder()
            }}
          />
          }
          
          {/* Cash Payment */}
          {payment === '64952f578188d2c8b9c26603' && <div className='col-12'>
            <Text
              label="Recived"
              type="Number"
              value = {received}
              onChange={(e) => setReceived(Number(e.target.value))}
            />
            <div className='d-flex justify-content-between'><h6>Balance:</h6> <h6 className='text-end'>{new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(received - total)}</h6></div>
          </div>}

          <Text
              label="Comment / Special Name (Opt.)"
              value = {comment}
              onChange={(e) => setComment(e.target.value)}
          />
          <div>
            <Button className='w-100 mt-2' size='large' color="success" variant="contained" onClick={orderSubmit} disabled={(items.length > 0 && payment ? submitDisable : true) || checkCashflow}>Checkout</Button>
            {checkCashflow && <dd className='text-center fs-6 fw-bold text-danger mt-2'>Cashflow Verified on Selected date</dd>}
          </div>
        </Paper>
      </div>

      <ModalBasic
        width = {settings.billReceipt === 'invoice' ? '1000px' : ''}
        open = {visible}
        onClose = {()=> {
          window.location.reload()
        setVisible(false)
        }}
        content = {settings.billReceipt === 'invoice' ?
          <SalesReceiptV2 order = {{orderId, invoiceNo, selectedCustomer, orderDate, items, total, payment, received}}/>
          :
          <SalesReceipt order = {{orderId, invoiceNo, selectedCustomer, orderDate, items, total, payment, received}}/>
        }
      />

    </div>
  )
}
