import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useAuth } from '../../context/auth';

import MaterialTable from '../../Components/Table/MaterialTable'
import ModalBasic from '../../Components/Modal/ModalBasic'

import {Add, Edit, DeleteForever} from '@mui/icons-material/'

import CreateBooth from './CreateBooth'
import UpdateBooth from './UpdateBooth'

export default function ListBooth() {

  const [auth] = useAuth();

  const [listBooth, setListBooth] = useState('')
  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  const [visible1, setVisible1] = useState(false)
  const [selected1, setSelected1] = useState(null)

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
      loadBooth()
  }, [])
  

  const loadBooth = async() => {
      try {
          const {data} = await Axios.post('/booths')
          if(data?.error){
              toast.error(data.error)
          } else {
              setListBooth(data)
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  const handleDeleteBooth = async(boothId) => {
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.delete(`/booth/${boothId}`)
      if (data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        toast.success('Booth Successfully Deleted')
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const columns = [
    { accessorKey: 'name', header: 'Booth'},
    { accessorKey: 'noOfPump', header: 'No of Pump'}
  ]

  const rowAction = ({row: {original}}) => [
    <div className='d-flex justify-content-center gap-2'>
      <Edit
        onClick={() => {
          setVisible(true)
          setSelected(original)
        }}
      />
      <DeleteForever
        onClick={() => {
          setVisible1(true)
          setSelected1(original)
        }}
      />
    </div>
  ]
  return (
    <div className='row justify-content-center'>
      <div className='col-xxl-6 col-lg-8 col-md-10 col-12 mb-3'>
        <div className='row justify-content-between mb-2'>
          <div className='col-md-6 col-12 d-flex gap-2 mb-2'>
            <button className = 'btn btn-outline-primary w-50' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
          </div>
        </div>
        <MaterialTable
          title = 'List Booths'
          col = {columns}
          row = {listBooth}
          rowAction = {rowAction}
          exportDisable = {true}
        />
        <ModalBasic
          open = {visibleAdd}
          onClose = {()=> {
          setVisibleAdd(false)
          }}
          content = {<CreateBooth/>}
        />

        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {<UpdateBooth booth = {selected}/>}
        />

        <ModalBasic
          open = {visible1}
          onClose = {()=> {
          setVisible1(false)
          }}
          content = {
            auth?.employee?.role === 0 ?
              <div className='d-flex justify-content-between'>
                <p>Are You sure want to?</p>
                <button className = 'btn btn-outline-danger' disabled={submitDisable} onClick={() => {
                    handleDeleteBooth(selected1._id)
                  }}>Delete
                </button>
              </div>
              :
              <div className='d-flex justify-content-center w-100'>
                <p>You don't have Permission to Delete</p>
              </div>
          }
        />
      </div>
    </div>
    )
}
