import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { SelectAutoComplete } from '../../../Components/Inputs/InputFields'

export default function UpdateVehicle(props) {
  const vehicleData = props.data

  const [listCustomers, setListCustomers] = useState('')
  const [listPointsCategories, setListPointsCategories] = useState('')
  //Model

  const [customerInput, setCustomerInput] = useState('')
  const [pointsCategoryInput, setPointsCategoryInput] = useState('')

  const [customer, setCustomer] = useState(vehicleData?.customer?._id)
  const [pointsCategory, setPointsCategory] = useState(vehicleData?.pointsCategory?._id)

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
      loadCustomers()
      loadPointsCategories()
  }, [])
  

  const loadCustomers = async() => {
      try {
          const {data} = await Axios.post('/customers')
          if(data?.error){
              toast.error(data.error)
          } else {
              setListCustomers(data)
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  const loadPointsCategories = async() => {
    try {
        const {data} = await Axios.post('/pointscategories')
        if(data?.error){
            toast.error(data.error)
        } else {
            setListPointsCategories(data)
        }
    } catch (err) {
        toast.error("Something went wrong, Try Again!")
        console.log(err)
    }
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    try {
      await setSubmitDisable(true)
      const {data} =  await Axios.put(`/pc-vehicle/${vehicleData?._id}`, {customer, pointsCategory})
      if (data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        toast.success(`Vehicle ${vehicleData?.vehicleNo} Updated Sucessfully`)
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error('Something went wrong, Try Again!')
    }
  }

  return (
    <div className='p-3'>
      <h4 className='text-center mb-3'>Vehicle - {vehicleData?.vehicleNo}</h4>
      <form onSubmit={handleSubmit}>
        <SelectAutoComplete
          label = "Customer"
          optionsList={listCustomers}
          option = {(i) => `${i?.phoneNumber + ' | '+ i?.name}`}
          value={customer}
          onChange={(event, value) => {
              setCustomer(value)
          }}
        />

        <SelectAutoComplete
          label = "Points Category"
          optionsList={listPointsCategories}
          option = {(i) => i.type}
          value={pointsCategory}
          onChange={(event, value) => {
              setPointsCategory(value)
          }}
        />

        <div class="d-grid gap-2">
          <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
            UPDATE VEHICLE
          </button>
        </div>
      </form>
    </div>
    )
}
