import React, {useState, useEffect} from 'react'
import { useNavigate, createSearchParams } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment'

import { useGdate } from '../../../context/date'

import {SelectAutoComplete} from '../../../Components/Inputs/InputFields';

import {DepData2000, DepData3000, DepData5000} from '../../../Data/data'

export default function DepCheck(props) {
  const tankData = props?.warehouseStock

  const navigate = useNavigate()

  const [gdate] = useGdate()

  const [selectDepList, setSelectDepList] = useState('')
  const [depData, setDepData] = useState([])
  const [product] = useState(tankData?.productCode?.product?._id)
  const [stock] = useState(tankData?.stock?.toFixed(3))
  const [dep, setDep] = useState()
  const [date] = useState(gdate?.date)

  //Mark Date should be morethan cashflow verified date
  const [checkCashflow, setCheckCashflow] = useState(false)

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    setCheckCashflow(() => moment(gdate?.cashflowDate) > moment(date) ? true : false)
  },[])

  useEffect(() => {
    switch(tankData?.productCode?.product?.capacity || selectDepList){
      case 2000:
        setDepData(DepData2000)
        break;
      case 3000:
        setDepData(DepData3000)
        break;
      case 5000:
        setDepData(DepData5000)
        break;
      default:
        tankData && toast.error('Dapth data not loaded. Refresh the Page!')
    }
  }, [product, selectDepList])
  

  const handleSubmit = async(e) => {
    e.preventDefault();
    {
      try {
        await setSubmitDisable(true)
        const {data} = await Axios.post(`/product-depth`, {product, dep: dep?.dep , volume: dep?.volume, stock, date})
        if (data?.error){
          toast.error(data?.error)
          setSubmitDisable(false)
        } else {
          toast.success(`Depth ${dep?.dep} | ${dep?.volume} L  Marked`)
          window.location.reload()
        }
      } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
      }
    }

  }

  return (
    <div className='p-3'>
      {tankData && <div className='d-flex justify-content-center align-items-baseline gap-2'>
        <h5>Tank - {tankData.productCode?.product?.name}</h5>
        <dd>{tankData.productCode?.product?.capacity}G</dd>
      </div>}

      <h5 className='text-center'>Depth Check</h5>

      {tankData ? <form onSubmit={handleSubmit}>
        <dd className='text-center'>{date}</dd>

        <SelectAutoComplete
          label = "Depth"
          optionsList={depData}
          option = {(i) => `${i.dep} | ${i.volume} L`}
          value={dep}
          onChange={(event, value) => {
              setDep(value)
          }}
        />

        <div className='text-center'>
          <dd className='mb-0'>Current Stock</dd>
          <dd>{stock}</dd>
        </div>

        <div className='d-flex justify-content-between'>
          Depth Differance
          <dd style={(dep?.volume - stock) > 0 ? {color:'greenyellow'} : {color:'red'}}>{(dep?.volume - stock)?.toFixed(3)}</dd>
        </div>

        <div className="d-grid gap-2">
          <button className='btn btn-primary mt-3' disabled={submitDisable || checkCashflow}>{props.btntxt || "SUBMIT"}</button>
          {checkCashflow && <dd className='text-center fs-6 fw-bold text-danger mt-2'>Cashflow Verified on Selected date</dd>}
          <button className='btn btn-success mt-3' onClick={()=> {
            navigate({
              pathname: `/dashboard/user/tank/depthlist/${tankData?.productCode?.product?._id}`,
              search: `?${createSearchParams({
                name: tankData?.productCode?.product?.name,
                capacity: tankData?.productCode?.product?.capacity
              })}`
            })
          }}>DEPTH MARK LIST</button>
        </div>

      </form>
      :
      <div className='row justify-content-center'>
        <div className='col-xl-4 col-md-6 col-12'>

          <SelectAutoComplete
            label = "Select Capacity"
            optionsList={[2000, 3000, 5000]}
            option = {(i) => i + ' G'}
            value={selectDepList}
            onChange={(event, value) => {
                setSelectDepList(value)
            }}
          />

          {selectDepList && <SelectAutoComplete
            label = "Depth"
            optionsList={depData}
            option = {(i) => `${i.dep} | ${i.volume} L`}
            value={dep}
            onChange={(event, value) => {
                setDep(value)
            }}
          />}

          <div className='d-flex justify-content-between fs-5'>
            Volume Liters
            <dd>{dep?.volume} L</dd>
          </div>

        </div>
      </div>}
    </div>
  )
}
