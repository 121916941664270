import React from 'react'

export default function VersionLog() {
  return (
    <div>
      <h5 className='text-center'>Version Logs</h5>

      <div>
        <h6>v1.0.0</h6>
        <ul>
          <li>Gas Station Management System</li>
          <li>Post of Sale System</li>
          <li>Accounts with Double Entries</li>
        </ul>
      </div>

      <div>
        <h6>v1.0.1</h6>
        <ul>
          <li>Setup Logo for Organization & Receipt V2</li>
          <li>Set File upload Preview status</li>
          <li>Version update black page bug fix</li>
        </ul>
      </div>

      <div>
        <h6>v1.0.2</h6>
        <ul>
          <li>Settings Page Layout Optimize</li>
          <li>Message Service Details Layout</li>
          <li>Settings Logo Remove Button Layout Changed</li>
        </ul>        
      </div>

      <div>
        <h6>v1.0.3</h6>
        <ul>
          <li>Black Page Solve with Hompage Script Remove & "/build"</li>
          <li>Employe Attendance Bug Fix & Dafult Values of working hr/day</li>
          <li>Employee Attendance Deletation with Double Entries</li>
        </ul>      
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.1.0</h6>
          <dd>2023-10-15</dd>
        </div>
        <ul>
          <li>Data: Employee Attendance get with Date Filters & Total Arrears</li>
          <li>Data: Employee Attendance Mark Attendance Double entry Employee Name added to Description</li>
          <li>Employee Attendance Layout Change and Added Present Days, Salary, Total Arrears</li>
          <li>Employee Attendance Details Show Total Salary, Paid Salries and Balance Arrars</li>
          <li>Daily CashFlow Debts Details Customer Name, BillNo and Vehicle No Added</li>
          <li>Fuel Purchase Fuel Type Added to Description</li>
        </ul>      
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.1.1</h6>
          <dd>2023-10-17</dd>
        </div>
        <ul>
          <li>Data: Day Fuel Sales get with SoldQuantity, ExpenseL, DrawingL</li>
          <li>Data: Accounts which has same accounts Type and Description Grouped and Total to use Extended verision of Income Statement</li>
          <li>Income Statement and Balance Sheet Separated</li>
          <li>Income Statement Modified as Gross Profit By Fuel-Types and Inventory</li>
          <li>Extended Version of Income Statement with Detailed View</li>
          <li>Dashboard UI Modified and added income Statement</li>
          <li>Fuel stock value on All Assets in Dashboard Bug Fixed</li>
        </ul>      
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.2.0</h6>
          <dd>2023-10-24</dd>
        </div>
        <ul>
          <li>Data: Products and Fuel sales unitCost Record Accordingly FIFO</li>
          <li>Day Pump Expense, drawings Account Records and delete automatically with mark Day Pump</li>
          <li>Admin can only delete double entries records in the double entry page</li>
          <li>FIFO Cost Management added to Sales Form and Day Pump Marks</li>
          <li>Income Statement and Extended version Profit Record according to FIFO</li>
          <li>Employee Attendance Detail Table Layout Change and work according to employee payment method</li>
        </ul>      
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.3.0</h6>
          <dd>2023-10-30</dd>
        </div>
        <ul>
          <li>Data: Receivable Cheque Total Calulation Group by Customer</li>
          <li>All-time Income Statement & Balance Sheet Added to Dashboard</li>
          <li>Extended View of Balance sheet (Available Extended View on Bank / Debts / Cheque / Creditors / Salary Arrears)</li>
          <li>Double Entry on Cheque Debit Cheque Selection Added</li>
          <li>Sidebar Menu Quick Links Added for Daily Access Menu items</li>
          <li>Daily Cashflow Brought forward Cheque Added along with Cash</li>
        </ul>      
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.3.1</h6>
          <dd>2023-11-01</dd>
        </div>
        <ul>
          <li>Stock Adjustment Exchange with maximum available Quantity</li>
          <li>Stock Adjustment FIFO Calculation Error Fixed</li>
          <li>Table Filters added</li>
          <li>Double Entry Amount Formated as currency</li>
          <li>New Depth data Added for both 3000G, 5000G tanks</li>
        </ul>      
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.3.2</h6>
          <dd>2023-11-04</dd>
        </div>
        <ul>
          <li>Employee Card Accounts Differance visibility fixed</li>
          <li>Employee Accounts Entry Debts Description Added</li>
          <li>Employee Card Accounts Differance display to Employee Accounts Form</li>
          <li>On Employee Accounts Submission hide Employee Acccounts Form</li>
          <li>Balance Sheet Extended View '0' Amount Hidden</li>
          <li>Daily Cashflow Debts billNo,Vehicle No Removed and added Description</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.3.3</h6>
          <dd>2023-11-07</dd>
        </div>
        <ul>
          <li>Data: Cheque Model Built for maintain (received, givien) cheques details</li>
          <li>Income Statemet Profit by fuel Type modified</li>
          <li>Income statement extended table modifed with unit Profit and top header</li>
          <li>Keyboard shortcuts added to Quick Links Menu</li>
          <li>Balance Sheet extended fuel stock by type added & stock error fixed</li>
          <li>Cheque details added to accounts with Object Id (links)</li>
          <li>Double entry cheques details modified for easy entries</li>
          <li>Sales & Purchase forms on Product Add, blank page visible fixed</li>
          <li>Sales & Purchase forms auto focus field error fixed</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.4.0</h6>
          <dd>2023-11-12</dd>
        </div>
        <ul>
          <li>Data: Cashflow Verification Model Added</li>
          <li>Cashflow verification on Daily basis</li>
          <li>Miscellaneous Income Entry feature set on cashflow component</li>
          <li>Double Entry cannot add / delete if cashflow verified</li>
          <li>Depth Check keyword limits to depths only</li>
          <li>All Stocks table Modified as total stock by productCode</li>
          <li>Stock Adjustment option moved to stock adjustment page</li>
          <li>Warehouse Stocks details available on All Stocks Page</li>
          <li>Employee accounts summarize loading fixed</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.5.0</h6>
          <dd>2023-11-20</dd>
        </div>
        <ul>
          <li>Data: Accounts entry Government Orders tracking added</li>
          <li>Data: Customer type add to customer model. due to track customer/gov orders</li>
          <li>once click submit button, it goes to disable state. (Avoid double submisstion)</li>
          <li>Customer order tracking module added (sp. government orders)</li>
          <li>List Orders modified with action button to add government order No later</li>
          <li>customer categorization added as induvidual & government</li>
          <li>Employee accounts "debts" option added for fueltype & liters. Since it requires for government order tracking</li>
          <li>Sales form page "comment" field feature added for special note added for orders</li>
          <li>Employee accounts will not able to add if employee accounts summarize verfied</li>
          <li>Vehicle No option for customer removed and change to comment section (use to vehicle no / any comment)</li>
          <li>Income statement extended report start date fixed</li>
          <li>Balance Statement cheque, bank, creditors & salary arrears detail balance error big fixed</li>
          <li>Cashflow verified limmitation add to dayEmployee & dayPump</li>
          <li>Restricted on Order No updates to only received and not submitted</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.5.1</h6>
          <dd>2023-11-24</dd>
        </div>
        <ul>
          <li>Data: Bulk Entries for Setup Added</li>
          <li>Mark Pump max read error fixed</li>
          <li>Income statment and extended bug fixes</li>
          <li>Balance Sheet and extended bug fixes</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.5.2</h6>
          <dd>2023-11-28</dd>
        </div>
        <ul>
          <li>Data: Double Entry invoice No Added</li>
          <li>Data: Inventory Orders auto generate invoice no on sales submission</li>
          <li>Government Order Value Added</li>
          <li>Government Order Value and sales compare field added to gov Order list</li>
          <li>Government Order Update set to customr accounts for all sales</li>
          <li>Income Statement detail print view fixes</li>
          <li>Customer Order detail print view fixes</li>
          <li>WHITE PAGE ON UPDATE FIXED</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.5.3</h6>
          <dd>2023-12-29</dd>
        </div>
        <ul>
          <li>Data: Subdomain wildcard supports (*.shed.lk) Added</li>
          <li>MUI Table exports added</li>
          <li>MUI Table Header Grouping Added</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.6.0</h6>
          <dd>2024-01-31</dd>
        </div>
        <ul>
          <li>Order Returns Product Lines separated as universal rule</li>
          <li>Data: Return product line model, controllers added</li>
          <li>Order Returns Updates on modules bug fixed</li>
          <li>Income Statement and detailed view profit reduction added on sales returns</li>
          <li>Balance sheet and detail view re-stock value fixed</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.6.1</h6>
          <dd>2024-02-05</dd>
        </div>
        <ul>
          <li>Card Payment Merge with Bank</li>
          <li>Card Payment Field Removed and merge with Bank</li>
          <li>Table default page size set to 100</li>
          <li>Data: Day Employee Card Payment Details Merge with Bank</li>
          <li>Data: Purchase type Tracking Added as Gas and Inventory</li>
          <li>Gas Purchase Returns will be able to returns with purchase returns</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.6.2</h6>
          <dd>2024-02-11</dd>
        </div>
        <ul>
          <li>Data: Purchase Return Productline Added</li>
          <li>Product Order Returns, Purchase Return added by product Type</li>
          <li>Stock value calculation with purchases, sales, sales return and purchase return</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.6.5</h6>
          <dd>2024-04-19</dd>
        </div>
        <ul>
          <li>Data: AccData Day Entries check controller Added</li>
          <li>Data: Cashflow verification check by compare AccData entry</li>
          <li>Data: Invoice no formated as max number invoice on Order controller </li>
          <li>Global Date set to next date of verified Cashflow</li>
          <li>Cashflow verification st</li>
          <li>Auto Update Global Date to next day on Cash flow Verification</li>
          <li>Dep current stock field set to read only</li>
          <li>First in first out method change to efficient method</li>
          <li>Active & non-active Employee, Customer, Supplier List</li>
          <li>Dashboard accounts card only for Admin</li>
          <li>Income and balancesheet visible to admin</li>
          <li>User role error on tanks and capacity</li>
          <li>Fuel accounts all entries table</li>
          <li>Balancesheet creditor, debts contact numbers added</li>
          <li>Dep delete only admin</li>
          <li>Fuel type Ref add to product</li>
          <li>Read-only dates on forms</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.6.9</h6>
          <dd>2024-04-26</dd>
        </div>
        <ul>

          <li>Data: Fuel Tank Type Formated with Ref of Fuel Type</li>
          <li>Data: 2 different type of Purhcase, Purchase Return Productline added for Fuel, Other Products</li>
          <li>Global Date Can change by Admin & User</li>
          <li>Date Set to Static Text for Forms</li>
          <li>Auto Calculate Liter and amount of Fuel Sales for Credit Customer</li>

        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.7.0</h6>
          <dd>2024-04-27</dd>
        </div>
        <ul>

          <li>NPM Packages Updated to latest</li>
          <li>Auto Operating System Color mode Detect (Light mode / Dark Mode)</li>

        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.7.1</h6>
          <dd>2024-04-29</dd>
        </div>
        <ul>

          <li>Tank Depth List 2000G Added</li>
          <li>Income Statement and Financial Statement not loaded on initial setup of system</li>
          <li>Logo Setup for Gas Station Type</li>
          <li>Menu Logo Setup</li>
          <li>Remove All Currency on Table</li>
          <li>Use Javascript currency Numberformat where it is required</li>
          
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.7.2</h6>
          <dd>2024-04-30</dd>
        </div>
        <ul>

          <li>Double Entry Accounts Employee List for Salary</li>
          <li>Sales Invoice No bug fix (2nd invoice no error)</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.7.3</h6>
          <dd>2024-05-03</dd>
        </div>
        <ul>
          <li>Data: Product Name, Product Code, Brand Name changed to Uppercase Format</li>
          <li>Data: Day Employee Data upgraded with Salary Field</li>
          <li>Data: Double Entry disable Acccounts Model</li>
          <li>Day Pump Restricted to submitted Fuel Employee accounts</li>
          <li>Fuel Employee Day entries move to new window</li>
          <li>Salary Payment Form added to Employee Fuel Accounts submission</li>
          <li>Day pump and Fuel Employee Accounts can be deleted on employee fuel summary submission page</li>
          <li>Sales form Product added table updated to add same products multiple times</li>
          <li>Tank Stock adjustment can be done by only admin</li>
          <li>Double Entry deletation can be done by User roles</li>
          <li>Loss of Fuel and Inventory updated</li>
          <li>Loss inventory and fuel can be track using Income Statement and balance sheet</li>
          <li>Cashflow Non Cash flow accounts details added with Name of Bank, Customer, Employee, Supplier</li>
          <li>Limit Some Accounts from double entry</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.7.4</h6>
          <dd>2024-05-04</dd>
        </div>
        <ul>
          <li>Backend Url Removed and hosted same url</li>
          <li>Data: Employee Admin role creation error fixed</li>
          <li>Initialization Setup updated</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.7.5</h6>
          <dd>2024-05-05</dd>
        </div>
        <ul>
          <li>Darkmode Background colour Changed</li>
          <li>Components Selection Inputfield none value update to 'null'</li>
          <li>Sidebar Quick menu Key press event moved to topbar</li>
          <li>Sidebar Quick menu 'Add Purchase' item added</li>
          <li>Cashflow Daily Entry check Card added</li>
          <li>Fuel Employee Accounts modified with Table default Page Size</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.7.6</h6>
          <dd>2024-05-07</dd>
        </div>
        <ul>
          <li>Data: Gas Station Name / Accounts Category / Accounting / Customer / Supplier / Supplier Company / Employee / Bank / Bank Account / Bank Branch Set to record as UpperCase Value</li>
          <li>Restricted not to perform any entry on all forms on verified cashflow dates</li>
          <li>Print Page function Removed on Table Page instend use Export Option</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.7.8</h6>
          <dd>2024-05-23</dd>
        </div>
        <ul>
          <li>Data: Purchase Return Product Type Added</li>
          <li>Selection Autocomplete InputField Added</li>
          <li>Data: All Select Input Change to Select AutoComplete input Type</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.7.9</h6>
          <dd>2024-05-28</dd>
        </div>
        <ul>
          <li>Select AutoComplete data transfer error fix</li>
          <li>Accounts Data type removed</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.8.0</h6>
          <dd>2024-05-29</dd>
        </div>
        <ul>
          <li>Cheque model data chqSupplier, chqAccountings Added for purpose of pending cheque release</li>
          <li>Salary Accounts in fuel Account removed</li>
          <li>Cheque related activities with double entries added on cheques Page</li>
          <li>Given cheques total show by bank account under bank component in balancesheet</li>
          <li>Product added with 1 product code</li>
          <li>Sidebar auto close on menu selection</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.8.1</h6>
          <dd>2024-06-05</dd>
        </div>
        <ul>
          <li>Data: Given Cheques data by Customer, Bank</li>
          <li>Received cheque double entry bug fixed</li>
          <li>Financial Statement update with given cheque details to supplier & customer</li>
          <li>Tank Check Implemented and Profit / loss inventory</li>
          <li>Quick Links Menu Updated with Cheque Item</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.8.2</h6>
          <dd>2024-06-08</dd>
        </div>
        <ul>
          <li>Double Entry Accounts show total amount of Bank, Customer, Supplier, Employee </li>
          <li>Quick Panel Added for easy Access</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v1.9.0</h6>
          <dd>2024-06-11</dd>
        </div>
        <ul>
          <li>Material React Table</li>
          <li>PDF Export with Render Value</li>
          <li>CSV Export with Render Value</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v2.0.0</h6>
          <dd>2024-06-18</dd>
        </div>
        <ul>
          <li>Material React Table</li>
          <li>Featured Table with many options</li>
          <li>PDF Export with Render Value</li>
          <li>CSV Export with Render Value</li>
          <li>Query URL Search</li>
          <li>Deletation function disabled for entities</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v2.0.1</h6>
          <dd>2024-06-19</dd>
        </div>
        <ul>
          <li>Material React Table Load action Added</li>
          <li>Visible State update on Customer, Supplier Modules</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v2.0.2</h6>
          <dd>2024-06-22</dd>
        </div>
        <ul>
          <li>Quantity Negative value restricted</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v2.0.5</h6>
          <dd>2024-07-12</dd>
        </div>
        <ul>
          <li>Customer, Supplier, Bank Cheque Tranfers added to prespective pages</li>
          <li>Cheques Receives from Supplier Added</li>
          <li>Purchase, Sales receipts visible on accounts pages</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v2.0.6</h6>
          <dd>2024-07-19</dd>
        </div>
        <ul>
          <li>Data: Routes Changes get to Post</li>
          <li>Routes Changes get to Post</li>
          <li>Minor Bug fixes</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v2.0.8</h6>
          <dd>2024-07-19</dd>
        </div>
        <ul>
          <li>data: Warehouse stock access restriction changed</li>
          <li>Directory name Change to Capitalize 2.0.7</li>
          <li>Minor changes on TankCard Purchase URL change, Search Query Added on Purchase Page</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v2.0.9</h6>
          <dd>2024-08-01</dd>
        </div>
        <ul>
          <li>E Receipts Added</li>
          <li>Sales and Purchase Receipts modified</li>
          <li>Data: Sales Related data restriction removed due to E Receipts</li>
        </ul>
      </div>

      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v2.1.0</h6>
          <dd>2024-08-10</dd>
        </div>
        <ul>
          <li>Whatsapp API Testing</li>
          <li>SMS Service Added</li>
          <li>Tank Check Updated</li>
          <li>Backend Code modification</li>
        </ul>
      </div>


      <div>
        <div className='d-flex align-items-baseline gap-2'>
          <h6>v2.1.1</h6>
          <dd>2024-08-11</dd>
        </div>
        <ul>
          <li>Tank Check Bug Fix</li>
          <li>E-Receipt Receiver name, Phone Number Configuration</li>
        </ul>
      </div>


    </div>
  )
}
