import React, {useState, useEffect, useRef} from 'react'
import { toPng } from "html-to-image";
import QRCode from 'qrcode';

export default function QrCodeGenerator(props) {
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        generateQrCode()
    },[])

    var opts = {
      errorCorrectionLevel: 'H',
      version: 2,
      quality: 1,
      margin: 0,
      width: props.size || 150
    }

    const generateQrCode = async () => {
        try {
          const response = await QRCode.toDataURL(props.qrText, opts);
          setImageUrl(response);
        }catch (error) {
          console.log(error);
        }
    }

    const elementRef = useRef(null);

    const htmlToImageConvert = () => {
      toPng(elementRef.current, { cacheBust: false })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = `${props.qrText}.png`;
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    };

  return (
    <div>
        {imageUrl ? (
          <div className='bg-light p-3 ' ref={elementRef} onClick={htmlToImageConvert}>
            <img src={imageUrl} alt="img"/>
            {props?.hide ? '' : <h4 className='text-center fw-bold text-dark pt-1' >{props.qrText}</h4>}
          </div>
          ) : null
        }
    </div>
  )
}
