import React, {useState,useEffect} from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment';

import { useAuth } from '../../../context/auth'
import { useGdate } from '../../../context/date'

import {currency} from '../../../Functions/NoFomats'

import {Add, DeleteForever} from '@mui/icons-material/'

import MaterialTable from '../../../Components/Table/MaterialTable'
import ModalBasic from '../../../Components/Modal/ModalBasic'

import UpdateAttendance from './UpdateAttendance';

import AccountsForm from '../../Accounts/DoubleEntires/AccountsForm'

export default function EmpAttendanceDetails() {
    const {id} = useParams();
    const [searchParams] = useSearchParams()

    const [auth] = useAuth();
    const [gdate] = useGdate();

    const [listAttendance, setListAttendance] = useState('')
    const [salaryArrearsCredit, setSalaryArrearsCredit] = useState('')
    const [salaryArrearsDebit, setSalaryArrearsDebit] = useState('')
    const [salaryArrearsAccounts, setSalaryArrearsAccounts] = useState('')
    //Model
    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState(null)

    const [visibleDelete, setVisibleDelete] = useState(false)
    const [selectedDelete, setSelectedDelete] = useState(null)

    const [visibleCredit, setVisibleCredit] = useState(false)
    const [visibleDebit, setVisibleDebit] = useState(false)

    const [submitDisable, setSubmitDisable] = useState(false)

    useEffect(() => {
        loadAttendance()
        loadSalary()
    },[])

    const loadAttendance = async() => {
        try {
            const {data} = await Axios.post(`/empattendances`, {employee: id, date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
            if (data?.error) {
                toast.error(data.error)
            } else {
                setListAttendance(data)
                console.log(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const loadSalary = async() => {
        try {
            const {data} = await Axios.post(`/accdatas`,{"empStatus.status": false, "empStatus.employee": id, accounting: '6515831c1a89e4fc7010012a'})
            if(data?.error) {
                toast.error(data.error)
            } else {
                setSalaryArrearsCredit(data?.filter((i) => i?.type === 'credit') ? data?.filter((i) => i?.type === 'credit').reduce((a,v) => a = a + v.amount, 0) : '0')
                setSalaryArrearsDebit(data?.filter((i) => i?.type === 'debit') ? data?.filter((i) => i?.type === 'debit')?.reduce((a,v) => a = a + v.amount, 0) : '0')
                setSalaryArrearsAccounts(data.reduce((a,v) => a = a + v.amount, 0) ? data.reduce((a,v) => a = a + v.amount, 0) : '0')
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const handleDeleteAttendance = async(selected) => {
        try {
            await setSubmitDisable(true)
            const {data} = await Axios.delete(`/empattendance/${selected?._id}`)
            if (data?.error) {
                toast.error(data.error)
                setSubmitDisable(false)
            } else {
                const {data} = await Axios.post(`/accdatas/removemany`, {"refId": `epa-${moment(selected?.date).format("YYYYMMDDHH")}-${selected?.employee?._id}-${selected?.payment?.amount}` , "empStatus.status": false, date: selected?.date})
                if (data?.error) {
                    toast.error(data?.error)
                    setSubmitDisable(false)
                } else {
                    toast.success('Attendance Deleted')
                    window.location.reload()
                }
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const columns = [
        { accessorKey: 'date', header: 'Date'},
        { accessorKey: `${listAttendance[0]?.payment?.type}`, header: `WORK ${listAttendance[0]?.payment?.type ? listAttendance[0]?.payment?.type?.toUpperCase() : ''}`},
        { accessorKey: 'salary', header: 'Salary', Cell: ({row: {original}}) => currency(original.salary)},
    ]

    const rowAction = ({row}) => [
        <div className='d-flex justify-content-center'>
            <DeleteForever
                onClick={() => {
                setVisibleDelete(true)
                setSelectedDelete(row.original)
                }}
            />
        </div>
    ]

    return (
        <div className='row justify-content-center'>
            <div className='col-xl-10 col-md-12 mb-3'>
                <div className='hstack gap-3 mb-2'>
                    <button className = 'btn btn-outline-danger ms-auto' onClick={() => {setVisibleDebit(true)}}><Add/> Debit</button> 
                    <button className = 'btn btn-outline-success' onClick={() => {setVisibleCredit(true)}}><Add/> Credit</button>  
                </div>
                <MaterialTable
                    title = {'Attendaces details of ' + searchParams.get('name').toUpperCase() + ' | ' + gdate?.reportStartDate + ' - ' + gdate?.reportEndDate}
                    col = {columns}
                    row = {listAttendance}
                />

                <div className='d-flex justify-content-between pt-3'>
                    <dd>Total Salary</dd>
                    <dd>{new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(salaryArrearsCredit ? salaryArrearsCredit : '0')}</dd>              
                </div>

                <div className='d-flex justify-content-between'>
                    <dd>Total Paid Salary</dd>
                    <dd>{new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(salaryArrearsDebit ? salaryArrearsDebit : '0')}</dd>              
                </div>
                <hr/>
                <div className='d-flex justify-content-between'>
                    <dd className='fw-bold'>Total Salary Arrears</dd>
                    <dd className='fw-bold' style={{color: salaryArrearsAccounts > 0 ? 'red' : 'green'}}>{new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(salaryArrearsAccounts ? salaryArrearsAccounts : '0')}</dd>              
                </div>

                <ModalBasic
                open = {visible}
                onClose = {()=> {
                setVisible(false)
                }}
                content = {<UpdateAttendance data = {selected}/>}
                />

                <ModalBasic
                    open = {visibleDelete}
                    onClose = {()=> {
                    setVisibleDelete(false)
                    }}
                    content = {
                        auth?.employee?.role === 0 ?
                        <div className='d-flex justify-content-between'>
                            <p>Are You sure want to?</p>
                            <button className = 'btn btn-outline-danger' disabled={submitDisable} onClick={() => {
                                handleDeleteAttendance(selectedDelete)
                            }}>Delete
                            </button>
                        </div>
                        :
                        <div className='d-flex justify-content-center w-100'>
                            <p>You don't have Permission to Delete</p>
                        </div>
                    }
                />

                <ModalBasic
                    width = '80%'
                    open = {visibleDebit}
                    onClose = {()=> {
                    setVisibleDebit(false)
                    }}
                    content = {<AccountsForm data= {{inputAccounting: '6515831c1a89e4fc7010012a'}}/>}
                />

                <ModalBasic
                    width = '80%'
                    open = {visibleCredit}
                    onClose = {()=> {
                    setVisibleCredit(false)
                    }}
                    content = {<AccountsForm data= {{inputAccounting2: '6515831c1a89e4fc7010012a'}}/>}
                />
            </div>
        </div>
    )
}
