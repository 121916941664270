import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { SettingsProvider } from './context/settings';
import { AuthProvider } from './context/auth'
import { DkProvider } from './context/dk';
import { GdateProvider } from './context/date';
import {SearchProvider} from './context/search'
import { MenuTypeProvider } from './context/menu';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
        <SettingsProvider>
                <AuthProvider>
                        <DkProvider>
                                <GdateProvider>
                                        <SearchProvider>
                                                <MenuTypeProvider>
                                                        <App />
                                                </MenuTypeProvider>
                                        </SearchProvider>
                                </GdateProvider>
                        </DkProvider>
                </AuthProvider>
        </SettingsProvider>
</React.StrictMode>
);

reportWebVitals();
