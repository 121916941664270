import React, {useState} from 'react'
import { NavLink } from 'react-router-dom';

import { useAuth } from '../../../context/auth';

import {Menu} from 'antd';

import { Dashboard, People, AccountBalanceWallet, Home, Person, Settings} from '@mui/icons-material/';

export default function MenuGeneral(props) {

  const [auth] = useAuth();

  const [current, setCurrent] = useState('');

  const empItems = [
    {
      label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard">Home</NavLink>),
      key: 'home',
      icon: <Home/>
    },
];

  const userItems = [
    {
      label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard">Home</NavLink>),
      key: 'home',
      icon: <Home/>
    },
    {
      label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user">Dashboard</NavLink>),
      key: 'dashboard',
      icon: <Dashboard/>
    },

    //Employee
    {
      label: 'Employees',
      key: 'employee',
      icon: <People/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/employee/card">Fuel Accounts</NavLink>),
          key: 'employee-card',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/employee/listdayemployee">All Day Employees</NavLink>),
          key: 'day-employee',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/employee/attendance">Attendances</NavLink>),
          key: 'employee-attendance',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/employee/list">All Employees</NavLink>),
          key: 'employee-List',
        },
      ]
    },
    
    //Customers
    {
      label: 'Customers',
      key: 'customers',
      icon: <Person/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/customer/List">All Customers</NavLink>),
          key: 'customer-List',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/customers/list-govorders">Government Orders</NavLink>),
          key: 'customer-govOrders-List',
        },
      ]
    },

    //Suppliers
    {
      label: 'Suppliers',
      key: 'supliers',
      icon: <Person/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/supplier/List">All Suppliers</NavLink>),
          key: 'supplier-List',
        },
      ]
    },

    //Accounts
    {
      label: 'Accounts',
      key: 'accounts',
      icon: <AccountBalanceWallet/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to={'dashboard/user/accounts/entry'}>Double Entries</NavLink>),
          key: 'double-entry',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to={'dashboard/user/accounts/daily-cashflow'}>Daily Cash Flow</NavLink>),
          key: 'daily-cash-flow',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/accounts/bank-accounts">All Bank Accounts</NavLink>),
          key: 'accounts-bankAccounts',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/accounts/cheques">All Cheques</NavLink>),
          key: 'accounts-cheques',
        },
      ]
    },
  ];

  const adminItems = [
    {
      label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard">Home</NavLink>),
      key: 'home',
      icon: <Home/>
    },
    {
      label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user">Dashboard</NavLink>),
      key: 'dashboard',
      icon: <Dashboard/>
    },

    //Employee
    {
      label: 'Employees',
      key: 'employee',
      icon: <People/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/employee/card">Fuel Accounts</NavLink>),
          key: 'employee-card',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/employee/listdayemployee">All Day Employee</NavLink>),
          key: 'day-employee',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/employee/attendance">Attendances</NavLink>),
          key: 'employee-attendance',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/employee/List">All Employees</NavLink>),
          key: 'employee-List',
        },
      ]
    },

    //Customers
    {
      label: 'Customers',
      key: 'customers',
      icon: <Person/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/customer/List">All Customers</NavLink>),
          key: 'customer-List',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/customers/list-govorders">Government Orders</NavLink>),
          key: 'customer-govOrders-List',
        },
      ]
    },

    //Suppliers
    {
      label: 'Suppliers',
      key: 'supliers',
      icon: <Person/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/supplier/List">All Suppliers</NavLink>),
          key: 'supplier-List',
        },
      ]
    },

    //Accounts
    {
      label: 'Accounts',
      key: 'accounts',
      icon: <AccountBalanceWallet/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to='dashboard/user/accounts/entry'>Double Entries</NavLink>),
          key: 'double-entry',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to='dashboard/user/accounts/daily-cashflow'>Daily Cash Flow</NavLink>),
          key: 'daily-cash-flow',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/accounts/bank-accounts">All Bank Accounts</NavLink>),
          key: 'accounts-bankAccounts',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/accounts/cheques">All Cheques</NavLink>),
          key: 'accounts-cheques',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/admin/accounts/list">All Accounts Type</NavLink>),
          key: 'accounts-List',
        },

      ]
    },

    //Settings
    {
      label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/admin/settings">Settings</NavLink>),
      key: 'settings',
      icon: <Settings/>
    },
  ];

  const sb = () => {
    switch (true) {
      case (auth?.employee?.role === 0):
        return adminItems
      case (auth?.employee?.role === 1):
        return userItems
        case (auth?.employee?.role === 2):
          return empItems
    }
  }

  props.menuSelection({menuItem: current})
  
  return (
    <Menu theme={'dark'}  selectedKeys={current} onClick={(e) => setCurrent(e.key)} mode="inline" items={sb()} />
  )
}
