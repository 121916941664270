import React, {useState, useEffect} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import { useAuth } from '../../context/auth';
import { useGdate } from '../../context/date'

import { Text, SelectAutoComplete, SwitchInput } from '../../Components/Inputs/InputFields';
import {Typography} from '@mui/material/'
import moment from 'moment';

export default function PumpDetails(props) {
  const pumpData = props?.pump

  const [auth] = useAuth()
  const [gdate] = useGdate()

  const [activeEmployees, setActiveEmployees] = useState('')
  const [ListEmployee, setListEmployee] = useState('')
  const [listPurchaseDetails, setListPurchaseDetails] = useState('')
  //Mark Date should be morethan cashflow verified date
  const [checkCashflow, setCheckCashflow] = useState(false)

  const [pump, setPump] = useState(pumpData)
  const [date] = useState(gdate?.date)
  const [employee, setEmployee] = useState('')
  const [startReading, setStartReading] = useState('0')
  const [endReading, setEndReading] = useState('0')
  const [testing, setTesting] = useState('')
  const [testRefill, setTestRefill] = useState(true)
  const [expenseL, setExpenseL] = useState('')
  const [drawingsL, setDrawingsL] = useState('')
  const [soldQuantity, setSoldQuantity] = useState()
  const [price, setPrice] = useState('')
  const [inCome, setInCome] = useState()

  const [expense, setExpense] = useState()
  const [drawings, setDrawings] = useState()

  const [FIFOList, setFIFOList] = useState('')

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
      loadEmployees()
      loadPurchaseDetails()
      setCheckCashflow(() => moment(gdate?.cashflowDate) > moment(date) ? true : false)
  },[]);

  useEffect(() => {
    loadDayEmployees()
  },[activeEmployees]);

  useEffect(() => {
    setPump(pumpData)
    setPrice(Number(pumpData?.wareHouseStock?.productCode?.price))
    loadLastReading()
  },[pumpData]);

  useEffect(() => {
    if (pumpData?.wareHouseStock?.stock < (endReading - startReading)) {
      toast.error(`${pumpData?.wareHouseStock?.stock} L  Stock Available`)
      setEndReading( (Number(startReading) + Number(pumpData?.wareHouseStock?.stock))?.toFixed(3) )
    } else if (endReading - startReading >= 0) {
      FIFO(listPurchaseDetails)
    }
  }, [startReading, endReading])
    
  //Calculate Sold Quantity
  useEffect(() => {
    setSoldQuantity( (Number(endReading) - (Number(startReading) + Number(testing) + Number(expenseL) + Number(drawingsL))).toFixed(3) )
  }, [startReading, endReading, testing, expenseL, drawingsL])
    
  // Calculate Total Income
  useEffect(() => {
    setInCome(Number(price) * Number(soldQuantity))
  }, [soldQuantity, price])

  //Calculate Total expense, Drawings
  useEffect(() => {
    setExpense(FIFOList[0]?.uc * Number(expenseL))
  }, [expenseL, FIFOList])

  useEffect(() => {
    setDrawings(FIFOList[0]?.uc * Number(drawingsL))
  }, [drawingsL, FIFOList])

  const loadLastReading = async() => {
    try {
      const {data} = await Axios.post(`/daypump/read-last`, {"pump._id": pumpData?._id})
      if (data?.error){
        toast.error(data.error)
      } else {
        console.log(data)
        setStartReading(data ? data?.endReading?.toFixed(3) : 0)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadEmployees = async() => {
    try {
      const {data} = await Axios.post('/employees/filter', {role: { $gt: auth?.employee?.role }, active: true } )
      if (data?.error){
        toast.error(data?.error)
      } else{
        setActiveEmployees(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  //Check Employee Submission
  const loadDayEmployees = async() => {
    try {
        const {data} = await Axios.post(`/dayemployees`, {date: gdate?.date})
        if(data?.error){
            toast.error(data.error)
        } else {
          setListEmployee(activeEmployees && activeEmployees?.filter((e) => data?.every((d) => d?.employee?._id !== e?._id)))
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  const loadPurchaseDetails = async() => {
    try {
        const {data} = await Axios.post(`/purchaseproductlines`, {productCode: pumpData?.wareHouseStock?.productCode?._id})
        if (data?.error) {
            toast.error(data.error)
        } else {
          setListPurchaseDetails(data?.reverse()?.filter((i) => i?.quantity > 0))
        }
    } catch (err) {
      toast.error("Something went wrong, Try Again!")
      console.log(err)
    }
  }

  const FIFO = async(productLines) => {
    // SBS - Stock Before Sales
    // SAS - Stock After Sales
    // PBSIndex - Purchase Index of Before Sales
    // PASIndex - Purchase Index of After Sales
    try {
      var SBS = pumpData?.wareHouseStock?.stock
      var SAS = pumpData?.wareHouseStock?.stock - (endReading-startReading)
      var sumBSQty = 0
      var sumASQty = 0
      var PBSindex = ''
      var PASindex = ''
      
      //FIND SAS Index
      for (var i=0; i<productLines?.length; i++) {
        sumASQty += productLines[i]?.quantity
        if (sumASQty >= SAS) {
          PASindex = i
          SAS -= (sumASQty - productLines[i]?.quantity)
          break
        }
      }
      
      // FIND SBS Index
      for (var s=0; s<productLines?.length; s++) {
        sumBSQty += productLines[s]?.quantity
        if (sumBSQty >= SBS) {
          PBSindex = s
          SBS = sumBSQty - SBS
          break
        }
      }
      
      const arr = []
      var quantity = Number(endReading - startReading)

      quantity > 0 && await productLines?.slice(PASindex, PBSindex + 1)?.reverse()?.map((pl, index) => {
        const uc = (pl?.itemPrice / pl?.quantity)?.toFixed(2)
        // First Element of Array
        if(index === 0) {
          if ((pl?.quantity - SBS) >= quantity) {
            arr.push({uc, qty: quantity, check: "Same"})
          } else {
            arr.push({uc, qty: Number(pl?.quantity - SBS), check: `${index}`})
          }
          // Last Element of Array
        } else if (index === (PBSindex - PASindex)) {
          arr.push({uc, qty: Number(pl?.quantity - SAS), check: `last`})
        } else {
          // Balance Elements of Array
          arr.push({uc, qty: Number(pl?.quantity), check: `${index}`})
        }
      })
      var groupedArray = [];
      await arr?.reduce(function(a, v) {
        if (!a[v.uc]) {
          a[v.uc] = { uc: v.uc, qty: 0};
          groupedArray.push(a[v.uc])
        }
        a[v.uc].qty += v.qty;
        return a;
      }, {});

      var result = [];
      var sRead = 0
      await groupedArray?.map((i, index) => {
        if (index === 0) {
          result.push({uc: i?.uc, qty: i?.qty, startRead: (startReading), endRead: (Number(startReading) + Number(i?.qty))?.toFixed(3) })
          sRead = Number(startReading) + Number(i?.qty)
        } else {
          result.push({uc: i?.uc, qty: i?.qty, startRead: (sRead)?.toFixed(3), endRead: Number(sRead + i?.qty)?.toFixed(3) })
        }
      })

      setFIFOList(result)

    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    try {
      await setSubmitDisable(true)
      if (checkCashflow) {
        toast.error(`Already ${date} Cashflow Verified`)
        setSubmitDisable(false)
      } else if (!employee) {
        toast.error("Employee is Required")
        setSubmitDisable(false)

      } else if (Number(endReading - startReading) - Number(testing + expenseL + drawingsL) <= 0) {
        toast.error("Enter Correct Reading")
        setEndReading((Number(startReading) + Number(testing + expenseL + drawingsL))?.toFixed(3))
        setSubmitDisable(false)

      } else {
        const {data} = await Axios.post(`/daypump-bulk`, FIFOList && FIFOList.map((i,index) => {
          return {pump, date, employee, startReading: Number(i?.startRead)?.toFixed(3), endReading: Number(i?.endRead)?.toFixed(3), testing: Number(index === 0 ? testing : 0)?.toFixed(3), testRefill, expenseL: Number(index === 0 ? expenseL : 0)?.toFixed(3), drawingsL: Number(index === 0 ? drawingsL : 0)?.toFixed(3), soldQuantity: (i?.qty - (index === 0 ? (testing + expenseL + drawingsL) : 0))?.toFixed(3), price, inCome: Number(price * (i?.qty - (index === 0 ? (testing + expenseL + drawingsL) : 0))?.toFixed(3))?.toFixed(2), unitCost: i?.uc}
        }))
        if(data?.error){
          toast.error(data.error)
          setSubmitDisable(false)
        } else {
          const insertedId = data?.insertedIds[0]
          try {
            const {data} = await Axios.put(`/warehousestock/${pumpData?.wareHouseStock?._id}`, {
              $inc: { 'stock':  -((Number(soldQuantity) + Number(expenseL) + Number(drawingsL) + Number(testRefill === true ? 0 : testing))?.toFixed(3)), 'sold': +((Number(soldQuantity) + Number(expenseL) + Number(drawingsL) + Number(testRefill === true ? 0 : testing))?.toFixed(3))}
            });
            if (data?.error){
              toast.error(data.error)
              setSubmitDisable(false)
            } else {
              if (expenseL > 0 || drawingsL > 0) {
                const {data} = await Axios.post(`/accdatabulk`, 
                  [
                    {date, empStatus: {status: false}, detail: {description: `Pump ${pumpData?.name} Expense: ${expenseL}L Drawings: ${drawingsL}L`}, amount : -Number(Number(expense) + Number(drawings))?.toFixed(2), accounting: '63ecfbe55b36ecf83d9dd4a3', refId: `dp-${insertedId}-${date}-${employee?._id}`},
                    {date, empStatus: {status: false}, detail: {description: `Pump ${pumpData?.name} Expense: ${expenseL}L`}, amount : expense > 0 ? Number(expense)?.toFixed(2) : 0, accounting: '63e31b29f029ceab39dcd291', refId: `dp-${insertedId}-${date}-${employee?._id}`},
                    {date, empStatus: {status: false}, detail: {description: `Pump ${pumpData?.name} Drawings: ${drawingsL}L`}, amount : -Number(drawings)?.toFixed(2), accounting: '63e31af4f029ceab39dcd28f', refId: `dp-${insertedId}-${date}-${employee?._id}`},
                  ]
                )
                if (data?.error) {
                  toast.error(data.error)
                  setSubmitDisable(false)
                } else {
                  toast.success('Pump Records, Accounts Saved')
                  window.location.reload()
                }
              } else {
                toast.success('Pump Records Saved')
                window.location.reload()
              }
            }
          } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
            setSubmitDisable(false)
          }
        }
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  return (
    <div className='p-3'>
      <h5 className='text-center'>Pump - {pumpData?.name}</h5>
      <form onSubmit={handleSubmit}>
        <dd className='text-center'>{date}</dd>

        {auth?.employee?.role < 2 &&
          <SelectAutoComplete
            label = "Employee"
            optionsList={ListEmployee}
            option = {(i) => i?.name}
            value={employee?._id}
            onChange={(event, value) => {
              setEmployee(value)
            }}
          />
        }

        <Text
          label="Start Reading" 
          value = {startReading}
          type = "number"
          onChange={(e) => setStartReading(Number(e.target.value))}
          readOnly = {auth?.employee?.role === 0 ? 'false' : 'true'}
        />

        <Text
          label="End Reading" 
          value = {endReading}
          type = "number"
          onChange={(e) => setEndReading(Number(e.target.value))}
        />

        <Text
          label="Tested Litters" 
          value = {testing}
          type = "number"
          onChange={(e) => setTesting(Number(e.target.value))}
          end = "L"
        />
        <dd className='text-center fw-bold mb-0'>Test Fuel Refill to Tank</dd>
        <SwitchInput
          startLabel = "No"
          endLabel = "Yes"
          color = 'success'
          checked = {testRefill}
          onChange = {(e) => {
            setTestRefill(preVal => !preVal)
          }}
        />

        <Text
          label="Expense Litters" 
          value = {expenseL}
          type = "number"
          onChange={(e) => setExpenseL(Number(e.target.value))}
          end = "L"
        />

        <Text
          label="Drawings Litters" 
          value = {drawingsL}
          type = "number"
          onChange={(e) => setDrawingsL(Number(e.target.value))}
          end = "L"
        />

        <Text
          label="Rate" 
          value = {price}
          readOnly = "true"
          end = "LKR"
        />

        <Text
          label="Sold Litters" 
          value = {soldQuantity ? `${soldQuantity}` : ''}
          readOnly = "true"
          end = "L"

        />

        <div className='p-1'>
            <h5 className='row justify-content-center text-center'>Total Income</h5>
            <Typography className='row justify-content-center fw-bold' variant='h5' color='success.main'>{new Intl.NumberFormat().format(inCome ? inCome : 0)}</Typography>
        </div>

        <div className="d-grid">
          <button type='submit' className='btn btn-primary mt-3' disabled={submitDisable || (endReading - startReading < 0) || checkCashflow}>SUBMIT</button>
          {checkCashflow && <dd className='text-center fs-6 fw-bold text-danger mt-2'>Cashflow Verified on Selected date</dd>}
        </div>
      </form> 
    </div>
  )
}