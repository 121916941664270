import React, {useState,useEffect} from 'react'
import { useNavigate, createSearchParams } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment';

import { useAuth } from '../../../context/auth';
import { useGdate } from '../../../context/date';

import {currency} from '../../../Functions/NoFomats'

import {Check, Clear, Visibility, Help} from '@mui/icons-material/'

import {Text, SelectAutoComplete} from '../../../Components/Inputs/InputFields'
import MaterialTable from '../../../Components/Table/MaterialTable'
import ModalBasic from '../../../Components/Modal/ModalBasic'

export default function Attendance() {
  const navigate = useNavigate()

  const [auth] = useAuth()
  const [gdate] = useGdate()

  const type = ['hours', 'day'] 

  const [listEmpAttendances, setListEmpAttendances] = useState('')

  const [paymentType, setPaymentType] = useState()
  const [value, setValue] = useState('')
  const [date] = useState(gdate?.date)

  const [selectedRows, setSelectedRows] = useState([])

  const [visible, setVisible] = useState(false)
  const [payInfoModel, setPayInfoModel] = useState(false)

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    loadEmpAttendances()
  },[])

  useEffect(() => {
    loadEmpAttendances(paymentType)
    if (paymentType === 'hours') {
      setValue(8)
    } else if (paymentType === 'day') {
      setValue(1)
    }
  }, [paymentType])
  

  const loadEmpAttendances = async() => {
      try {
        const {data} = await Axios.post('/employees/attendance', [{role: { $gte: auth?.employee?.role } , "payment.type": paymentType}, {date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}}])
        if(data?.error){
            toast.error(data.error)
        } else {
            setListEmpAttendances(data?.filter((i) => i?.role > 0)?.map((a) => {
              const salary = a?.attendances?.reduce((a,v) => a = a + v?.salary, 0)
              const salaryArrears = (a.salaryArrears.length > 0 ? a?.salaryArrears[0]?.balanceArrears : 0)
              switch (a?.payment?.type) {
                case 'hours':
                  return ({...a , salary, salaryArrears, works: a?.attendances?.reduce((a,v) => a = a + (v?.hours ? v?.hours : 0), 0) + ' H'})
                case 'day':
                  return ({...a , salary, salaryArrears, works: a?.attendances?.reduce((a,v) => a = a + (v?.day ? v?.day : 0), 0) + ' D'})
                case 'month':
                  return ({...a , salary, salaryArrears, works: a?.attendances?.reduce((a,v) => a = a + (v?.month ? v?.month : 0), 0) + ' M'})
                default:
                  return ({...a , salary, salaryArrears, works: ''})
              }
            }))}
      } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
      }
  }

  var getDaysArray = (startDate, endDate) => {
    for (var a=[], d = new Date(startDate); d <= new Date(endDate); d.setDate(d.getDate()+1) )
      { 
        a.push(new Date(d).toISOString()); 
      }
    return a;
  };

  const dateList = getDaysArray(gdate?.reportStartDate,gdate?.reportEndDate);

  const dates = dateList?.map((i) => {
    return {accessorKey: `date ${moment(i).format("YYYY-MM-DD")}`, header: `${moment(i).format("MM-DD")}`, size: 55, Cell:(p) => p.row.attendances?.find((a) => a?.date === moment(i).format("YYYY-MM-DD")) ? <Check color='success'/> : <Clear color='error'/>, valueGetter:(p) => p.row.attendances?.find((a) => a?.date === moment(i).format("YYYY-MM-DD")) ? '1' : '0', filterable: false, sortable: false }
  })

  const getSelectedRows = (data) => {
    data.length !== selectedRows.length && setSelectedRows(data?.map(d => d?.original))
  }

  const handleMarkAttendance = async() => {
    try {
      await setSubmitDisable(true)
      if (selectedRows.length > 0 ) {
        if (moment(date) >= moment(gdate.cashflowDate)) {
          const {data} = await Axios.post(`/empattendance`, selectedRows?.map((i) => {
            switch (paymentType) {
              case 'hours':
                return {employee: i?._id, date, hours: value, payment: i?.payment, salary: Number(i?.payment?.amount * value).toFixed(2)}
              case 'day':
                return {employee: i?._id, date, day: value, payment: i?.payment, salary: Number(i?.payment?.amount * value).toFixed(2)}
              case 'month':
                return {employee: i?._id, date, month: value, payment: i?.payment, salary: Number(i?.payment?.amount * value).toFixed(2)}
              default:
                return {employee: i?._id, date, payment: i?.payment, salary: 0}
            }
          }))
          if (data?.error) {
            toast.error(data.error)
            setSubmitDisable(false)
          } else {
            if (paymentType && value > 0) {
              var allEntries = []
              await selectedRows?.map((i) => {
                allEntries.push(
                  {date, empStatus: {status: false, employee: i?._id}, detail: {description: `${i?.name} Salary Arrears ${value} ${i?.payment?.type}`}, amount: Number(i?.payment?.amount * value).toFixed(2), accounting: '6515831c1a89e4fc7010012a', refId : `epa-${moment(date).format("YYYYMMDDHH")}-${i?._id}-${i?.payment?.amount}`},
                  {date, empStatus: {status: false, employee: i?._id}, detail: {description: `${i?.name} Salary ${value} ${i?.payment?.type}`}, amount: Number(i?.payment?.amount * value).toFixed(2), accounting: '64cb525518dca625f12ce5bf', refId : `epa-${moment(date).format("YYYYMMDDHH")}-${i?._id}-${i?.payment?.amount}`}
                )
              })
              const {data} = await Axios.post(`/accdatabulk`, allEntries)
              if (data?.error) {
                toast.error(data.error)
                setSubmitDisable(false)
              } else {
                toast.success(`(${data?.insertedCount / 2}) Attendances Marked with Salary`)
                window.location.reload()
              }
            } else {
                toast.success(`${data?.insertedCount} Only Attendances Marked`)
                window.location.reload()
            }
    
          }
        } else {
          toast.error("Cashflow verified on selected date")
          setSubmitDisable(false)
        }
      } else {
        toast.error("Atleast 1 Employee should be selected")
        setSubmitDisable(false)
      }

    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const columns = [
    { accessorKey: 'name', header: 'Name', size: 100},
    { accessorKey: 'payment.type', header: 'Pay Type', size: 100, Cell: ({row: {original}}) => original.payment?.type?.toUpperCase()},
    // { accessorKey: 'attendance.length', header: 'Att', size: 80, Cell: ({row: {original}}) => original.attendances?.length},
    { accessorKey: 'works', header: 'Works', size: 100},
    { accessorKey: 'salary', header: 'Salary', size: 100, Cell: ({row: {original}}) => currency(original.salary), export: (i) => currency(i)},
    { accessorKey: 'salaryArrears', header: 'Arrears', size: 100, Cell: ({row: {original}}) => currency(original.salaryArrears), export: (i) => currency(i)},
    ...dates,
  ]

  const rowAction = ({row}) => [
    <div className='d-flex justify-content-center'>
      <Visibility
        onClick={() => {
          navigate({
            pathname: `/dashboard/user/employee/attendance/${row.original?._id}`,
            search: `?${createSearchParams({
              name: row.original?.name
            })}`
          })
        }}
      />
    </div>
  ]

  return (
    <div className='row justify-content-center'>
      <dd className='text-center'>{date}</dd>
      <div className='col-12 mb-3'>
        <div className='row mb-2 justify-content-center'>
          <div className='col-md-3 col-sm-6 col-12'>
            {/* Salary Type Field, with selection of field leads to mark salary arrears also */}
            <div className='d-flex align-items-center gap-2'>

              <SelectAutoComplete
                label = "Salary Type"
                optionsList={type}
                option = {(i) => i}
                value={paymentType}
                onChange={(event, value) => {
                    setPaymentType(value)
                }}
              />
              <Help fontSize='small' onClick={() => {setPayInfoModel(true)}}/>
            </div>
          </div>
          
          {/* Value of Day or hours */}
          {paymentType && <div className='col-lg-2 col-md-3 col-sm-6 col-12'>
            <Text
              label="hours / day"
              value = {value}
              onChange={(e) => setValue(e.target.value)}
              focus = 'true'
            />
          </div>}

          {/* Mark Attendance Button with or without payment arrears*/}
          <div className='col-lg-2 col-md-3 col-sm-6 col-12'>
            <button className = 'btn btn-outline-success w-100' disabled={submitDisable} onClick={() => {
              if (paymentType) {
                handleMarkAttendance()
              } else {
                setVisible(true)
              }
            }}><Check/> ATTENDANCE</button>
          </div>
        </div>

        <MaterialTable
          title = {'Employee Attendances | ' + gdate?.reportStartDate + ' - ' + gdate?.reportEndDate}
          col = {columns}
          columnPinningLeft = {['name', 'payment.type', 'works', 'salary', 'salaryArrears']}
          row = {listEmpAttendances}
          rowAction = {rowAction}
          getSelectedRows = {getSelectedRows}
        />
      </div>
      
      <ModalBasic
        open = {payInfoModel}
        onClose = {()=> {
        setPayInfoModel(false)
        }}
        content = {
          <div>
            <h6 className='text-center fw-bold'>1. Mark Only Attendance</h6>
            <div>
              Follow Steps:
              <ol>
                <li>Select checkbox of Employees</li>
                <li>Then Click on "ATTENDACE" button</li>
                <li>A Popup will Show</li>
                <li>Click on 'SUBMIT' Button</li>
              </ol>
            </div>
            <hr/>
            <h6 className='text-center fw-bold'>2. Mark Attendance with Payment</h6>
            <div>
              Follow Steps:
              <ol>
                <li>Select Salary Type</li>
                <li>Then Select checkbox of Employees</li>
                <li>Lastly Click on "ATTENDACE" button</li>
              </ol>
              <div className='d-flex justify-content-center gap-3'>
                <ul>
                  <li>Half Day: 0.5</li>
                  <li>Full Day: 1</li>
                </ul>

                <ul>
                  <li>Hours: 1-24h</li>
                </ul>
              </div>

              <p className='text-danger'>Employees with Salary Type "MONTH" should be marked with Double Entry</p>

            </div>
        </div>
        }
      />

      <ModalBasic
        open = {visible}
        onClose = {()=> {
        setVisible(false)
        }}
        content = {
          <div className='d-flex justify-content-between align-items-center'>
            <p>{moment(date) < moment(gdate.cashflowDate) ? 'Cashflow verfied cannot mark' : 'Only Attendance will be Marked'}</p>
            <button className = 'btn btn-success' onClick={handleMarkAttendance} disabled={submitDisable || (moment(date) < moment(gdate.cashflowDate))}>Submit
            </button>
        </div>
        }
      />
    </div>
  )
}


