import React, {useState,useEffect} from 'react'
import { useNavigate, createSearchParams } from 'react-router-dom';
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useAuth } from '../../context/auth';

import {Add, Edit, Receipt, Check, Clear} from '@mui/icons-material/'

import {SwitchInput} from '../../Components/Inputs/InputFields'

import MaterialTable from '../../Components/Table/MaterialTable'
import ModalBasic from '../../Components/Modal/ModalBasic'

import CreateEmployee from './CreateEmployee'
import UpdateEmployee from './UpdateEmployee'

import AccountsForm from '../Accounts/DoubleEntires/AccountsForm'

export default function ListEmployee() {

  const navigate = useNavigate()

  const [auth] = useAuth()

  const [listEmployee, setListEmployee] = useState('')
  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  const [visible1, setVisible1] = useState(false)

  const [visibleCredit, setVisibleCredit] = useState(false)
  const [visibleDebit, setVisibleDebit] = useState(false)

  const [submitDisable, setSubmitDisable] = useState(false)

  const [checked, setChecked] = useState(true)

  useEffect(() => {
      loadEmployee()
  }, [])
  

  const loadEmployee = async() => {
      try {
        const {data} = await Axios.post('/employees/filter', {role: { $gte: auth?.employee?.role } })
        if(data?.error){
            toast.error(data.error)
        } else {
            setListEmployee(data)
        }
      } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
      }
  }

  const columns = [
    {accessorKey: 'nic', header: 'NIC', size: 100},
    {accessorKey: 'name', header: 'Name', size: 100},
    {accessorKey: 'email', header: 'Email / Username', size: 100},
    {accessorKey: 'phoneNumber', header: 'Phone Number', size: 100},
    {accessorKey: 'role', header: 'Role', size: 100, Cell: ({row: {original}}) => {
      switch(original.role) {
        case 0:
          return "Admin"
        case 1:
          return "User"
        case 2:
          return "Employee"
        default:
          return "Employee"
      }}, 
      export: (i) => {
      switch(i) {
        case 0:
          return "Admin"
        case 1:
          return "User"
        case 2:
          return "Employee"
        default:
          return "Employee"
      }}
    },
    {accessorKey: 'designation', header: 'Designation', size: 100},
    {accessorKey: 'active', header: 'Active', size: 100, Cell: ({row: {original}}) => original.active ? <Check color='success' /> : <Clear color='error'/>},
    {accessorKey: 'address', header: 'Address', size: 100},
  ]

  const rowAction = ({row}) => [
    <div className='d-flex justify-content-center gap-2'>
      <Receipt
        onClick={() => {
          navigate({
            pathname: `/dashboard/user/employee-accounts/${row.original._id}`,
            search: `?${createSearchParams({
              name: row.original.name,
            })}`
          })
        }}
      />
      <Edit
        onClick={() => {
          setVisible(true)
          setSelected(row.original)
        }}
      />
    </div>
  ]

  return (
    <div className='row justify-content-center'>
      <div className='col-12 mb-2'>
        <div className='hstack gap-3 mb-2'>
          <button className = 'btn btn-outline-primary' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
          <button className = 'btn btn-outline-success ms-auto' onClick={() => {setVisibleDebit(true)}}><Add/> Pay Arrears</button> 
          <button className = 'btn btn-outline-danger' onClick={() => {setVisibleCredit(true)}}><Add/> Add Arrears</button>
        </div>

        <div className='d-flex justify-content-end'>
          <SwitchInput
            endLabel = "Active"
            checked = {checked}
            color = 'primary'
            onChange = {(e) => {
              setChecked(preVal => !preVal)
            }}
          />
        </div>
        
        <MaterialTable
          title = "List of Employees"
          col = {columns}
          row = {listEmployee && checked ? listEmployee?.filter((f) => f?.active === true) : listEmployee}
          rowAction = {rowAction}
        />

        <ModalBasic
          open = {visibleAdd}
          onClose = {()=> {
          setVisibleAdd(false)
          }}
          content = {<CreateEmployee/>}
        />

        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {<UpdateEmployee employee = {selected}/>}
        />

        <ModalBasic
          size = '80%'
          open = {visibleDebit}
          onClose = {()=> {
          setVisibleDebit(false)
          }}
          content = {<AccountsForm data= {{inputAccounting: '6515831c1a89e4fc7010012a'}}/>}
        />

        <ModalBasic
          size = '80%'
          open = {visibleCredit}
          onClose = {()=> {
          setVisibleCredit(false)
          }}
          content = {<AccountsForm data= {{inputAccounting: '64cb525518dca625f12ce5bf', inputAccounting2: '6515831c1a89e4fc7010012a'}}/>}
        />
      </div>
    </div>
  )
}
