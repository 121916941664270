import React, {useState, useEffect} from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment'

import {useSettings} from '../../context/settings'
import { useGdate } from '../../context/date'

import { Text, SelectAutoComplete} from '../../Components/Inputs/InputFields';

export default function EmpAccForm() {
  const {id} = useParams()
  const [searchParams] = useSearchParams()
  const [settings] = useSettings()
  const [gdate] = useGdate()
  const empAccounts = [{_id:'64952f578188d2c8b9c26603', name: 'Cash'}, {_id:'63e31a93f029ceab39dcd281', name: 'Bank / Card Pays'}, {_id:'63e31abdf029ceab39dcd283', name: 'Debts / Bill'}, {_id:'63e31af4f029ceab39dcd28f', name: 'Drawings'}, {_id:'63e31b29f029ceab39dcd291', name: 'Expense'}]
  const [dayPumpEmployee, setDayPumpEmployee] = useState('')
  const [employeeAccEntries, setEmployeeAccEntries] = useState('')

  const [listAccounts] = useState(empAccounts)
  const [listBanks, setListBanks] = useState('')
  const [listCustomers, setListCustomers] = useState('')
  const [listFuelTypes, setListFuelTypes] = useState('')
  const [listGovOrders, setListGovOrders] = useState('')
  
  //Mark Date should be morethan cashflow verified date
  const [checkCashflow, setCheckCashflow] = useState(false)

  const [date] = useState(gdate?.date)

  const [accounting, setAccounting] = useState()
  const [description, setDescription] = useState('')
  const [customer, setCustomer] = useState()
  const [bank, setBank] = useState()
  const [billNo, setBillNo] = useState()
  const [comment, setComment] = useState()
  const [fuelType, setFuelType] = useState()
  const [liter, setLiter] = useState()
  const [govOrder, setGovOrder] = useState()

  const [amount, setAmount] = useState('')
  const [status] = useState(true)

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    loadPumpEmployees()
    loadAccountsData()
    loadDayEmployees()
    setCheckCashflow(() => moment(gdate?.cashflowDate) > moment(date) ? true : false)
  },[])

  useEffect(() => {
    setDescription('')
    setCustomer()
    setBank()
    setBillNo()
    setComment()
    setFuelType()
    setLiter()
    setAmount('')

    accounting?._id === "63e31a93f029ceab39dcd281" && loadBanks()
    accounting?._id === "63e31abdf029ceab39dcd283" && loadCustomers()
    accounting?._id === "63e31abdf029ceab39dcd283" && loadFuelTypes()
  },[accounting])

  useEffect(() => {
    setGovOrder()
  },[customer])

  useEffect(() => {
    setLiter()
    setAmount('')
  },[fuelType])

  useEffect(() => {
    fuelType && setLiter(new Intl.NumberFormat().format((amount / fuelType?.price)))
  },[amount])

  useEffect(() => {
    setDescription(`Fuel Cr - ${billNo ? ('Bill No ' + billNo) : ''} ${fuelType?.fuelType ? fuelType?.fuelType: ''} ${liter} L`)
  },[billNo, fuelType, liter])

  // Load Day pump data
  const loadPumpEmployees = async() => {
    try {
      const {data} = await Axios.post('/daypumps/dayemployees', {$expr : { $eq: [ '$employee' , { $toObjectId: id } ] }, date: gdate?.date} )
      if (data?.error){
        toast.error(data?.error)
      } else {
        setDayPumpEmployee(data?.reduce((a,v) => a += v?.total, 0))
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")

    }
  }

  //Load Employee Entries
  const loadAccountsData = async() => {
    try {
        const {data} = await Axios.post(`/accdatas/filteremployeeaccount`,{employee: id, date: gdate?.date})
        if(data?.error){
            toast.log(data.error)
        } else {
          setEmployeeAccEntries(data?.filter((i) => i?._id?.accounting !== "63eb82f1661aeb9f06368651")?.reduce((a,v) => a += v?.total, 0))
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  //Check Employee Submission
  const loadDayEmployees = async() => {
    try {
        const {data} = await Axios.post(`/dayemployees`, {employee: id, date: gdate?.date})
        if(data?.error){
            toast.error(data.error)
        } else {
            data?.length > 0 && setSubmitDisable(true)
            data?.length > 0 && toast.error("Entry Restricted, Accounts Submitted")
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  const loadBanks = async() => {
    try {
      const {data} = await Axios.post(`/bank-accounts`)
      if(data?.error){
        toast.error(data?.error)
      } else {
        setListBanks(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadCustomers = async() => {
    try {
      const {data} = await Axios.post(`/customers`)
      if(data?.error){
        toast.error(data?.error)
      } else {
        setListCustomers(data?.filter((i) => i?.active === true))
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadFuelTypes = async() => {
    try {
        const {data} = await Axios.post('/productcodes')
        if(data?.error){
            toast.error(data.error)
        } else {
          const result = []
          await data?.filter((i) => i?.product?.category?._id === "63e65efc2f840202604fbb7f")?.reduce(function(a, v) {
            if (!a[v?.product?.type?.fuelType]) {
              a[v?.product?.type?.fuelType] = {_id: v?.product?.type?._id, fuelType: v?.product?.type?.fuelType, price: v?.price};
              result.push(a[v?.product?.type?.fuelType])
            }
            return a;
          }, {});
          setListFuelTypes(result)
        }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadGovOrders = async(id) => {
    try {
      const {data} = await Axios.post(`/govorders`, {customer: id, status: 'receive'})
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListGovOrders(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    try {
      await setSubmitDisable(true)
      if (settings?.requiredFuelBill && accounting?._id === '63e31abdf029ceab39dcd283' && (!billNo || !comment || !fuelType)) {
        toast.error('Bill, comment & Fuel Type Required')
        setSubmitDisable(false)
      } else {
        const {data} = await Axios.post(`/accdata`, {date, empStatus: {status, employee : id}, detail: {description, bank, customer, billNo, comment, fuel: {fuelType, liter}, govOrder}, amount : `${Number( (accounting?._id === '63e31af4f029ceab39dcd28f') ? (amount >= 0 ? (-amount) : amount) : amount )?.toFixed(2)}` , accounting})
        if(data?.error){
          toast.error(data.error)
          setSubmitDisable(false)
        } else {
          toast.success("Accounts Data Saved Sucessfully")
          window.location.reload()
        }
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    };
  }

  return (
    <div className='row justify-content-center'>
      <div className='p-3 col-lg-4 col-md-6 col-sm-8 col-12'>
        <h5 className='text-center text-capitalize'>{searchParams.get('name')} Accounts</h5>
        <h6 className='text-center text-capitalize'>Differance Amount - {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(dayPumpEmployee - employeeAccEntries)}</h6>
        <form onSubmit={handleSubmit}>
          <dd className='text-center'>{date}</dd>

          <SelectAutoComplete
            label = "Accounts"
            focus = {true}
            optionsList={listAccounts}
            option = {(i) => i.name}
            value={accounting}
            onChange={(event, value) => {
                setAccounting(value)
            }}
          />

          {/* Bank Accounts Conditions */}
          {accounting?._id === "63e31a93f029ceab39dcd281" && 
            <SelectAutoComplete
              label = "Bank"
              optionsList={listBanks}
              option = {(i) => `${i.bank + ' | '+ i.accountNo}`}
              value={bank}
              onChange={(event, value) => {
                  setBank(value)
              }}
            />
          }

          {/* Credit Customer Accounts Conditions */}
          {accounting &&
            (accounting?._id === "63e31abdf029ceab39dcd283" ?
              <div>
                <Text
                  label="Bill No" 
                  value = {billNo}
                  onChange={(e) => setBillNo(e.target.value)}
                />

                <SelectAutoComplete
                  label = "Customer"
                  optionsList={listCustomers}
                  option = {(i) => `${i.name + ' | '+ i.phoneNumber}`}
                  value={customer}
                  onChange={(event, value) => {
                      setCustomer(value)
                      value?.type === 'government' && loadGovOrders(value?._id)
                  }}
                />
                
                {!customer && <button type="button" className="btn btn-link p-0 mb-3" onClick={() => window.open(`/dashboard/user/customer/List`,'_blank')}>Add Customer</button>}

                <Text
                  label="Comment (Vehicle No / Name)" 
                  value = {comment}
                  onChange={(e) => setComment(e.target.value)}
                />

                <SelectAutoComplete
                    label = "Fuel Type (opt.)"
                    optionsList={listFuelTypes}
                    option = {(i) => i?.fuelType}
                    value={fuelType}
                    onChange={(event, value) => {
                        setFuelType(value)
                    }}
                />

                  <Text
                    label = "Liters (opt.)"
                    value = {liter}
                    type = "Number"
                    onChange={(e) => {
                      setLiter(e.target.value)
                      setAmount(e.target.value * fuelType?.price)
                    }}
                    end = "L"
                  />

                {customer?.type === 'government' && 
                <SelectAutoComplete
                  label = "Government Order (Opt.)"
                  optionsList={listGovOrders}
                  option = {(i) => `${i.govOrder + ' | '+ i.receiveDate}`}
                  value={govOrder}
                  onChange={(event, value) => {
                      setGovOrder(value)
                  }}
                />}

              </div>
              :
              <Text
                label = "Description (opt.)"
                value = {description}
                onChange={(e) => setDescription(e.target.value)}
              />
            )
          }

          <Text
            label="Amount" 
            value = {amount}
            type = "number"
            onChange={(e) => {
              setAmount(e.target.value)
            }}
            start = 'LKR'
          />

          <div className="d-grid gap-2">
            <button className='btn btn-primary mt-3' type='submit' disabled={submitDisable || checkCashflow || (dayPumpEmployee === 0)}>Submit</button>
            {checkCashflow && <dd className='text-center fs-6 fw-bold text-danger mt-2'>Cashflow Verified on Selected date</dd>}
          </div>

        </form>
      </div>
    </div>
  )
}
