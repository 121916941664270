import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment';

import { useAuth } from '../../../context/auth'
import { useGdate } from '../../../context/date'

import {currency} from '../../../Functions/NoFomats'

import {Add, Verified, PriorityHigh, DeleteForever} from '@mui/icons-material/'

import MaterialTable from '../../../Components/Table/MaterialTable'
import ModalBasic from '../../../Components/Modal/ModalBasic'

import AccountsForm from './AccountsForm'

export default function AllDoubleEntries() {
    
    const [auth] = useAuth()
    const [gdate] = useGdate()

    const [listAccData, setListAccData] = useState('')

    const [visibleDebit, setVisibleDebit] = useState(false)

    const [visible1, setVisible1] = useState(false)
    const [selected1, setSelected1] = useState(null)

    useEffect(() => {
        loadAccData()
    },[gdate?.reportStartDate, gdate?.reportEndDate])

    const loadAccData = async() => {
        try {
            const {data} = await Axios.post(`/accdatas`, {date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
            if(data?.error){
                toast.error(data.error)
            } else {
                await setListAccData(data?.map((i) => {
                    var debit = ''
                    var credit = ''
                    if ((i.accounting?.category?.type && i?.amount > 0) || (!i.accounting?.category?.type && i?.amount < 0)) {
                        debit = Math.abs(i?.amount)
                    } else if ((!i.accounting?.category?.type && i?.amount > 0) || (i.accounting?.category?.type && i?.amount < 0)) {
                        credit = Math.abs(i?.amount)
                    }
                    return {...i, debit, credit}
                }))
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const columns = [
        { accessorKey: 'date', header: 'Date', size: 100},
        { accessorKey: 'accounting.name', header: 'Accounts', size: 100, Cell: ({row: {original}}) => original.accounting?.name?.toUpperCase()},
        { accessorKey: 'empStatus.employee.name', header: 'Employee', size: 100, Cell: ({row: {original}}) => original.empStatus?.employee?.name},
        { accessorKey: 'detail.description', header: 'Description', width: 400, Cell: ({row: {original}}) => original.detail?.description},
        { accessorKey: 'debit', header: 'Debit', Cell: ({row: {original}}) => original.debit ? currency(original.debit) : '', export: (i) => i ? currency(i) : ''},
        { accessorKey: 'credit', header: 'Credit', Cell: ({row: {original}}) => original.credit ? currency(original.credit) : '', export: (i) => i ? currency(i) : ''},
    ]

    const rowAction = ({row}) => [
        <div className='d-flex justify-content-center gap-2'>
            {moment(row.original.date) < moment(gdate?.cashflowDate) ? <Verified color='success'/> : 
            (row.original.refId?.startsWith("de-") ?
                <DeleteForever
                    onClick={() => {
                    setVisible1(true)
                    setSelected1(row.original)
                    }}
                />
                :
                <PriorityHigh color='warning'/>
            )}
        </div>
    ]

    const handleDeleteAccounts = async(selected) => {
        try {
            const {data} = await Axios.post(`/accdatas/removemany`, {"refId": selected?.refId , "empStatus.status": false, date: selected?.date})
            if (data?.error) {
                toast.error(data?.error)
            } else if (listAccData?.find((i) => i?.refId === selected?.refId && i?.empStatus.status === false && i?.date === selected?.date)?.detail?.cheque?._id) {
                const {data} = await Axios.post(`/cheque/delete`, {_id: listAccData?.find((i) => i?.refId === selected?.refId && i?.empStatus.status === false && i?.date === selected?.date)?.detail?.cheque?._id})
                if (data?.error) {
                    toast.error(data?.error)
                } else {
                    toast.success("Double Entries & cheque Deleted")
                    window.location.reload()
                }
            } else {
                toast.success("Double Entries Deleted")
                window.location.reload()
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    return (
        <div className='row justify-content-center'>
            <div className='col-12 mb-3'>
                <div className='d-flex justify-content-end mb-2'>
                    <button className = 'btn btn-outline-primary' onClick={() => {setVisibleDebit(true)}}><Add/>Double Entry</button> 
                </div>
                <MaterialTable
                    title = {"All Double Entries | " + gdate?.reportStartDate + ' To ' + gdate?.reportEndDate} 
                    col = {columns}
                    row = {listAccData}
                    rowAction = {rowAction}
                />
            </div>

            <ModalBasic
                width = '80%'
                open = {visibleDebit}
                onClose = {()=> {
                setVisibleDebit(false)
                }}
                content = {<AccountsForm/>}
            />

            <ModalBasic
                open = {visible1}
                onClose = {()=> {
                setVisible1(false)
                }}
                content = {
                    auth?.employee?.role < 2 ?
                    <div className='d-flex justify-content-between gap-2'>
                        <div>
                            {selected1?.refId ? <dd>Make Sure. <br/> All Entires with similar Referance ID will be deleted</dd> : <dd>Only Double Entires can be deleted</dd>}
                        </div>
                        <div className='d-flex align-items-center'>
                            <button className = 'btn btn-outline-danger' disabled = {selected1?.refId ? false : true} onClick={() => {handleDeleteAccounts(selected1)}}>Delete</button>
                        </div>
                    </div>
                    :
                    <div className='d-flex justify-content-center w-100'>
                        <p>You don't have Permission to Delete</p>
                    </div>
                }
            />
        </div>
    )
}
