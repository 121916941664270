import React, {useState,useEffect} from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment'

import { useAuth } from '../../../context/auth';

import {unit} from '../../../Functions/NoFomats'

import {Check, DeleteForever} from '@mui/icons-material/'

import MaterialTable from '../../../Components/Table/MaterialTable'
import ModalBasic from '../../../Components/Modal/ModalBasic'

export default function ListDep() {
  const {id} = useParams()
  const [searchParams] = useSearchParams()

  const [auth] = useAuth();

  const [ListDep, setListDep] = useState('')
  const [listCashflowCheck, setListCashflowCheck] = useState('')
  //Model

  const [visible1, setVisible1] = useState(false)
  const [selected1, setSelected1] = useState(null)

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
      loadDepth()
      loadCashflowsCheck()
  }, [])
    

  const loadDepth = async() => {
      try {
          const {data} = await Axios.post('/product-depths', {product: id})
          if(data?.error){
              toast.error(data.error)
          } else {
              setListDep(data?.map((i) => ({...i, differance: (i?.volume - i?.stock)})))
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  const loadCashflowsCheck = async() => {
    try {
      const {data} = await Axios.post('/cashflows')
      if (data?.error) {
        toast.error(data?.error)
      } else {
        setListCashflowCheck(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something wernt wrong, Try Again!")
    }
  }

  const handleDeleteDep = async(id) => {
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.delete(`/product-depth/${id}`)
      if (data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        toast.success('Depth Data Deleted')
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const columns = [
    {accessorKey: 'date', header: 'Date', size: 100},
    {accessorKey: 'dep', header: 'Depth', size: 100},
    {accessorKey: 'volume', header: 'Volume', size: 100, Cell : ({row: {original}}) => unit(original.volume, "L"), export: (i) => unit(i, "L")},
    {accessorKey: 'stock', header: 'Stock', size: 100, Cell : ({row: {original}}) => unit(original.stock, "L"), export: (i) => unit(i, "L")},
    {accessorKey: 'differance', header: 'Differance', size: 100, Cell : ({row: {original}}) => unit(original.differance, "L"), export: (i) => unit(i, "L")},
    {accessorKey: 'createdAt', header: 'Marked On', size: 100, Cell : ({row: {original}}) => moment(original.createdAt).format("YYYY-MM-DD hh:mm: A"), export : (i) => moment(i).format("YYYY-MM-DD hh:mm: A")},
  ]

  const rowAction = ({row: {original}}) => [
    <div className='d-flex gap-2'>
      {listCashflowCheck?.find((c) => c?.date === original.date) ? <div className='text-success'>CASHFLOW <Check/></div> : 
        <DeleteForever fontSize='large' className='p-2 shadow rounded-circle'
          onClick={() => {
            setVisible1(true)
            setSelected1(original)
          }}
        />
      }
    </div>
  ]

  return (
    <div className='row justify-content-center'>
      <div className='col-xl-8 col-lg-10 col-12 mb-3'>
        <MaterialTable
          title = {`Dep List Tank ${searchParams.get('name')} - ${searchParams.get('capacity')} G`}
          col = {columns}
          row = {ListDep}
          rowAction = {rowAction}
        />

        <ModalBasic
          open = {visible1}
          onClose = {()=> {
          setVisible1(false)
          }}
          content = {
            auth?.employee?.role < 2 ?
              <div className='d-flex justify-content-between gap-2'>
                <p>Are You sure want to? On deletation selected Customer data will be erased</p>
                <div>
                  <button className='btn btn-outline-danger' disabled={submitDisable} onClick={() => {
                      handleDeleteDep(selected1._id)
                    }}>Delete
                  </button>
                </div>
              </div>
              :
              <div>
                <p className='text-center'>You don't have Permission to Delete</p>
                <p className='text-center fs-4'>Login as Admin</p>
              </div>
          }
        />
      </div>
    </div>
    )
}
