import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import {useNavigate, createSearchParams} from 'react-router-dom'
import moment from 'moment'

import { useGdate } from '../../context/date'
import { useAuth } from '../../context/auth'

import {Money, Payments, Check, Error, Warning, Inventory, Circle} from '@mui/icons-material/';

import ReportCard from '../../Components/Card/ReportCard';
import AllAcounts from './AllAcounts'
import FinancialStatement from '../Accounts/FinancialYear/FinancialStatements/FinancialStatement'
import Stocks from './Stocks'
import GasDashboard from '../GasDashboard'

export default function Dashboard() {
  const [gdate] = useGdate()
  const [auth] = useAuth()

  const navigate = useNavigate();

  const [loadStatement, setLoadStatement] = useState(false)

  const [totalCash, setTotalCash] = useState('0')
  const [sales, setSales] = useState('0')
  const [dayPump, setDayPump] = useState('0')
  const [dayEmployee, setDayEmployee] = useState('0')

  const [totalDebit, setTotalDebit] = useState('')
  const [totalCredit, setTotalCredit] = useState('')

  const [dayLength, setDayLength] = useState('report')

  useEffect(() => {
    loadTotalAccounts()
  },[])

  useEffect(() => {
    loadData()
    loadDayPump()
    loadDayEmployee()
    setDayLength(reportLength())
    loadAccountsByCategoryType()
  },[gdate?.reportStartDate, gdate?.reportEndDate])


  const loadTotalAccounts = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/filteraccounts`)
      if(data?.error) {
        toast.error(data.error)
      } else {
        await setTotalCash(data.find((i) => i._id.accounting[0]?._id === '64952f578188d2c8b9c26603') ? data.find((i) => i._id.accounting[0]?._id === '64952f578188d2c8b9c26603') : '' )
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadData = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/filteraccounts`,{date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}} )
      if(data?.error) {
        toast.error(data.error)
      } else {
        await setSales(data.find((i) => i._id.accounting[0]?._id === '63eb82f1661aeb9f06368651') ? data.find((i) => i._id.accounting[0]?._id === '63eb82f1661aeb9f06368651') : '' )
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadDayPump = async() => {
    try {
      const {data} = await Axios.post(`/daypumps/fuelsales-typepricecost`, {date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
      if(data?.error){
        toast.error(data.error)
      }
      else {
        setDayPump(data && data?.reduce((a,v) => a = a + v?.total, 0))
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadDayEmployee = async() => {
    try {
      const {data} = await Axios.post(`/dayemployees/dailysales`, {date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
      if(data?.error){
        toast.error(data.error)
      }
      else {
        setDayEmployee(data ? data?.reduce((a,v) =>  a = a + v?.cash + v?.banks + v?.bill + v?.drawings + v?.expenses + v?.salary, 0) : '0.00')
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadAccountsByCategoryType = async() => {
    try {
        const {data} = await Axios.post(`/accdatas/sumBycategorytype`, {date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
        if(data?.error){
            toast.error(data.error)
        } else {
            setTotalDebit(data?.find((i) => i?._id === true)?.total)
            setTotalCredit(data?.find((i) => i?._id === false)?.total)
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  const reportLength = () => {
    const dayDiff = moment(gdate?.reportEndDate).diff(gdate?.reportStartDate, 'day')
    const monthDiff = moment(gdate?.reportEndDate).diff(gdate?.reportStartDate, 'month')

    if (dayDiff === 0 ) {
      return 'Daily'
    } else if ( dayDiff >= 1 && monthDiff === 0) {
      return ( (dayDiff+1) + ' Days' )
    } else if (monthDiff > 0) {
      return ( monthDiff + 1 + ' Months' )
    } else {
      return (gdate?.reportStartDate + ' - ' + gdate?.reportEndDate + ' Report')
    }
  }

  return (
    <div>
      <div className='row p-2' style={{borderRadius: '5px', backgroundImage: 'linear-gradient(122deg, rgba(70,70,70,0.5) 0%, rgba(200,200,200,0.5) 50%, rgba(70,70,70,0.5) 100%)', backdropFilter: 'blur(10px)'}}>
        {/* TOTAL CASH */}
        <div className='col-xxl-3 col-md-6 col-sm-12 p-2'>
          <ReportCard
            topbarColor = 'greenyellow'
            icon = {<Money fontSize='large'/>}
            accounts = {"BUSINESS CASH"}
            date = 'All Time'
            amount = {(totalCash ? totalCash?.total : '0.00')}
            name = "CASH ON HAND"
            onClick = {(e) => {
              navigate(`/dashboard/user/accounts/daily-cashflow`)
            }}
          />
        </div>

        {/* Sales */}
        <div className='col-xxl-3 col-md-6 col-sm-12 p-2'>
          <ReportCard
            topbarColor = 'blue'
            icon = {<Inventory fontSize='large'/>}
            accounts = {(sales?._id?.accounting[0]?.name.toUpperCase() || "SALES")}
            date = {dayLength}
            amount = {(sales ? sales?.total : '0.00')}
            name = "FUEL & INVENTORY SALES"
            description = "Income Accounts"
            onClick = {(e) => {
              navigate({
                pathname: `/dashboard/user/accounts/63eb82f1661aeb9f06368651`,
                search: `?${createSearchParams({
                accounts: sales?._id?.accounting[0]?.name
                })}`
            })}}
          />
        </div>

        {/* Double Entries Reports */}
        <div className='col-xxl-3 col-md-6 col-sm-12 p-2'>
          <ReportCard
            topbarColor = 'red'
            icon = {<Payments fontSize='large'/>}
            accounts = { "Double Entries"}
            date = {dayLength}
            amount = {(Math.abs(totalDebit || totalCredit ? totalDebit - totalCredit : '0.00'))}
            name = {(totalDebit || totalCredit) ? (Number(totalDebit)?.toFixed(2) - Number(totalCredit)?.toFixed(2) === 0 ? <Check fontSize='large' color= "success"/> : <Error fontSize='large' color= "error"/>) : <Warning fontSize='large' color= "warning"/>}
            description = {(totalDebit || totalCredit) ? "All Entry Records" : "No Entries Available"}
            onClick = {(e) => {
              navigate(`/dashboard/user/accounts/alldoubleentries`)
            }}
          />
        </div>

        {/* Fuel Sales */}
        <div className='col-xxl-3 col-md-6 col-sm-12 p-2'>
          <ReportCard
            topbarColor = 'orange'
            icon = {<Payments fontSize='large'/>}
            accounts = "Fuel Sales"
            date = {dayLength}
            amount = {Number(dayEmployee)?.toFixed(2) - Number(dayPump ? dayPump : '0')?.toFixed(2) === 0 ? dayEmployee : (Number(dayPump ? dayPump : '0')?.toFixed(2) - Number(dayEmployee)?.toFixed(2))}
            name = {Number(dayEmployee)?.toFixed(2) - Number(dayPump ? dayPump : '0')?.toFixed(2) === 0 ? <Check fontSize='large' color= "success"/> : <Error fontSize='large' color= "error"/>}
            description = {Number(dayEmployee)?.toFixed(2) - Number(dayPump ? dayPump : '0')?.toFixed(2) === 0 ? "Fuel Sales Record Correctly" : "Check Day Employee / Pump"}
            onClick = {(e) => {
              navigate(`/dashboard/user/pump/fuel-sales`)
            }}
          />
        </div>
      </div>

      {/* All Accounts Components */}
      {auth?.employee?.role === 0 && <div className='col-12'>
        <AllAcounts/>
      </div>}

      {/* FINANCIAL STATEMENT */}
      {loadStatement === true ? 
        <div className='col-12'>
          <FinancialStatement/>
        </div>
      :
        auth?.employee?.role === 0 ? <div className='col-12 text-center p-3'> <button className='btn btn-outline-primary' onClick={() => setLoadStatement(true)}>Load Statements</button> </div>
        :
        <div className=' p-2 text-center fs-4'>Balance-sheet Status : <Circle color= {Number(totalDebit)?.toFixed(2) - Number(totalCredit)?.toFixed(2) === 0 ? 'success' : 'error'}/></div>
      }

      <hr/>

      <div className='row p-2'>
        <div className='col-xl-6 col-12'>
          <GasDashboard/>
        </div>

        <div className='col-xl-6 col-12'>
          <Stocks/>
        </div>
      </div>

    </div>
  )
}
