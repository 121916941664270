import React, {useState, Fragment} from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import {Image} from 'antd';
import CpetcoLogoImg from '../../Assets/images/ceypetco_logo.png'
import GasLogo from '../../Assets/images/gas-logo.png'
import IOCLogo from '../../Assets/images/ioc-logo.png'


import {Menu as MenuIcon} from '@mui/icons-material/';

import { useMenuType } from '../../context/menu';
import { useSettings } from '../../context/settings';

import {SelectAutoComplete} from '../Inputs/InputFields'

import SidebarMenu from './Menu/Menu'
import MenuGas from './Menu/MenuGas'
import MenuPos from './Menu/MenuPOS'
import QuickLinks from './Menu/MenuQuickLinks'


export default function Sidebar() {
  const [state, setState] = useState(false);
  const [settings] = useSettings()
  const [menuType, setMenuType] = useMenuType();
  
  const toggleDrawer = (open) => (event) => {
    setState(open);
  };

  const menuSelection = (item) => {
    if (item?.menuItem?.trim()) {
      setState(false)
    }
  }
  const switchMenu = (menu) => {switch (menu?.value) {
    case "General":
      return <SidebarMenu menuSelection = {menuSelection}/>;
    case "Gas":
      return <MenuGas menuSelection = {menuSelection}/>;
    case "POS":
      return <MenuPos menuSelection = {menuSelection}/>;
    case "Quick-Links":
      return <QuickLinks menuSelection = {menuSelection}/>;
    default:
      return <SidebarMenu menuSelection = {menuSelection}/>
  }}

  const SearchLogo = () => {
    switch (settings?.gasStationType) {
      case 'ceypetco':
        return (CpetcoLogoImg)
      case 'ioc':
        return (IOCLogo)
      default:
        return (GasLogo)
    }
  }

  const list = () => (
    <Box sx={{bgcolor: 'rgb(0, 21, 41)', height: '100%', width: 250}}>
      <div className='sticky-top'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: 50,
            margin: 8,
          }}
        >
          <Image
            height="100%"
            src={settings?.logo ? settings?.logo?.file : SearchLogo()}
            alt='Logo'
          />
        </div>

        <SelectAutoComplete
          label = "Menu"
          focused = {true}
          color = 'primary'
          optionsList={["General", "Gas", "POS", "Quick-Links"]}
          option = {(i) => i}
          value={menuType?.value}
          onChange={(event, value) => {
            setMenuType({...menuType, value : value})
            localStorage.setItem( "menuType", JSON.stringify(value));
          }}
        />

        {switchMenu(menuType)}
      </div>
    </Box>

  );

  return (
    <div className='d-flex align-items-center'>
      <Fragment key='left'>
        <Button onClick={toggleDrawer(true)}><MenuIcon style={{color: '#FFFFFF'}}/></Button>
        <Drawer
          anchor='left'
          open={state}
          onClose={toggleDrawer(false)}
        >
          {list()}
        </Drawer>
      </Fragment>
    </div>
  );
}