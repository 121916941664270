import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { GeneralNo } from '../../../Functions/NoFomats'

import MaterialTable from '../../../Components/Table/MaterialTable'
import ModalBasic from '../../../Components/Modal/ModalBasic'

import {Add, Edit} from '@mui/icons-material/'

import CreatePointsCategory from './CreatePointsCategory'
import UpdatePointsCategory from './UpdatePointsCategory'

export default function ListPointsCategories() {

  const [listPointsCategories, setListPointsCategories] = useState('')

  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    loadPointsCategoryies()
  },[])

  const loadPointsCategoryies = async() => {
    try {
      const {data} = await Axios.post(`/pointscategories`)
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListPointsCategories(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const columns = [
    {accessorKey: 'type', header: 'Type', size: 180},
    {accessorKey: 'pointsRate', header: 'Points %', size: 100,},
    {accessorKey: 'maxAmount', header: 'Max Amount', size: 180, renderCell: ({row: {original}}) => GeneralNo(original.maxAmount)},
  ]

  const rowAction = ({row: {original}}) => [
    <div className='d-flex gap-2'>
      <Edit
        onClick={() => {
          setVisible(true)
          setSelected(original)
        }}
      />
    </div>
  ]

  return (
    <div className='row justify-content-center'>
      <div className='col-xxl-6 col-xl-8 col-lg-10 col-12 mb-3'>
        <div className='row justify-content-between mb-2'>
          <div className='col-md-6 col-sm-6 col-12 d-flex gap-2 mb-2'>
              <button className = 'btn btn-outline-primary w-50' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
          </div>
        </div>
        {listPointsCategories && 
          <MaterialTable
            title = 'List Points Categories'
            col = {columns}
            row = {listPointsCategories}
            rowAction = {rowAction}
          />
        }
        <ModalBasic
          open = {visibleAdd}
          onClose = {()=> {
          setVisibleAdd(false)
          }}
          content = {<CreatePointsCategory/>}
        />

        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {<UpdatePointsCategory data = {selected}/>}
        />

      </div>
    </div>
  )
}
