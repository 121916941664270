import React, {useState} from 'react'

import ModalBasic from '../../../Components/Modal/ModalBasic'

import { Button, Link } from '@mui/material'
import {Add, Visibility} from '@mui/icons-material/'
import AccountsForm from '../../Accounts/DoubleEntires/AccountsForm'

export default function ManageEntity(props) {
    const [dEntryModel, setDEntryModel] = useState(false)
    const [accountsEntry, setAccountsEntry] = useState()

    const AccountsModelData = () => {
        switch (accountsEntry) {
            case 'salaryArrearsPay':
                return ({inputAccounting: '6515831c1a89e4fc7010012a'})
            case 'salaryArrearsAdd':
                return ({inputAccounting: '64cb525518dca625f12ce5bf', inputAccounting2: '6515831c1a89e4fc7010012a'})
            case 'bankDeposit':
                return ({inputAccounting: '63e31a93f029ceab39dcd281'})
            case 'bankWithdrawal':
                return ({inputAccounting2: '63e31a93f029ceab39dcd281'})
            case 'customerReceive':
                return ({inputAccounting2: '63e31abdf029ceab39dcd283'})
            case 'customerPay':
                return ({inputAccounting: '63e31abdf029ceab39dcd283'})
            case 'supplierPay':
                return ({inputAccounting: '64acdf13722d07654e219f89'})
            case 'supplierCredit':
                return ({inputAccounting2: '64acdf13722d07654e219f89'})
            default:
                break;
        }
    }

    return (
        <div className='col-lg-6 col-12 p-2'>
            <div className='p-3 shadow w3-bottombar w3-animate-zoom'>
                <h5 className='text-center mb-3'>{props.title}</h5>
                <div className='d-flex justify-content-between align-items-center'>
                    <Link href={props.viewLink} color='inherit' underline="none">
                        <Visibility/> {props.viewTitle}
                    </Link>
                    <Button variant='outlined' color='success' onClick={() => {
                        setDEntryModel(true) 
                        setAccountsEntry(props.value1)
                    }}>{props.debitBtn}</Button> 
                    <Button variant='outlined' color='error' onClick={() => {
                        setDEntryModel(true)
                        setAccountsEntry(props.value2)
                    }}>{props.creditBtn}</Button>
                </div>
            </div>

            <ModalBasic
            width = '80%'
            open = {dEntryModel}
            onClose = {()=> {
            setDEntryModel(false)
            }}
            content = {AccountsModelData && <AccountsForm data= {AccountsModelData()}/>}
            />
        </div>
    )
}
