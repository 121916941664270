import React from 'react'
import {useNavigate} from 'react-router-dom'

import {Facebook, Instagram, WhatsApp} from '@mui/icons-material/';

import DevPic from '../Assets/images/dev-pic.JPG'

export default function Developer() {
    const navigate = useNavigate();
    return (
        <div>
            <div className='row'>
                <div className='col-lg-6 col-12 align-self-center' style={{fontFamily: 'serif'}}>
                    <div className='d-flex justify-content-center gap-2'>
                        <h1 className='text-center'>Hello, I'm</h1> 
                        <h1 className='text-center fw-bold' style={{fontFamily: 'sans-serif', backgroundImage: 'linear-gradient(60deg, rgba(200,150,0,0.9) 0%, rgba(225,0,100,0.9) 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>Shadhir Shaz</h1>
                    </div>
                    

                    <h1 className='text-center'> Web & Mobile App Developer <br/> Based in Sri Lanka</h1>
                    <p className='text-center text-justify fs-6 m-3'>I was started as a freelance Developer then We established a company Bido(pvt)Ltd. <br/>Worked on Web and Mobile Applications Projects for 1 year as a feelancer. Primary goal is Providing Apps with Responsive Designs</p>

                    <div className='d-flex justify-content-center gap-3'>
                        <Facebook fontSize='large' onClick={() => {navigate(window.location.href = `https://www.facebook.com/shadhirshaz?mibextid=ZbWKwL`)}}/>
                        <h3>|</h3>
                        <Instagram fontSize='large' onClick={() => {navigate(window.location.href = `https://instagram.com/shadhir_shaz?igshid=OTk0YzhjMDVlZA==`)}}/>
                        <h3>|</h3>
                        <WhatsApp fontSize='large' onClick={() => {navigate(window.location.href = `https://api.whatsapp.com/send/?phone=%2B94722700085&text&type=phone_number&app_absent=0`)}}/>
                    </div>
                    
                </div>

                <div className='col-lg-6 col-12 text-center align-self-center'>
                    <img
                        className='p-2 shadow'
                        style={{borderRadius: '50%'}}
                        width={"80%"}
                        src={DevPic}
                    />
                </div>
            </div>

            

            <div className='row mt-3 p-2'>
                <div className='col-md-6 col-12' style={{fontFamily: 'serif'}}>
                    <h2 className='p-3 text-center'>Development Services</h2>
                    <li className='fs-6'>I have all kind of skills in UI / UX Desgins.</li>
                    <li className='fs-6'>I always ensure the quality of product by run unlimited test cases.</li>
                    <li className='fs-6'>So Clients receives best products</li>

                </div>

                <div className='col-md-6 text-center p-3'>
                    <h3>WEB DESIGN</h3>
                    <hr/>
                    <h3>MOBILE APP DESIGN</h3>
                    <hr/>
                    <h3>SYSTEM DESIGN</h3>
                    <hr/>
                    <h3>DESIGN STRATEGY</h3>
                </div>
            </div>

            <div className='row p-3 mt-3'>
                <div className='col-12 text-center align-self-center' style={{fontFamily: 'unset'}}>
                    <h2>Have An Awesome Project Idea? Let's Discuss</h2>
                </div>
            </div>
        </div>
    )
}
