import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import { useParams, useSearchParams } from 'react-router-dom'
import {toast} from 'react-hot-toast'
import moment from 'moment'

import { useGdate } from '../../context/date'
import {useAuth} from '../../context/auth'

import {Text, SwitchInput, SelectAutoComplete} from '../../Components/Inputs/InputFields'

import {DepData2000, DepData3000, DepData5000} from '../../Data/data'
import FileUpload from '../../Components/FIleUpload/FileUpload'

import StockLoadList from './StockLoadList'

export default function StockUpdateForm() {
  const {id} = useParams()
  const [searchParams] = useSearchParams()

  const [auth] = useAuth()
  const [gdate] = useGdate()

  //Mark Date should be morethan cashflow verified date
  const [checkCashflow, setCheckCashflow] = useState(false)

  const [tank, setTank] = useState()
  const [listBank, setListBank] = useState()
  const [listSuppliers, setListSuppliers] = useState()

  const [updateStock, setUpdateStock] = useState('')
  const [checked, setChecked] = useState(true)

  const [purchaseDate] = useState(gdate?.date)
  const [invoiceNo, setInvoiceNo] = useState('')
  const [invoice, setInvoice] = useState()

  const [payMethod] = useState([{_id:'64952f578188d2c8b9c26603', name: 'Cash'}, {_id:'63e31a93f029ceab39dcd281', name: 'Bank'}, {_id:'64acdf13722d07654e219f89', name: 'Credit'}])
  const [pay, setPay] = useState()

  const [bank, setBank] = useState()
  const [supplier, setSupplier] = useState()
  const [amount, setAmount] = useState('')

  const [depData, setDepData] = useState([])
  const [dep1, setDep1] = useState()
  const [dep2, setDep2] = useState()
  const [dif, setDif] = useState('')

  const [submitDisable, setSubmitDisable] = useState(false)
  const [submitDisable2, setSubmitDisable2] = useState(false)


  useEffect(() => {
    loadTank()
    loadBanks()
    loadSuppliers()
    setCheckCashflow(() => moment(gdate?.cashflowDate) > moment(purchaseDate) ? true : false)
  }, [])

  useEffect(() => {
    tank?.productCode?.product?.capacity && handleDepData(tank?.productCode?.product?.capacity)
  }, [tank?.productCode?.product?.capacity])

  useEffect(() => {
    setDif((Number(dep2?.volume) - Number(dep1?.volume)))
  }, [dep1, dep2])

  useEffect(() => {
    setUpdateStock('')
    setSupplier()
    setInvoiceNo('')
    setInvoice()
    setAmount('')
  },[checked])

  useEffect(() => {
    setBank()
  },[pay])

  const loadTank = async() => {
    try {
      const {data} = await Axios.post(`/warehousestocks`, {_id: id})
      if (data?.error) {
        toast.error(data.error)
      } else {
        setTank(data[0])
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadBanks = async() => {
    try {
      const {data} = await Axios.post(`/bank-accounts`)
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListBank(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadSuppliers = async() => {
    try {
      const {data} = await Axios.post(`/Suppliers`)
      if(data?.error){
        toast.error(data?.error)
      } else {
        setListSuppliers(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const getPDFData = (data) => {
    if(data?.file) {
      setInvoice(data)
    } else {
      setInvoice()
    }
  }

  const handleDepData = (data) => {
    switch(data){
      case 2000:
        setDepData(DepData2000)
        break;
      case 3000:
        setDepData(DepData3000)
        break;
      case 5000:
        setDepData(DepData5000)
        break;
      default:
        toast.error('Dapth data not loaded. Refresh the Page!')
    }
  }

  const handleUpdateStock = async() => {
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.put(`/warehousestock/${tank._id}`, { $inc: { 'stock': +(Number(updateStock)) } })
      if(data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        toast.success(`Stock ${updateStock}L updated`)
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const acc2 = (pay, purchaseId) => {
    if (pay === '64acdf13722d07654e219f89') {
      return {date: moment(purchaseDate).format("YYYY-MM-DD"), empStatus: {status: false}, detail:{description: `${supplier?.name} : ${tank?.productCode?.product?.type?.fuelType} Purchase INV No: ${invoiceNo}`, supplier, purchase: purchaseId}, amount: Number(amount).toFixed(2) , accounting: '64acdf13722d07654e219f89'}
    } else {
      return {date: moment(purchaseDate).format("YYYY-MM-DD"), empStatus: {status: false}, detail:{description: `${supplier?.name} : ${tank?.productCode?.product?.type?.fuelType} Purchase INV No: ${invoiceNo}`, bank, purchase: purchaseId}, amount: `${amount >= 0 ? Number(-amount).toFixed(2) : Number(amount).toFixed(2)  }` , accounting: pay}
    }
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      await setSubmitDisable2(true)
      if (checked) {
        const {data} = await Axios.post(`/createpurchase`, {invoiceNo, supplier: supplier, purchaseDate, items: [{productStock : tank, quantity: Number(updateStock)?.toFixed(3), itemPrice: Number(amount)?.toFixed(2) }], total: Number(amount)?.toFixed(2), payment: pay, invoice, type: 'gas'})
        if (data?.error) {
          toast.error(data.error)
          setSubmitDisable2(false)
        } else {
          const stockData = await Axios.post(`/stockload`, {wareHouseStock: tank, purchase: data?.purchases?._id, quantity: Number(updateStock)?.toFixed(3), depth: dep2})
          if (stockData?.data?.error) {
            toast.error(stockData?.data.error)
            setSubmitDisable2(false)
          } else {
            const data1 = await Axios.post(`/accdata`, {date: moment(purchaseDate).format("YYYY-MM-DD"), empStatus: {status: false}, detail:{description: `${supplier?.name} : ${tank?.productCode?.product?.type?.fuelType} Purchase INV No: ${invoiceNo}`}, amount: Number(amount).toFixed(2) , accounting: "63ecfbe55b36ecf83d9dd4a3"})
            const data2 = await Axios.post(`/accdata`, acc2(pay, data?.purchases?._id))
            if(data1?.data?.error || data2?.data?.error){
              toast.error(data1?.data?.error || data2?.data?.error)
              setSubmitDisable2(false)
            } else {
              toast.success("Purchase Recorded")
              window.location.reload()
            }
          }
        }
      } else {
        toast.success(`Stock ${updateStock}L updated`)
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  return (
    <div className='row p-3 justify-content-center'>
      <h5 className='text-center text-capitalize'>Purchase Form <br/>{searchParams.get('name')}</h5>
      <dd className='text-center'>{purchaseDate}</dd>

      {auth?.employee?.role === 0 && <div className='col-lg-4 col-md-6 col-sm-8 col-12'>
        <SwitchInput
          startLabel = "Stock Update"
          endLabel = "Purchase"
          checked = {checked}
          onChange = {(e) => {
            setChecked(preVal => !preVal)
          }}
        />
      </div>}

      {!checked && <form className='row justify-content-center' onSubmit={handleUpdateStock}>
        <div className='col-lg-4 col-md-6 col-sm-8 col-12'>
          <Text
            label="Stock Arrival"
            type = "Number"
            value = {updateStock}
            onChange={(e) => {
              setUpdateStock(e.target.value)
            }}
            end = "L"
          />

          <div className="d-grid gap-2">
            {<button className='btn btn-primary mt-3' disabled={submitDisable}>STOCK UPDATE</button>}
          </div>
        </div>

      </form>}

      {checked && <form className='row justify-content-center'>
        <div className='col-lg-4 col-md-8 col-12'>
          <Text
            label="Stock Arrival"
            type = "Number"
            value = {updateStock}
            onChange={(e) => {
              setUpdateStock(e.target.value)
              setAmount((e.target.value * tank?.productCode?.cost)?.toFixed(2))
            }}
            end = "L"
          />
        
          {depData &&
            <div>
              <SelectAutoComplete
                label = "Before Load"
                notFoundLabel = "Depth"
                optionsList={depData}
                option = {(i) => `${i.dep} | ${i.volume} L`}
                value={dep1}
                onChange={(event, value) => {
                    setDep1(value)
                }}
              />

            <SelectAutoComplete
                label = "After Load"
                notFoundLabel = "Depth"
                optionsList={depData}
                option = {(i) => `${i.dep} | ${i.volume} L`}
                value={dep2}
                onChange={(event, value) => {
                    setDep2(value)
                }}
              />

              <div className='d-flex justify-content-between pt-2'>
                Litters as Entered <dd>{new Intl.NumberFormat().format(updateStock)} L</dd>
              </div>
              <div className='d-flex justify-content-between'>
                Litters on Depth <dd>{new Intl.NumberFormat().format(dif)} L</dd>
              </div>
              <hr></hr>
              <div className='d-flex justify-content-between pb-2'>
                Differance {(Math.abs(Number(dif - updateStock)) < 500 ? <dd style={{color: '#39ff14'}}>{new Intl.NumberFormat().format(dif - updateStock)} L</dd> : <dd style={{color: '#FF0000'}}>{new Intl.NumberFormat().format(dif - updateStock)} L</dd>)}
              </div>
            </div>
          }
        </div>

        <div className='col-lg-4 col-md-8 col-12'>

          <SelectAutoComplete
            label = "Payment Method"
            optionsList={payMethod}
            option = {(i) => i?.name}
            value={pay}
            onChange={(event, value) => {
                setPay(value._id)
            }}
          />

          {pay === '63e31a93f029ceab39dcd281' && 
          <SelectAutoComplete
            label = "Bank"
            optionsList={listBank}
            option = {(i) => (i?.bank + ' | ' + i?.accountNo)}
            value={bank}
            onChange={(event, value) => {
                setBank(value)
            }}
          />}

          <Text
            label="Amount" 
            value = {amount}
            type = "number"
            onChange={(e) => setAmount(e.target.value)}
            end = "LKR"
          />
        </div>

        <div className="col-lg-4 col-md-8 col-12">
          {listSuppliers &&
            <div>
              <SelectAutoComplete
                label = "Supplier"
                optionsList={listSuppliers}
                option = {(i) => `${i.name + ' | '+ i.phoneNumber}`}
                value={supplier}
                onChange={(event, value) => {
                    setSupplier(value)
                }}
              />
              <button type="button" className="btn btn-link p-0 mb-3" onClick={() => window.open(`/dashboard/user/admin/supplier/List`,'_blank')}>Add Supplier</button>
            </div>
          }

          <Text
            label="Invoice No"
            value = {invoiceNo}
            onChange={(e) => setInvoiceNo(e.target.value)} 
          />

          <FileUpload submit = {getPDFData} fileType= "PDF"/>

          <button className='btn btn-primary mt-3 w-100' onClick={handleSubmit} disabled={(pay && invoiceNo && supplier ? submitDisable2 : true) || checkCashflow}>PURCHASE</button>
          {checkCashflow && <dd className='text-center fs-6 fw-bold text-danger mt-2'>Cashflow Verified on Selected date</dd>}
        </div>
      </form>}

      <StockLoadList data={tank}/>
    </div>
  )
}
