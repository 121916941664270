import React, {useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import {Text} from '../../../Components/Inputs/InputFields'

import {Check, Clear} from '@mui/icons-material';

export default function UpdatePointsCustomer(props) {
    const CustomerData = props.customer;

    const [name, setName] = useState(CustomerData?.name)
    const [nic, setNic] = useState(CustomerData?.nic)
    const [phoneNumber, setPhoneNumber] = useState(CustomerData?.phoneNumber)
    const [address, setAddress] = useState(CustomerData?.address)

    const [submitDisable, setSubmitDisable] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await setSubmitDisable(true)
            const {data} = await Axios.put(`/pointscustomer/${CustomerData?._id}`, {...CustomerData, name, nic, phoneNumber, address});
            if(data?.error) {
                toast.error(data.error);
                setSubmitDisable(false)
            } else {
                toast.success("Customer Details sucessfully Updated");
                window.location.reload()
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        
        }
    }
    
    return (
        <div className='p-3'>
            <h4 className='text-center mb-3'>Customer : {name}</h4>
            <form onSubmit={handleSubmit}>
                <Text
                    label="Name"
                    value = {name}
                    onChange={(e) => setName(e.target.value)}
                    focus = 'true' 
                />

                <Text
                    label="NIC Number"
                    value = {nic}
                    onChange={(e) => setNic(e.target.value)}
                    end = {nic.length > 0 ? (nic.length === 10 || nic.length === 12 ? <Check color='success' /> : <Clear color='error'/>) : ''}
                />

                <Text
                    label="Phone Number"
                    type ="Number"
                    value = {phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)} 
                    end = {phoneNumber.trim() > 0 ? (phoneNumber.length === 10 ? <Check color='success' /> : <Clear color='error'/>) : ''}
                />

                <Text
                    label="Address"
                    value = {address}
                    onChange={(e) => setAddress(e.target.value)} 
                />
            
                <div class="d-grid gap-2">
                <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
                    UPDATE CUSTOMER
                </button>
                </div>
            </form>
        </div>
    )
}
