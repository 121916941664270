import React, { useState, createContext, useContext, useEffect } from "react";
import moment from 'moment'

const GdateContext = createContext();

const GdateProvider = (props) => {
    const [gdate, setGdate] = useState({
        date: moment(new Date()).format("YYYY-MM-DD"),
        reportStartDate: moment().startOf('month').format('YYYY-MM-DD'),
        reportEndDate: moment().endOf('month').format('YYYY-MM-DD'),
        cashflowDate: moment(new Date()).format("YYYY-MM-DD"),
        cashflowStatus: true
    });

    useEffect(() => {
        const data = localStorage.getItem("globalDates");
        if (data) {
            const parsed = JSON.parse(data);
            setGdate({...gdate, 
                date: parsed?.date, 
                reportStartDate: parsed?.reportStartDate, 
                reportEndDate: parsed?.reportEndDate, 
                cashflowDate: parsed?.cashflowDate, 
                cashflowStatus: parsed?.cashflowStatus
            });
        }
    }, []);

    return(
        <GdateContext.Provider value={[gdate, setGdate]}>
            {props.children}
        </GdateContext.Provider>
    );
};

const useGdate = () => useContext(GdateContext);

export {GdateProvider, useGdate};