import React, {useState,useEffect} from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment'

import {GeneralNo, unit, currency} from '../../../Functions/NoFomats'
import MaterialTable from '../../../Components/Table/MaterialTable'

export default function ListTankCheck() {
    const {id} = useParams()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const [listTankChecks, setListTankChecks] = useState('')

    useEffect(() => {
        loadTankCheck()
      },[id])

      
    const loadTankCheck = async() => {
        try {
            const {data} = await Axios.post(`/tank-checks`, {"wareHouseStock._id": id})
            if(data?.error){
                toast.error(data.error)
            } else {
                await setListTankChecks(data?.reverse()?.map((i) => {
                    const purchaseLoad = (i?.loadLiters/ i?.wareHouseStock?.productCode?.product?.type?.loadLiter)
                    const fillupL = (i?.pumpList?.length > 0 ? i?.pumpList.reduce((a,v) => a = a + (v?.liters) - (v?.testing), 0) : 0)
                    const fillupLoad = (fillupL / i?.wareHouseStock?.productCode?.product?.type?.loadLiter)
                    const tankCheckStock = ((i?.loadLiters - fillupL) ? (i?.loadLiters - fillupL) : 0)
                    const currentStock = i?.wareHouseStock?.stock
                    const actualStock = (i?.actualStock ? i?.actualStock : ((i?.loadLiters - fillupL) ? (i?.loadLiters - fillupL) : 0))
                    return ({...i, purchaseLoad, fillupL, fillupLoad, tankCheckStock, currentStock, actualStock})
                }))
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const columns = [
        { accessorKey: 'date', header: 'Date', size: 100},
        { accessorKey: 'purchaseLoad', header: 'Purchase Load', size: 100, Cell : ({row: {original}}) => GeneralNo(original.purchaseLoad)},
        { accessorKey: 'fillupLoad', header: 'Fillup Load', size: 100, Cell : ({row: {original}}) => GeneralNo(original.fillupLoad)},
        { accessorKey: 'purchaseL', header: 'Purchase L', size: 100, Cell : ({row: {original}}) => unit(original.loadLiters, "L")},
        { accessorKey: 'fillupL', header: 'Fillup L', size: 100, Cell : ({row: {original}}) => unit(original.fillupL, "L")},
        { accessorKey: 'tankCheckStock', header: 'Tank Check Stock', size: 100, Cell : ({row: {original}}) => unit(original.tankCheckStock, "L")},
        { accessorKey: 'currentStock', header: 'Current Stock', size: 100, Cell : ({row: {original}}) => unit(original.currentStock, "L")},
        { accessorKey: 'actualStock', header: 'Actual Stock', size: 100, Cell : ({row: {original}}) => unit(original.actualStock, "L")},
        { accessorKey: 'amount', header: 'Loss Amount', size: 100, Cell : ({row: {original}}) => currency(original.amount ? original.amount : 0)},
        { accessorKey: 'createdAt', header: 'Marked On', size: 100, Cell : ({row: {original}}) => moment(original.createdAt).format("YYYY-MM-DD hh:mm A")},
    ]

    const detailPanel = ({row: {original}}) => 
        <MaterialTable
            col = {[
                {accessorKey: 'name', header: 'Pump'},
                {accessorKey: 'previousReading', header: 'Previous Reading'},
                {accessorKey: 'reading', header: 'Current Reading'},
                {accessorKey: 'liters', header: 'Liters'},
                {accessorKey: 'testing', header: 'Testing'}
            ]}
            row = {original?.pumpList}
            expandDisable = {true}
            topToolbar = {false}
        />

    return (
        <div className='row justify-content-center'>
            <div className='col-12 mb-3'>
                <div className='row mb-2'>
                    <div className='col-xxl-3 col-xl-4 col-lg-5 col-sm-6 col-12 d-flex gap-2 mb-2'>
                        <button className = 'btn btn-primary w-50' onClick={() => navigate(`/dashboard/user/tank/tank-check/${id}`)}>Check Tank</button>
                    </div>
                </div>

                <div>
                    <MaterialTable
                        title = {'Tank ' + searchParams.get('name')?.toUpperCase() + ' Checks List'} 
                        col = {columns}
                        row = {listTankChecks}
                        detailPanel = {detailPanel}
                    />
                </div>
            </div>
        </div>
    )
}
