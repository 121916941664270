import React, {useEffect, useState} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import MaterialTable from '../../Components/Table/MaterialTable'

export default function Stocks() {
    const [listProducts, setListProducts] = useState('')

    useEffect(() => {
        loadProducts()
    },[])

    const loadProducts = async() => {
        try {
            const {data} = await Axios.post(`/warehousestocks/group-byproductcode`)
            if(data?.error){
                toast.error(data.error)
            } else {
                setListProducts(data?.filter((i) => i?.totalStock < 20))
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const col = [
        { accessorKey: 'productCode.productCode', header: 'Code', size: 100},
        { accessorKey: 'productCode.product.brand.name', header: 'Brands', size: 100},
        { accessorKey: 'productCode.product.name', header: 'Products', size:100},
        { accessorKey: 'totalStock', header: 'Total Stock', size: 100, 
            Cell: ({cell: {row: {original}}}) => `${original.totalStock} ${original.productCode?.product?.unit}`,
        },
    ]

    return (
        <div className='mt-3'>
            <MaterialTable col={col} row={listProducts} title = 'Low Stocks'/>
      </div>
    )
}
