import React, {useState,useEffect} from 'react'
import { useNavigate, createSearchParams } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useAuth } from '../../../context/auth'
import { currency } from '../../../Functions/NoFomats'

import {Add, Edit, Receipt} from '@mui/icons-material/'

import MaterialTable from '../../../Components/Table/MaterialTable'
import ModalBasic from '../../../Components/Modal/ModalBasic'

import CreateBankAccount from './CreateBankAccount'
import UpdateBankAccount from './UpdateBankAccount'

import AccountsForm from '../DoubleEntires/AccountsForm'

export default function ListBankAccounts() {

    const navigate = useNavigate()
     const [auth] = useAuth()
    const [listBanks, setListBanks] = useState('')
    const [listBalanceDetails, setListBalanceDetails] = useState('')
    const [listGivenCheques, setListGivenCheques] = useState()
    const [formatData, setFormatData] = useState('')

    //Model
    const [visibleAdd, setVisibleAdd] = useState(false)

    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState(null)

    const [visibleCredit, setVisibleCredit] = useState(false)
    const [visibleDebit, setVisibleDebit] = useState(false)

    useEffect(() => {
        loadBankAccounts()
        loadBalanceDetails()
        loadGivenCheques()
    }, [])

    useEffect(() => {
        listBanks && listBalanceDetails && setFormatData(listBanks?.map((b) => {
            const chequeTotal = listGivenCheques?.find((g) => b?._id === g?._id?._id)?.total ? listGivenCheques?.find((g) => b?._id === g?._id?._id)?.total : '' 
            const effects = listBalanceDetails?.find((d) => d?._id?._id === b?._id)?.total
            return {...b, chequeTotal, effects: (effects ? effects : 0)}
        }))
    }, [listBanks, listBalanceDetails, listGivenCheques])

    const loadBankAccounts = async() => {
        try {
            const {data} = await Axios.post('/bank-accounts')
            if(data?.error){
                toast.error(data.error)
            } else {
                setListBanks(data)
            }
        } catch (err) {
            toast.error("Something went wrong, Try Again!")
            console.log(err)
        }
    }

    const loadBalanceDetails = async() => {
        try {
        const {data} = await Axios.post(`/accdatas/banktotal`, {  $expr : { $eq: [ '$accounting' , { $toObjectId: '63e31a93f029ceab39dcd281' } ] }, "detail.bank" : { $exists: true} })
          if (data?.error) {
              toast.error(data.error)
          } else {
            setListBalanceDetails(data)
          }
        } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
        }
    }

    const loadGivenCheques = async() => {
        try {
          const {data} = await Axios.post(`/cheques/given-bank`, {status: 'debit', chqDeposit: false})
          if (data?.error) {
              toast.error(data.error)
          } else {
            setListGivenCheques(data)
          }
        } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
        }
    }

    const columns = [
        { accessorKey: 'bank', header: 'Bank', size: 100},
        { accessorKey: 'accountName', header: 'Account Name', size: 100},
        { accessorKey: 'accountType', header: 'Acc Type', size: 100},
        { accessorKey: 'accountNo', header: 'Account No', size: 100},
        { accessorKey: 'branch', header: 'Branch'},
        { accessorKey: 'effects', header: 'Balance', size: 100, 
            Cell: ({row: {original}}) => currency(original.effects),
            export: (i) => currency(i)
        },
        { accessorKey: 'chequeTotal', header: 'Cheque Total', size: 100, 
        Cell: ({row: {original}}) => currency(original.chequeTotal),
        export: (i) => currency(i)
        }
    ]

    const rowAction = ({row: {original}}) => [
        <div className='d-flex justify-content-center gap-2'>
            <Receipt
                onClick={(e) => {
                    navigate()
                    navigate({
                        pathname: `/dashboard/user/bank-accounts/${original._id}`,
                        search: `?${createSearchParams({
                        name: original.accountName,
                        chequeTotal: original.chequeTotal
                        })}`
                    });
                }}
            />
            <Edit
                onClick={() => {
                    setVisible(true)
                    setSelected(original)
                }}
            />
        </div>
    ]

    return (
        <div className='row justify-content-center'>
            <div className='col-12 mb-2'>
                <div className='hstack gap-3 mb-2'>
                    <button className = 'btn btn-outline-primary' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
                    <button className = 'btn btn-outline-success ms-auto' onClick={() => {setVisibleDebit(true)}}><Add/> Debit</button> 
                    <button className = 'btn btn-outline-danger' onClick={() => {setVisibleCredit(true)}}><Add/> Credit</button>
                </div>

                <div>
                    <MaterialTable
                        title = {'Bank Accounts'}
                        col = {columns} 
                        row = {formatData}
                        rowAction = {rowAction}
                    />
                </div>

                <ModalBasic
                    open = {visibleAdd}
                    onClose = {()=> {
                        setVisibleAdd(false)
                    }}
                    content = {auth?.employee?.role === 0 ? <CreateBankAccount/> : <div className='text-center'>You don't have Permission</div>}
                />

                <ModalBasic
                    open = {visible}
                    onClose = {()=> {
                        setVisible(false)
                    }}
                    content = {<UpdateBankAccount bank = {selected}/>}
                />

                <ModalBasic
                    size = '80%'
                    open = {visibleDebit}
                    onClose = {()=> {
                    setVisibleDebit(false)
                    }}
                    content = {<AccountsForm data= {{inputAccounting: '63e31a93f029ceab39dcd281'}}/>}
                />

                <ModalBasic
                    size = '80%'
                    open = {visibleCredit}
                    onClose = {()=> {
                    setVisibleCredit(false)
                    }}
                    content = {<AccountsForm data= {{inputAccounting2: '63e31a93f029ceab39dcd281'}}/>}
                    
                />
            </div>
        </div>
        )
}
