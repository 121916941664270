import React, {useRef, useState, useEffect} from 'react'
import Axios from 'axios'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import {toast} from 'react-hot-toast'
import {useReactToPrint} from 'react-to-print'

import { useDk } from '../../../../context/dk'

import {currency, unit} from '../../../../Functions/NoFomats'

import {Print} from '@mui/icons-material'
import { styled } from '@mui/material/styles';
import {Table, TableBody, TableCell, TableHead, TableRow, Paper} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';

import PDFViewer from '../../../../Components/Viewers/PDFViewer'

export default function PurchaseDetails() {
    const {id} = useParams()

    const [dk, setDk] = useDk();

    const [PurchaseDetails, setPurchaseDetails] = useState('')
    const [items, setItems] = useState('')

    useEffect(() => {
        loadPurchaseDetails()
        loadItems()
    },[])

    const loadPurchaseDetails = async() => {
        try {
            const {data} = await Axios.post(`/purchases`, {_id: id})
            if (data?.error) {
                toast.error(data.error)
            } else {
                setPurchaseDetails(data[0])
            }
        } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
        }
    }

    const loadItems = async() => {
        try {
            const {data} = await Axios.post(`/purchaseproductlines`, {purchase: id})
            if (data?.error) {
                toast.error(data.error)
            } else {
                setItems(data)
            }
        } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
        }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
    }));

    const componentRef = useRef();
    const printData = useReactToPrint({
        content : () => componentRef.current,
        documentTitle: `Purchase Report ${PurchaseDetails?.supplier?.name} - ${PurchaseDetails?.invoiceNo}`,
        onBeforeGetContent: async() => {
            await setDk({...dk, darkmode : false})
        },
        onAfterPrint: () => {
            const data = localStorage.getItem("darkmode");
            if (data) {
                const parsed = JSON.parse(data);
                setDk({...dk, darkmode: parsed});
            }
        }
    })

    return (
        <div className='row justify-content-center'>
            <div className='d-flex justify-content-between'>
                <button className='btn btn-warning' onClick={printData}><Print/></button>
                <div className='d-flex gap-2 align-items-center'>
                    {PurchaseDetails?.invoice?.file ? "INVOICE AVAILABLE" : "INVOICE NOT FOUND"}
                    {PurchaseDetails?.invoice?.file && <PDFViewer data={PurchaseDetails?.invoice?.file}/>}                
                </div>

            </div>

            <div className='row justify-content-center p-5' ref= {componentRef}>
                <h3 className='text-center'>Purchase Report</h3>

                <hr/>
                <dd className='text-end lh-sm' style={{fontSize: '14px'}}>Marked On: {moment(PurchaseDetails?.createdAt).format("YYYY-MM-DD hh:mm A")}</dd>

                <div className='d-flex justify-content-between mb-2 w-75'>
                    <div className='d-flex gap-2' style={{width : 300}}>
                        <dd style={{fontSize: '14px'}}>SUPPLIER:</dd>
                        <div>
                            <dd style={{fontSize: '14px'}}>Name: {PurchaseDetails?.supplier?.name}</dd>
                            <dd style={{fontSize: '14px'}}>Company: {PurchaseDetails?.supplier?.company}</dd>
                            <dd style={{fontSize: '14px'}}>Address: {PurchaseDetails?.supplier?.address}</dd>
                            <dd style={{fontSize: '14px'}}>Phone No: {PurchaseDetails?.supplier?.phoneNumber}</dd>
                        </div>
                    </div>

                    <div className='row text-end' style={{width : 300}}>
                        <dd style={{fontSize: '14px'}}>INVOICE NO: {PurchaseDetails?.invoiceNo}</dd>
                        <dd style={{fontSize: '14px'}}>DATE: {PurchaseDetails?.purchaseDate}</dd>
                        <dd style={{fontSize: '14px'}}>PAYMENT: {PurchaseDetails?.payment?.name}</dd>
                        <dd style={{fontSize: '14px'}}>NO.ITEMS: {items?.length}</dd>
                    </div>
                </div>

                <div style={{overflowX: 'scroll'}}>
                    {items &&
                        <Table className='w-100' size='small' component={Paper}>
                            <TableHead>
                                <TableRow>
                                {['#', 'Product Code', 'Product', 'Warehouse', 'Qty', 'Unit Price', 'Item Price']?.map((i) => 
                                    <StyledTableCell style={{fontSize: '12px'}}>{i}</StyledTableCell>
                                )}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {items?.map((i,index) =>
                                <TableRow key={index}>
                                    {[(index+1), i?.productCode?.productCode, i?.productCode?.product?.name, i?.wareHouse?.name, unit(i?.quantity, i?.productCode?.product?.unit), currency(i?.itemPrice / i?.quantity), currency(i?.itemPrice)]?.map((i) => 
                                    <StyledTableCell style={{fontSize: '12px'}}>{i}</StyledTableCell>
                                    )}
                                </TableRow> 
                                )}
                            </TableBody>
                        </Table>
                    }
                </div>

                <div className='row m-3 pt-3 justify-content-end'>
                    <div className='col-md-4 col-sm-6 col-12'>
                        <div className='row'>
                            <div className='d-flex justify-content-between'>
                                <p className='lh-sm fw-bold' style={{fontSize: '16px'}}>Total:</p>
                                <p className='lh-sm fw-bold' style={{fontSize: '16px'}}>{PurchaseDetails ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(PurchaseDetails?.total)}` : '0.00'}</p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}
