import React, {useState,useEffect} from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useGdate } from '../../context/date'
import { currency } from '../../Functions/NoFomats'

import { Visibility } from '@mui/icons-material'

import MaterialTable from '../../Components/Table/MaterialTable'

export default function ListSupplierAccounts() {
    const navigate = useNavigate()
    const {id} = useParams()
    const [searchParams] = useSearchParams()
    const [gdate] = useGdate()

    const [allData, setAllData] = useState('')
    const [listAccData, setListAccData] = useState('')

    useEffect(() => {
        loadAccData()
      },[id])

    useEffect(() => {
        allData && filterDataByDate()
      },[allData, gdate?.reportStartDate, gdate?.reportEndDate])


    
    const loadAccData = async() => {
        try {
            const {data} = await Axios.post(`/accdatas`, {accounting: "64acdf13722d07654e219f89", "detail.supplier": id})
            if(data?.error){
                toast.error(data.error)
            } else {
                var effects = 0
                await setAllData(data?.map((i) => {
                    effects += i?.amount
                    var debit = ''
                    var credit = ''
                    i.amount < 0 ? debit = i?.amount : credit = i?.amount
                    return {...i, debit, credit, effects: effects}
                }))
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const filterDataByDate = async() => {
        try {
            setListAccData(allData?.filter((i) => i?.date >= gdate?.reportStartDate && i?.date <= gdate?.reportEndDate))
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const columns = [
        {accessorKey: 'date', header: 'Date', size: 100},
        {accessorKey: 'detail.description', header: 'Description'},
        {accessorKey: 'debit', header: 'Debit', size: 100, Cell: ({row: {original}}) => original.debit && currency(original.debit, true), export: (i) => i && currency(i, true), exportHead: 'Debit',},
        {accessorKey: 'credit', header: 'Credit', size: 100, Cell: ({row: {original}}) => original.credit && currency(original.credit, true), export: (i) => i && currency(i, true), exportHead: 'Credit',},
        {accessorKey: 'effects', header: 'Effects', size: 100, Cell: ({row: {original}}) => currency(original.effects), export: (i) => currency(i)}
    ]

    const rowAction = ({row: {original}}) => [
        <div className='d-flex justify-content-center gap-2'>
          {original.detail.purchase && <Visibility
            onClick={(e) => {
              navigate({
                pathname: `/dashboard/user/inventory/purchase-report/${original.detail.purchase}`
              })
            }}
          />}
        </div>
      ]

    return (
        <div className='row justify-content-center'>
            <div className='col-12 mb-3'>
                <dd>Total Cheques Given : {currency(searchParams.get('chequeTotal'))}</dd>
                <MaterialTable
                    title = {listAccData && 'Supplier ' + searchParams.get('name') + ' | ' + gdate?.reportStartDate + ' - ' + gdate?.reportEndDate}
                    col={columns} 
                    row={listAccData}
                    rowAction = {rowAction}
                />
            </div>
        </div>
    )
}
