import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useAuth } from '../../context/auth';

import {Add, Edit, DeleteForever} from '@mui/icons-material/'

import MaterialTable from '../../Components/Table/MaterialTable';
import ModalBasic from '../../Components/Modal/ModalBasic'

import CreatePump from './CreatePump'
import UpdatePump from './UpdatePump'

export default function ListPumps() {
  const [auth] = useAuth();

  const [listPumps, setListPumps] = useState('')
  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  const [visible1, setVisible1] = useState(false)
  const [selected1, setSelected1] = useState(null)

  useEffect(() => {
      loadPumps()
  }, [])
  

  const loadPumps = async() => {
      try {
          const {data} = await Axios.post('/pumps')
          if(data?.error){
              toast.error(data.error)
          } else {
            setListPumps(data)
          }
      } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
      }
  }

  const handleDeletePump = async(pumpId) => {
    try {
      const {data} = await Axios.delete(`/pump/${pumpId}`)
      if (data?.error) {
        toast.error(data.error)
      } else {
        toast.success('Day Pump Record Deleted')
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const columns = [
    { accessorKey: 'name', header: 'Pump', size: 100},
    { accessorKey: 'wareHouseStock.productCode.product.name', header: 'Tank', size: 100, exportHead: 'Tank'},
    { accessorKey: 'booth.name', header: 'Booth', size: 100, exportHead: 'Booth'},
  ]

  const rowAction = ({row: {original}}) => [
    <div className='d-flex gap-2'>
      <Edit
        onClick={() => {
          setVisible(true)
          setSelected(original)
        }}
      />
      <DeleteForever
        onClick={() => {
          setVisible1(true)
          setSelected1(original)
        }}
      />
    </div>
  ]

  return (
    <div className='row justify-content-center'>
      <div className='col-xl-8 col-lg-10 col-12 mb-3'>
      <   div className='row justify-content-between mb-2'>
            <div className='col-md-4 col-sm-6 col-12 d-flex gap-2 mb-2'>
                <button className = 'btn btn-outline-primary w-50' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
            </div>
        </div>
        <MaterialTable
          title = "List Pumps"
          col = {columns}
          row = {listPumps}
          rowAction = {rowAction}
        />

        <ModalBasic
          open = {visibleAdd}
          onClose = {()=> {
          setVisibleAdd(false)
          }}
          content = {<CreatePump/>}
        />

        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {<UpdatePump pump={selected}/>}
        />

        <ModalBasic
          open = {visible1}
          onClose = {()=> {
          setVisible1(false)
          }}
          content = {
            auth?.employee?.role === 0 ?
              <div className='d-flex justify-content-between'>
                <p>Are You sure want to?</p>
                <button className='btn btn-outline-danger' onClick={() => {
                    handleDeletePump(selected1._id)
                  }}>Delete
                </button>
              </div>
              :
              <div className='d-flex justify-content-center w-100'>
                <p>You don't have Permission to Delete</p>
              </div>
          }
        />
      </div>
    </div>
  )
}
