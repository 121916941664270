import React, {useState} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useGdate } from '../../../context/date'

import { Text, SelectAutoComplete } from '../../../Components/Inputs/InputFields';

export default function CreateAccount() {
    const [gdate] = useGdate()

    const [date] = useState(gdate.date)
    const [bank, setBank] = useState('')
    const [accountName, setAccountName] = useState('')
    const [accountType, setaccountType] = useState('')
    const [accountNo, setAccountNo] = useState('')
    const [branch, setBranch] = useState('')
    const [amount, setAmount] = useState()

    const [submitDisable, setSubmitDisable] = useState(false)

    const bankList = ['AMANA', 'BOC', 'CARGILLS', 'CITI', 'COMMERCIAL', 'DFCC', 'NDB', 'HNB', 'NATIONS TRUST', 'PAN ASIA', 'PEOPLES', 'SAMPATH', 'SEYLAN', 'UNION']

    const handleSubmit = async(e) => {
        e.preventDefault()
        try {
            await setSubmitDisable(true)
            const {data} = await Axios.post(`/bank-account`, {bank, accountName, accountType, accountNo, branch})
            if(data?.error) {
                toast.error(data.error)
                setSubmitDisable(false)
            } else {
                const data1 = await Axios.post(`/accdatabulk`, [
                  {date, empStatus: {status: false}, detail:{description: `Bank: ${data.bank} - ${data?.accountName} Opening Balance`, bank: data._id}, amount: (amount ? amount : 0), accounting: "63e31a93f029ceab39dcd281", refId: `de-bank-${data?._id}-${date}-Opening Balance`},
                  {date, empStatus: {status: false}, detail:{description: `Bank: ${data.bank} - ${data?.accountName} Opening Balance`}, amount: (amount ? amount : 0), accounting: '6493d4f0157bacc34353cf7b', refId: `de-bank-${data?._id}-${date}-Opening Balance`}
                ])
                if(data1?.data?.error){
                  toast.error(data.error)
                  setSubmitDisable(false)
                } else {
                  toast.success("Customer Added");
                  window.location.reload()
                }
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    return (
        <div className='p-3'>
            <h5 className='text-center text-capitalize'>Bank Accounts</h5>
            <form onSubmit={handleSubmit}>
                <SelectAutoComplete
                    label = "Bank"
                    optionsList={bankList}
                    option = {(i) => i}
                    value={bank}
                    onChange={(event, value) => {
                        setBank(value)
                    }}
                />

                <Text
                    label="Account Name" 
                    value = {accountName}
                    onChange={(e) => setAccountName(e.target.value)}
                />

                <SelectAutoComplete
                    label = "Account Type"
                    optionsList={['SAVINGS', 'CURRENT']}
                    option = {(i) => i}
                    value={accountType}
                    onChange={(event, value) => {
                        setaccountType(value)
                    }}
                />

                <Text
                    label="Account No" 
                    value = {accountNo}
                    onChange={(e) => setAccountNo(e.target.value)}
                />

                <Text
                    label="Branch" 
                    value = {branch}
                    onChange={(e) => setBranch(e.target.value)}
                />

                <Text
                    label="Current Balance"
                    type ="Number"
                    value = {amount}
                    onChange={(e) => setAmount(e.target.value)} 
                    end = {amount ? (amount > 0 ? "Cr" : "Dr") : ''}
                />

                <div className="d-grid gap-2">
                <button className='btn btn-primary mt-3' name="submitButton" type='submit' disabled = {submitDisable}>ADD BANK</button>
                </div>

            </form>
        </div>
    )
}
