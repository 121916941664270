import React, {useState, useEffect, useRef} from 'react'
import Axios from 'axios'
import { toast } from 'react-hot-toast'
import moment from 'moment'

import { useGdate } from '../../../context/date'

import {currency} from '../../../Functions/NoFomats'

import { Text, SelectAutoComplete } from '../../../Components/Inputs/InputFields'
import {TextField, Autocomplete, Button, Paper} from '@mui/material';
import {Clear} from '@mui/icons-material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarcode } from '@fortawesome/free-solid-svg-icons'

import MaterialTable from '../../../Components/Table/MaterialTable'
import ModalBasic from '../../../Components/Modal/ModalBasic'

import Scanner from '../../../Components/Scan-Qr/Scanner'

export default function PurchaseReturnForm() {
    const [gdate] = useGdate()

    const [listBanks, setListBanks] = useState('')
    const [listPurchase, setlistPurchase] = useState('')

    const [state, setState] = useState(true)

    const [selectInvoiceVal, setSelectInvoiceVal] = useState('')
    const [invoiceInputValue, setInvoiceInputValue] = useState('')
    
    const [listProducts, setListProducts] = useState('')

    const [selectProductVal, setSelectProductVal] = useState('')
    const [productInputValue, setProductInputValue] = useState('')

    const [tank, setTank] = useState()
    const [quantity, setQuantity] = useState()
    const [returnPrice, setReturnPrice] = useState('')

    //Mark Date should be morethan cashflow verified date
    const [checkCashflow, setCheckCashflow] = useState(false)

    const [items, setItems] = useState([])
    const [returnDate] = useState(gdate?.date)
    const [paymentStatus, setPaymentStatus] = useState('')
    const [payment, setPayment] = useState()
    const [bank, setBank] = useState()
    const [returnValue, setReturnValue] = useState('')

    const [visibleScanner, setVisibleScanner] = useState(false)

    const [payMethod] = useState([{_id:'64952f578188d2c8b9c26603', name: 'Cash', type: 'credit'}, {_id:'63e31a93f029ceab39dcd281', name: 'Bank', type: 'credit'}, {_id:'64acdf13722d07654e219f89', name: 'Credit', type: 'debit'}])
    const productFocusRef = useRef()

    const [submitDisable, setSubmitDisable] = useState(false)

    useEffect(() => {
        loadBanks()
        loadPurchase()
        setCheckCashflow(() => moment(gdate?.cashflowDate) > moment(returnDate) ? true : false)
    },[])

    useEffect(() => {
        setItems('')
        selectInvoiceVal && loadProductLines()
        selectInvoiceVal?.payment?._id === "63e31abdf029ceab39dcd283" ? setPayment(selectInvoiceVal?.payment?._id) : setPayment("64952f578188d2c8b9c26603")
    },[selectInvoiceVal])

    useEffect(() => {
        items && setReturnValue(items?.reduce((t,i) => t = t + Number(i?.returnPrice), 0)?.toFixed(2))
    },[items])

    useEffect(() => {
        setPayment()
    }, [paymentStatus])

    useEffect(() => {
        setBank()
    }, [payment])

    const loadBanks = async() => {
        try {
            const {data} = await Axios.post(`/bank-accounts`)
            if(data?.error){
                toast.error(data?.error)
            } else {
                setListBanks(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const loadPurchase = async() => {
        try {
            const {data} = await Axios.post(`/purchases`)
            if (data?.error){
                toast.error(data.error)
            } else {
                setlistPurchase(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong!, Try Again")
        }
    }

    const loadProductLines = async() => {
        try {
            const {data} = await Axios.post(`/purchaseproductlines`, {purchase: selectInvoiceVal._id})
            if(data?.error) {
                toast.error(data.error)
            } else {
                setListProducts(data)
            }
        } catch (err) {
        console.log(err)
        toast.error("Something went wrong! Try Again")
        }
    }

    const findTank = async() => {
        try {
          const {data} = await Axios.post('/warehousestocks/filtercategory', {_id: "63e65efc2f840202604fbb7f"})
          if (data?.error){
            toast.error(data?.error)
          } else {
            setTank(data?.find((i) => i?.productCode?._id === selectProductVal?.productCode?._id  && i?.wareHouse?._id === selectProductVal?.wareHouse?._id))
          }
        } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
        }
    }

    const getBarcodeData = (data) => {
        setInvoiceInputValue(data?.result?.decodedText)
        if (selectInvoiceVal.invoiceNo !== data?.result?.decodedText) {
            listPurchase && setSelectInvoiceVal((listPurchase.find((i) => data?.result?.decodedText === i.invoiceNo)) ? (listPurchase.find((i) => data?.result?.decodedText === i.invoiceNo)) : '')
        }
        setVisibleScanner(false)
        data?.result?.decodedText && setState(false)
    }

    const productAdd = (e) => {
        e.preventDefault()
        try {
            if(selectInvoiceVal?.type === 'gas') {
                findTank()
            }
            if(quantity <= selectProductVal?.quantity && quantity > 0) {
                setItems([...items, {productLine: selectProductVal, quantity, returnPrice}])
                setQuantity('')
                setReturnPrice('')
                setProductInputValue('')
                setSelectProductVal('')
                productFocusRef?.current?.firstChild?.focus();
            }
            else {
                toast.error(`${selectProductVal?.quantity + ' ' + selectProductVal?.productCode?.product?.unit} Ordered`)
                setQuantity(1)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong!, Try Again")
        }
    }

    const tankStockHandle = async() => {
        if (selectInvoiceVal?.type === 'gas') {
            const stockData = await Axios.post(`/stockload`, {wareHouseStock: tank, purchase: selectInvoiceVal?._id, quantity: Number(-items[0]?.quantity)?.toFixed(3)})
            if (stockData?.data?.error) {
                toast.error(stockData?.data.error)
                setSubmitDisable(false)
            } else {
                toast.success("Purchase Returns Updated")
                window.location.reload()
            }
        } else {
            toast.success("Purchase Returns Updated")
            window.location.reload()
        }
    }

    const PurchaseSubmit = async(e) => {
        e.preventDefault()
        try {
            await setSubmitDisable(true)
            if(paymentStatus === "received") {
                const {data} = await Axios.post(`/purchasereturn`, {purchase: selectInvoiceVal?._id, returnDate, items, total: Number(returnValue), payment: payment._id, paymentStatus})
                if (data?.error) {
                    toast.error(data.error)
                    setSubmitDisable(false)
                } else {
                    const data1 = await Axios.post(`/accdata`, {date: moment(returnDate).format("YYYY-MM-DD"), empStatus: {status: false}, detail:{description: `Return Purchase No: ${selectInvoiceVal?.invoiceNo}`}, amount: Number(-returnValue)?.toFixed(2) , accounting: "63ecfbe55b36ecf83d9dd4a3"})
                    const data2 = await Axios.post(`/accdata`, {date: moment(returnDate).format("YYYY-MM-DD"), empStatus: {status: false}, detail:{description: `Return Purchase No: ${selectInvoiceVal?.invoiceNo}`, supplier: selectInvoiceVal?.supplier?._id, bank}, amount: Number(returnValue >= 0 ? (payment?.type === 'credit' ? returnValue : -returnValue) : (payment?.type === 'credit' ? -returnValue : returnValue))?.toFixed(2) , accounting: payment})
                    if(data1?.data?.error || data2?.data?.error){
                        toast.error(data1?.data?.error || data2?.data?.error)
                        setSubmitDisable(false)
                    } else {
                        tankStockHandle()
                    }
                }
            } else {
                const {data} = await Axios.post(`/purchasereturn`, {purchase: selectInvoiceVal?._id, returnDate, items, total: Number(returnValue), paymentStatus})
                if (data?.error) {
                    toast.error(data.error)
                    setSubmitDisable(false)
                } else {
                    tankStockHandle()
                }
            }
        } catch (err) {
        console.log(err)
        toast.error("Something went wrong!, Try Again")
        }
    }

    const columns = [
        { accessorKey: 'productLine.wareHouse.name', header: 'Warehouse', size: 100},
        { accessorKey: 'productLine.productCode.product.name', header: 'Item', size: 100},
        { accessorKey: 'quantity', header: 'Qty', size: 100},
        { accessorKey: 'purchasePrice', header: 'Purchase Price', size: 100, Cell: ({row: {original}}) => currency((original.productLine?.itemPrice / original.productLine?.quantity) * original.quantity)},
        { accessorKey: 'returnPrice', header: 'Return Price', size: 100, Cell: ({row: {original}}) => currency((original.returnPrice))},
    ]

    const rowAction = ({row: {original}}) => [
        <div className='d-flex justify-content-center'>
            <Clear
                onClick={() => {
                setItems(items?.filter((c) => c?.productLine?._id !== original.productLine?._id))
                toast.success("Product Removed")
                productFocusRef?.current?.firstChild?.focus();
                }}
            />
        </div>
    ]

    return (
        <div className='row'>
            <div className='col-xxl-8 col-12 p-2'>
                {/* FIND ORDER */}
                <div className='row mb-2'>
                    <div className='col-md-10 col-9'>
                        {state && listPurchase &&
                            <Autocomplete
                                size='small'
                                value={selectInvoiceVal?._id}
                                onChange={(event, value) => {
                                    setSelectInvoiceVal(value)
                                }}
                                inputValue={invoiceInputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInvoiceInputValue(newInputValue)
                                }}
                                options={listPurchase}
                                renderInput={(params) => <TextField {...params} label="Invoice No" autoFocus/>}
                                getOptionLabel = {(params) => `${params?.invoiceNo} | ${params?.purchaseDate}`}
                                noOptionsText = "No Order Found"
                            />
                        }
                    </div>

                    <div className='col-md-2 col-3 d-grid'>          
                        <Button style={{maxHeight: '40px'}} variant="outlined" color='inherit' onClick={() => {setVisibleScanner(true)}}>
                        <FontAwesomeIcon icon={faBarcode}/>
                        </Button>
                    </div>
                </div>

                {/* RETURN PRODUCTS FORM */}
                {selectInvoiceVal && listProducts &&
                <form className='row' onSubmit={productAdd}>
                    <div className='col-md-5 col-12 mb-2'>
                        <Autocomplete
                            style={{minWidth: '50%'}}
                            size='small'
                            value={selectProductVal?.productCodeData}
                            onChange={(event, value) => {
                                setSelectProductVal(value)
                            }}
                            inputValue={productInputValue}
                            onInputChange={(event, newInputValue) => {
                                setProductInputValue(newInputValue)
                            }}
                            options={listProducts}
                            renderInput={(params) => <TextField {...params} label="Product" autoFocus ref={productFocusRef}/>}
                            getOptionLabel = {(params) => `${params?.productCode?.productCode?.toUpperCase() + ' | '+ params.productCode?.product?.name + ' | '+ params?.quantity + ' ' + params.productCode?.product?.unit}`}
                            noOptionsText = "No Product Found"
                        />                
                    </div>

                    <div className='col-md-2 col-sm-4 col-12'>
                        <Text
                            label="Quantity"
                            type="Number"
                            value = {quantity}
                            onChange={(e) => {
                                setQuantity(Number(e.target.value))
                                setReturnPrice(Number(e.target.value * (selectProductVal?.itemPrice / selectProductVal.quantity)?.toFixed(2)))
                            }}
                        />
                    </div>

                    <div className='col-md-3 col-sm-8 col-12'>
                        <Text
                            label="Value"
                            type="Number"
                            value = {returnPrice}
                            onChange={(e) => setReturnPrice(e.target.value)}
                            end = "LKR"
                        />
                    </div>

                    <div className='col-md-2 col-12 d-grid'>
                        {selectProductVal && quantity ? <button className='btn btn-primary' style={{maxHeight: '40px'}} variant="contained" type='submit'>RETURN</button> : <button className='btn btn-dark' style={{maxHeight: '40px'}} variant="contained" type='submit' disabled>RETURN</button>}          
                    </div>
                </form>}

                {/* RETUNED PRODUCTS TABLE */}
                <div className='col-12 mt-2'>
                    <MaterialTable
                        col = {columns}
                        row = {items ? items : []}
                        rowAction = {rowAction}
                        exportDisable = {true}
                    />
                </div>
            </div>

            {/* ORDER SUMMARY */}
            <div className='col-xxl-4 col-12 p-2 d-flex justify-content-center'>
                <Paper elevation={5} className='p-5' style={{minWidth: 400}}>
                    <h4 className='text-center pb-3'>Purchase Returns Summary</h4>
                    <div className='d-flex justify-content-between'><p>Invoice No : </p> <p>{selectInvoiceVal && selectInvoiceVal?.invoiceNo}</p></div>
                    <div className='d-flex justify-content-between'><p>Purchase Date : </p> <p>{selectInvoiceVal && selectInvoiceVal?.purchaseDate}</p></div>
                    <div className='d-flex justify-content-between'><p>Return Date : </p> <p>{returnDate}</p></div>
                    <div className='d-flex justify-content-between'><p>No of Item Returns : </p> <p>{items.length}</p></div>
                    <div className='d-flex justify-content-between'><p>Billed Total : </p> <p>{new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(selectInvoiceVal ? selectInvoiceVal?.total : 0)}</p></div>
                    <hr style={{borderStyle: 'double'}}></hr>
                    <div className='d-flex justify-content-between mb-2'><h5>Returns Value : </h5> <h5>{new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(items.length > 0 ? returnValue : 0)}</h5></div>

                    <SelectAutoComplete
                        label = "Payment Status"
                        optionsList={["received","panding","unknown"]}
                        option = {(i) => i}
                        value={paymentStatus}
                        onChange={(event, value) => {
                            setPaymentStatus(value)
                        }}
                    />

                    {paymentStatus === "received" && payMethod && 
                    <SelectAutoComplete
                        label = "Payment Method"
                        optionsList={payMethod}
                        option = {(i) => i.name}
                        value={payment}
                        onChange={(event, value) => {
                            setPayment(value)
                        }}
                    />}

                    {payment?._id === "63e31a93f029ceab39dcd281" &&
                        <SelectAutoComplete
                            label = "Bank"
                            optionsList={listBanks}
                            option = {(i) => i.bank + ' | ' + i.accountNo}
                            value={bank}
                            onChange={(event, value) => {
                                setBank(value)
                            }}
                        />
                    }

                    <div>
                        <Button className='w-100 mt-2' size='large' color="success" variant="contained" onClick={PurchaseSubmit} disabled={(selectInvoiceVal && items.length && paymentStatus ? submitDisable : true) || checkCashflow}>Checkout</Button>
                        {checkCashflow && <dd className='text-center fs-6 fw-bold text-danger mt-2'>Cashflow Verified on Selected date</dd>}
                    </div>
                </Paper>
            </div>

                <ModalBasic
                    open = {visibleScanner}
                    onClose = {()=> {
                    setVisibleScanner(false)
                    }}
                    content = {
                    <div className='p-3'>
                        <Scanner result = {getBarcodeData}/>
                    </div>
                    }
                />
            </div>
    )
}
