import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import moment from 'moment'
import {toast} from 'react-hot-toast'

import { useAuth } from '../../../context/auth'
import { useGdate } from '../../../context/date'

import {currency} from '../../../Functions/NoFomats'

import MaterialTable from '../../../Components/Table/MaterialTable'
import ModalBasic from '../../../Components/Modal/ModalBasic'

import {Add, DeleteForever} from '@mui/icons-material/'

import StockManage from './StockManage';

export default function ListStockAdjustment() {

    const [auth] = useAuth()
    const [gdate] = useGdate()

    const [listStockAdjustments, setListStockAdjustments] = useState('')
    //Model
    const [visibleAdd, setVisibleAdd] = useState(false)

    const [visible1, setVisible1] = useState(false)
    const [selected1, setSelected1] = useState(null)

    const [submitDisable, setSubmitDisable] = useState(false)

    useEffect(() => {
        loadAdjustment()
    }, [])
  
    const loadAdjustment = async() => {
        try {
            const {data} = await Axios.post('/stock-adjustments')
            if(data?.error){
                toast.error(data.error)
            } else {
                setListStockAdjustments(data)
            }
        } catch (err) {
            toast.error("Something went wrong, Try Again!")
            console.log(err)
        }
    }

    const handleDeleteAdjustment = async(selected) => {
        try {
             await setSubmitDisable(true)
            const {data} = await Axios.delete(`/stock-adjustment/${selected._id}`)
            if (data?.error) {
                toast.error(data.error)
                setSubmitDisable(false)
            } else {
                if (selected?.type === 'exchange') {
                    const {data} = await Axios.post(`/warehousestock/exchange`, [
                        {_id: selected?.wareHouseStock._id, stock: Number(-selected?.quantity).toFixed(3)},
                        {_id: selected?.eWareHouseStock?._id, stock: Number(selected?.quantity).toFixed(3)}
                    ]);
                    if(data?.error) {
                        toast.error(data.error)
                        setSubmitDisable(false)
                    } else {
                        toast.success("Exchanged Deleted")
                        window.location.reload()
                    }
                } else {
                    const {data} = await Axios.post(`/accdatas/removemany`, {refId: `Stockadj-${selected?.date}-${selected?.refNo}`})
                    if (data?.error) {
                        toast.error(data?.error)
                        setSubmitDisable(false)
                    } else {
                        const {data} = await Axios.put(`/warehousestock/${selected?.wareHouseStock._id}`, { $inc: { 'stock': -(Number(selected?.quantity).toFixed(3)) } })
                        if(data?.error) {
                            toast.error(data.error)
                            setSubmitDisable(false)
                        } else {
                            toast.success("Adjustment Deleted")
                            window.location.reload()
                        }
                    }
                }
            }
        } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
        }
    }

    const columns = [
        {accessorKey: 'refNo', header: 'Ref No', size: 100},
        {accessorKey: 'wareHouseStock.productCode.productCode', header: 'Product Code', size: 100},
        {accessorKey: 'wareHouseStock.productCode.product.name', header: 'Product', size: 100},
        {accessorKey: 'date', header: 'Date', size: 100},
        {accessorKey: 'type', header: 'Type', size: 100, Cell: ({row: {original}}) => original.type?.toUpperCase()},
        {accessorKey: 'wareHouseStock.wareHouse.name', header: 'Warehouse', size: 100},
        {accessorKey: 'eWareHouseStock.wareHouse.name', header: 'Transfer From', size: 100},
        {accessorKey: 'quantity', header: 'Quantity', size: 100, Cell: ({row: {original}}) => original.quantity + " " + original.wareHouseStock?.productCode?.product?.unit},
        {accessorKey: 'amount', header: 'Value', size: 100, Cell: ({row: {original}}) => currency(original.amount)},
        {accessorKey: 'createdAt', header: 'Updated On', size: 100, Cell: ({row: {original}}) => moment(original.createdAt).format("YYYY-MM-DD hh:mm A"), Export: (i) => moment(i).format("YYYY-MM-DD hh:mm A")},
    ]

    const rowAction = ({row}) => [
        <div className='d-flex justify-content-center'>
            <DeleteForever
                onClick={() => {
                setVisible1(true)
                setSelected1(row.original)
                }}
            />
        </div>
    ]

    return (
        <div className='row justify-content-center'>
            <div className='col-12 mb-3'>
                <div className='d-flex gap-2 mb-2'>
                    <button className = 'btn btn-outline-primary' onClick={() => {setVisibleAdd(true)}}><Add/> ADJUST</button>
                </div>
                <MaterialTable
                    title = "List of Stock Adjustments"
                    col = {columns}
                    row = {listStockAdjustments}
                    rowAction = {rowAction}
                    exportDisable = {true}
                />

                <ModalBasic
                    open = {visible1}
                    onClose = {()=> {
                    setVisible1(false)
                    }}
                    content = {
                        auth?.employee?.role === 0 ?
                        <div className='d-flex justify-content-between'>
                            <p>Are You sure want to?</p>
                            <button className = 'btn btn-outline-danger' disabled={submitDisable} onClick={() => {
                                handleDeleteAdjustment(selected1)
                            }}>Delete
                            </button>
                        </div>
                        :
                        <div className='d-flex justify-content-center w-100'>
                            <p>You don't have Permission to Delete</p>
                        </div>
                    }
                />

                <ModalBasic
                    open = {visibleAdd}
                    onClose = {()=> {
                    setVisibleAdd(false)
                    }}
                    content = {<StockManage/>}
                />
            </div>
        </div>
    )
}
