import React, {useEffect, useState} from 'react'
import moment from 'moment';
import { NavLink, useNavigate } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { useAuth } from '../../context/auth';
import { useGdate } from '../../context/date'
import { useDk } from '../../context/dk';
import { useMenuType } from '../../context/menu';


import {AccountCircle, LightMode, DarkMode, CalendarMonth} from '@mui/icons-material/';

import ModalBasic from '../Modal/ModalBasic'
import {Text} from '../Inputs/InputFields'
import DateFilter from '../DateFilter/DateFilter'
import Sidebar from '../SideBar/SideBar'


export default function TopMenu() {

  const { Header } = Layout;
  const navigate = useNavigate();

  const [auth, setAuth] = useAuth();
  const [gdate, setGdate] = useGdate()
  const [dk, setDk] = useDk();
  const [menuType, setMenuType] = useMenuType();
  
  const [current, setCurrent] = useState('');

  const [date, setDate] = useState()
  const [reportStartDate, setReportStartDate] = useState()
  const [reportEndDate, setReportEndDate] = useState()

  useEffect(() => {
    document.addEventListener('keydown', detectKeyDown, true)
  },[])

  useEffect(() => {
    setDate(gdate?.date)
    setReportStartDate(gdate?.reportStartDate)
    setReportEndDate(gdate?.reportEndDate)
  },[gdate])

  //Model
  const [visible, setVisible] = useState(false)

  const detectKeyDown = async(e) => {
    const {altKey, key} = e
    if(altKey === true && key?.toLowerCase() === 'q') {
      navigate(`/dashboard/pump/card`)
    }
    if(altKey === true && key?.toLowerCase() === 'w') {
      navigate(`/dashboard/user/tank/card`)
    }
    if(altKey === true && key?.toLowerCase() === 'f') {
      navigate(`/dashboard/user/employee/card`)
    }
    if(altKey === true && key?.toLowerCase() === 'x') {
      navigate(`/dashboard/user/inventory/purchase`)
    } 
    if(altKey === true && key?.toLowerCase() === 's') {
      navigate(`/dashboard/inventory/sale`)
    }
    if(altKey === true && key?.toLowerCase() === 't') {
      e.preventDefault()
      navigate(`/dashboard/user/accounts/cheques`)
    }
    if(altKey === true && key?.toLowerCase() === 'd') {
      e.preventDefault()
      navigate(`/dashboard/user/accounts/entry`)
    } 
    if(altKey === true && key?.toLowerCase() === 'a') {
      navigate(`/dashboard/user/employee/attendance`)
    }
    if(altKey === true && key?.toLowerCase() === 'c') {
      navigate(`/dashboard/user/accounts/daily-cashflow`)
    } 
  }

  const logout = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("globalDates")
    localStorage.removeItem("menuType")
    setAuth({...auth, employee: null, token:""});
    setMenuType({...menuType, value: ""})
    navigate("/");
  }

  const LMode = [
    {
      label: <DarkMode/>,
      key: 'lightmode'
    },
  ]

  const DMode = [
    {
      label: <LightMode/>,
      key: 'darkmode'
    },
  ]

  const account = [
    {
      label: (<NavLink className='text-decoration-none' aria-current="page" to="/login">Login</NavLink>),
      key: 'login',
      icon: <AccountCircle/>,
    },
  ]

  const Loggedaccount = [
    {
      label: <label className='text-capitalize'>{auth?.employee?.name}</label>,
      key: 'account',
      icon: <AccountCircle/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/profile">Profile</NavLink>),
          key: 'profile',
        },
        {
          label: (<a onClick={logout}>Logout</a>),
          key: 'logout',
        },
      ],
    }
  ]

  const DateMenu = [
    {
      label: <CalendarMonth/>,
      key: 'dateMenu'
    },
  ]

  const onClick = (e) => {
    setCurrent(e.key);
  };

  const getData = (d) => {
    setReportStartDate(d.date)
    setReportEndDate(d.date2)
  }
  

  const handleSubmit = async() => {
    try {
      await setGdate({...gdate, date, reportStartDate, reportEndDate})
      await localStorage.setItem( "globalDates", JSON.stringify({...gdate, date, reportStartDate, reportEndDate}))
      window.location.reload()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Header
      className='sticky-top'
      style={{
      padding: 0,
      }}
    >
      <div className='d-flex justify-content-between'>
          {auth?.employee && <Sidebar/>}
          <Menu
            className='d-flex align-items-center'
            theme={'dark'} 
            mode="horizontal"
            onClick={(e) => {
              setDk({...dk, darkmode : !dk.darkmode})
              localStorage.setItem( "darkmode", JSON.stringify(!dk?.darkmode));
            }} 
            items={dk.darkmode ? DMode : LMode}
          />
          {auth?.employee && <Menu
            className='d-flex align-items-center'
            theme={'dark'} 
            mode="horizontal"
            selectedKeys={''} 
            onClick={(e) => {
              setVisible(true)
            }} 
            items={DateMenu}
          />}
          <Menu
            className='d-flex justify-content-end w-100'
            theme={'dark'}
            onClick={onClick} 
            selectedKeys={[current]} 
            mode="horizontal" 
            items={auth?.employee ? Loggedaccount : account} 
          />
      </div>

      <ModalBasic
        open = {visible}
        onClose = {()=> {
          setVisible(false)
        }}
        content = {
          <div className='p-3'>
            <dd className='text-center'>Forms</dd>
            {auth?.employee?.role > 1 ?
            <dd className='text-center'>{date}</dd>
            :
            <Text
              label="System Global Date"
              type = 'date'
              value = {date}
              onChange={(e) => {
                setDate(e.target.value)
              }} 
            />}
            

            <hr></hr>

            <dd className='text-center'>Date Range - Reports & Salary</dd>
            <Text
              label="Start Date"
              type = 'date'
              value = {reportStartDate}
              onChange={(e) => {
                setReportStartDate(e.target.value)
              }} 
            />

            <Text
              label="End Date"
              type = 'date'
              value = {reportEndDate}
              onChange={(e) => {
                setReportEndDate(e.target.value)
              }} 
            />

            <div className='text-center mb-2'>
              <DateFilter onSubmit = {getData}/>
            </div>
            <div className="d-grid gap-2">
              <button className='btn btn-primary' type='submit' onClick={handleSubmit}>SET DATE</button>
            </div>  
          </div>
        }
      />
    </Header>
  )
}
