import React, {useRef, useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useGdate } from '../../../context/date'

import {currency} from '../../../Functions/NoFomats'

import {Add} from '@mui/icons-material/'

import { SelectAutoComplete } from '../../../Components/Inputs/InputFields'

import MaterialTable from '../../../Components/Table/MaterialTable'
import ModalBasic from '../../../Components/Modal/ModalBasic'

import CreateGovOrder from './CreateGovOrder'
import UpdateGovOrder from './UpdateGovOrder'

export default function ListGovOrders() {

  const [gdate] = useGdate()

  const [listGovCustomers, setListGovCustomers] = useState('')
  const [listGovOrders, setListGovOrders] = useState('')
  const [listAccData, setListAccData] = useState('')

  const [customer, setCustomer] = useState()

  const [status, setStatus] = useState()
  const [filterRows, setFilterRows] = useState('')

  const [selectedRows, setSelectedRows] = useState('')
  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    loadGovCustomers()
    loadAccData()
  }, [])

  useEffect(() => {
    listAccData && loadGovOrders()
  }, [listAccData])

  useEffect(() => {
    listGovOrders && setFilterRows(listGovOrders?.filter((i) => i?.customer?._id === customer?._id && i?.status === status?.status ))
  }, [customer, status])
  

  const loadGovCustomers = async() => {
    try {
        const {data} = await Axios.post('/customers')
        if (data?.error) {
            toast.error(data.error)
        } else {
            setListGovCustomers(data?.filter((i) => i?.type === 'government'))
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  const loadAccData = async() => {
    try {
        const {data} = await Axios.post('/accdatas', {accounting: '63e31abdf029ceab39dcd283', "detail.govOrder": {$exists : true}})
        if(data?.error){
            toast.error(data.error)
        } else {
          setListAccData(data)
        }
    } catch (err) {
        toast.error("Something went wrong, Try Again!")
        console.log(err)
    }
  }

  const loadGovOrders = async() => {
      try {
          const {data} = await Axios.post('/govOrders', {receiveDate: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
          if(data?.error){
              toast.error(data.error)
          } else {
              setListGovOrders(data?.map((i) => ({...i, salesAmount: getSalesTotal(i)})))
              setFilterRows(data?.map((i) => ({...i, salesAmount: getSalesTotal(i)})))
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  const getSalesTotal = (row) => listAccData?.filter((a) => a?.detail?.govOrder?._id === row._id)?.reduce((a,v) => a = a + v?.amount, 0)


  const getSelectedRows = (data) => {
    data.length !== selectedRows.length && setSelectedRows(data?.map(d => d?.original))
  }

  const statusStyle = (status) => {
    switch (status) {
      case "submit":
        return "text-primary"
      case "cheque":
        return "text-success"
      default:
        return ""
    }
  }

  const columns = [
    { accessorKey: 'customer.name', header: 'Customer'},
    { accessorKey: 'govOrder', header: 'Order No'},
    { accessorKey: 'amount', header: 'G.O Amount', Cell: ({row: {original}}) => currency(original.amount)},
    { accessorKey: 'salesAmount', header: 'Sales Amount', Cell: ({row: {original}}) => <div className={original.salesAmount?.toFixed(2) === original.amount?.toFixed(2) ? 'text-success' : 'text-danger'}> {currency(original.salesAmount)} </div>},
    { accessorKey: 'status', header: 'Status', Cell: ({row: {original}}) => <div className={statusStyle(original.status)}>{original.status?.toUpperCase()}</div>},
    { accessorKey: 'receiveDate', header: 'Received', size: 100},
    { accessorKey: 'submitDate', header: 'Submited', size: 100},
    { accessorKey: 'chequeDate', header: 'Released', size: 100}
  ]
  
  return (
    <div className='row justify-content-center p-3'>
      <div className='col-12'>
        <div className='row justify-content-between mb-2'>
          <div className='col-lg-2 col-md-3 col-12 mb-2'>
            <button className = 'btn btn-outline-primary w-100' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
          </div>

          <div className='col-lg-10 col-md-9 col-12 d-flex justify-content-end gap-2 mb-2'>
            <SelectAutoComplete
              label = "Customer"
              optionsList={listGovCustomers}
              option = {(i) => `${i.name + ' | '+ i.phoneNumber}`}
              value={customer}
              onChange={(event, value) => {
                  setCustomer(value)
              }}
            />

            <SelectAutoComplete
              label = "Mark"
              optionsList={[{status: 'receive', mark:"Submission"}, {status: 'submit', mark:"Release"}]}
              option = {(i) => i?.mark}
              value={status}
              onChange={(event, value) => {
                  setStatus(value)
              }}
            />

            <div>
              <button className = 'btn btn-outline-success' disabled={customer && status && selectedRows?.length > 0 ? false : true} onClick={() => {setVisible(true)}}>Update</button>
            </div>
          </div>
        </div>

        <MaterialTable
          title = {'Government Orders | ' + gdate?.reportStartDate + ' - ' + gdate?.reportEndDate}
          col = {columns}
          row = {filterRows}
          getSelectedRows = {getSelectedRows}
        />
        <ModalBasic
          open = {visibleAdd}
          onClose = {()=> {
          setVisibleAdd(false)
          }}
          content = {<CreateGovOrder/>}
        />

        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {<UpdateGovOrder data = {selectedRows} customer={customer?._id} status={status}/>}
        />
      </div>
    </div>
    )
}
