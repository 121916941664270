import React, {useState} from 'react'
import { NavLink} from 'react-router-dom'

import { useAuth } from '../../../context/auth'

import {Menu} from 'antd'

export default function SideBarQuickLinks(props) {

    const [auth] = useAuth();

    const [current, setCurrent] = useState('');

    const altIcon = <img width="24" height="24" src="https://img.icons8.com/fluency/48/alt.png" alt="alt"/>

    const empItems = [
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard">Home</NavLink>),
            key: 'ql-home',
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/pump/card">Mark Pump {altIcon} + Q</NavLink>),
            key: 'ql-pump',
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/inventory/sale">Add Sales {altIcon} + S</NavLink>),
            key: 'ql-sales',
        }
    ];

    const userItems= [
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user/quick-panel">Quick Panel</NavLink>),
            key: 'ql-quick-panel',
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/pump/card">Mark Pump {altIcon} + Q</NavLink>),
            key: 'ql-pump',
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user/tank/card">Tank Stock {altIcon} + W</NavLink>),
            key: 'ql-tanks',
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user/employee/card">Fuel Accounts {altIcon} + F</NavLink>),
            key: 'ql-fuel-accounts',
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user/inventory/purchase">Add Purchase {altIcon} + X</NavLink>),
            key: 'ql-purchase',
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/inventory/sale">Add Sales {altIcon} + S</NavLink>),
            key: 'ql-sales',
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user/accounts/cheques">Cheques {altIcon} + T</NavLink>),
            key: 'ql-cheque',
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user/accounts/entry">Double Entry {altIcon} + D</NavLink>),
            key: 'ql-double-entries',
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user/employee/attendance">Atteandance {altIcon} + A</NavLink>),
            key: 'ql-employee-attendance',
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user/accounts/daily-cashflow">Daily Cash Flow {altIcon} + C</NavLink>),
            key: 'ql-daily-cashflow',
        },
    ];

    const sb = () => {
        switch (true) {
        case (auth?.employee?.role < 2):
            return userItems
        default:
            return empItems
        }
    }

    props.menuSelection({menuItem: current})
    
    return (
        <Menu theme={'dark'}  selectedKeys={current} onClick={(e) => setCurrent(e.key)} mode="inline" items={sb()} />
    )
}
