import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useGdate } from '../../../context/date'

import { Text, SelectAutoComplete } from '../../../Components/Inputs/InputFields';

export default function CreateCheque() {
    const [gdate] = useGdate()

    const [listAccountings, setListAccountings] = useState()
    const [listCustomers, setListCustomers] = useState()
    const [listBanks, setListBanks] = useState()
    const [listSuppliers, setListSuppliers] = useState()

    const [date] = useState(gdate.date)
    const [status, setStatus] = useState()
    const [chequeFrom, setChequeFrom] = useState()
    const [accounting, setAccounting] = useState()

    const [chqDate, setChqDate] = useState(date)
    const [chqNo, setChqNo] = useState()
    const [description, setDescription] = useState()
    const [description2, setDescription2] = useState()
    const [amount, setAmount] = useState()

    const [customer, setCustomer] = useState()
    const [supplier, setSupplier] = useState()
    const [bank, setBank] = useState()


    const [submitDisable, setSubmitDisable] = useState(false)

    useEffect(() => {
        loadAccounts()
        loadCustomers()
    }, [])

    useEffect(() => {
        loadBanks()
        loadSuppliers()
    }, [status === 'debit'])

    const loadAccounts = async() => {
        try {
          const {data} = await Axios.post(`/accountings`)
          if (data?.error) {
            toast.error(data.error)
          } else {
            setListAccountings(data?.filter((i) => i?.deDisable !== true))
          }
        } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
        }
    }

    const loadCustomers = async() => {
        try {
          const {data} = await Axios.post(`/customers`)
          if(data?.error){
            toast.error(data?.error)
          } else {
            setListCustomers(data)
          }
        } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
        }
    }
    
    const loadSuppliers = async() => {
        try {
          const {data} = await Axios.post(`/suppliers`)
          if(data?.error){
            toast.error(data?.error)
          } else {
            setListSuppliers(data)
          }
        } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
        }
    }

    const loadBanks = async() => {
        try {
          const {data} = await Axios.post(`/bank-accounts`)
          if(data?.error){
            toast.error(data?.error)
          } else {
            setListBanks(data)
          }
        } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
        }
    }
    
    const chequeCreditSource = (chequeFrom) => {
      if (chequeFrom === 'Supplier') {
        return ({date, status:'credit', chqSupplier: supplier, chqNo, chqDate, amount: Number(amount)?.toFixed(2)})
      } else {
        return ({date, status:'credit', chqCustomer: customer, chqNo, chqDate, amount: Number(amount)?.toFixed(2)})
      }
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        try {
          setSubmitDisable(true)
            if (status === 'credit') {
                const {data} = await Axios.post(`/cheque`, chequeCreditSource(chequeFrom))
                if (data.error) {
                  toast.error(data?.error)
                  setSubmitDisable(false)
                } else {
                    const chequeData = await data
                    const data1 = await Axios.post(`/accdatabulk`, [
                        {date, empStatus: {status: false}, detail: {description, cheque: chequeData?._id, customer, supplier}, accounting: '64fda2c5c969962bfbcee3eb', amount: Number(amount)?.toFixed(2), refId : `cheque-${chequeData?._id}-${chqNo}-${chqDate}-${amount}`},
                        {date, empStatus: {status: false}, detail: {description: description2, customer, supplier}, accounting: (chequeFrom === 'Supplier' ? '64acdf13722d07654e219f89' : '63e31abdf029ceab39dcd283') , amount: Number((chequeFrom === 'Supplier' ? amount : -amount))?.toFixed(2), refId : `cheque-${chequeData?._id}-${chqNo}-${chqDate}-${amount}`}
                    ])
                    if (data1.data.error) {
                      toast.error(data1.data.error)
                      setSubmitDisable(false)
                    } else {
                      toast.success("Cheque Update with Accounts")
                      window.location.reload()
                    }
                }
            } else if (status === 'debit') {
                const {data} = await Axios.post(`/cheque`, {date, status:'debit', chqAccounting: accounting, chqCustomer: customer, chqSupplier: supplier, chqBank: bank, chqNo, chqDate, chqDeposit: false, amount: Number(amount)?.toFixed(2)})
                if (data?.error) {
                  toast.error("Cannot update Cheque Details")
                  setSubmitDisable(false)
                } else {
                    toast.success('Given Cheque Updated')
                    window.location.reload()
                }
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
    }

    return (
        <div className='p-3'>
            <h5 className='text-center text-capitalize'>Create Cheque</h5>
            <dd className='text-center'>{date}</dd>
            <form onSubmit={handleSubmit}>
                <SelectAutoComplete
                  label = "Status"
                  focus = {true}
                  optionsList={[{id: 'credit', name: 'Received'}, {id: 'debit', name: 'Given'}]}
                  option = {(i) => i?.name}
                  value={status}
                  onChange={(event, value) => {
                      setStatus(value?.id)
                  }}
                />

                <SelectAutoComplete
                  label = "Cheque From"
                  optionsList={[{name: 'Customer'}, {name: 'Supplier'}]}
                  option = {(i) => i?.name}
                  value={chequeFrom}
                  onChange={(event, value) => {
                      setChequeFrom(value?.name)
                  }}
                />

                {status === 'debit' &&
                    <div>
                        <SelectAutoComplete
                            label = "Accounts"
                            optionsList={listAccountings}
                            option = {(i) => `${i.name?.toUpperCase() + ' | Type: '+ i.category?.name}`}
                            value={accounting}
                            onChange={(event, value) => {
                                setAccounting(value)
                            }}
                        />

                        <SelectAutoComplete
                            label = "Bank of Cheque"
                            optionsList={listBanks}
                            option = {(i) => `${i.bank + ' | '+ i.accountName + ' | '+ i.accountNo}`}
                            value={bank}
                            onChange={(event, value) => {
                                setBank(value)
                            }}
                        />
                    </div>
                }

                {(status === 'credit' || accounting?._id === '63e31abdf029ceab39dcd283') && chequeFrom === 'Customer' &&
                    <SelectAutoComplete
                        label = "Customer"
                        optionsList={listCustomers}
                        option = {(i) => i?.name + ' | ' + i?.phoneNumber}
                        value={customer}
                        onChange={(event, value) => {
                            setCustomer(value)
                        }}
                    /> 
                }

                {(status === 'credit' || accounting?._id === '64acdf13722d07654e219f89') && chequeFrom === 'Supplier' &&
                    <SelectAutoComplete
                    label = "Supplier"
                    optionsList={listSuppliers}
                    option = {(i) => `${i.name + ' | '+ i.company}`}
                    value={supplier}
                    onChange={(event, value) => {
                        setSupplier(value)
                    }}
                />}

                {status === 'credit' && chequeFrom &&
                <div>
                  <Text
                    label = "Cheque Accounts Description" 
                    value = {description}
                    onChange={(e) => setDescription(e.target.value)}
                  />

                  <Text
                    label = {`${chequeFrom === 'Supplier' ? 'Creditor' : 'Debts'} Accounts Description`}
                    value = {description2}
                    onChange={(e) => setDescription2(e.target.value)}
                  /> 
                </div>
                }

                <Text
                    label = "Cheque No" 
                    value = {chqNo}
                    onChange={(e) => setChqNo(e.target.value)}
                />

                <Text
                    label = "Cheque Date"
                    type = 'date'
                    value = {chqDate}
                    onChange={(e) => setChqDate(e.target.value)}
                />

                <Text
                    label = "Amount" 
                    type = 'Number'
                    value = {amount}
                    onChange={(e) => setAmount(e.target.value)}
                />

                <div className="d-grid gap-2">
                <button className='btn btn-primary mt-3' name="submitButton" type='submit' disabled = {submitDisable}>CREATE CHEQUE</button>
                </div>

            </form>
        </div>
    )
}
