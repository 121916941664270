import React, {useState, useEffect} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import {Text, SelectAutoComplete} from '../../Components/Inputs/InputFields'

export default function CreatePump() {

  const [listTanks, setListTanks] = useState('')
  const [listBooths, setListBooths] = useState('')

  const [name, setName] = useState('')
  const [wareHouseStock, setWareHouseStock] = useState()
  const [booth, setBooth] = useState()

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    loadData();
    loadBooths();
  }, []);

  const loadData = async() => {
    try {
      const {data} = await Axios.post(`/warehousestocks/filtercategory`, {_id: "63e65efc2f840202604fbb7f"})
      if (data?.error) {
        toast.error(data?.error)
      } else {
        setListTanks(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadBooths = async() => {
    try {
      const {data} = await Axios.post(`/booths`);
      if (data?.error) {
        toast.error(data?.error)
      } else {
        setListBooths(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post(`/pump`, {name, wareHouseStock, booth});
      if(data?.error) {
        toast.error(data.error);
        setSubmitDisable(false)
      } else {
        toast.success("Pump sucessfully Created");
        window.location.reload()
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong, Try Again!")
      
    }
  }

  return (
    <div className='p-3'>
      <h4 className='text-center mb-3'>Create Pump</h4>
      <form onSubmit={handleSubmit}>
        <Text
          label="Pump Name"
          value = {name}
          onChange={(e) => setName(e.target.value)}
          focus = {true}
        />

        <SelectAutoComplete
          label = "Tank"
          optionsList={listTanks}
          option = {(i) => i.productCode?.product?.name}
          value={wareHouseStock}
          onChange={(event, value) => {
            setWareHouseStock(value)
          }}
        />
        <p className='text-center'>Tanks stocks will be recorded with main Warehouse</p>

        <SelectAutoComplete
          label = "Booth"
          optionsList={listBooths}
          option = {(i) => i.name}
          value={booth}
          onChange={(event, value) => {
            setBooth(value)
          }}
        />

        <div class="d-grid gap-2">
          <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
            CREATE PUMP
          </button>
        </div>
      </form>
    </div>
  )
}
