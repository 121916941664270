import React, {useState, useEffect, useRef} from 'react'
import Axios from 'axios'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-hot-toast'
import {useReactToPrint} from 'react-to-print'

import {Print, Fullscreen} from '@mui/icons-material'
import {styled, Table, TableBody, TableCell, TableHead, TableRow, Paper} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell'
import { useDk } from '../../../../context/dk'

export default function IncomeStatement(props) {
  const navigate = useNavigate()
  const [dk, setDk] = useDk()

  //Sales Income Accounts not included
  const [incomeAccounts, setIncomeAccounts] = useState('')
  const [totalOtherIncome, setTotalOtherIncome] = useState(0)

  //Purchase Expense Accounts not included
  const [expenseAccounts, setExpenseAccounts] = useState('')
  const [totalExpenses, setTotalExpenses] = useState(0)

  const [dayPumpTotal, setDayPumpTotal] = useState('')

  const [fuelProfit, setFuelProfit] = useState()
  const [inventoryProfit, setInventoryProfit] = useState()

  const [grossProfit, setGrossProfit] = useState()
  const [netProfit, setNetProfit] = useState()

  const [PrintIcon, setPrintIcon] = useState(true)

  //Purchases
  const [fuelLoadsTotal, setFuelLoadsTotal] = useState()
  const [inventoryPurchaseTotal, setInventoryPurchaseTotal] = useState()

  //Purchase Returns
  const [fuelLoadReturns, setFuelLoadReturns] = useState()
  const [inventoryPurchaseReturns, setInventoryPurchaseReturns] = useState()

  //Sales
  const [dayPumpCostTotal, setDayPumpCostTotal] = useState()
  const [inventorySalesCost, setInventorySalesCost] = useState()

  //Sales Returns
  const [inventoryReturnsCost, setInventoryReturnsCost] = useState()
  const [inventoryReturnsProfit, setInventoryReturnsProfit] = useState()

  //Loss Inventory
  const [lossInventory, setLossInventory] = useState()
  const [lossFuel, setLossFuel] = useState()

  //Stock Value
  const [fuelStockValue, setFuelStockValue] = useState()
  const [inventoryStockValue, setInventoryStockValue] = useState()

  useEffect(() => {
    loadTotalAccounts()
    loadPurchases()
    loadPurchasesReturns()
    loadDayPumpTotal()
    loadProductLineTotal()
    loadOrdersReturns()
    loadLossInventory()
    loadLossFuel()
  },[])

  useEffect(() => {
    setGrossProfit(fuelProfit + inventoryProfit - inventoryReturnsProfit)
  },[fuelProfit, inventoryProfit, inventoryReturnsProfit])

  useEffect(() => {
    incomeAccounts && setTotalOtherIncome( incomeAccounts ? incomeAccounts?.reduce((a,v) => a + v?.total, 0) : '0' )
    expenseAccounts && setTotalExpenses( expenseAccounts ? expenseAccounts?.reduce((a,v) => a + v?.total, 0) : '0' )
  }, [incomeAccounts, expenseAccounts])

  //NET PROFIT
  useEffect(() => {
    setNetProfit((grossProfit + totalOtherIncome) - (totalExpenses))
  },[grossProfit, totalOtherIncome, totalExpenses])

  //GAS STOCK VALUE
  useEffect(() => {
    setFuelStockValue(fuelLoadsTotal - (dayPumpCostTotal + fuelLoadReturns + lossFuel))
  },[fuelLoadsTotal, dayPumpCostTotal, fuelLoadReturns, lossFuel])

  //INVENTORY STOCK VALUE
  useEffect(() => {
    setInventoryStockValue((inventoryPurchaseTotal + inventoryReturnsCost) - (inventorySalesCost + inventoryPurchaseReturns + lossInventory))
  },[inventoryPurchaseTotal, inventoryReturnsCost, inventorySalesCost, inventoryPurchaseReturns, lossInventory])


  useEffect(() => {
    sendIncomeStatementDetails()
  },[netProfit, fuelStockValue, inventoryStockValue])

  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  //Income and expense accounts category filtered and load as accounts
  const loadTotalAccounts = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/filteraccounts`)
      if(data?.error) {
        toast.error(data.error)
      } else {
        await setIncomeAccounts(data.filter((i) => i._id.accounting[0]?.category === '63dca26006b9cdaae9c5c8f6' && i._id.accounting[0]?._id !== '63eb82f1661aeb9f06368651') ? data.filter((i) => i._id.accounting[0]?.category === '63dca26006b9cdaae9c5c8f6' && i._id.accounting[0]?._id !== '63eb82f1661aeb9f06368651') : '' )
        await setExpenseAccounts(data.filter((i) => i._id.accounting[0]?.category === '63dca26f06b9cdaae9c5c8fc' && i._id.accounting[0]?._id !== '63ecfbe55b36ecf83d9dd4a3') ? data.filter((i) => i._id.accounting[0]?.category === '63dca26f06b9cdaae9c5c8fc' && i._id.accounting[0]?._id !== '63ecfbe55b36ecf83d9dd4a3') : '' )
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  //Purchase Inventory, Fuel - load as Total
  const loadPurchases = async() => {
    try {
        const {data} = await Axios.post(`/purchases`)
        if(data?.error){
            toast.error(data.error)
        } else {
          setFuelLoadsTotal(data?.length > 0 ? data?.filter((i) => i?.type === 'gas')?.reduce((a,v) => a = a + v?.total, 0) : '0')
          setInventoryPurchaseTotal(data?.length > 0 ? data?.filter((i) => i?.type === 'inventory')?.reduce((a,v) => a = a + v?.total, 0) : '0')
        }
    } catch (err) {
        toast.error("Something went wrong, Try Again!")
        console.log(err)
    }
  }

  //Purchase Return Inventory, Fuel - load as Total
  const loadPurchasesReturns = async() => {
    try {
        const {data} = await Axios.post(`/purchasereturns`)
        if(data?.error){
            toast.error(data.error)
        } else {
          setFuelLoadReturns(data?.length > 0 ? data?.filter((i) => i?.purchase?.type === 'gas')?.reduce((a,v) => a = a + v?.total, 0) : '0')
          setInventoryPurchaseReturns(data?.length > 0 ? data?.filter((i) => i?.purchase?.type === 'inventory')?.reduce((a,v) => a = a + v?.total, 0) : '0')
        }
    } catch (err) {
        toast.error("Something went wrong, Try Again!")
        console.log(err)
    }
  }

  //Gas profit, Sales cost Total by fuel type DayPump Details GROUPBY Type
  const loadDayPumpTotal = async() => {
    try {
      const {data} = await Axios.post(`/daypumps/fuelsales-bytype`)
      if (data?.error) {
        toast?.error(data.error)
      } else {
        setDayPumpTotal(data)
        // //Day Pumps Profit
        setFuelProfit(data ? data?.reduce((a,v) => a = a + (v?.sales - v?.costOfSales), 0) : '0')
        //Day Pump Cost Total
        setDayPumpCostTotal(data.length > 0 ? data?.reduce((a,v) => a = a + (v?.costOfSales), 0) : '0')
      }
    } catch (err) {
      console.log(err)
    }
  }

  //Sales Inventory cost Total, Sales Profit
  const loadProductLineTotal = async() => {
      try {
        const {data} = await Axios.post(`/productline-productcode`)
        if (data?.error) {
          toast?.error(data.error)
        } else {
          //Inventory Profit
          setInventoryProfit(data ? data?.reduce((a,v) => a = a + ((v?.quantity * (v?._id?.price - v?._id?.unitCost)) - v?.discount), 0) : '0')
          setInventorySalesCost(data?.reduce((a,v) => a + (v?._id?.unitCost * v?.quantity), 0) ? data?.reduce((a,v) => a + (v?._id?.unitCost * v?.quantity), 0) : '0' )
        }
      } catch (err) {
        console.log(err)
      }
  }

  //Return Inventory Sales cost Total, Sales Return Profit
  const loadOrdersReturns = async() => {
    try {
      const {data} = await Axios.post(`/return-productline-productcode`)
      if (data?.error) {
        toast?.error(data.error)
      } else {
        setInventoryReturnsCost(data ? data?.reduce((a,v) => a = a + (v?._id?.unitCost * v?.quantity), 0) : '0')
        setInventoryReturnsProfit(data && data?.reduce((a,v) => a = a + ((v?.quantity * (v?._id?.price - v?._id?.unitCost)) - v?.discount), 0))
      }
    } catch (err) {
      console.log(err)
    }
  }

  //Loss Inventory
  const loadLossInventory = async() => {
    try {
      const {data} = await Axios.post(`/stock-adjustments`, {type: 'adjustment'})
      if (data?.error) {
        toast?.error(data.error)
      } else {
        setLossInventory(data ? data?.reduce((a,v) => a += v?.amount, 0) : '0')
      }
    } catch (err) {
      console.log(err)
    }
  }

  //Loss Fuel
  const loadLossFuel = async() => {
    try {
      const {data} = await Axios.post(`/tank-checks/fuelType`)
      if (data?.error) {
        toast?.error(data.error)
      } else {
        setLossFuel(data?.reduce((a,v) => a += v?.amount, 0))
      }
    } catch (err) {
      console.log(err)
    }
  }
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  }));

  const sendIncomeStatementDetails = async() => {
    await props?.getIncomeStatementDetails && props?.getIncomeStatementDetails({netProfit, fuelStockValue, inventoryStockValue})
  }

  const componentRef = useRef();
  const printData = useReactToPrint({
      content : () => componentRef.current,
      documentTitle: `All Time Income Statement`,
      onBeforeGetContent: async() => {
          setPrintIcon(false)
          await setDk({...dk, darkmode : false})
      },
      onAfterPrint: () => {
        setPrintIcon(true)
        const data = localStorage.getItem("darkmode");
        if (data) {
            const parsed = JSON.parse(data);
            setDk({...dk, darkmode: parsed});
        }
      },
  })

  return (
    <div className='row justify-content-center mt-2' ref={componentRef}>
      <div className='d-flex justify-content-center gap-2 align-items-baseline'>
        <h5>INCOME STATEMENT</h5>
        {PrintIcon && <Print fontSize='small' onClick={() => {printData()}}/>}
        {PrintIcon && <Fullscreen fontSize='small' onClick={() => {navigate('/dashboard/user/accounts/incomeStatement-detail')}}/>}
      </div>
    
      <div style={{overflowX: 'auto'}}>
        <Table component={Paper} size='small'>
          <TableHead>
            <TableRow>
              <StyledTableCell className='w-75'>Accounts Details</StyledTableCell>
              <StyledTableCell className='w-75' align="right">Amount</StyledTableCell>
              <StyledTableCell align="right">Amount</StyledTableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
            {/* GROSS PROFIT */}
            <TableRow key='gross-profit'>
              <TableCell className='fw-bold'>GROSS PROFIT</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>

            {dayPumpTotal && dayPumpTotal?.map((i) =><TableRow key={i?._id?.type}>
              <TableCell>{i?._id?.type} | {i?.liter} L</TableCell>
              <TableCell align="right">{(new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.liter ? (i?.sales - i?.costOfSales) : 0))}</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>)}

            <TableRow key='inventory-profit'>
              <TableCell>Inventory Profit</TableCell>
              <TableCell align="right">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(inventoryProfit)}</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>

            <TableRow key='inventory-returns-profit'>
              <TableCell>Inventory Return Profit (-)</TableCell>
              <TableCell align="right">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(inventoryReturnsProfit)}</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>

            <TableRow key='gross-profit-total' style={{backgroundColor: 'greenyellow'}}>
              <TableCell className='fw-bold text-dark text-end'>Total Gross Profit</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell className='fw-bold text-dark text-end'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(grossProfit)}</TableCell>
            </TableRow>

            {/* OTHER Income */}
            <TableRow key='other-incomeAccounts'>
              <TableCell className='fw-bold'>OTHER INCOME</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>

            {incomeAccounts && incomeAccounts.map((i) =>
              <TableRow key={i?._id?.accounting[0]?._id}>
                <TableCell> {i?._id?.accounting[0]?.name?.toUpperCase() + ' | ' + i?._id?.accounting[0]?.description}</TableCell>
                <TableCell align="right">{(new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.total ? i?.total : 0))}</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            )}

            <TableRow key='otherIncomeTotal' style={{backgroundColor: 'greenyellow'}}>
              <TableCell className='fw-bold text-dark text-end'>Total Income</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell className='fw-bold text-dark text-end'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(totalOtherIncome)}</TableCell>
            </TableRow>

            {/* OTHER EXPENSES & LOSSES */}
            <TableRow key='other-expense'>
              <TableCell className='fw-bold'>OTHER EXPENSES & LOSSES</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>

            {expenseAccounts && expenseAccounts.map((i) =>
              <TableRow key={i?._id?.accounting[0]?._id}>
                <TableCell>
                  {i?._id?.accounting[0]?.name?.toUpperCase() + ' | ' + i?._id?.accounting[0]?.description}
                </TableCell>
                <TableCell align="right">{(new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.total ? i?.total : 0))}</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            )}

            <TableRow key='expenseTotal' style={{backgroundColor: 'red'}}>
              <TableCell className='fw-bold text-light text-end'>Total  Expenses & Losses</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell className='fw-bold text-light text-end'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(totalExpenses) }</TableCell>
            </TableRow>

            {/* NET Income Accounts */}
            <TableRow key='netprofit' style={{backgroundColor: 'greenyellow'}}>
              <TableCell className='fw-bold fs-6 text-dark text-center'>NET PROFIT</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell className='fw-bold fs-6 text-dark text-end'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(netProfit) }</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
