import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import {Add, Circle} from '@mui/icons-material/'

import { useGdate } from '../../../context/date'

import ModalBasic from '../../../Components/Modal/ModalBasic'
import ManageEntity from './ManageEntity'
import CreateCheque from '../../Accounts/Cheques/CreateCheque'
import UpdateCheque from '../../Accounts/Cheques/UpdateCheque'
import GasDashboard from '../../GasDashboard'


export default function QuickPanel() {
    const [gdate] = useGdate()

    const [balanceSheetDif, setBalanceSheetDif] = useState()
    const [chequeAdd, setChequeAdd] = useState(false)
    const [chequeUpdate, setChequeUpdate] = useState(false)

    const quickMenu = [
        {name: 'Find Depth', link: '/dashboard/user/tank/find-depth'},
        {name: 'All Depth', link: '/dashboard/user/tank/find-depth'},
        {name: 'All Loads', link: '/dashboard/user/tank/find-depth'},
        {name: 'All Sales', link: '/dashboard/user/inventory/sales'},
        {name: 'All Sales Return', link: '/dashboard/user/inventory/sale-returns'},
        {name: 'All Purchase', link: '/dashboard/user/inventory/purchases'},
        {name: 'All Purchase Return', link: '/dashboard/user/inventory/purchase-returns'},
        {name: 'All Stocks', link: '/dashboard/user/inventory/stocks'},
    ]

    useEffect(()=> {
        loadAccountsByCategoryType()
    },[])

    const loadAccountsByCategoryType = async() => {
        try {
            const {data} = await Axios.post(`/accdatas/sumBycategorytype`)
            if(data?.error){
                toast.error(data.error)
            } else {
                setBalanceSheetDif(data.find((i) => i?._id === true)?.total - data.find((i) => i?._id === false)?.total)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

  return (
    <div className='row justify-content-around col-12'>
        <div className='row justify-content-between p-2'>
            <h5 className='col-md-4 col-12 text-center'>
                Selected Date: {gdate?.date}
            </h5>
            <h5 className='col-md-4 col-12 text-center'>
                Balance-sheet Status : 
                <Circle color= {balanceSheetDif === 0 ? 'success' : 'error'}/>
            </h5>
            <div className='col-md-4 col-12 text-center'>
                <button className='btn btn-outline-warning'>
                    <Link className='text-center text-decoration-none' to={'/dashboard/user'}>DASHBOARD</Link>
                </button>
            </div>
        </div>

        {/* 1st Column */}
        <div className='row col-xxl-8 col-12'>
            <div className='col-12'>
                <div className='p-3 border shadow w3-bottombar w3-animate-zoom'>
                    <h5 className='text-center mb-3'>Quick Access</h5>
                    <div className='row'>
                        {quickMenu?.map(i => 
                            <Link className='col-lg-3 col-md-4 col-6 text-center text-decoration-none' to={i?.link}>{i?.name}</Link>
                        )}
                    </div>                
                </div>
            </div>
            <ManageEntity
                title = 'Manage Employees'
                viewTitle = 'All Employees'
                viewLink = '/dashboard/user/employee/List'
                debitBtn = 'Pay Arrears'
                value1 = 'salaryArrearsPay'
                creditBtn = 'Add Arrears'
                value2 = 'salaryArrearsAdd'
            />

            <ManageEntity
                title = 'Manage Banks'
                viewTitle = 'All Banks'
                viewLink = '/dashboard/user/accounts/bank-accounts'
                debitBtn = 'Deposit'
                value1 = 'bankDeposit'
                creditBtn = 'Withdrawal'
                value2 = 'bankWithdrawal'
            />

            <ManageEntity
                title = 'Manage Customers'
                viewTitle = 'All Customers'
                viewLink = '/dashboard/user/customer/List'
                debitBtn = 'Receive'
                value1 = 'customerReceive'
                creditBtn = 'Pay'
                value2 = 'customerPay'
            />

            <ManageEntity
                title = 'Manage Suppliers'
                viewTitle = 'All Suppliers'
                viewLink = '/dashboard/user/supplier/List'
                debitBtn = 'Pay'
                value1 = 'supplierPay'
                creditBtn = 'Credits'
                value2 = 'supplierCredit'
            />

            {/* CHEQUE ENTRIES */}
            <div className='col-12'>
                <div className='p-3 border shadow w3-bottombar w3-animate-zoom'>
                    <h5 className='text-center mb-3'>Cheques</h5>
                    <div className='d-flex justify-content-around'>
                        <button className = 'btn btn-outline-success mb-2' onClick={() => {setChequeAdd(true)}}><Add/> Add Cheque</button>
                        <button className = 'btn btn-outline-primary' onClick={() => {setChequeUpdate(true)}}><Add/> Update Cheque</button>
                    </div>                
                </div>
            </div>



        </div>

        {/* Gas Stock Chart */}
        <div className='col-xxl-4 col-12'>
          <GasDashboard noTitle = {true}/>
        </div>


        {/* Cheque Model */}
        <ModalBasic
            open = {chequeAdd}
            onClose = {()=> {
            setChequeAdd(false)
            }}
            content = {<CreateCheque/>}
        />

        <ModalBasic
            open = {chequeUpdate}
            onClose = {()=> {
            setChequeUpdate(false)
            }}
            content = {<UpdateCheque/>}
        />
    </div>
  )
}
