import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import moment from 'moment'
import {toast} from 'react-hot-toast'

import { useSettings } from '../../../../context/settings'

import {currency} from '../../../../Functions/NoFomats'

import MaterialTable from '../../../../Components/Table/MaterialTable'
import ModalBasic from '../../../../Components/Modal/ModalBasic'

import { Visibility} from '@mui/icons-material/'

import ReturnsReceipt from '../Receipts/ReturnsReceipt'
import ReturnsReceiptV2 from '../Receipts/ReturnsReceiptV2'

export default function ListOrderReturns() {

  const [settings] = useSettings()

  const [listOrders, setListOrders] = useState('')

  const [items, setItems] = useState('')

  //Model
  const [visibleDetails, setVisibleDetails] = useState(false)
  const [selectedDetails, setSelectedDetails] = useState('')

  useEffect(() => {
      loadOrders()
  }, [])
  
  const loadOrders = async() => {
      try {
          const {data} = await Axios.post('/order-returns')
          if(data?.error){
              toast.error(data.error)
          } else {
              setListOrders(data)
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  const loadItems = async(id) => {
    try {
      const {data} = await Axios.post(`/return-productlines`, {orderReturn: id})
      if (data?.error) {
        toast.error(data.error)
      } else {
        await setItems(data)
        setVisibleDetails(true)
      }
    } catch (err) {
      toast.error("Something went wrong, Try Again!")
      console.log(err)
    }
  }

  const columns = [
    {accessorKey: 'order.invoiceNo', header: 'Invoice No', size: 100},
    {accessorKey: 'returnDate', header: 'Return Date', size: 180},
    {accessorKey: 'payment.name', header: 'Return Type', size: 100},
    {accessorKey: 'total', header: 'Total', size: 100, Cell: ({row: {original}}) => currency(original.total)},
    {accessorKey: 'updatedAt', header: 'Last Updated', Cell: ({row: {original}}) => moment(original.updatedAt).format('YYYY-MM-DD hh:mm:ss A'), export: (i) => moment(i).format('YYYY-MM-DD hh:mm:ss A')},
  ]

  const rowAction = ({row: {original}}) => [
    <div className='d-flex gap-2'>
      <Visibility
        onClick={() => {
          loadItems(original._id)
          setSelectedDetails(original)
        }}
      />
    </div>
  ]
  
  return (
    <div className='row justify-content-center'>
      <div className='col-12 mb-3'>
        <MaterialTable
          title = "List of Sales Returns"
          col = {columns}
          row = {listOrders}
          rowAction = {rowAction}
        />
        <ModalBasic
          width = {settings.billReceipt === 'invoice' ? '1000px' : ''}
          open = {visibleDetails}
          onClose = {()=> {
          setVisibleDetails(false)
          }}
          content = {settings.billReceipt === 'invoice' ?
          <ReturnsReceiptV2 order = {{selectInvoiceVal: selectedDetails?.order, items: items, returnDate: selectedDetails?.returnDate, returnValue: selectedDetails?.total, printedDate: selectedDetails?.createdAt, payment: selectedDetails?.payment?._id}}/>
            :
            <ReturnsReceipt order = {{selectInvoiceVal: selectedDetails?.order, items: items, returnDate: selectedDetails?.returnDate, returnValue: selectedDetails?.total, printedDate: selectedDetails?.createdAt, payment: selectedDetails?.payment?._id}}/>
          }
        />
      </div>
    </div>
  )
}
