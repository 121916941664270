import React, {useEffect, useState} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useAuth } from '../../context/auth'

import {Check} from '@mui/icons-material/'

import { SelectAutoComplete } from '../../Components/Inputs/InputFields'

export default function UpdateCustomerAccounts(props) {
    const orderData = props?.data
    const [auth] = useAuth()

    const [listGovOrders, setListGovOrders] = useState('')
    const [salesOrderDetail, setSalesOrderDetail] = useState()

    const [govOrder, setGovOrder] = useState(orderData?.detail?.govOrder)

    const [submitDisable, setSubmitDisable] = useState(false)

    useEffect(() => {
        loadGovOrders(orderData?.detail?.customer?._id)
        orderData?.detail?.invoiceNo && loadSalesOrder(orderData?.detail?.invoiceNo)
    },[orderData])

    const loadGovOrders = async(id) => {
        try {
          const {data} = await Axios.post(`/govorders`, {customer: id, status: 'receive'})
          if (data?.error) {
            toast.error(data.error)
          } else {
            setListGovOrders(data)
          }
        } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
        }
    }

    const loadSalesOrder = async(id) => {
        try {
          const {data} = await Axios.post(`/orders`, {invoiceNo: id})
          if (data?.error) {
            toast.error(data.error)
          } else {
            setSalesOrderDetail(data[0])
          }
        } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        try {
            if(!govOrder) {
                toast.error("Update failed due to no changes")
            } else {
                await setSubmitDisable(true)
                const {data} = await Axios.put(`/accdata/${orderData?._id}`, {detail: {...orderData?.detail, govOrder} })
                if (data?.error) {
                    toast.error(data.error)
                    setSubmitDisable(false)
                } else {
                    if (orderData?.detail?.invoiceNo) {
                        const {data} = await Axios.put(`/order/${salesOrderDetail?._id}`, {govOrder})
                        if (data?.error) {
                            toast.error(data.error)
                            setSubmitDisable(false)
                        } else {
                            toast.success(`Order No: ${govOrder?.govOrder} for Orders Updated`)
                            window.location.reload()
                        }
                    }
                    else {
                        toast.success(`Order No: ${govOrder?.govOrder} for fuel Updated`)
                        window.location.reload()
                    }
                }
            }
        } catch (err) {
            toast.error("Something went wrong, Try Again!")
            console.log(err)
        }
    }

    const govOrderStatus = (status) => {
        switch (status) {
            case 'submit':
                return 'Submited'
            case 'cheque':
                return 'Cheque Released';
        }
    }
    
    return (
        <div>
            {!orderData?.detail?.govOrder || orderData?.detail?.govOrder?.status === 'receive' ? 
                <div className='p-3'>
                    <h5 className='text-center'>Update Order No</h5>
                    {orderData?.detail?.govOrder && auth?.employee?.role > 0 ? 
                        <p className='text-center'><Check fontSize='large' color='success'/> <br/> Government Order No Updated. <br/> For any changes login with Admin</p>
                        :
                        <form onSubmit={handleSubmit}>
                            <SelectAutoComplete
                                label = "Government Order (Opt.)"
                                optionsList={listGovOrders}
                                option = {(i) => `${i.govOrder + ' | '+ i.receiveDate}`}
                                value={govOrder}
                                onChange={(event, value) => {
                                    value ? setGovOrder(value) : setGovOrder()
                                }}
                            />
                            <div className='d-grid'>
                                <button className='btn btn-primary' type='submit' onClick={handleSubmit} disabled={submitDisable}>SUBMIT</button>
                            </div>
                        </form>
                    }
                </div>
                :
                <div className='text-center'>
                    Government order {govOrderStatus(orderData?.detail?.govOrder?.status)}<br/>
                    <Check fontSize='large' color='success'/> <br/> 
                    So, cannot edit this order No
                </div>
            }
        </div>
    )
}
