import React, {useState, useEffect, useRef} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import {useReactToPrint} from 'react-to-print'

import {GeneralNo, currency, unit} from '../../Functions/NoFomats'

import {Warehouse} from '@mui/icons-material/'

import MaterialTable from '../../Components/Table/MaterialTable'
import ModalBasic from '../../Components/Modal/ModalBasic'

export default function ListStock() {

  const [listProducts, setListProducts] = useState('')
  const [listWareHouseStocks, setListWareHouseStocks] = useState('')

  //FIFO Purpose and Stocks by Warehouses
  const [listPurchaseDetails, setListPurchaseDetails] = useState('')

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    loadPurchaseDetails()
    loadWareHouseStocks()
  }, [])

  useEffect(() => {
    listPurchaseDetails && listWareHouseStocks && loadProducts()
  }, [listPurchaseDetails, listWareHouseStocks])

  const loadWareHouseStocks = async() => {
    try {
        const {data} = await Axios.post('/warehousestocks/filtercategory', {_id: {"$ne" : '63e65efc2f840202604fbb7f'}})
        if(data?.error){
            toast.error(data.error)
        } else {
          setListWareHouseStocks(data)
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  const loadPurchaseDetails = async() => {
    try {
        const {data} = await Axios.post(`/purchaseproductlines`)
        if (data?.error) {
            toast.error(data.error)
        } else {
          setListPurchaseDetails(data?.filter((i) => i?.productCode?.product?.category?._id !== '63e65efc2f840202604fbb7f')?.reverse())
        }
    } catch (err) {
      toast.error("Something went wrong, Try Again!")
      console.log(err)
    }
  }
  
  const loadProducts = async() => {
      try {
          const {data} = await Axios.post(`/warehousestocks/group-byproductcode`)
          if(data?.error){
              toast.error(data.error)
          } else {
            setListProducts(data?.map((i) => {
              const stockValue = FIFO(i)?.reduce((a,v) => a = a + (v?.unitCost * v?.qty), 0) ? FIFO(i)?.reduce((a,v) => a = a + (v?.unitCost * v?.qty), 0) : 0
              const unitCost = (stockValue / i?.totalStock) ? (stockValue / i?.totalStock) : 0
              const unitProfit = (i?.productCode?.price - unitCost) ? (i?.productCode?.price - unitCost) : 0
              return ({...i, stockValue, unitCost, unitProfit})
            }))
          }
      } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
      }
  }

  const stockBgColor = (stock) => {
    switch (true) {
      case stock === 0:
        return {backgroundColor: 'red', color: 'black'}
        break;
      case stock <= 20:
        return {backgroundColor: 'orange', color: 'black'}
        break;
      case stock <= 50:
        return {backgroundColor: 'yellow', color: 'black'}
        break;
    }
  }

  const FIFO = (selectedProductCodeDetails) => {
    try {
      const purchaseList = listPurchaseDetails && listPurchaseDetails?.filter((i) => i?.productCode?._id === selectedProductCodeDetails?._id)
      const totalStock = selectedProductCodeDetails?.totalStock
      var sumQty = 0
      const arr = []

      for (var i=0; i<purchaseList?.length; i++) {
        sumQty += purchaseList[i]?.quantity
        if (sumQty >= totalStock) {
          arr.push({unitCost: Number(purchaseList[i]?.itemPrice / purchaseList[i]?.quantity)?.toFixed(2), qty: ((purchaseList[i]?.quantity + totalStock) - sumQty)})
          break
        } else {
          arr.push({unitCost: Number(purchaseList[i]?.itemPrice / purchaseList[i]?.quantity)?.toFixed(2), qty: purchaseList[i]?.quantity})
        }
      }

      return(arr);

    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const StocksColumns = [
    { accessorKey: 'productCode.productCode', header: 'Code', size: 100},
    { accessorKey: 'productCode.product.brand.name', header: 'Brand', size: 100},
    { accessorKey: 'productCode.product.name', header: 'Product', size: 100},
    { accessorKey: 'totalStock', header: 'Stock', size: 100, Cell: ({row: {original}}) => <div className='text-center p-2 w-100 fw-bold' style={stockBgColor(original.totalStock)}>{unit(original.totalStock, original?.productCode?.product?.unit)}</div>},
    { accessorKey: 'totalSold', header: 'Sold', size: 100, Cell: ({row: {original}}) => unit(original.totalSold, original?.productCode?.product?.unit)},
    { accessorKey: 'unitCost', header: 'Avg Unit Cost', size: 100, Cell: ({row: {original}}) => currency(original?.stockValue / original?.totalStock)},
    { accessorKey: 'productCode.price', header: 'Unit Price', size: 100, Cell: ({row: {original}}) => currency(original.productCode?.price), export: (i) => currency(i)},
    { accessorKey: 'unitProfit', header: 'Avg Unit Profit', size: 100, Cell: ({row: {original}}) => currency(original.productCode?.price - (original?.stockValue / original?.totalStock)), export: (i) => currency(i)},
    { accessorKey: 'stockValue', header: 'Stock Value', size: 100, Cell: ({row: {original}}) => currency(original?.stockValue), export: (i) => currency(i)},
  ]

  const rowAction = ({row}) => [
    <div className='d-flex justify-content-center'>
      <Warehouse
        onClick={() => {
          setVisible(true)
          setSelected(listWareHouseStocks && listWareHouseStocks?.filter((w) => w?.productCode?._id === row.original?.productCode?._id))
        }}
      />
    </div>
  ] 

  return (
    <div className='row justify-content-center'>
      <div className='col-12 mb-3'>
        <MaterialTable
          title = 'All Stocks'
          col = {StocksColumns}
          groupingColumns = {['productCode.product.brand.name', 'productCode.product.name']}
          groupedColumnMode = 'remove'
          columnDrag = {true}
          row = {listProducts}
          rowAction = {rowAction}
        />
      </div>

      <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {
          <MaterialTable
            title = "Stocks By Warehouses"
            col = {[
                { accessorKey: 'warehouse', header: 'Warehouse', size: 125, Cell: ({row: {original}}) => original.wareHouse?.name},
                { accessorKey: 'stock', header: 'Stock', size: 100, Cell: ({row: {original}}) => (new Intl.NumberFormat().format(original.stock) + ' ' + original.productCode?.product?.unit)},
                { accessorKey: 'sold', header: 'Sold', size: 100, Cell: ({row: {original}}) => (new Intl.NumberFormat().format(original.sold) + ' ' + original.productCode?.product?.unit)},
            ]}
            row = {selected}
            exportDisable = {true}
          />}
        />
    </div>
  )
}
