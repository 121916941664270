import React, {useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import moment from 'moment'
import {toast} from 'react-hot-toast'

import {currency} from '../../../../Functions/NoFomats'

import { Visibility} from '@mui/icons-material/'

import MaterialTable from '../../../../Components/Table/MaterialTable'

export default function ListPurchase() {
  const navigate = useNavigate()

  const [listPurchase, setListPurchase] = useState('')

  useEffect(() => {
      loadPurchases()
  }, [])
  
  const loadPurchases = async() => {
      try {
          const {data} = await Axios.post('/purchases')
          if(data?.error){
              toast.error(data.error)
          } else {
              setListPurchase(data)
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  const columns = [
    {accessorKey: 'invoiceNo', header: 'Invoice No', width: 100},
    {accessorKey: 'supplier.name', header: 'Supplier', width: 100},
    {accessorKey: 'purchaseDate', header: 'Purchase Date', width: 100},
    {accessorKey: 'payment.name', header: 'Payment', width: 100},
    {accessorKey: 'total', header: 'Total', width: 100, Cell: ({row: {original}}) => currency(original.total), export: (i) => currency(i)},
    {accessorKey: 'updatedAt', header: 'Marked On', width: 100, Cell: ({row: {original}}) => moment(original.updatedAt).format('YYYY-MM-DD hh:mm:ss A'), export: (i) => moment(i).format('YYYY-MM-DD hh:mm:ss A')},
  ]

  const rowAction = ({row}) => [
    <div className='d-flex justify-content-center'>
      <Visibility
        onClick={() => {
          navigate(`/dashboard/user/inventory/purchase-report/${row.original._id}`)
        }}
      />
    </div>
  ]

  return (
    <div className='row justify-content-center'>
      <div className='col-12 mb-3'>
        <MaterialTable
          title = "List of Purchases"
          col = {columns}
          row = {listPurchase}
          rowAction = {rowAction}
        />  
      </div>
    </div>
  )
}
