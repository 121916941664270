import React, {useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import { useGdate } from '../../context/date'

import { Text } from '../../Components/Inputs/InputFields'

import {Check, Clear} from '@mui/icons-material';

export default function CreateSupplier() {
  const [gdate] = useGdate()

  const [date] = useState(gdate.date)
  const [name, setName] = useState("")
  const [nic, setNic] = useState('')
  const [company, setCompany] = useState('')
  const [address, setAddress] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [amount, setAmount] = useState()

  const [submitDisable, setSubmitDisable] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post(`/supplier`, {name, nic, company, address, phoneNumber});
      if(data?.error) {
        toast.error(data.error);
        setSubmitDisable(false)
      } else {
        const data1 = await Axios.post(`/accdatabulk`, [
          {date, empStatus: {status: false}, detail:{description: `Supplier: ${data?.name} Opening Balance`, supplier: data?._id}, amount : (amount ? amount : 0), accounting: "64acdf13722d07654e219f89", refId: `de-supplier-${data?._id}-${date}-Opening Balance`},
          {date, empStatus: {status: false}, detail:{description: `Supplier: ${data?.name} Opening Balance`}, amount: (amount ? -amount : -0), accounting: '6493d4f0157bacc34353cf7b', refId: `de-supplier-${data?._id}-${date}-Opening Balance`}
        ])
        if(data1?.data?.error){
          toast.error(data.error)
          setSubmitDisable(false)
        } else {
          toast.success("Supplier Added");
          window.location.reload()
        }
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }
  
  return (
    <div className='p-3'>
      <h4 className='text-center mb-3'>Add Supplier</h4>
        <form onSubmit={handleSubmit}>
          <Text
            label="Name"
            value = {name}
            onChange={(e) => setName(e.target.value)}
            focus = 'true' 
          />

          <Text
            label="NIC Number"
            value = {nic}
            onChange={(e) => setNic(e.target.value)}
            end = {nic.length > 0 ? (nic.length === 10 || nic.length === 12 ? <Check color='success' /> : <Clear color='error'/>) : ''}
          />

          <Text
            label="Company"
            value = {company}
            onChange={(e) => setCompany(e.target.value)} 
          />

          <Text
            label="Address"
            value = {address}
            onChange={(e) => setAddress(e.target.value)} 
          />

          <Text
            label="Phone Number"
            type ="Number"
            value = {phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)} 
            end = {phoneNumber.trim() > 0 ? (phoneNumber.length === 10 ? <Check color='success' /> : <Clear color='error'/>) : ''}
          />

          <Text
            label="Payable Amount"
            type ="Number"
            value = {amount}
            onChange={(e) => setAmount(e.target.value)} 
            end = {amount ? (amount > 0 ? "Dr" : "Cr") : ''}
          />

          <div class="d-grid gap-2">
            <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
              ADD SUPPLIER
            </button>
          </div>
        </form>
    </div>
  )
}
