import * as React from 'react';
import { Card, CardActionArea, Typography } from '@mui/material';
import {AccessTime, Insights} from '@mui/icons-material/';

export default function ReportCard(props) {

  const amount = props.amount ? props.amount : 0
  const formatedAmount = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(amount)
  const amountArray = formatedAmount?.split(".")

  return (
    <Card style={{borderRadius: 10, backgroundImage: `${props.bg}`, backdropFilter: 'blur(5px)'}}>
      <div style={{backgroundColor: props.topbarColor, height: 3}}></div>
      <CardActionArea className='p-4' onClick= {props.onClick}>
        <Insights className='position-absolute' style={{top:'5%', right: "5%", fontSize: "150px", opacity: 0.1}} />

        <div className='d-flex gap-2'>
          {props.icon}
          <Typography variant="h6" component="div">{props.accounts}</Typography>
        </div>

        <div className='d-flex align-items-baseline justify-content-end'>
          <Typography className='fs-5 fw-bold'>
            {amountArray && amountArray[0]}
          </Typography>

          <Typography style={{fontSize: '14px'}}>
            .{amountArray && amountArray[1]}
          </Typography>
        </div>

        <div className='row d-flex align-items-end' style={{height: 50}}>
            <Typography variant="button" color="text.secondary">
              {props.name}
            </Typography>
            <div className='d-flex justify-content-between'>
              <Typography variant="body2" color="text.secondary">
                {props.description}
              </Typography>
              <Typography variant="text.secondary" align="right" component="div"><AccessTime fontSize='small'/> {props.date}</Typography>
          </div>
        </div>
      </CardActionArea>
    </Card>
  );
}