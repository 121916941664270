import React, {useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import moment from 'moment'
import {toast} from 'react-hot-toast'

import {currency} from '../../../../Functions/NoFomats'

import {Edit, Visibility} from '@mui/icons-material/'

import MaterialTable from '../../../../Components/Table/MaterialTable'
import ModalBasic from '../../../../Components/Modal/ModalBasic'

import UpdatePurchaseReurn from './UpdatePurchaseReurn'

export default function ListPurchaseReturns() {
  const navigate = useNavigate()

  const [listPurchaseReturns, setListPurchaseReturns] = useState('')

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  useEffect(() => {
      loadPurchases()
  }, [])
  
  const loadPurchases = async() => {
      try {
          const {data} = await Axios.post('/purchasereturns')
          if(data?.error){
              toast.error(data.error)
          } else {
              setListPurchaseReturns(data)
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  const columns = [
    { accessorKey: 'purchase.invoiceNo', header: 'Invoice No', size: 100},
    { accessorKey: 'purchase.supplier.name', header: 'Supplier', size: 100},
    { accessorKey: 'purchase.purchaseDate', header: 'Purchase Date', size: 100},
    { accessorKey: 'returnDate', header: 'Return Date', size: 100},
    { accessorKey: 'paymentStatus', header: 'Refund Status', size: 100, Cell: ({row: {original}}) => {
      switch (original.paymentStatus) {
        case "received":
          return <div className='w-100 p-2' style={{color: 'green'}}>{original.paymentStatus?.toUpperCase()}</div>
        case "pending":
          return <div className='w-100 p-2' style={{color: 'orange'}}>{original.paymentStatus?.toUpperCase()}</div>
        case 'unknown':
          return <div className='w-100 p-2' style={{color: 'red'}}>{original.paymentStatus?.toUpperCase()}</div>
    }},
    },
    { accessorKey: 'payment.name', header: 'Refund', size: 100, Cell: ({row: {original}}) => original.payment?.name?.toUpperCase()},
    { accessorKey: 'total', header: 'Total', size: 100, Cell: ({row: {original}}) => currency(original.total), export: (i) => currency(i)},
    { accessorKey: 'updatedAt', header: 'Marked On', size: 100, Cell: ({row: {original}}) => moment(original.updatedAt).format('YYYY-MM-DD hh:mm A'), export: (i) => moment(i).format('YYYY-MM-DD hh:mm A')},
  ]

  const rowAction = ({row: {original}}) => [
    <div className='d-flex gap-2'>
      <Visibility
        onClick={() => {
          navigate(`/dashboard/user/inventory/purchase-return-report/${original._id}`)
        }}
      />
      <Edit
        onClick={() => {
          setVisible(true)
          setSelected(original)
        }}
      />
    </div>
  ]
  return (
    <div className='row justify-content-center'>
      <div className='col-12 mb-3'>
        <MaterialTable
          title = "List of Purchase Returns"
          col = {columns}
          row = {listPurchaseReturns}
          rowAction = {rowAction}
        />
      </div>

      <ModalBasic
        open = {visible}
        onClose = {()=> {
        setVisible(false)
        }}
        content = {<UpdatePurchaseReurn data = {selected}/>}
      />

    </div>
    )
}
