import React, {useState} from 'react'
import { toast } from 'react-hot-toast';
import { Send } from '@mui/icons-material';

import { sendMessage } from '../../Functions/Message';

import {Text} from '../../Components/Inputs/InputFields'

export default function SendEReceipt(props) {
    const {orderId, invoiceNo, selectedCustomer} = props?.order
    const [cusName, setCusName] = useState(selectedCustomer?.name ? selectedCustomer.name : 'Customer');
    const [phoneNo, setPhoneNo] = useState(selectedCustomer?.phoneNumber)

    const [submitDisable, setSubmitDisable] = useState(false)

    const handleSubmit = async() => {
        setSubmitDisable(true)
        const {success, error} = await sendMessage({
            numbers: [phoneNo], 
            message: `${props?.sysName} \nDear ${cusName?.toUpperCase()}, \nYour E-Bill Invoice No: ${invoiceNo} available. \n${window.location.origin + '/e-receipt/' + orderId} 
            \nPowered By BIDO(PVT)LTD.`
        })
        if (error) {
            toast.error(error)
            setSubmitDisable(false)
        } else {
            toast.success(success)
        }
    }

    return (
        <div className='p-3'>
            <h4 className='text-center'>Send E Receipt</h4>
            <h6 className='text-center mb-3'>Invoice No: {invoiceNo}</h6>
            <form onSubmit={handleSubmit}>
                <Text
                label="Customer Name"
                value = {cusName}
                onChange={(e) => setCusName(e.target.value)}
                focus = 'true'
                />

                <Text
                label="Phone No"
                value = {phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                />
                <div class="d-grid gap-2">
                <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable} onClick={handleSubmit}>
                SEND <Send/>
                </button>
                </div>
            </form>
        </div>
    )
}
