import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment'

import { useAuth } from '../../context/auth'
import { useGdate } from '../../context/date'

import {currency} from '../../Functions/NoFomats'

import {Check, DeleteForever} from '@mui/icons-material/'

import MaterialTable from '../../Components/Table/MaterialTable'
import ModalBasic from '../../Components/Modal/ModalBasic'

export default function ListDayEmployee() {
    const [auth] = useAuth()
    const [gdate] = useGdate()

    //Model
    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState(null)

    const [listData, setListData] = useState('')

    const [submitDisable, setSubmitDisable] = useState(false)


    useEffect(() => {
        loadData()
    }, [])
    
    const loadData = async() => {
        try {
            const {data} = await Axios.post(`/dayemployees`, {date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
            if(data?.error){
                toast.error(data.error)
            } else {
                setListData(data?.map(i => ({...i, noOfPumps: i.pumpList?.length})))
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }
 
    const handleDeleteDayEmployee = async(selected) => {
        try {
            try {
                await setSubmitDisable(true)
                const {data} = await Axios.post(`/accdatas/removemany`, {"empStatus.employee": selected?.employee?._id, "empStatus.status": true, date: selected?.date, accounting: '63eb82f1661aeb9f06368651'})
                if (data?.error){
                    toast.error(data.error)
                    setSubmitDisable(false)
                } else {
                    const {data} = await Axios.delete(`/dayemployee/${selected?._id}`)
                    if(data?.error) {
                        toast.error(data.error)
                        setSubmitDisable(false)
                    } else {
                        toast.success("Day Employee Records Deleted")
                        window.location.reload()
                    }
                }
            } catch (err) {
                console.log(err)
                toast.error("Something went wrong Try Again!")
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong Try Again!")
        }
    }

    const columns = [
        {accessorKey: 'date', header: 'Date', size: 100},
        {accessorKey: 'employee.name', header: 'Employee', size: 100},
        {accessorKey: 'cash', header: 'Cash', size: 100, Cell: ({row: {original}}) => currency(original.cash), export: (i) => currency(i)},
        {accessorKey: 'bank', header: 'Bank / Card Pays', size: 100, Cell: ({row: {original}}) => currency(original.bank), export: (i) => currency(i)},
        {accessorKey: 'bill', header: 'Bill', size: 100, Cell: ({row: {original}}) => currency(original.bill), export: (i) => currency(i)},
        {accessorKey: 'drawings', header: 'Drawings', size: 100, Cell: ({row: {original}}) => currency(original.drawings), export: (i) => currency(i)},
        {accessorKey: 'expenses', header: 'Expenses', size: 100, Cell: ({row: {original}}) => currency(original.expenses), export: (i) => currency(i)},
        {accessorKey: 'salary', header: 'Salary', size: 100, Cell: ({row: {original}}) => currency(original.salary), export: (i) => currency(i)},
        {accessorKey: 'noOfPumps', header: 'No of Pumps', size: 100},
    ]

    const rowAction = ({row}) => [
        <div className='d-flex justify-content-center'>
            {moment(row.original.date) < moment(gdate?.cashflowDate) ? <Check color='success'/>
            :
            <DeleteForever
                onClick={() => {
                setVisible(true)
                setSelected(row.original)
                }}
            />}
        </div>
    ]

    return (
        <div className='row justify-content-center'>
            {/* <div className='d-flex justify-content-center gap-2 align-items-baseline'>
                <h5>Daily Employee Details</h5>
                <p>{gdate?.reportStartDate + ' - ' + gdate?.reportEndDate} </p>
            </div> */}
            <div className='col-12 mb-3'>
                <MaterialTable
                    title = {'Daily Employee Details | ' + gdate?.reportStartDate + ' - ' + gdate?.reportEndDate}
                    col = {columns}
                    row = {listData}
                    rowAction = {rowAction}
                />
            </div>

            <div className='row'>
                <div className='d-flex justify-content-between'>
                    <h6>Total Fuel Sales By Employees</h6>
                    <h6>{new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format( listData && listData?.reduce((a,v) =>  a = a + v?.cash + v?.bank + v?.bill + v?.drawings + v?.expenses + v?.salary , 0 ) )}</h6>
                </div>
            </div>

            <ModalBasic
                open = {visible}
                onClose = {()=> {
                setVisible(false)
                }}
                content = {
                    auth?.employee?.role === 0 ?
                    <div>
                        <p>If Delete, Employee Sales, Expense Accounts will be Deleted?</p>
                        <div className='d-flex justify-content-end'>
                            <button className='btn btn-outline-danger' disabled={submitDisable} onClick={() => {
                                handleDeleteDayEmployee(selected)
                                }}>Delete
                            </button>
                        </div>
  
                    </div>
                    :
                    <div className='d-flex justify-content-center w-100'>
                        <p>You don't have Permission to Delete</p>
                    </div>
                    
                }
            />
        </div>
    )
}
