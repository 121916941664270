import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import moment from 'moment'
import {toast} from 'react-hot-toast'

import { useSettings } from '../../../../context/settings'

import {currency} from '../../../../Functions/NoFomats'

import MaterialTable from '../../../../Components/Table/MaterialTable'
import ModalBasic from '../../../../Components/Modal/ModalBasic'

import {Visibility} from '@mui/icons-material/'

import SalesReceipt from '../Receipts/SalesReceipt';
import SalesReceiptV2 from '../Receipts/SalesReceiptV2';

export default function ListOrders() {

  const [settings] = useSettings()

  const [listOrders, setListOrders] = useState('')

  const [items, setItems] = useState('')
  //Model
  const [visibleDetails, setVisibleDetails] = useState(false)
  const [selectedDetails, setSelectedDetails] = useState('')

  useEffect(() => {
      loadOrders()
  }, [])
  

  const loadOrders = async() => {
      try {
          const {data} = await Axios.post('/orders')
          if(data?.error){
              toast.error(data.error)
          } else {
              setListOrders(data?.map(i => {
                const customerType = i.customer ? i?.customer.type?.toUpperCase() : "RETAIL"
                const Updated = i.updatedAt !== i.createdAt ? moment(i.updatedAt).format('YYYY-MM-DD HH:MM') : '-'
                return ({...i, customerType, Updated})
              }))
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  const loadItems = async(id) => {
    try {
      const {data} = await Axios.post(`/productlines`, {order: id})
      if (data?.error) {
        toast.error(data.error)
      } else {
        await setItems(data)
        setVisibleDetails(true)
      }
    } catch (err) {
      toast.error("Something went wrong, Try Again!")
      console.log(err)
    }
  }

  const columns = [
    {accessorKey: 'invoiceNo', header: 'Invoice No', size: 100},
    {accessorKey: 'orderDate', header: 'Order Date', size: 100},
    {accessorKey: 'customerType', header: 'Type', size: 100},
    {accessorKey: 'payment', header: 'Payment', size: 100, Cell: ({row : {original}}) => original.payment?.name?.toUpperCase()},
    {accessorKey: 'total', header: 'Total', size: 100, Cell: ({row : {original}}) => currency(original.total), export: (i) => currency(i)},
    {accessorKey: 'comment', header: 'Comments'},
    {accessorKey: 'govOrder.govOrder', header: 'Gov.Order'},
    {accessorKey: 'createdAt', header: 'Order Created', Cell: ({row : {original}}) => moment(original.createdAt).format('YYYY-MM-DD HH:MM'), export: (i) => moment(i).format('YYYY-MM-DD HH:MM')},
    {accessorKey: 'updated', header: 'Updates'},
  ]

  const rowAction = ({row: {original}}) => [
    <div className='d-flex justify-content-center'>
      <Visibility
        onClick={() => {
          loadItems(original._id)
          setSelectedDetails(original)
        }}
      />
    </div>
  ]

  return (
    <div className='row justify-content-center'>
      <div className='col-12 mb-3'>
        <div className='d-flex gap-2 mb-2'>
        </div>
        <MaterialTable
          title = "List of Inventory Sales"
          col = {columns}
          row = {listOrders}
          rowAction = {rowAction}
        />
        <ModalBasic
          width = {settings.billReceipt === 'invoice' ? '1000px' : ''}
          open = {visibleDetails}
          onClose = {()=> {
          setVisibleDetails(false)
          }}
          content = {settings.billReceipt === 'invoice' ?
            <SalesReceiptV2 order = {{orderId: selectedDetails?._id, invoiceNo: selectedDetails?.invoiceNo, selectedCustomer: selectedDetails?.customer, orderDate: selectedDetails?.orderDate, items, total: selectedDetails?.total, payment: selectedDetails?.payment?._id, received: selectedDetails?.received, printedDate: selectedDetails?.createdAt}}/>
            :
            <SalesReceipt order = {{orderId: selectedDetails?._id, invoiceNo: selectedDetails?.invoiceNo, selectedCustomer: selectedDetails?.customer, orderDate: selectedDetails?.orderDate, items, total: selectedDetails?.total, payment: selectedDetails?.payment?._id, received: selectedDetails?.received, printedDate: selectedDetails?.createdAt}}/>
          }
        />
      </div>
    </div>
    )
}
