import React, { useState, createContext, useContext, useEffect } from "react";
import Axios from 'axios';

const AuthContext = createContext();

const AuthProvider = (props) => {
    const [auth, setAuth] = useState({
        employee: '',
        token: '',
    });

    //axios config
    Axios.defaults.baseURL = process.env.REACT_APP_API;
    Axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage?.getItem("auth"))?.token || auth?.token;

    useEffect(() => {
        const data = localStorage.getItem("auth");
        if (data) {
            const parsed = JSON.parse(data);
            setAuth({...auth, employee: parsed.employee, token: parsed.token});
        }
    },[]);

    return(
        <AuthContext.Provider value={[auth, setAuth]}>
            {props.children}
        </AuthContext.Provider>
    );
};

const useAuth = () => useContext(AuthContext);

export {AuthProvider, useAuth};