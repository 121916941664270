import React from 'react'
import DayPump from './ListdayPump'
import DayEmployee from '../Employee/ListDayEmployee'

export default function FuelSales() {
  return (
    <div>
        <h4 className='text-center'>Pump Sales</h4>
        <DayPump/>
        <hr/>
        <h4 className='text-center'>Employee Sales</h4>
        <DayEmployee/>
    </div>
  )
}