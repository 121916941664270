import React, {useEffect} from 'react'
import {BrowserRouter} from "react-router-dom"
import { Toaster, toast } from 'react-hot-toast'
import moment from 'moment';

import { ConfigProvider } from 'antd';
import { Layout } from 'antd';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { useDk } from './context/dk';
import { useGdate } from './context/date';
import { useAuth } from './context/auth';
import { useSettings } from './context/settings';

import TopMenu from './Components/Topbar/TopMenu'
import RoutesPage from './Routes/RoutesPage';

import Setup from './Pages/Setup'

export default function App() {
  
  const [settings] = useSettings()
  const [dk] = useDk()
  const [auth] = useAuth()
  const [gdate] = useGdate()

  const MUITheme = createTheme({
    palette: {
      mode: `${dk.darkmode ? 'dark' : 'light'}`,
    },
  });

  useEffect(() => {
    window.addEventListener("offline", (event) => {
      toast.loading("Connection lost.");
    });

    window.addEventListener("online", (event) => {
      toast.dismiss()
      toast.success("Connection Restored.");
    }); 
  },[])

  return (
    <BrowserRouter>
      <Toaster/>
      {settings ? 
        <ConfigProvider>       
          <ThemeProvider theme={MUITheme}>
            <CssBaseline/>
            <Layout style={{minHeight: '100vh'}}>
              <Layout className="site-layout" style={{backgroundColor: dk.darkmode ? '#121212' : 'rgba(255, 255, 250, 1'}}>
                {/* TopMenu Implemented */}
                <TopMenu/>
                {auth?.employee?.role < 2 && !gdate?.cashflowStatus && <div className='text-light text-center p-1 bg-danger'>{moment(gdate?.cashflowDate).format("YYYY-MM-DD")} CashFlow Need to be Verified <a className='text-light fw-bold' href="/dashboard/user/accounts/daily-cashflow">CLICK HERE</a></div>}
                {/* Content */}
                {<RoutesPage/>}

                {/* FOOTER */}
                <div className='p-3 text-center' style={{
                    color: 'white',
                    backgroundColor: 'rgba(0,0,0,0.8)',
                  }}>
                Copyrights © {new Date().getFullYear() !== 2023 ? '2023 - ' + new Date().getFullYear() : 2023} <b><a className='text-decoration-none' href='https://bido.lk' target='_blank'>Bido (pvt) Ltd.</a></b> All rights reserved. Designed By <b><a className='text-decoration-none' href='/developer'>Shadhir Shaz</a></b>

                </div>

              </Layout>
            </Layout>
          </ThemeProvider>
        </ConfigProvider>
        :
        <Setup/>
      }
    </BrowserRouter>
  )
}

