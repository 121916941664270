import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import {unit} from '../../../Functions/NoFomats'

import {Add} from '@mui/icons-material/'

import MaterialTable from '../../../Components/Table/MaterialTable'
import ModalBasic from '../../../Components/Modal/ModalBasic'

import CreateFuelType from './CreateFuelType'
import UpdateFuelType from './UpdateFuelType'

export default function ListFuelType() {

  const [listFuelType, setListFuelType] = useState('')
  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  useEffect(() => {
      loadFuelType()
  }, [])
  
  const loadFuelType = async() => {
      try {
          const {data} = await Axios.post('/fueltypes')
          if(data?.error){
              toast.error(data.error)
          } else {
              setListFuelType(data)
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-xl-6 col-lg-8 col-md-10 col-12 mb-3'>
        <div className='row justify-content-between mb-2'>
            <div className='col-sm-6 col-12 d-flex gap-2 mb-2'>
              <button className = 'btn btn-outline-primary w-50' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
            </div>
        </div>
        <MaterialTable
          title = "Fuel Types"
          col = {[
              { accessorKey: 'fuelType', header: 'FuelType'},
              { accessorKey: 'loadLiter', header: 'Load Liters', Cell: ({row: {original}}) => unit(original.loadLiter, 'L')},
          ]}
          row = {listFuelType}
        />
        <ModalBasic
          open = {visibleAdd}
          onClose = {()=> {
          setVisibleAdd(false)
          }}
          content = {<CreateFuelType/>}
        />

        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {<UpdateFuelType data = {selected}/>}
        />

      </div>
    </div>
  )
}
