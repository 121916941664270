import React, {useEffect, useState} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment'

import {useGdate} from '../../../context/date'

import { GeneralNo, unit } from '../../../Functions/NoFomats'

import {Clear} from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'

import {Text, SelectAutoComplete} from '../../../Components/Inputs/InputFields'

import MaterialTable from '../../../Components/Table/MaterialTable'

export default function TankCheck() {
  const {id} = useParams()
  const navigate = useNavigate()
  const [gdate] = useGdate()

  //Mark Date should be morethan cashflow verified date
  const [checkCashflow, setCheckCashflow] = useState(false)

  const [listSuppliers, setListSuppliers] = useState()

  const [listTankCheck, setListTankCheck] = useState('')
  const [listStockLoad, setListStockLoad] = useState('')

  const [tank, setTank] = useState('')
  const [listPurchaseDetails, setListPurchaseDetails] = useState('')

  const [pumps, setPumps] = useState('')
  const [pumpsMaxRead, setPumpsMaxRead] = useState('')

  const [selectedPump, setSelectedPump] = useState()
  const [previousReading, setPreviousReading] = useState('')
  const [reading, setReading] = useState('')
  const [liters, setLiters] = useState(0)
  const [testing, setTesting] = useState(0)

  const [pumpList, setPumpList] = useState('')

  const [totalLiters, setTotalLiters] = useState(0)
  const [testLiters, setTestLiters] = useState(0)
  const [loadLiters, setLoadLiters] = useState(0)
  const [lossLiters, setLossLiters] = useState(0)

  const [actualStock, setActualStock] = useState()
  const [nQty, setNQty] = useState()
  const [supplier, setSupplier] = useState()
  const [amount, setAmount] = useState()

  const [purchase, setPurchase] = useState()

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    loadTank()
    loadSuppliers()
    loadPumps()
    loadTankCheck()
    loadStockLoad()
    setCheckCashflow(() => moment(gdate?.cashflowDate) > moment(gdate?.date) ? true : false)
  },[])

  useEffect(() => {
    tank && loadPurchaseDetails()
  },[tank])

  useEffect(() => {
    listTankCheck && loadStockLoad()
    listTankCheck && loadMaxReading()
  },[listTankCheck])

  useEffect(() => {
    listStockLoad && setLoadLiters(listStockLoad?.reduce((a,v) => a = a + Number(v?.quantity), 0) ? listStockLoad?.reduce((a,v) => a = a + Number(v?.quantity), 0) : 0)
  },[listStockLoad])

  useEffect(() => {
    reading && setLiters(Number(reading - previousReading)?.toFixed(3))
  },[previousReading, reading])

  useEffect(() => {
    pumpList && setTotalLiters(pumpList?.reduce((a,v) => a = a + Number(v?.liters), 0) ? pumpList?.reduce((a,v) => a = a + Number(v?.liters), 0) : 0)
    pumpList && setTestLiters(pumpList?.reduce((a,v) => a = a + Number(v?.testing), 0) ? pumpList?.reduce((a,v) => a = a + Number(v?.testing), 0) : 0)
  },[pumpList])

  useEffect(() => {
    actualStock &&  (nQty < 0 ) && FIFO(listPurchaseDetails)
    nQty < 0 ? setLossLiters(Math.abs(nQty)) : setLossLiters()
    nQty > 0 && setAmount()
    nQty > 0 ? setSubmitDisable(true) : setSubmitDisable(false)
  }, [actualStock, nQty])

  const loadTank = async() => {
    try {
      const {data} = await Axios.post(`/warehousestocks`, {_id: id})
      if (data?.error) {
        toast.error(data.error)
      } else {
        setTank(data[0])
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }
  
  const loadSuppliers = async() => {
    try {
      const {data} = await Axios.post(`/Suppliers`)
      if(data?.error){
        toast.error(data?.error)
      } else {
        setListSuppliers(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadPurchaseDetails = async() => {
    try {
        const {data} = await Axios.post(`/purchaseproductlines`, {productCode: tank?.productCode?._id})
        if (data?.error) {
            toast.error(data.error)
        } else {
          setListPurchaseDetails(data?.reverse())
        }
    } catch (err) {
      toast.error("Something went wrong, Try Again!")
      console.log(err)
    }
  }
  
  const loadPumps = async() => {
      try {
          const {data} = await Axios.post(`/pumps`, {"wareHouseStock": id })
          if(data?.error){
              toast.error(data.error)
          } else {
            setPumps(data)
          }
      } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
      }
  }

  const loadTankCheck = async() => {
    try {
        const {data} = await Axios.post(`/tank-checks`, {"wareHouseStock._id": id})
        if(data?.error){
            toast.error(data.error)
        } else {
          setListTankCheck(data)
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  const dataDateFrom = (createdDate) => {
    if (createdDate) {
      return { createdAt: {$gt: moment(createdDate)}}
    } else {
      return {}
    }
  }

  const loadMaxReading = async() => {
    try {
      const {data} = await Axios.post(`/daypumps/maxreads`, {$expr: {$gte: ["$createdAt", { $toDate: listTankCheck[0]?.createdAt }] }} )
      if (data?.error){
        toast.error(data.error)
      } else {
        setPumpsMaxRead(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadStockLoad = async() => {
    try {
      const {data} = await Axios.post(`/stockloads`, {"wareHouseStock._id": id, ...dataDateFrom(listTankCheck[0]?.createdAt) })
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListStockLoad(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const meterMark = async(e) => {
    e.preventDefault()
    try {
      await setPumpList([...pumpList, {name: selectedPump.name, pump: selectedPump, previousReading, reading, liters, testing}])
      setSelectedPump()
      setPreviousReading('')
      setReading('')
      setLiters('')
      setTesting('')
      
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const FIFO = async(productLines) => {
    try {
      //Filter by productcode and get total of all stocks 
      var SBS = loadLiters - (totalLiters - testLiters)
      var SAS = Number(actualStock)
      var sumBSQty = 0
      var sumASQty = 0
      var PBSindex = ''
      var PASindex = ''

      //FIND SAS Index
      for (var i=0; i<productLines?.length; i++) {
        sumASQty += productLines[i]?.quantity
        if (sumASQty >= SAS) {
          PASindex = i
          SAS -= (sumASQty - productLines[i]?.quantity)
          break
        }
      }
    
      // FIND SBS Index
      for (var i=0; i<productLines?.length; i++) {
        // Go through purchase lines until reach start of product Stock
        sumBSQty += productLines[i]?.quantity
        if (sumBSQty >= SBS) {
          // PBS is index of 1st stock purchase product line
          PBSindex = i
          //Subtract Balance amount (Sold) of stock in 1st purchase product line
          SBS = sumBSQty - SBS
          break
        }
      }

      const arr = []
      const quantity = ((loadLiters + testLiters) - (totalLiters + Number(actualStock)))
      await productLines?.slice(PASindex, PBSindex + 1)?.reverse().map((pl, index) => {
        const uc = (pl?.itemPrice / pl?.quantity)?.toFixed(2)
        // First Element of Array
        if(index === 0) {
          if ((pl?.quantity - SBS) >= quantity) {
            arr.push({uc, qty: quantity, check: "Same"})
          } else {
            arr.push({uc, qty: (pl?.quantity - SBS), check: `${index}`})
          }
          // Last Element of Array
        } else if (index === (PBSindex - PASindex)) {
          arr.push({uc, qty: (pl?.quantity - SAS), check: `last`})
        } else {
          // Balance Elements of Array
          arr.push({uc, qty: (pl?.quantity), check: `${index}`})
        }
      })

      var result = [];
      arr.reduce(function(a, v) {
        if (!a[v.uc]) {
          a[v.uc] = { uc: v.uc, qty: 0 };
          result.push(a[v.uc])
        }
        a[v.uc].qty += v.qty;
        return a;
      }, {});
    
      arr && await setAmount(() => arr?.reduce((a,v) => a += (v?.uc * v?.qty), 0)?.toFixed(2))
      setPurchase(productLines[PBSindex]?.purchase?._id)

    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const StockUpdateNEntry = async(qty) => {
    try {
      if (qty > 0) {
        const {data} = await Axios.post(`/createpurchase`, {invoiceNo: 'Tank Check Profit', supplier, purchaseDate: gdate?.date, items: [{productStock : tank, quantity: Number(qty)?.toFixed(3), itemPrice: 0 }], total: 0, payment: '64952f578188d2c8b9c26603', type: 'gas'})
        if (data?.error) {
          toast.error(data.error)
          setSubmitDisable(false)
        } else {
          const stockData = await Axios.post(`/stockload`, {wareHouseStock: tank, purchase: data?.purchases?._id, quantity: Number(qty)?.toFixed(3)})
          if (stockData?.data?.error) {
            toast.error(stockData.data.error)
            setSubmitDisable(false)
          } else {
            console.log(stockData)
            const accData = await Axios.post(`/accdatabulk`, [
              {date: moment(gdate?.date).format("YYYY-MM-DD"), empStatus: {status: false}, detail:{description: `Tank Check: ${tank?.productCode?.product?.name} : ${unit(nQty, "L")} Inventory Profit`}, amount: 0, accounting: "63ecfbe55b36ecf83d9dd4a3"},
              {date: moment(gdate?.date).format("YYYY-MM-DD"), empStatus: {status: false}, detail:{description: `Tank Check: ${tank?.productCode?.product?.name} : ${unit(nQty, "L")} Inventory Profit`}, amount: 0, accounting: '64952f578188d2c8b9c26603'}
            ])
            if(accData?.data?.error){
              toast.error(accData.data.error)
              setSubmitDisable(false)
            } else {
              toast.success("Tank Check & Inventory Profit Updated")
            navigate(`/dashboard/user/tank/list-tank-check/${tank?._id}`)
            }
          }

        }
      } else {
        const {data} = await Axios.post(`/accdatabulk`, [
          {date: gdate?.date, empStatus: {status: false}, detail: {description: `Tank Check: ${tank?.productCode?.product?.name} : ${unit(nQty, "L")} Inventory loss`}, amount : -(amount), accounting: '63ecfbe55b36ecf83d9dd4a3'},
          {date: gdate?.date, empStatus: {status: false}, detail: {description: `Tank Check: ${tank?.productCode?.product?.name} : ${unit(nQty, "L")} Inventory loss`}, amount, accounting: '64eec83c972323a31bc16e3d'}
        ])
        if (data?.error) {
          toast.error(data.error)
          setSubmitDisable(false)
        } else {
          const {data} = await Axios.put(`/warehousestock/${tank?._id}`, { $inc: { 'stock': (Number(qty)) } })
          if (data?.error) {
            toast.error(data.error)
            setSubmitDisable(false)
          } else {
            const stockData = await Axios.post(`/stockload`, {wareHouseStock: tank, purchase, quantity: Number(qty)?.toFixed(3)})
            if (stockData?.data?.error) {
              toast.error(stockData?.data.error)
            } else {
              toast.success("Tank Check & Loss Inventory Updated")
              navigate(`/dashboard/user/tank/list-tank-check/${tank?._id}`)
            }
          }
        }
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post(`/tank-check`, {date: gdate?.date, wareHouseStock: tank, pumpList, loadLiters, actualStock, lossLiters, amount: (nQty < 0 && amount)})
      if (data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else if (actualStock) { 
        StockUpdateNEntry(nQty)
      } else {
        toast.success("Tank Check Updated")
        navigate(`/dashboard/user/tank/list-tank-check/${tank?._id}`)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const columns = [
    { accessorKey: 'pump.name', header: 'Pump', size: 180},
    { accessorKey: 'pReading', header: 'Previous Reading', size: 150, Cell : ({row: {original}}) => GeneralNo(original.previousReading)},
    { accessorKey: 'cReading', header: 'Current Reading', size: 150, Cell : ({row: {original}}) => GeneralNo(original.reading)},
    { accessorKey: 'liters', header: 'Liters', size: 120, Cell : ({row: {original}}) => unit(original.liters, "L")},
    { accessorKey: 'testing', header: 'Testing L', size: 120, Cell : ({row: {original}}) => unit(original.testing, "L")},
  ]

  const rowAction = ({row: {original}}) => [
    <div className='d-flex justify-content-center'>
      <Clear
        onClick={() => {
          setPumpList(pumpList.filter(c => c?.pump?._id !== original.pump?._id))
          toast.success("Pump Reading Removed")
        }}
      />
    </div>
  ]

  return (
    <div className='row justify-content-center'>
      <h6 className='text-center'>Check Tank</h6>
      <h5 className='text-center'>{tank?.productCode?.product?.name}</h5>
      <dd className='text-center'>Current Stock {unit(tank?.stock, 'L')}</dd>
      {pumps && pumpsMaxRead &&
        <form onSubmit={meterMark} className='col-xxl-4 col-lg-5 col-md-8 col-sm-10 col-12 mt-3'>
          <SelectAutoComplete
            label = "Pump"
            focus = 'true'
            optionsList={pumps}
            option = {(i) => i?.name}
            value={selectedPump}
            onChange={(event, value) => {
              setSelectedPump(value)
              setReading(Number(pumpsMaxRead?.find((i) => i?._id?.pump === value?._id) ? pumpsMaxRead.find((i) => i?._id?.pump === value?._id)?.endReading : (listTankCheck[0]?.pumpList?.find((i) => i?.pump?._id === value?._id) ? listTankCheck[0]?.pumpList?.find((i) => i?.pump?._id === value?._id)?.reading : (pumpsMaxRead?.find((i) => i?._id?.pump === value?._id) ? pumpsMaxRead?.find((i) => i?._id?.pump === value?._id)?.previousReading : 0)) ))
              setTesting(Number(pumpsMaxRead?.find((i) => i?._id?.pump === value?._id) ? pumpsMaxRead.find((i) => i?._id?.pump === value?._id)?.testing : 0))
              setPreviousReading(listTankCheck[0]?.pumpList?.find((i) => i?.pump?._id === value?._id) ? listTankCheck[0]?.pumpList?.find((i) => i?.pump?._id === value?._id)?.reading : (pumpsMaxRead?.find((i) => i?._id?.pump === value?._id) ? pumpsMaxRead?.find((i) => i?._id?.pump === value?._id)?.previousReading : 0))
            }}
          />

          <Text
            label="Previous Reading"
            type = "Number"
            value = {previousReading}
            onChange={(e) => {
              setPreviousReading(e.target.value)
            }}
          />

          <Text
            label="Reading"
            type = "Number"
            value = {reading}
            onChange={(e) => {
              setReading(e.target.value)
            }}
          />

          <Text
            label="Test Lliters"
            type = "Number"
            value = {testing}
            onChange={(e) => {
              setTesting(e.target.value)
            }}
            end = "L"
          />

          <div className='col-12 d-grid'>
            <button style={{maxHeight: '40px'}} className='btn btn-primary' type='submit' onClick={meterMark} disabled = {selectedPump && reading ? '' : true}>Mark</button>
          </div>

        </form>
      }

        <div className='col-xxl-4 col-lg-5 col-md-8 col-sm-10 col-12 mt-3'>
          <div className='row'>
            <div className='col-5'>
              <dd className='fw-bold'>Details</dd>
              <dd>Fillup + Test</dd>
              <dd>Test (-)</dd>
              <dd>Fillup</dd>
            </div>

            <div className='col-4 text-end'>
              <dd className='fw-bold'>Lliters</dd>
              <dd>{unit(totalLiters, "L")}</dd>
              <dd>{unit(testLiters, "L")}</dd>
              <dd>{unit((totalLiters - testLiters), "L")}</dd>
            </div>

            <div className='col-3 text-end'>
              <dd className='fw-bold'>Load</dd>
              <dd>-</dd>
              <dd>-</dd>
              <dd>{GeneralNo((totalLiters - testLiters) / tank?.productCode?.product?.type?.loadLiter)}</dd>

            </div>

            <hr/>

            <div className='col-5'>
              <dd className='fw-bold'>Purchase</dd>
              <dd className='fw-bold' style={{color: 'green'}}>Differance</dd>
            </div>

            <div className='col-4 text-end'>
              <dd className='fw-bold'>{unit(loadLiters, "L")}</dd>
              <dd className='fw-bold' style={{color: 'green'}}>{unit( (loadLiters - (totalLiters - testLiters)), "L")}</dd>

            </div>

            <div className='col-3 text-end'>
              <dd className='fw-bold'>{GeneralNo(loadLiters / tank?.productCode?.product?.type?.loadLiter)}</dd>
              <dd className='fw-bold' style={{color: 'green'}}>{GeneralNo( (loadLiters - ((totalLiters - testLiters))) / tank?.productCode?.product?.type?.loadLiter)}</dd>
            </div>
          </div>

          
          <div className='pt-2 d-grid'>
            {pumpList?.length === pumps?.length && <div>
              <dd className='text-center fw-semibold'>Leave it blank if you don't want to update</dd>
              <Text
                label="Enter Actual Stock"
                type = "Number"
                value = {actualStock}
                onChange={(e) => {
                  setActualStock(e.target.value)
                  setNQty((totalLiters + Number(e.target.value)) - (loadLiters + testLiters))
                }}
                end = "L"
              />
            </div>}
            {nQty < 0 && <dd className='text-center'>Loss Inventory of {unit((-nQty), "L")} | Amount : {new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(amount)}</dd>}
            {nQty > 0 && <SelectAutoComplete
              label = "Supplier"
              optionsList={listSuppliers}
              option = {(i) => `${i.name + ' | '+ i.phoneNumber}`}
              value={supplier}
              onChange={(event, value) => {
                  setSupplier(value)
                  setSubmitDisable(false)
              }}
            />}

            {/* Submit Button */}
            {<LoadingButton onClick={handleSubmit} loading={(pumpList?.length === pumps?.length ? false : true) || checkCashflow} variant="contained" disabled={submitDisable}> 
              <span>SUBMIT</span> </LoadingButton>}
            {checkCashflow && <dd className='text-center fs-6 fw-bold text-danger mt-2'>Cashflow Verified on Selected date</dd>}
          </div>
        </div>

        <div className='col-xl-10 col-12 mt-3'>
          {pumpList.length > 0 && <MaterialTable
            title = "Added Pumps for Tank"
            col = {columns}
            row = {pumpList?.length > 0 ? pumpList : 0}
            rowAction = {rowAction}
            exportDisable = {true}
          />}
        </div>

    </div>
  )
}
