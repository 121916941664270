import React, {useRef, useState, useEffect} from 'react'
import Axios from 'axios'
import moment from 'moment';
import {toast} from 'react-hot-toast'
import {useReactToPrint} from 'react-to-print'

import {Print, Verified} from '@mui/icons-material'
import {styled, Table, TableBody, TableCell, TableHead, TableRow, Paper} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell'

import {Text} from '../../../Components/Inputs/InputFields'

import { useGdate } from '../../../context/date'
import { useDk } from '../../../context/dk'

import EntryCheckCard from './EntryCheckCard';

export default function FinancialStatement() {
  const [gdate, setGdate] = useGdate()
  const [dk, setDk] = useDk()

  const [fuelSales , setFuelSales] = useState('')

  const [findTankDep, setFindTankDep] = useState('')
  const [findFuelAccounts, setFindFuelAccounts] = useState('')
  const [findPurchase, setFindPurchase] = useState('')
  const [findAttendance, setFindAttendance] = useState('')

  const [inventorySales, setInventorySales] = useState('')
  const [inventoryReturns, setInventoryReturns] = useState('')
  const [totalSales, setTotalSales] = useState('')

  const [allAccounts, setAllAccounts] = useState('')
  const [totalNc, setTotalNc] = useState('')
  const [totalNegNc, setTotalNegNc] = useState('')

  const [bfCash, setBfCash] = useState('')
  const [bfCheque, setBfCheque] = useState('')

  const [cashAccounts, setCashAccounts] = useState('')
  const [chequeAccounts, setChequeAccounts] = useState('')

  const [cashTotalAccounts, setCashTotalAccounts] = useState('')
  const [chequeTotalAccounts, setChequeTotalAccounts] = useState('')

  const [cashflowCheck, setcashflowCheck] = useState('')
  const [amount, setAmount] = useState('')

  const [submitDisable, setSubmitDisable] = useState(false)
  const [submitDisable2, setSubmitDisable2] = useState(false)

  useEffect(() => {
    loadFuelSales()
    loadTankDep()
    loadFuelAccounts()
    loadEmpAttendances()
    loadOrders()
    loadOrdersReturns()
    loadAccData()
    loadBFIncomeAccounts()
    loadIncomeAccounts()
    loadIncomeTotalAccounts()
    //Check selected date less then Cashflow Verify date THEN CASHFLOW VERIFIED 
    setcashflowCheck(() => moment(gdate?.date) < moment(gdate?.cashflowDate) ? true : false)
  },[])

  useEffect(() => {
    setTotalSales((fuelSales ? fuelSales?.reduce((a,v) => a = a + v?.total, 0) : 0) + inventorySales - inventoryReturns)
  },[fuelSales, inventorySales, inventoryReturns])

  useEffect(() => {
    setTotalNc(allAccounts && allAccounts?.reduce((a,v) => a + (allAccountsDebitEntry(v) ? allAccountsDebitEntry(v) : 0), 0))
    setTotalNegNc(allAccounts && allAccounts?.reduce((a,v) => a + (allAccountsCreditEntry(v) ? allAccountsCreditEntry(v) : 0), 0))
  },[allAccounts])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  }));

  const loadFuelSales = async() => {
    try {
      const {data} = await Axios.post(`/daypumps/fuelsales-typepricecost`, {date: gdate?.date})
      if(data?.error){
        toast.error(data.error)
      }
      else {
        setFuelSales(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadTankDep = async() => {
    try {
        const {data} = await Axios.post('/product-depths', {date: gdate?.date})
        if(data?.error){
            toast.error(data.error)
        } else {
            setFindTankDep(data && true)
        }
    } catch (err) {
        toast.error("Something went wrong, Try Again!")
        console.log(err)
    }
  }

  const loadFuelAccounts = async() => {
    try {
        const {data} = await Axios.post(`/dayemployees`, {date: gdate?.date})
        if(data?.error){
            toast.error(data.error)
        } else {
            setFindFuelAccounts(data && true)
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  const loadEmpAttendances = async() => {
    try {
      const {data} = await Axios.post('/empattendances', {date: gdate?.date})
      if(data?.error){
          toast.error(data.error)
      } else {
          setFindAttendance(data && true)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadOrders = async() => {
    try {
      const {data} = await Axios.post(`/orders`, {orderDate: gdate?.date})
      if(data?.error){
        toast.error(data.error)
      }
      else {
        setInventorySales(data?.reduce((a,v) => a = a + v?.total, 0) ? data?.reduce((a,v) => a = a + v?.total, 0) : 0)

      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadOrdersReturns = async() => {
    try {
      const {data} = await Axios.post(`/order-returns`, {returnDate: gdate?.date})
      if(data?.error){
        toast.error(data.error)
      }
      else {
        setInventoryReturns(data?.reduce((a,v) => a = a + v?.total, 0) ? data?.reduce((a,v) => a = a + v?.total, 0) : 0)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadAccData = async() => {
    try {
        const {data} = await Axios.post(`/accdatas`, {date: gdate?.date})
        if(data?.error){
            toast.error(data.error)
        } else {
            setAllAccounts(data.filter((i) => i?.accounting?._id !== '64952f578188d2c8b9c26603' && i?.accounting?._id !== '63eb82f1661aeb9f06368651' && i?.accounting?._id !== '64fda2c5c969962bfbcee3eb' ? (i) => i?.accounting?._id !== '64952f578188d2c8b9c26603' && i?.accounting?._id !== '63eb82f1661aeb9f06368651' && i?.accounting?._id !== '64fda2c5c969962bfbcee3eb' : ''))
            setFindPurchase(data?.find((i) => i?.accounting?._id === '63ecfbe55b36ecf83d9dd4a3' && i?.amount > 0))
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  //LOAD BROUGHT FORWARD CASH & CHEQUE
  const loadBFIncomeAccounts = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/filteraccounts`, {date: {$lte: moment(gdate?.date).subtract(1, 'day').format('YYYY-MM-DD')}})
      if(data?.error) {
        toast.error(data.error)
      } else {
        await setBfCash(data.find((i) => i._id.accounting[0]?._id === '64952f578188d2c8b9c26603') ? data.find((i) => i._id.accounting[0]?._id === '64952f578188d2c8b9c26603')?.total : 0 )
        await setBfCheque(data.find((i) => i._id.accounting[0]?._id === '64fda2c5c969962bfbcee3eb') ? data.find((i) => i._id.accounting[0]?._id === '64fda2c5c969962bfbcee3eb')?.total : 0 )
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadIncomeAccounts = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/filteraccounts`, {date: gdate?.date})
      if(data?.error) {
        toast.error(data.error)
      } else {
        await setCashAccounts(data.find((i) => i._id.accounting[0]?._id === '64952f578188d2c8b9c26603') ? data.find((i) => i._id.accounting[0]?._id === '64952f578188d2c8b9c26603')?.total : 0 )
        await setChequeAccounts(data.find((i) => i._id.accounting[0]?._id === '64fda2c5c969962bfbcee3eb') ? data.find((i) => i._id.accounting[0]?._id === '64fda2c5c969962bfbcee3eb')?.total : 0 )
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadIncomeTotalAccounts = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/filteraccounts`, {date: {$lte: gdate?.date}})
      if(data?.error) {
        toast.error(data.error)
      } else {
        await setCashTotalAccounts(data.find((i) => i._id.accounting[0]?._id === '64952f578188d2c8b9c26603') ? data.find((i) => i._id.accounting[0]?._id === '64952f578188d2c8b9c26603')?.total : 0 )
        await setChequeTotalAccounts(data.find((i) => i._id.accounting[0]?._id === '64fda2c5c969962bfbcee3eb') ? data.find((i) => i._id.accounting[0]?._id === '64fda2c5c969962bfbcee3eb')?.total : 0 )
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  //Need to load Total Cash and Cheque account so can compare the amount with cash and cheque on last 2 rows
  const allAccountsDebitEntry = (i) => {
    switch (true) {
        case ( (i.accounting?.category?.type && i?.amount > 0) || (!i.accounting?.category?.type && i?.amount < 0) ):
            return Math.abs(i?.amount);
    }
  }

  const allAccountsCreditEntry = (i) => {
      switch (true) {
          case ( (!i.accounting?.category?.type && i?.amount > 0) || (i.accounting?.category?.type && i?.amount < 0) ):
              return Math.abs(i.amount);
      }
  }

  const componentRef = useRef();
  const printData = useReactToPrint({
      content : () => componentRef.current,
      documentTitle: `Daily Cash Flow Accounts - ${gdate?.date}`,
      onBeforeGetContent: async() => {
          await setDk({...dk, darkmode : false})
      },
      onAfterPrint: () => {
          const data = localStorage.getItem("darkmode");
          if (data) {
              const parsed = JSON.parse(data);
              setDk({...dk, darkmode: parsed});
          }
      },
  })

  const nonCashflowNames = (accdata) => {
    switch (accdata?.accounting?._id) {
      case '6515831c1a89e4fc7010012a':
        return (accdata?.empStatus?.employee?.name?.toUpperCase())
      case '63e31a93f029ceab39dcd281':
        return (accdata?.detail?.bank?.accountName?.toUpperCase())
      case '64acdf13722d07654e219f89':
        return (accdata?.detail?.supplier?.name?.toUpperCase())
      case '63e31abdf029ceab39dcd283':
        return (accdata?.detail?.customer?.name?.toUpperCase())
      default:
        return ''
    }
  }

  const handleAccounts = async(e) => {
    e.preventDefault()
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post('/accdatabulk', [
        {date: gdate?.date, empStatus: {status: false}, detail: {description: 'Miscellaneous income on Cashflow check'}, amount: Number(amount)?.toFixed(2), accounting: '6506bfcdd3328c7a7f56db8d', refId : `de-${moment(new Date()).format("YYYYMMDDHHmmss")}-cashflowCheck-${amount}`},
        {date: gdate?.date, empStatus: {status: false}, detail: {description: 'Miscellaneous income on Cashflow check'}, amount: Number(amount)?.toFixed(2), accounting: '64952f578188d2c8b9c26603' , refId : `de-${moment(new Date()).format("YYYYMMDDHHmmss")}-cashflowCheck-${amount}`}
      ])
      if (data?.error) {
        toast.error(data?.error)
        setSubmitDisable2(false)
      } else {
        toast.success("Miscellaneous Entry Added")
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something wernt wrong, Try Again!")
    }
  }

  const loadCashFlowsToBeVerify = async() => {
    try {
        const {data} = await Axios.post(`/cashflows-verifiedlist`)
        if (data?.error) {
            console.log(data?.error)
        } else {
          await setGdate({...gdate, 
            date: data?.last ? moment(data?.date).add(1, 'day').format('YYYY-MM-DD') : data?.date, 
            reportStartDate: data?.last ? moment(data?.date).add(1, 'day').startOf('month').format('YYYY-MM-DD') : moment(data?.date).startOf('month').format('YYYY-MM-DD'), 
            reportEndDate: data?.last ? moment(data?.date).add(1, 'day').endOf('month').format('YYYY-MM-DD') : moment(data?.date).endOf('month').format('YYYY-MM-DD'),
            cashflowDate: data?.last ? moment(data?.date).add(1, 'day').format('YYYY-MM-DD') : data?.date,
            cashflowStatus: data?.last
          })
          await localStorage.setItem( "globalDates", JSON.stringify({...gdate, 
            date: data?.last ? moment(data?.date).add(1, 'day').format('YYYY-MM-DD') : data?.date, 
            reportStartDate: data?.last ? moment(data?.date).add(1, 'day').startOf('month').format('YYYY-MM-DD') : moment(data?.date).startOf('month').format('YYYY-MM-DD'), 
            reportEndDate: data?.last ? moment(data?.date).add(1, 'day').endOf('month').format('YYYY-MM-DD') : moment(data?.date).endOf('month').format('YYYY-MM-DD'),
            cashflowDate: data?.last ? moment(data?.date).add(1, 'day').format('YYYY-MM-DD') : data?.date,
            cashflowStatus: data?.last
          }))
        }
    } catch (err) {
        console.log(err)
    }
  }

  const handleCashFlowVerify = async(e) => {
    e.preventDefault()
    try {
      await setSubmitDisable2(true)
      const {data} = await Axios.post('/cashflow', {date: gdate?.date, status: true, amount: Number(cashAccounts + chequeAccounts)?.toFixed(2)})
      if (data?.error) {
        toast.error(data?.error)
        setSubmitDisable(false)
      } else {
        await loadCashFlowsToBeVerify()
        toast.success("Cash Flow Checks Verified")
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something wernt wrong, Try Again!")
    }
  }

  const verticalBorder = {borderLeft: 'solid'}

  return (
    <div className='row justify-content-center'>
      <div className='mb-2'>
        <button className = 'btn btn-warning' onClick={() => {printData()}}><Print/></button> 
      </div>

      <div className='row col-lg-8 col-sm-10 col-12'>
        <EntryCheckCard name="DAY PUMP" entry={fuelSales}/>
        <EntryCheckCard name="TANK" entry={findTankDep}/>
        <EntryCheckCard name="FUEL ACCOUNTS" entry={findFuelAccounts}/>
        <EntryCheckCard name="PURCHASE" entry={findPurchase}/>
        <EntryCheckCard name="SALES" entry={inventorySales}/>
        <EntryCheckCard name="DOUBLE ENTRY" entry={allAccounts}/>
        <EntryCheckCard name="ATTENDANCE" entry={findAttendance}/>
        <EntryCheckCard name="CASHFLOW" entry={cashflowCheck}/>
      </div>

      {/* CASH FLOW STATEMENT */}
      <div className = 'row justify-content-center pt-3' ref= {componentRef}>
        <div className='d-flex justify-content-center gap-2 align-items-baseline'>
          <h4>DAILY CASH FLOW STATEMENT</h4>
          <p>{gdate?.date}</p>
        </div>
        
        <div className='col-xxl-10 col-12'>
          <div style={{overflowX: 'auto'}}>
          <Table component={Paper} size='small'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Daily Cash Flow</StyledTableCell>
                <StyledTableCell style={verticalBorder} align="right">Amount</StyledTableCell>
                <StyledTableCell style={verticalBorder} align="right">Amount</StyledTableCell>
              </TableRow>
            </TableHead>
          
            <TableBody>

              {/* CASH BROUGHT FORWARD */}
              <TableRow key='cash-bf'>
                <TableCell className='text-end fw-bold'>CASH B/F</TableCell>
                <TableCell style={verticalBorder} align="right"></TableCell>
                <TableCell style={verticalBorder} className='fw-bold' align="right">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(bfCash)}</TableCell>
              </TableRow>

              {/* CHEQUE BROUGHT FORWARD */}
              <TableRow key='cheque-bf'>
                <TableCell className='text-end fw-bold'>CHEQUE B/F</TableCell>
                <TableCell style={verticalBorder} align="right"></TableCell>
                <TableCell style={verticalBorder} className='fw-bold' align="right">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(bfCheque)}</TableCell>
              </TableRow>


              {/* FUEL SALES */}
              <TableRow key='fuel-sales'>
                <TableCell className='fw-bold'>FUEL SALES</TableCell>
                <TableCell style={verticalBorder} align="right"></TableCell>
                <TableCell style={verticalBorder} align="right"></TableCell>
              </TableRow>

              {fuelSales && fuelSales?.map((i) =>
                <TableRow key={i?._id?.type}>
                  <TableCell> {i?._id?.type + ' | ' + Number(i?.liter)?.toFixed(3) + ' L'}</TableCell>
                  <TableCell style={verticalBorder} align="right"></TableCell>
                  <TableCell style={verticalBorder} align="right">{(new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.total ? i?.total : 0))}</TableCell>
                </TableRow>
              )}

              {/* INVENTORY SALES */}
              <TableRow key='inventory-sales'>
                <TableCell className='fw-bold'>INVENTORY SALES & RETURNS</TableCell>
                <TableCell style={verticalBorder} align="right"></TableCell>
                <TableCell style={verticalBorder} align="right"></TableCell>
              </TableRow>

              <TableRow key='all-inventory-sales'>
                <TableCell>All Inventory Sales</TableCell>
                <TableCell style={verticalBorder} align="right"></TableCell>
                <TableCell style={verticalBorder} align="right">{(new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(inventorySales ? inventorySales : 0))}</TableCell>
              </TableRow>

              <TableRow key='all-inventory-returns'>
                <TableCell>All Inventory Returns</TableCell>
                <TableCell style={verticalBorder} align="right"></TableCell>
                <TableCell style={verticalBorder} align="right">-({(new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(inventoryReturns ? inventoryReturns : 0))})</TableCell>
              </TableRow>

              {/* NONE CASH FLOWS ACCOUNTS */}
              <TableRow key='nonCashFlow'>
                <TableCell className='fw-bold'>NONE CASH FLOWS</TableCell>
                <TableCell style={verticalBorder} align="right"></TableCell>
                <TableCell style={verticalBorder} align="right"></TableCell>
              </TableRow>

              {allAccounts && allAccounts?.map((i) =>
                <TableRow key={i?._id}>
                  <TableCell> {i?.accounting?.name?.toUpperCase() + ' | ' + nonCashflowNames(i) + ' : ' +i?.detail?.description}</TableCell>
                  <TableCell style={verticalBorder} align="right">{allAccountsDebitEntry(i) && (new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(allAccountsDebitEntry(i)))}</TableCell>
                  <TableCell style={verticalBorder} align="right">{allAccountsCreditEntry(i) && (new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(Math.abs(allAccountsCreditEntry(i))))}</TableCell>
                </TableRow>
              )}

              <TableRow key='noneCashFlowTotal' sx={{bgcolor: '#9e9e9e'}}>
                <TableCell className='text-end fw-bold text-dark'>TOTAL</TableCell>
                <TableCell style={verticalBorder} className='fw-bold text-dark' align="right">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(totalNc) }</TableCell>
                <TableCell style={verticalBorder} className='fw-bold text-dark' align="right">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(bfCash + bfCheque + totalSales + totalNegNc) }</TableCell>
              </TableRow>

              <TableRow key='cash-cheque' style={Number(cashAccounts + chequeAccounts)?.toFixed(2) === Number(totalSales - (totalNc - totalNegNc))?.toFixed(2) ? {backgroundColor: 'greenyellow'} : {backgroundColor: 'red'}}>
                <TableCell className='text-center fw-bold' style={{fontSize: '16px', color: 'black'}}>{Number((totalSales + totalNegNc) - totalNc)?.toFixed(2) === Number(cashAccounts + chequeAccounts)?.toFixed(2) ? 'CASH + CHEQUE' : `ERROR! Missing Entry of ${new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format( Math.abs((cashAccounts + chequeAccounts + totalNc) - (totalSales + totalNegNc)) ) }`}</TableCell>
                <TableCell style={verticalBorder} align="right"></TableCell>
                <TableCell style={verticalBorder} className='fw-bold' style={{fontSize: '16px', color: 'black'}} align="right">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format((bfCash + bfCheque + totalSales + totalNegNc) - totalNc) }</TableCell>
              </TableRow>

              {/* CASH */}
              <TableRow key='cash'>
                <TableCell className='fw-bold'>{Number(bfCash + cashAccounts)?.toFixed(2) === Number(cashTotalAccounts)?.toFixed(2) ? "CASH" : `ERROR CASH Should be ${new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(bfCash + cashAccounts)}`}</TableCell>
                <TableCell style={verticalBorder} align="right">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(bfCash + cashAccounts + Number(amount))}</TableCell>
                <TableCell style={verticalBorder} align="right"></TableCell>
              </TableRow>

              {/* CHEQUE */}
              <TableRow key='cheque'>
                <TableCell className='fw-bold'>{Number(bfCheque + chequeAccounts)?.toFixed(2) === Number(chequeTotalAccounts)?.toFixed(2) ? "CHEQUE" : `ERROR CHEQUE Should be ${new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(bfCheque + chequeAccounts)}`}</TableCell>
                <TableCell style={verticalBorder} align="right">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(bfCheque + chequeAccounts)}</TableCell>
                <TableCell style={verticalBorder} align="right"></TableCell>
              </TableRow>

            </TableBody>
          </Table>
          </div>

          {!cashflowCheck && 
          <div className='row mt-3'>
            <p className='text-center fs-5 col-xl-6 col-md-5'>Adjustments in Cash</p>
            <div className='col-xl-4 col-md-4 col-sm-8'>
              <Text
                label="Miscellaneous income +/-" 
                value = {amount}
                type = "number"
                onChange={(e) => setAmount(e.target.value)}
                start = 'LKR'
              />
            </div>
            <div className='col-xl-2 col-md-3 col-sm-4'>
              <button className='btn btn-primary w-100' onClick={handleAccounts} disabled={submitDisable}>ADD ENTRY</button>
            </div>
          </div>}

          <div className='d-flex justify-content-end mt-3'>
            <button className='btn btn-success' onClick={handleCashFlowVerify} disabled= {submitDisable2 || cashflowCheck || (moment(gdate?.date) > moment(gdate?.cashflowDate)) ? true : false}>{
              cashflowCheck ? <Verified/> : (gdate?.date === gdate?.cashflowDate ? "VERIFY" : ('Verify ' + gdate?.cashflowDate))
              }
            </button>
          </div>
        </div>
      </div>
      
    </div>
  )
}
