import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useGdate } from '../../context/date'

import {GeneralNo, currency, unit} from '../../Functions/NoFomats'

import {Check, Error} from '@mui/icons-material/'

import MaterialTable from '../../Components/Table/MaterialTable'

export default function ListDayPump() {
    const [gdate] = useGdate()

    const [listData, setListData] = useState('')

    useEffect(() => {
        loadData()
    },[gdate])
    
    const loadData = async() => {
        try {
            const {data} = await Axios.post(`/daypumps`, {date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
            if(data?.error){
                toast.error(data.error)
            } else {
                setListData(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const allAccounts = [
        { accessorKey: 'date', header: 'Date', size: 100},
        { accessorKey: 'pump.name', header: 'Pump', size: 100},
        { accessorKey: 'employee.name', header: 'Employee', size: 100},
        { accessorKey: 'price', header: 'Price', size: 100, Cell : ({row: {original}}) => currency(original.price), export : (i) => currency(i)},
        { accessorKey: 'startReading', header: 'Start Reading', size: 100, Cell : ({row: {original}}) => GeneralNo(original.startReading), export : (i) => GeneralNo(i)},
        { accessorKey: 'endReading', header: 'End Reading', size: 100, Cell : ({row: {original}}) => GeneralNo(original.endReading), export : (i) => GeneralNo(i)},
        { accessorKey: 'testing', header: 'Test', size: 100, Cell : ({row: {original}}) => unit(original.testing, 'L'), export : (i) => unit(i, 'L')},
        { accessorKey: 'testRefill', header: 'T.Refill', size: 100, Cell : ({row: {original}}) => original.testing !== 0 ? (original?.testRefill ? <Check color='success'/> : <Error color='warning'/>): '-' },
        { accessorKey: 'expenseL', header: 'Expense', size: 100, Cell : ({row: {original}}) => unit(original.expenseL, 'L'), export : (i) => unit(i, 'L')},
        { accessorKey: 'drawingsL', header: 'Drawings', size: 100, Cell : ({row: {original}}) => unit(original.drawingsL, 'L'), export : (i) => unit(i, 'L')},
        { accessorKey: 'soldQuantity', header: 'Sold', size: 100, Cell : ({row: {original}}) => unit(original.soldQuantity, 'L'), export : (i) => unit(i, 'L')},
        { accessorKey: 'inCome', header: 'Amount', size: 100, Cell : ({row: {original}}) => currency(original.inCome, true), export : (i) => currency(i)},
    ]

    return (
        <div className='row justify-content-center'>
            <div className='col-12 mb-3'>
                <MaterialTable
                    title = {'Daily Pump Details | ' + gdate?.reportStartDate + ' - ' + gdate?.reportEndDate}
                    col = {allAccounts}
                    row = {listData}
                />
            </div>

            <div className='row'>
                <div className='d-flex justify-content-between'>
                    <h6>Total Fuel Sales By Pumps</h6>
                    <h6>{GeneralNo(listData && listData?.reduce((a,v) =>  a = a + v?.inCome , 0 )) }</h6>
                </div>
            </div>
        </div>
    )
}
