import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useAuth } from '../../context/auth'

import { GeneralNo, unit, currency } from '../../Functions/NoFomats'

import {Add, Edit} from '@mui/icons-material/'

import MaterialTable from '../../Components/Table/MaterialTable'
import ModalBasic from '../../Components/Modal/ModalBasic'

import CreateTank from './CreateTank'
import UpdateTank from './UpdateTank'

export default function ListTank() {
  const [auth] = useAuth()

  const [listTank, setListTank] = useState('')
  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  // const [visible1, setVisible1] = useState(false)
  // const [selected1, setSelected1] = useState(null)

  // const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
      loadTank()
  }, [])
  

  const loadTank = async() => {
      try {
          const {data} = await Axios.post('/productcodes')
          if(data?.error){
              toast.error(data.error)
          } else {
            setListTank(data?.filter((i) => i?.product?.category?._id === "63e65efc2f840202604fbb7f")?.map(i => ({...i, capacityL: (i?.product?.capacity * 4.855)})))
          }
      } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
      }
  }

  // const handleDeleteTank = async(productCodeData) => {
  //   try {
  //     await setSubmitDisable(true)
  //     const {data} = await Axios.delete(`/warehousestock/delete`, {productCode: productCodeData?._id})
  //     if (data?.error) {
  //       toast.error(data.error)
  //       setSubmitDisable(false)
  //     } else {
  //       const {data} = await Axios.delete(`/productcodes/${productCodeData?.product?._id}`)
  //       if (data?.error) {
  //         toast.error(data.error)
  //         setSubmitDisable(false)
  //       } else {
  //         const {data} = await Axios.delete(`/product/${productCodeData?.product?._id}`)
  //         if (data?.error) {
  //           toast.error(data.error)
  //           setSubmitDisable(false)
  //         } else {
  //           toast.success('Tank Successfully Deleted')
  //           window.location.reload()
  //         }
  //       }
  //     }

  //   } catch (err) {
  //     console.log(err)
  //     toast.error("Something went wrong, Try Again!")
  //   }
  // }

  const colums = [
    { accessorKey: 'product.name', header: 'Tank Name', size: 100},
    { accessorKey: 'productCode', header: 'Product Code', size: 100},
    { accessorKey: 'product.type.fuelType', header: 'Type', size: 10, Cell : ({row: {original}}) => (original.product?.type?.fuelType)},
    { accessorKey: 'product.capacity', header: 'Capacity (G)', size: 100, Cell : ({row: {original}}) => `${GeneralNo(original.product?.capacity)} G`},
    { accessorKey: 'capacityL', header: 'Capacity (L)', size: 140, Cell : ({row: {original}}) => unit(original.capacityL, 'L'), export : (i) => unit(i, 'L')},
    { accessorKey: 'cost', header: 'Cost', size: 120, Cell : ({row: {original}}) => currency(original.cost)},
    { accessorKey: 'price', header: 'Price', size: 120, Cell : ({row: {original}}) => currency(original.price)},
  ]

  const rowAction = ({row: {original}}) => [
    <div className='d-flex justify-content-center gap-2'>
      <Edit
        onClick={() => {
          setVisible(true)
          setSelected(original)
        }}
      />
      {/* <DeleteForever
        onClick={() => {
          setVisible1(true)
          setSelected1(i.row)
        }}
      /> */}
    </div>
  ]

  return (
    <div className='row justify-content-center'>
      <div className='col-12 mb-3'>
        <div className='row justify-content-between mb-2'>
          <div className='col-xxl-3 col-xl-4 col-lg-5 col-sm-6 col-12 d-flex gap-2 mb-2'>
            <button className = 'btn btn-outline-primary w-50' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
          </div>
        </div>
        <MaterialTable
          title = "List of Tank Details"
          col = {colums}
          row = {listTank}
          rowAction = {rowAction}
        />

        <ModalBasic
          open = {visibleAdd}
          onClose = {()=> {
          setVisibleAdd(false)
          }}
          content = {<CreateTank/>}
        />

        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {<UpdateTank tank= {selected}/>}
        />

        {/* <ModalBasic
          open = {visible1}
          onClose = {()=> {
          setVisible1(false)
          }}
          content = {
            auth?.employee?.role === 0 ?
              <div className='d-flex justify-content-between'>
                <p>Are You sure want to?</p>
                <button className='btn btn-outline-danger'  disabled={submitDisable} onClick={() => {
                    handleDeleteTank(selected1)
                  }}>Delete
                </button>
              </div>
              :
              <div className='d-flex justify-content-center w-100'>
                <p>You don't have Permission to Delete</p>
              </div>
              
          }
        /> */}
      </div>
    </div>
  )
}
