import React, {useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import {Text} from '../../Components/Inputs/InputFields'

export default function CreateBooth() {

  const [name, setName] = useState("");
  const [noOfPump, setNoOfPump] = useState('');

  const [submitDisable, setSubmitDisable] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post(`/booth`, {name, noOfPump});
      if(data?.error) {
        toast.error(data.error);
        setSubmitDisable(false)
      } else {
        toast.success("Booth sucessfully Created");
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
      
    }
  }
  return (
    <div className='p-3'>
      <h4 className='text-center mb-3'>Create Booth</h4>
      <form onSubmit={handleSubmit}>
        <Text
          label="Booth Name"
          value = {name}
          onChange={(e) => setName(e.target.value)}
          focus = 'true'
        />
        <Text
          label="No of Pumps"
          value = {noOfPump}
          type = "Number"
          onChange={(e) => setNoOfPump(e.target.value)} 
        />
        <div class="d-grid gap-2">
          <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
            CREATE BOOTH
          </button>
        </div>
      </form>
    </div>
  )
}
