import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useAuth } from '../../../context/auth';

import MaterialTable from '../../../Components/Table/MaterialTable'
import ModalBasic from '../../../Components/Modal/ModalBasic'

import {Add, Edit, QrCode, DeleteForever} from '@mui/icons-material/'

import QrCodeGenerator from '../../../Components/Scan-Qr/QrCodeGenerator'
import CreateVehicle from './CreateVehicle'
import UpdateVehicle from './UpdateVehicle';

export default function ListVehicles() {
  const [auth] = useAuth();

  const [listVehicles, setListVehicles] = useState('')
  const [listPoints, setListPoints] = useState('')
  //Model
  const [visibleAddVehicle, setVisibleAddVehicle] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  const [visibleDeleteModel, setVisibleDeleteModel] = useState(false)
  const [selectedDeleteModel, setSelectedDeleteModel] = useState(null)

  const [visibleQRModel, setVisibleQRModel] = useState(false)
  const [selectedQRModel, setSelectedQRModel] = useState(null)

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
      loadPointsByVehicle()
  }, [])
  
  useEffect(() => {
    listPoints && loadVehicles()
  }, [listPoints])

  const loadPointsByVehicle = async() => {
    try {
        const {data} = await Axios.post('/fillups-pointbyvehicles')
        if(data?.error){
            toast.error(data.error)
        } else {
          setListPoints(data)
        }
    } catch (err) {
      console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  const loadVehicles = async() => {
    try {
        const {data} = await Axios.post('/pc-vehicles')
        if(data?.error){
            toast.error(data.error)
        } else {
          setListVehicles(data?.map((i) => {
            const availablePoints = ((listPoints.find((p) => p._id?.vehicle[0]?._id === i?._id)?.points) - (listPoints.find((p) => p._id?.vehicle[0]?._id === i._id)?.redeem))
            return ({...i, availablePoints})
          }))
        }
    } catch (err) {
      console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  const handleDeleteVehicle = async(id) => {
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.delete(`/pc-vehicle/${id}`)
      if (data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        toast.success(`Vehicle ${selectedDeleteModel?.vehicleNo} Deleted Successfully`)
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error('Something went wrong, Try Again!')
    }
  }

  const columns = [
    {accessorKey: 'customer.phoneNumber', header: 'Phone No', size: 100},
    {accessorKey: 'customer.name', header: 'Customer Name', size: 100},
    {accessorKey: 'vehicleNo', header: 'Vehicle No', size: 100},
    {accessorKey: 'pointsCategory.type', header: 'Category', size: 100},
    {accessorKey: 'availablePoints', header: 'Av Points', size: 100},
  ]

  const rowAction = ({row: {original}}) => [
    <div className='d-flex justify-content-center gap-2'>
      <QrCode
        onClick={() => {
          setVisibleQRModel(true)
          setSelectedQRModel(original)
        }}
      />
      <Edit
        onClick={() => {
          setVisible(true)
          setSelected(original)
        }}
      />
      <DeleteForever
        onClick={() => {
          setVisibleDeleteModel(true)
          setSelectedDeleteModel(original)
        }}
      />
    </div>
  ]

  return (
    <div className='row justify-content-center'>
      <div className='col-xxl-8 col-xl-10 col-12 mb-3'>
        <div className='row justify-content-between mb-2'>
          <div className='col-xxl-3 col-xl-4 col-lg-5 col-sm-6 col-12 d-flex gap-2 mb-2'>
            <button className = 'btn btn-outline-primary' onClick={() => {setVisibleAddVehicle(true)}}><Add/> VEHICLE</button>
          </div>
        </div>
        <MaterialTable
          title = 'List Fillup Vehicles'
          col = {columns}
          row = {listVehicles}
          rowAction = {rowAction}
        />

        <ModalBasic
          open = {visibleAddVehicle}
          onClose = {()=> {
          setVisibleAddVehicle(false)
          }}
          content = {<CreateVehicle/>}
        />

        
        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {auth?.employee?.role <= 1 ? 
          <UpdateVehicle data={selected}/>
          : 
          <div className='d-flex justify-content-center w-100'>
            <p>You don't have Permission to Edit</p>
          </div>
        }
        />

        <ModalBasic
          open = {visibleQRModel}
          onClose = {()=> {
          setVisibleQRModel(false)
          }}
          content = {
            <div className='d-flex justify-content-center p-1'>
              <QrCodeGenerator qrText = {selectedQRModel?.vehicleNo}/>
            </div>
          }
        />

        <ModalBasic
          open = {visibleDeleteModel}
          onClose = {()=> {
          setVisibleDeleteModel(false)
          }}
          content = {
            auth?.employee?.role === 0 ?
              <div className='d-flex justify-content-between'>
                <p>Are You sure want to?</p>
                <button className='btn btn-outline-danger' disabled={submitDisable} onClick={() => {
                    handleDeleteVehicle(selectedDeleteModel._id)
                  }}>Delete
                </button>
              </div>
              :
              <div className='d-flex justify-content-center w-100'>
                <p>You don't have Permission to Delete</p>
              </div>
          }
        />

      </div>
    </div>
  )
}
