import React, {useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import {Text,} from '../../Components/Inputs/InputFields'

export default function UpdateTank(props) {
    const tankData = props.tank

    const [name, setName] = useState(tankData?.product?.name)
    const [capacity, setCapacity] = useState(tankData?.product?.capacity)

    const [productCode, setproductCode] = useState(tankData?.productCode)
    const [cost, setCost] = useState(tankData?.cost)
    const [price, setPrice] = useState(tankData?.price)

    const [submitDisable, setSubmitDisable] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await setSubmitDisable(true)
            const data1 = await Axios.put(`/product/${tankData?.product?._id}`, {...tankData.product, name, capacity});
            const data2 = await Axios.put(`/productcode/update`, {...tankData, productCode, cost, price});
            if(data1?.data?.error || data2?.data?.error) {
                toast.error(data1?.data?.error || data2?.data?.error);
                setSubmitDisable(false)
            } else {
                toast.success("Tank sucessfully Updated")
                window.location.reload()
            }
        } catch (err) {
        console.log(err);
        toast.error("Something went wrong, Try Again!")
        
        }
    }

    return (
        <div className='p-3'>
            <h4 className='text-center mb-3'>Tank {name}</h4>
            <form onSubmit={handleSubmit}>
                <Text
                    label="Tank Name"
                    value = {name}
                    onChange={(e) => setName(e.target.value)} 
                />

                <Text
                    label="Capacity in Gallon"
                    type="Number"
                    value = {capacity}
                    onChange={(e) => setCapacity(e.target.value)} 
                />

                <Text
                    label="Product Code"
                    value = {productCode}
                    onChange={(e) => setproductCode(e.target.value)} 
                />

                <Text
                    label="Cost"
                    value = {cost}
                    onChange={(e) => setCost(e.target.value)} 
                />

                <Text
                    label="Price"
                    value = {price}
                    onChange={(e) => setPrice(e.target.value)} 
                />

                <div class="d-grid gap-2">
                <button className = 'btn btn-primary' type = 'submit'  disabled={submitDisable}>
                    UPDATE TANK
                </button>
                </div>
            </form>
        </div>
  )
}
