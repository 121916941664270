import React, {useRef, useState, useEffect} from 'react'
import moment from 'moment'
import {useReactToPrint} from 'react-to-print'

import { useSettings } from '../../../../context/settings'
import { useDk } from '../../../../context/dk'

import {currency, unit} from '../../../../Functions/NoFomats'

import {Print} from '@mui/icons-material'
import { styled } from '@mui/material/styles';
import {Table, TableBody, TableCell, TableHead, TableRow, Paper} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';

import {Divider} from '@mui/material';

import CpetcoLogoImg from '../../../../Assets/images/ceypetco_logo.png'

export default function ReturnsReceiptV2(props) {
    const order = props.order

    const [settings] = useSettings();
    const [dk, setDk] = useDk();

    const [noQuantity, setNoQuantity] = useState('')
    const [subTotal, setSubTotal] = useState('')
    const [discount, setDiscount] = useState('')

    const [printedDate, setPrintedDate] = useState(new Date())
    const [creditNotificationAccounts, setCreditNotificationAccounts] = useState(order?.selectInvoiceVal?.payment?._id)

    useEffect(() => {
        setSubTotal(order?.items?.reduce((t,i) => t = t + (i?.productLine?.quantity * i?.productLine?.productStock?.productCode?.price), 0))
        setDiscount(order?.items?.reduce((t,i) => t = t + Number(i?.productLine?.unitDiscount * i?.productLine?.quantity), 0))
        setNoQuantity(order?.items?.reduce((t,i) => t = t + Number(i.productLine?.quantity), 0))
        order?.printedDate && setPrintedDate(order?.printedDate)
        order?.orderPayment && setCreditNotificationAccounts(order?.orderPayment)
    },[order])


    const paymentMethod = [{_id:'64952f578188d2c8b9c26603', name: 'Cash'}, {_id:'63e31a93f029ceab39dcd281', name: 'Bank / Card Pay'}, {_id:'63e31abdf029ceab39dcd283', name: 'Credit'}]

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
    }));

    const componentRef = useRef();
    const printData = useReactToPrint({
        content : () => componentRef.current,
        documentTitle: `Return Receipt ${order?.selectInvoiceVal?.invoiceNo}`,
        onBeforeGetContent: async() => {
            await setDk({...dk, darkmode : false})
        },
        onAfterPrint: () => {
            const data = localStorage.getItem("darkmode");
            if (data) {
                const parsed = JSON.parse(data);
                setDk({...dk, darkmode: parsed});
            }
        }
    })

  return (
    <div className='row justify-content-center'>
        <div>
            <button className='btn btn-warning' onClick={printData}><Print/></button>
        </div>
        
        <div className='row justify-content-center p-5' style={{width: 900}} ref= {componentRef}>
            <div className='d-flex justify-content-between'>
                <div className='d-flex gap-2 align-items-center'>
                    <img
                        height = '60px' 
                        src={settings?.logo ? settings?.logo?.file : CpetcoLogoImg}
                        alt='Logo'
                    />
                    <h4 style={{fontFamily: 'TirantiSolidLET'}}>{settings?.nameOnReceipt ? settings?.name : ''}</h4>    
                </div>
                <div>
                    <dd className='text-end lh-sm' style={{fontSize: '14px'}}>Address: {settings?.address}</dd>
                    <dd className='text-end lh-sm' style={{fontSize: '14px'}}>Telephone No: {settings?.phoneNumber}</dd>
                    <dd className='text-end lh-sm' style={{fontSize: '14px'}}>Email: {settings?.mail}</dd>
                </div>
            </div>
            
            <hr></hr>
            <dd className='text-end lh-sm' style={{fontSize: '14px'}}>Printed On: {moment(printedDate).format("YYYY-MM-DD hh:mm A")}</dd>

            <h4 className='text-center fw-bold' >RETURNS RECEIPT</h4>
            
            <div className='d-flex justify-content-center mb-2'>
                <div className='row justify-content-between'>
                    <dd style={{fontSize: '12px'}}>INVOICE NO: {order?.selectInvoiceVal?.invoiceNo}</dd>
                    <dd style={{fontSize: '12px'}}>CUSTOMER: {order?.selectedCustomer ? order?.selectedCustomer?.name : 'Retail'}</dd>
                    <dd style={{fontSize: '12px'}}>DATE: {order?.returnDate}</dd>
                    
                </div>

                <div className='row text-end'>
                    <dd style={{fontSize: '12px'}}>Payment: {paymentMethod?.find((i) => i._id === order?.payment) ? paymentMethod?.find((i) => i._id === order?.payment).name : 'Unknown'}</dd>
                    <dd style={{fontSize: '12px'}}>No.Items: {order?.items?.length}</dd>
                    <dd style={{fontSize: '12px'}}>No.Qty: {new Intl.NumberFormat().format(noQuantity)}</dd>
                </div>
            </div>

            <div className='d-flex justify-content-center'>
                <dd style={{fontSize: '12px'}}>ORDER DATE: {order?.selectInvoiceVal?.orderDate}</dd>
            </div>

            <div className='w-100'>
                <div style={{overflowX: 'scroll'}}>
                    {order?.items && 
                        <Table className='w-100' size='small' component={Paper}>
                            <TableHead>
                                <TableRow>
                                {['#', 'Product Code', 'Product', 'Unit Price', 'Qty', 'Discount', 'Price']?.map((i) => 
                                    <StyledTableCell style={{fontSize: '12px'}}>{i}</StyledTableCell>
                                )}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {order?.items?.map((i,index) =>
                                <TableRow key={index}>
                                    {[(index+1), i?.productLine?.productStock?.productCode?.productCode, i?.productLine?.productStock?.productCode?.product?.name, currency(i?.productLine?.productStock?.productCode?.price), unit(i?.quantity, i?.productLine?.productStock?.productCode?.product?.unit), currency(i?.productLine?.unitDiscount * i?.quantity), currency((i?.quantity * (i?.productLine?.productStock?.productCode?.price - i?.productLine?.unitDiscount)))]?.map((i) => 
                                    <StyledTableCell style={{fontSize: '12px'}}>{i}</StyledTableCell>
                                    )}
                                </TableRow> 
                                )}
                            </TableBody>
                        </Table>
                    }
                </div>

                <div className='row m-3'>
                    <div className='col-md-6 col-12 d-flex align-items-center justify-content-center'>
                        {creditNotificationAccounts === '63e31abdf029ceab39dcd283' && 
                            <p className='text-center fw-bold text-danger' style={{fontSize: '16px'}}>CREDIT Sales returns will be <br></br> Debited from Customer Accounts</p>
                        }
                    </div>

                    <div className='col-md-6 col-12'>
                        <div className='row mt-3'>
                            <div className='d-flex justify-content-between'>
                                <p className='lh-sm' style={{fontSize: '14px'}}>Sub Total:</p>
                                <p className='lh-sm' style={{fontSize: '14px'}}>{order?.items.length > 0 ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(subTotal)}` : '0.00'}</p> 
                            </div>

                            <div className='d-flex justify-content-between'>
                                <p className='lh-sm' style={{fontSize: '14px'}}>Total Discount:</p>
                                <p className='lh-sm' style={{fontSize: '14px'}}>- ( {discount ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(discount)}` : '0.00'} )</p> 
                            </div>

                            <div className='d-flex justify-content-between'>
                                <p className='lh-sm fw-bold' style={{fontSize: '16px'}}>Total Returns:</p>
                                <p className='lh-sm fw-bold' style={{fontSize: '16px'}}>{(order?.items.length > 0 && creditNotificationAccounts !== '63e31abdf029ceab39dcd283') ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(subTotal - discount)}` : '0.00'}</p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h6 className='text-center p-1'>Thanks, Come Again</h6>
            <Divider light>*** END ***</Divider>
        </div>
        
    </div>
  )
}
