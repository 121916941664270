import React, {useState, useEffect} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import {SelectAutoComplete} from '../../../../Components/Inputs/InputFields'

import {useGdate} from '../../../../context/date'

export default function UpdatePurchaseReurn(props) {
    const purchaseReturndata = props?.data

    const [gdate] = useGdate()

    const [listBanks, setListBanks] = useState('')

    const [date] = useState(gdate?.date)
    const [paymentStatus, setPaymentStatus] = useState(purchaseReturndata?.paymentStatus)
    const [payment, setPayment] = useState()
    const [bank, setBank] = useState()

    const [payMethod] = useState([{_id:'64952f578188d2c8b9c26603', name: 'Cash', type: 'credit'}, {_id:'63e31a93f029ceab39dcd281', name: 'Bank', type: 'credit'}, {_id:'64acdf13722d07654e219f89', name: 'Credit', type: 'debit'}])
    const [submitDisable, setSubmitDisable] = useState(false)

    useEffect(() => {
        loadBanks()
    }, [])

    useEffect(() => {
        setBank()
    }, [payment])

    const loadBanks = async() => {
        try {
            const {data} = await Axios.post(`/bank-accounts`)
            if(data?.error){
                toast.error(data?.error)
            } else {
                setListBanks(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await setSubmitDisable(true)
            if(paymentStatus === "received") {
                const data1 = await Axios.post(`/accdata`, {date, empStatus: {status: false}, detail:{description: `Return Purchase No: ${purchaseReturndata?.purchase?.invoiceNo}`}, amount: Number(-purchaseReturndata?.total)?.toFixed(2) , accounting: "63ecfbe55b36ecf83d9dd4a3"})
                const data2 = await Axios.post(`/accdata`, {date, empStatus: {status: false}, detail:{description: `Return Purchase No: ${purchaseReturndata?.purchase?.invoiceNo}`, supplier: purchaseReturndata?.purchase?.supplier?._id, bank}, amount: purchaseReturndata?.total >= 0 ? purchaseReturndata?.total : (payment?.type === purchaseReturndata?.total ? -purchaseReturndata?.total : purchaseReturndata?.total)?.toFixed(2) , accounting: payment})
                if(data1?.data?.error || data2?.data?.error){
                    toast.error(data1?.data?.error || data2?.data?.error)
                    setSubmitDisable(false)
                } else {
                    const {data} = await Axios.put(`/purchasereturn/${purchaseReturndata._id}`, {paymentStatus, payment});
                    if(data?.error) {
                        toast.error(data.error);
                        setSubmitDisable(false)
                    } else {
                        toast.success("Purchase Return Updated");
                        window.location.reload()
                    }
                }
            } else {
                const {data} = await Axios.put(`/purchasereturn/${purchaseReturndata._id}`, {paymentStatus, payment});
                if(data?.error) {
                    toast.error(data.error);
                    setSubmitDisable(false)
                } else {
                    toast.success("Purchase Return Updated");
                    window.location.reload()
                }
            }
        } catch (err) {
            console.log(err)
            toast.error("Update Failed Try Again!");
        }
    }
    
    return (
        <div className='p-3'>
            <h5 className='fw-bold text-center mb-3'>Update Payment {purchaseReturndata?.purchase?.invoiceNo}</h5>
            {purchaseReturndata?.paymentStatus === "received" ? 
                <h5 className='text-warning text-center'>Purchase Returns Payment Received Already</h5> 
                :
                <form onSubmit={handleSubmit}>
                    <SelectAutoComplete
                        label = "Payment Status"
                        optionsList={["received","panding","unknown"]}
                        option = {(i) => i}
                        value={paymentStatus}
                        onChange={(event, value) => {
                            setPaymentStatus(value)
                        }}
                    />


                    {paymentStatus === "received" && payMethod && 
                    <SelectAutoComplete
                        label = "Payment Method"
                        optionsList={payMethod}
                        option = {(i) => i.name}
                        value={payment}
                        onChange={(event, value) => {
                            setPayment(value._id)
                        }}
                    />
                    }

                    {payment === "63e31a93f029ceab39dcd281" &&
                        <SelectAutoComplete
                            label = "Bank"
                            optionsList={listBanks}
                            option = {(i) => i.bank + ' | ' + i.accountNo}
                            value={bank}
                            onChange={(event, value) => {
                                setBank(value)
                            }}
                        />
                    }
                
                    <div class="d-grid gap-2">
                    <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
                        UPDATE RETURNS
                    </button>
                    </div>
                </form>
            }
        </div>
    )
}
