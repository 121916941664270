export const accCategories = [{
  "_id": "63dca26a06b9cdaae9c5c8f9",
  "name": "ASSETS",
  "slug": "acccategory_assets",
  "type": true
},
{
  "_id": "63dca28d06b9cdaae9c5c8ff",
  "name": "LIABILITIES",
  "slug": "acccategory_liabilities",
  "type": false,
},
{
  "_id": "63dca26f06b9cdaae9c5c8fc",
  "name": "EXPENSE",
  "slug": "acccategory_expense",
  "type": true,
},
{
  "_id": "63dca26006b9cdaae9c5c8f6",
  "name": "INCOME",
  "slug": "acccategory_income",
  "type": false,
},
{
  "_id": "63dca29706b9cdaae9c5c902",
  "name": "EQUITY",
  "slug": "acccategory_equity",
  "type": false,
}]

export const accountings = [{
  "_id": "63e31a93f029ceab39dcd281",
  "name": "BANK",
  "slug": "accounting_bank",
  "category": "63dca26a06b9cdaae9c5c8f9",
  "deDisable": false,
  "description": "Deposited to Bank",
  "icon": "bank"
},
{
  "_id": "63e31abdf029ceab39dcd283",
  "name": "DEBTS",
  "slug": "accounting_debts",
  "category": "63dca26a06b9cdaae9c5c8f9",
  "deDisable": false,
  "description": "Credit has Given",
  "icon": "receipt"
},
{
  "_id": "63e31af4f029ceab39dcd28f",
  "name": "DRAWINGS",
  "slug": "accounting_drawings",
  "category": "63dca29706b9cdaae9c5c902",
  "deDisable": false,
  "description": "Personal Withdrawals",
  "icon": "equity"
},
{
  "_id": "63e31b29f029ceab39dcd291",
  "name": "EXPENSE",
  "slug": "accounting_expense",
  "category": "63dca26f06b9cdaae9c5c8fc",
  "deDisable": false,
  "description": "Business Expenses",
  "icon": "payments"
},
{
  "_id": "63eb82f1661aeb9f06368651",
  "name": "SALES",
  "slug": "accounting_sales",
  "category": "63dca26006b9cdaae9c5c8f6",
  "deDisable": true,
  "description": "All Types of Sales",
  "icon": "money"
},
{
  "_id": "63ecfbe55b36ecf83d9dd4a3",
  "name": "PURCHASE",
  "slug": "accounting_purchase",
  "category": "63dca26f06b9cdaae9c5c8fc",
  "deDisable": true,
  "description": "Inventory Purchases",
  "icon": "payments"
},
{
  "_id": "6493d4f0157bacc34353cf7b",
  "name": "CAPITAL",
  "slug": "accounting_capital",
  "category": "63dca29706b9cdaae9c5c902",
  "deDisable": false,
  "description": "Owner Equity",
  "icon": "equity"
},
{
  "_id": "64952f578188d2c8b9c26603",
  "name": "CASH",
  "slug": "accounting_cash",
  "category": "63dca26a06b9cdaae9c5c8f9",
  "deDisable": false,
  "description": "Cash in Hand",
  "icon": "money"
},
{
  "_id": "64acdf13722d07654e219f89",
  "name": "CREDITORS",
  "slug": "accounting_creditors",
  "category": "63dca28d06b9cdaae9c5c8ff",
  "deDisable": false,
  "description": "Credits Taken",
  "icon": "receipt"
},
{
  "_id": "64cb525518dca625f12ce5bf",
  "name": "SALARY",
  "slug": "accounting_salary",
  "category": "63dca26f06b9cdaae9c5c8fc",
  "deDisable": false,
  "description": "Salary Paid to Employee",
  "icon": "payments"
},
{
  "_id": "64d11b5d9df585552c62822c",
  "name": "LIABILITY",
  "slug": "accounting_liability",
  "category": "63dca28d06b9cdaae9c5c8ff",
  "deDisable": false,
  "description": "General Liabilities",
  "icon": "receipt"
},
{
  "_id": "64eec83c972323a31bc16e3d",
  "name": "LOSS INVENTORY",
  "slug": "accounting_loss-inventory",
  "category": "63dca26f06b9cdaae9c5c8fc",
  "deDisable": true,
  "description": "Adjustment in Products",
  "icon": "payments"
},
{
  "_id": "64fda2c5c969962bfbcee3eb",
  "name": "CHEQUE",
  "slug": "accounting_cheque",
  "category": "63dca26a06b9cdaae9c5c8f9",
  "deDisable": false,
  "description": "Receivable Cheques",
  "icon": "payments"
},
{
  "_id": "6506bfcdd3328c7a7f56db8d",
  "name": "UNKNOWN INCOME",
  "slug": "accounting_unknown-income",
  "category": "63dca26006b9cdaae9c5c8f6",
  "deDisable": false,
  "description": "Unknown Small Income",
  "icon": "money"
},
{
  "_id": "65111d83758254848db74158",
  "name": "LFS GOODS & ETC..",
  "slug": "accounting_lfs-goods-and-etc..",
  "category": "63dca26a06b9cdaae9c5c8f9",
  "deDisable": false,
  "description": "ALL TYPE OF ASSETS",
  "icon": "bank"
},
{
  "_id": "6515831c1a89e4fc7010012a",
  "name": "SALARY ARREARS",
  "slug": "accounting_salary-arrears",
  "category": "63dca28d06b9cdaae9c5c8ff",
  "deDisable": false,
  "description": "Pending Salaries to be Paid",
  "icon": "payments"
}]

export const categories = [{
  "_id": "63e65efc2f840202604fbb7f",
  "name": "Fuel",
  "slug": "category_fuel"
},
{
  "_id": "63e65f002f840202604fbb84",
  "name": "Oil",
  "slug": "category_oil"
},
{
  "_id": "646e22982c8b1d097c795672",
  "name": "Unknown",
  "slug": "category_unknown",
}]

export const employee = {
  "_id": "63e5165c8bf3134a52efa619",
  "name": "ADMIN",
  "email": "admin",
  "password": "admin@123",
  "nic": "200000000000",
  "role": 0,
  "active" : true,
  "payment": {
    "type": "month",
    "amount": "1000"
  }
}

export const fuelTypesCeypetco = [{
  "_id": "64e099788c4dba7b892527cd",
  "fuelType": "Petrol 92",
  "loadLiter" : 6567
},
{
  "_id": "64e0997e8c4dba7b892527d6",
  "fuelType": "Normal Diesel",
  "loadLiter" : 6600
},
{
  "_id": "655e308e5e4faf0bb7d0423a",
  "fuelType": "Petrol 95",
  "loadLiter" : 6567
},
{
  "_id": "655e30995e4faf0bb7d04247",
  "fuelType": "Super Diesel",
  "loadLiter" : 6600
},
{
  "_id": "655e30a35e4faf0bb7d04254",
  "fuelType": "Kerosine",
  "loadLiter" : 6600
}]

export const fuelTypesIOC = [{
  "_id": "64e0997e8c4dba7b892527d6",
  "fuelType": "Diesel",
  "loadLiter": 6600,
},
{
  "_id": "64e099788c4dba7b892527cd",
  "fuelType": "Petrol",
  "loadLiter": 6567,
},
{
  "_id": "655e308e5e4faf0bb7d0423a",
  "fuelType": "XMail",
  "loadLiter": 6600,
},
{
  "_id": "655e30995e4faf0bb7d04247",
  "fuelType": "Euro 3",
  "loadLiter": 6600,
}]

export const warehouses = [{
  "_id": "63f48039710db4fab3a62abb",
  "name": "MAIN",
  "slug": "warehouse_Main",
  "location": "Shed Office"
},
{
  "_id": "63f4ac7a574200ac0c7f951e",
  "name": "SECONDARY",
  "slug": "warehouse_secondary",
  "location": "Shed Sub Office"
}]