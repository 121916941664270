import React, {useState, useEffect} from 'react'
import moment from 'moment'

import { useSettings } from '../context/settings';
import { useGdate } from '../context/date';

import { Image } from 'antd';
import GasLogo from '../Assets/images/gas-logo.png'
import CpetcoLogoImg from '../Assets/images/ceypetco_logo.png'
import IOCLogo from '../Assets/images/ioc-logo.png'

import '../index.css'

export default function Home() {    
  const [settings] = useSettings()
  const [gdate] = useGdate()

  const [date, setDate] = useState(new Date())

  useEffect(() => {
    setInterval(() => setDate(new Date()), 1000)
  },[])

  const SearchLogo = () => {
    switch (settings?.gasStationType) {
      case 'ceypetco':
        return (CpetcoLogoImg)
        break;
      case 'ioc':
        return (IOCLogo)
        break;
      default:
        return (GasLogo)
        break;
    }
  }
  
  return (
    <div className='d-flex flex-column' style={{minHeight: (window.screen.height * 0.6)}}>
      <div className='row justify-content-center align-items-center flex-grow-1'>
        <div className='col-xxl-3 col-md-4 col-12 row justify-content-center p-1'>
          <Image
            width = '250px' 
            src={settings?.logo ? settings?.logo?.file : SearchLogo()}
            alt='Logo'
          />
        </div>
        <div className='col-xxl-7 col-lg-8 col-md-8 col-12'>
          <h1 className='text-center text-capitalize' style={{fontFamily: "Sofia"}}>Welcome to System</h1>
          <h1 className='text-center'>{settings?.name}</h1>
        </div>
      </div>

      {/* CLIENT VERSION */}
      <div className='row align-items-end flex-shrink-1'>
        <div className='col-lg-4 col-12'>
          <h3 className='text-center' style={{fontFamily: "Trirong"}}>CURRENT TIME & DATE</h3>
          <h1 className='text-center' style={{fontFamily: "Trirong", fontSize: 40}}>{moment(date).format("hh : mm : ss A")}</h1>
          <h1 className='text-center' style={{fontFamily: "Trirong", fontSize: 36}}>{moment(date).format("MMMM Do YYYY")}</h1>
        </div>

        <div className='row justify-content-between col-lg-8 col-12'>
          <div className='col-lg-5 col-12'>
            <h4 className='text-center' style={{fontFamily: "Trirong"}}>System Date</h4>
            <h2 className='text-center' style={{fontFamily: "Trirong"}}>{gdate?.date}</h2>
          </div>

          <div className='col-lg-7 col-12'>
            <h4 className='text-center' style={{fontFamily: "Trirong"}}>Reports Shows</h4>
            <h2 className='text-center' style={{fontFamily: "Trirong"}}>{gdate?.reportStartDate} <b>To</b> {gdate?.reportEndDate}</h2>
          </div>
          
        </div>
      </div>

      {/* DEMO VERSION */}
      {/* <div className='row align-items-end flex-shrink-1'>
        <div className='col-md-4 col-12'>
          <dd className='text-center fs-5 fw-bold'>ADMIN ACCESS</dd>
          <dd className='text-center'>MAIL : admin</dd>
          <dd className='text-center'>PASSWORD : admin@123</dd>
        </div>

        <div className='col-md-4 col-12'>
          <dd className='text-center fs-5 fw-bold'>MANAGER ACCESS</dd>
          <dd className='text-center'>MAIL : user</dd>
          <dd className='text-center'>PASSWORD : user@123</dd>
        </div>

        <div className='col-md-4 col-12'>
          <dd className='text-center fs-5 fw-bold'>EMPLOYEE ACCESS</dd>
          <dd className='text-center'>MAIL : employee</dd>
          <dd className='text-center'>PASSWORD : employee@123</dd>
        </div>
      </div> */}
    </div>
  )
}

