import React, {useEffect,useState, useRef} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Axios from 'axios'
import { toast } from 'react-hot-toast'
import {useReactToPrint} from 'react-to-print'

import {useGdate} from '../../../context/date'
import { useDk } from '../../../context/dk'

import {styled, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell'
import { Print } from '@mui/icons-material'

export default function CustomerOrders() {
    const {id} = useParams()
    const navigate = useNavigate()

    const [gdate] = useGdate()
    const [dk, setDk] = useDk()

    const [customer, setCustomer] = useState('')
    const [listAccdata, setListAccdata] = useState('')
    const [listOrders, setListOrders] = useState('')
    const [listProducts, setListProducts] = useState('')
    const [listItems, setListItems] = useState('')

    const [fuelSalesTableLayout, setFuelSalesTableLayout] = useState('col-xxl-6 col-12')

    useEffect(() => {
        loadCustomer()
        loadAccData()
        loadOders()
        loadProducts()
    },[])

    useEffect(() => {
        listOrders && loadItems()
    },[listOrders])

    useEffect(() => {
        listItems && console.log(listItems?.map((i) => i?.productStock?.productCode?.product)?.filter((item, index, self) => self?.indexOf(item?._id) === index))
    },[listItems])

    const loadCustomer = async() => {
        try {
            const {data} = await Axios.post(`/customer/${id}`)
            if(data?.error) {
                toast.error(data.error)
            } else {
                setCustomer(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }
    
    const loadAccData = async() => {
        try {
            const {data} = await Axios.post(`/accdatas`, {date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate} ,"detail.customer": id, "detail.fuel": {$exists: true}})
            if(data?.error) {
                toast.error(data.error)
            } else {
                setListAccdata(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const loadOders = async() => {
        try {
            const {data} = await Axios.post(`/orders`, {customer: id, orderDate: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate} })
            if(data?.error) {
                toast.error(data.error)
            } else {
                setListOrders(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const loadItems = async() => {
        try {
          const {data} = await Axios.post(`/productlines`, {order: {$in : listOrders?.map((i) => i?._id)}})
          if (data?.error) {
            toast.error(data.error)
          } else {
            setListItems(data)
          }
        } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
        }
    }

    const loadProducts = async() => {
        try {
          const {data} = await Axios.post(`/productline/group-byproduct`, {orderDate: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate},   $expr : { $eq: [ '$customer' , { $toObjectId: id } ] }})
          if (data?.error) {
            toast.error(data.error)
          } else {
            setListProducts(data)
          }
        } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
        }
    } 

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
    }));

    const componentRef = useRef();
    const printData = useReactToPrint({
        content : () => componentRef.current,
        documentTitle: `Customer Orders - ${gdate?.reportStartDate} - ${gdate?.reportEndDate}`,
        onBeforeGetContent: async() => {
            await setFuelSalesTableLayout('col-6')
            await setDk({...dk, darkmode : false})
        },
        onAfterPrint: async() => {
            const data = localStorage.getItem("darkmode");
            if (data) {
                const parsed = JSON.parse(data);
                await setDk({...dk, darkmode: parsed});
            }
            await setFuelSalesTableLayout('col-xxl-6 col-12')

        },
    })

    return (
        <div className='p-3'>
            <button className = 'btn btn-warning' onClick={() => {printData()}}><Print/></button>

            <div className='pt-3' ref={componentRef}>
                <h4 className='text-center'>Order Details</h4>
                <dd className='text-center'>{gdate?.reportStartDate} - {gdate?.reportEndDate}</dd>

                <div className='row justify-content-center'>
                    <div className='col-md-4 col-sm-6 col-12 d-flex justify-content-center'>
                        <div>
                            <dd>Customer: {customer?.name}</dd>
                            <dd>Type: {customer?.type?.toUpperCase()}</dd>
                        </div>
                    </div>

                    <div className='col-md-4 col-sm-6 col-12 d-flex justify-content-center'>
                        <div>
                            <dd>Phone No: {customer?.phoneNumber}</dd>
                            <dd>Address:{customer?.address}</dd>
                        </div>
                    </div>
                </div>

                <h5 className='mt-2 text-center'>Fuel fillup details</h5>
                {/* FUEL FILLUP DETAILS */}
                <div className='row'>
                    {/* 1ST HALF INDEX */}
                    <div className={fuelSalesTableLayout} style={{overflowX: 'auto'}}>
                        <Table className='border' size='small'>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell className='text-center border-end' colSpan={3}>Order Details</StyledTableCell>
                                    <StyledTableCell className='text-center' colSpan={3}>Fuel Details</StyledTableCell>

                                </TableRow>

                                <TableRow>
                                    <StyledTableCell >Date</StyledTableCell>
                                    <StyledTableCell >Comment</StyledTableCell>
                                    <StyledTableCell className='border-end'>Order No</StyledTableCell>
                                    <StyledTableCell >Type</StyledTableCell>
                                    <StyledTableCell >Liters</StyledTableCell>
                                    <StyledTableCell >Price</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {listAccdata && listAccdata?.filter((i,index) => index < Number(listAccdata?.length / 2) )?.map((i) =>
                                    <TableRow>
                                        <StyledTableCell>{i?.date}</StyledTableCell>
                                        <StyledTableCell>{i?.detail?.comment}</StyledTableCell>
                                        <StyledTableCell className='border-end'>{i?.detail?.govOrder?.govOrder}</StyledTableCell>
                                        <StyledTableCell>{i?.detail?.fuel?.fuelType?.fuelType}</StyledTableCell>
                                        <StyledTableCell>{i?.detail?.fuel?.liter} L</StyledTableCell>
                                        <StyledTableCell>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.amount)}</StyledTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                    
                    {/* 2ND HALF INDEX */}
                    <div className={fuelSalesTableLayout} style={{overflowX: 'auto'}}>
                        <Table className='border' size='small'>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell className='text-center border-end' colSpan={3}>Order Details</StyledTableCell>
                                    <StyledTableCell className='text-center' colSpan={3}>Fuel Details</StyledTableCell>

                                </TableRow>

                                <TableRow>
                                    <StyledTableCell>Date</StyledTableCell>
                                    <StyledTableCell>Comment</StyledTableCell>
                                    <StyledTableCell className='border-end'>Order No</StyledTableCell>
                                    <StyledTableCell>Type</StyledTableCell>
                                    <StyledTableCell>Liters</StyledTableCell>
                                    <StyledTableCell>Price</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {listAccdata && listAccdata?.filter((i,index) => index >= Number(listAccdata?.length / 2))?.map((i) =>
                                    <TableRow>
                                        <StyledTableCell>{i?.date}</StyledTableCell>
                                        <StyledTableCell>{i?.detail?.comment}</StyledTableCell>
                                        <StyledTableCell className='border-end'>{i?.detail?.govOrder?.govOrder}</StyledTableCell>
                                        <StyledTableCell>{i?.detail?.fuel?.fuelType?.fuelType}</StyledTableCell>
                                        <StyledTableCell>{i?.detail?.fuel?.liter} L</StyledTableCell>
                                        <StyledTableCell>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.amount)}</StyledTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </div>

                {/* INVENTORY DETAILS */}
                <h5 className='mt-5 text-center'>Inventory Order Details</h5>
                <div className='col-12' style={{overflowX: 'auto'}}>
                    <Table className='border border-2' size='small'>
                        <TableHead>
                            <StyledTableCell >Date</StyledTableCell>
                            <StyledTableCell >INV No</StyledTableCell>
                            <StyledTableCell >Comment</StyledTableCell>
                            <StyledTableCell >Order No</StyledTableCell>
                            <StyledTableCell >Amount</StyledTableCell>
                            {listProducts && listProducts?.map((i) =>
                                <StyledTableCell className='p-0 pt-2 pb-2 border' style={{fontSize: 12, writingMode: 'vertical-lr', transform: 'rotate(-180deg)'}}>{i?._id?.product?.slice(0,15)}</StyledTableCell>
                            )}
                        </TableHead>

                        <TableBody>
                            {listOrders && listOrders?.map((i) =>
                                <TableRow>
                                    <StyledTableCell>{i?.orderDate}</StyledTableCell>
                                    <StyledTableCell onClick={() => {navigate(`/`)}}>{i?.invoiceNo}</StyledTableCell>
                                    <StyledTableCell>{i?.comment}</StyledTableCell>
                                    <StyledTableCell>{i?.govOrder?.govOrder}</StyledTableCell>
                                    <StyledTableCell>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.total)}</StyledTableCell>
                                    {listProducts && listProducts?.map((f) => 
                                        <StyledTableCell className='text-center border'>{listItems && listItems?.find((item) => (item?.productStock?.productCode?.product?._id === f?._id?.productId) && (item?.order?._id === i?._id)) ? listItems?.find((item) => (item?.productStock?.productCode?.product?._id === f?._id?.productId) && (item?.order?._id === i?._id))?.quantity : '-'}</StyledTableCell>
                                    )}
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
        </div>
    )
}
