import React, { useState, createContext, useContext, useEffect } from "react";

const DkContext = createContext();

const DkProvider = (props) => {
    const [dk, setDk] = useState({
        darkmode: window.matchMedia("(prefers-color-scheme: dark)").matches
    });

    useEffect(() => {
        const data = localStorage.getItem("darkmode");
        if (data) {
            const parsed = JSON.parse(data);
            setDk({...dk, darkmode: parsed});
        }
    }, []);

    return(
        <DkContext.Provider value={[dk, setDk]}>
            {props.children}
        </DkContext.Provider>
    );
};

const useDk = () => useContext(DkContext);

export {DkProvider, useDk};