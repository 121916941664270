import React from 'react'

import { CheckCircle, Error } from '@mui/icons-material'

export default function EntryCheckCard(props) {
  return (
    <div className='col-md-3 col-6 p-1'>
        <div className='shadow p-2'>
            <dd className='text-center'>{props.name}</dd>
            <div className='d-flex justify-content-center'>
                {props.entry ? <CheckCircle color='success'/> : <Error color='warning'/>}
            </div>
        </div>
    </div>
  )
}
