import React from 'react';
import {MaterialReactTable} from 'material-react-table';
import { Button} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { jsPDF } from 'jspdf'; //or use your library of choice here
import autoTable from 'jspdf-autotable';
import { mkConfig, generateCsv, download } from 'export-to-csv'; 

export default function MaterialTable(props) {
  const columns = props.col
  const data = props.row
  const fileName = props.title || 'Gas Station Export'
  var pdffontSize = 10
  const exportDisable = props.exportDisable ? props.exportDisable : false
  const columnPinningLeft = props.columnPinningLeft ? props?.columnPinningLeft : []

  //CSV EXPORT CONFIGURATION
  const csvConfig = mkConfig({
    filename: fileName,
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });

  const setPdfFontSize = () => {
    const x = columns?.length
    switch (true) {
      case x < 5:
        return 10
      case x < 7:
        return 8
      case x < 10:
        return 7
      case x > 9:
        return 8
      default:
        return 8
    }
  }

  const formatRowsExport = (selectedRow, column) => {
    // If there 2 same accesorKey last split name. It will export only last object, So other than last object it should be use exportHead object in column
    var rows = []
    selectedRow.map((d) => {
        var rowData = {}
        column?.map((f) => {
            var srch = d;
            const str = f?.accessorKey.split(".")
            var key = str[0]
            if(str.length > 1) {
                for (var i=0; i < str.length; i++) {
                  key = str[i]
                  if (srch === null || typeof srch === 'undefined') {
                    srch = ''
                    break
                  } else if (typeof srch === 'object') {
                      srch = srch[str[i]]
                  } else {
                      srch = ''
                      break
                  }
                }
            } else {
                srch = (d[str[0]] ? d[str[0]] : '')
            }
            if (f.export) {
                srch = (f.export (srch))
            }
            key = f?.exportHead ? f?.exportHead : key
            rowData[key] = srch
        })
        rows.push(rowData)
    })
    return (rows)
  }

  const handleExportRowsPDF = (rows) => {
    pdffontSize = setPdfFontSize()
    var doc = new jsPDF({
      orientation: (columns?.length > 5 ? 'l' : 'p'),
      format: (columns?.length > 9 ? 'a3' : 'a4'),
    })

    const tableHeaders = columns?.map(i => (
        {
          ...i, 
          dataKey: (i?.exportHead ? i?.exportHead : i?.accessorKey.split('.')?.pop())
        }
      ))
    const selectedRows = rows?.map((i) => i?.original)
    const tableData = formatRowsExport(selectedRows, columns)

    doc.text(100, 25, props?.title)
    autoTable(doc, {
      startY: 30,
      theme: 'grid',
      body: tableData,
      columns: tableHeaders,
      styles: {fontSize: pdffontSize},
    });

    doc.save(fileName);
  };

  const handleExportRowsCSV = (rows) => {
    const dataRows = rows ? rows.map((row) => row.original): data
    const csv = generateCsv(csvConfig)(formatRowsExport(dataRows,columns));
    download(csvConfig)(csv);
  };

  const borderVal = '1px solid rgba(224,224,224,0.25)'

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      muiTableContainerProps = {{ sx: { maxHeight: window.screen.height * 0.6, overflow: 'auto'} }}
      muiTableHeadCellProps= {{ sx: { border: borderVal} }}
      muiTableBodyCellProps= {{ sx: { borderRight: borderVal, borderLeft: borderVal} }}
      enableStickyHeader = {true}
      enableTopToolbar = {props?.topToolbar}
      enablePagination = {false}
      enableDensityToggle = {false}
      enableColumnDragging = {props.columnDrag ? true : false}
      enableColumnPinning = {true}
      enableGrouping = {props?.groupingColumns && true}
      groupedColumnMode = {props?.groupedColumnMode ? props?.groupedColumnMode : ''}
      enableRowSelection = {props.getSelectedRows && true}
      enableRowActions = {props.rowAction && true}
      positionActionsColumn='last'
      renderRowActions= {props.rowAction}
      initialState={{
        isLoading : true,
        expanded: true,
        density: 'compact',
        columnPinning: {
          left: ['mrt-row-expand', 'mrt-row-select', ...columnPinningLeft],
          right: ['mrt-row-actions'],
        },
        columnVisibility: props.columnVisibility,
        grouping: props.groupingColumns
      }}
      state={{
        isLoading : !data ? true : false
      }}
      //Topbar with Print and Title
      renderTopToolbarCustomActions={({ table }) => {
        props.getSelectedRows && props?.getSelectedRows(table.getSelectedRowModel().rows)
        return (
          <div style={{width: '100%'}} className='d-flex'>
            {!exportDisable && <div className='d-flex gap-1'>
              {/* EXPORT PDF */}
              <Button
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  table.getSelectedRowModel().rows.length > 0 ? handleExportRowsPDF(table.getSelectedRowModel().rows) : handleExportRowsPDF(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
              >
                {table.getSelectedRowModel().rows.length > 0 ? 'PDF Selected Rows' : 'PDF'}
              </Button>
              
              {/* EXPORT CSV */}
              <Button
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  table.getSelectedRowModel().rows.length > 0 ? handleExportRowsCSV(table.getSelectedRowModel().rows) : handleExportRowsCSV(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
              >
                {table.getSelectedRowModel().rows.length > 0 ? 'CSV Selected Rows' : 'CSV'}
              </Button>
            </div>}
            <div className='d-flex flex-fill justify-content-center p-2'>
                {props.title}
            </div>
          </div>
        )
      }}
      //Column Grouping Features
      displayColumnDefOptions = {props?.groupingColumns && {
        'mrt-row-expand': {
          GroupedCell: ({ row, table }) => {
            const { grouping } = table.getState();
            return row.getValue(grouping[grouping.length - 1]);
          },
        },
      }}
      //Detail Panel
      renderDetailPanel={props.detailPanel}
    />
  )
};
  
