import React, {useState,useEffect} from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useAuth } from '../../context/auth'

import {currency} from '../../Functions/NoFomats'

import {SwitchInput} from '../../Components/Inputs/InputFields'
import MaterialTable from '../../Components/Table/MaterialTable'
import ModalBasic from '../../Components/Modal/ModalBasic'
import { Add, Edit, Receipt, DeleteForever, RequestQuote, Check, Clear} from '@mui/icons-material/'

import CreateCustomer from './CreateCustomer'
import UpdateCustomer from './UpdateCustomer'

import CustomerCredit from '../Message/CustomerCredit'
import AccountsFrom from '../Accounts/DoubleEntires/AccountsForm'

export default function ListCustomers() {

  const [auth] = useAuth()

  const navigate = useNavigate()

  const [listCustomers, setListCustomers] = useState('')
  const [listBalanceDetails, setListBalanceDetails] = useState('')
  const [listGivenChequeCustomer, setListGivenChequeCustomer] = useState()

  const [selectedRows, setSelectedRows] = useState('')

  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  const [visibleCredit, setVisibleCredit] = useState(false)
  const [visibleDebit, setVisibleDebit] = useState(false)

  const [checked, setChecked] = useState(true)

  useEffect(() => {
      loadBalanceDetails()
      loadGivenChequesByCustomer()
  }, [])

  useEffect(() => {
    listBalanceDetails && loadCustomers()
  },[listBalanceDetails, listGivenChequeCustomer])

  const loadBalanceDetails = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/customertotal`,  {  $expr : { $eq: [ '$accounting' , { $toObjectId: '63e31abdf029ceab39dcd283' } ] }, "detail.customer" : { $exists: true} } )
      if (data?.error) {
          toast.error(data.error)
      } else {
        setListBalanceDetails(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadGivenChequesByCustomer = async() => {
    try {
      const {data} = await Axios.post(`/cheques/given-customer`, {status: 'debit', chqDeposit: false})
      if (data?.error) {
          toast.error(data.error)
      } else {
        setListGivenChequeCustomer(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadCustomers = async() => {
    try {
        const {data} = await Axios.post('/customers')
        if(data?.error){
            toast.error(data.error)
        } else {
            setListCustomers(data?.map((i) => 
              ({...i, 
                balance: currency(listBalanceDetails?.find((c) => c?._id?._id === i._id) ? listBalanceDetails?.find((c) => c?._id?._id === i._id)?.total : 0),
                totalBusiness: currency(listBalanceDetails?.find((c) => c?._id?._id === i._id) ? listBalanceDetails?.find((c) => c?._id?._id === i._id)?.sumCreditAmount : 0),
                chequeTotal: (listGivenChequeCustomer?.find((g) => i?._id === g?._id?._id)?.total ? listGivenChequeCustomer?.find((g) => i?._id === g?._id?._id)?.total : 0)
              })
            ))
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  // const handleDeleteCustomer = async(id) => {
  //   try {
  //     await setSubmitDisable(true)
  //     const {data} = await Axios.delete(`/customer/${id}`)
  //     if (data?.error) {
  //       toast.error(data.error)
  //       setSubmitDisable(false)
  //     } else {
  //       toast.success('Customer Successfully Deleted')
  //       window.location.reload()
  //     }
  //   } catch (err) {
  //     console.log(err)
  //     toast.error("Something went wrong, Try Again!")
  //   }
  // }

  const getSelectedRows = (data) => {
    data.length !== selectedRows.length && setSelectedRows(data?.map(d => d?.original))
  }

  const columns = [
    { accessorKey: 'name', header: 'Name', size: 100},
    { accessorKey: 'nic', header: 'NIC No', size: 100},
    { accessorKey: 'address', header: 'Address'},
    { accessorKey: 'phoneNumber', header: 'Phone No', size: 100},
    { accessorKey: 'type', header: 'Type', size: 100},
    { accessorKey: 'active', header: 'Active', size: 100, Cell: ({row: {original}}) => original.active ? <Check color='success' /> : <Clear color='error'/>},
    { accessorKey: 'balance', header: 'Receivables', size: 100},
    { accessorKey: 'totalBusiness', header: 'Credit Business', size: 100},
    { accessorKey: 'chequeTotal', header: 'Cheque Total', size: 100, 
      Cell: ({row: {original}}) => currency(original.chequeTotal),
      export: (i) => currency(i)
    }
  ]

  const rowAction = ({row: {original}}) => [
    <div className='d-flex justify-content-center gap-2'>
      <Receipt
        onClick={(e) => {
          navigate({
            pathname: `/dashboard/user/customer-accounts/${original._id}`,
            search: `?${createSearchParams({
              name: original.name,
              type: original.type,
              chequeTotal: original.chequeTotal
            })}`
          })
        }}
      />
      <RequestQuote
        onClick={(e) => {
          navigate(`/dashboard/user/customer-orders/${original._id}`)
        }}
      />
      <Edit
        onClick={() => {
          setVisible(true)
          setSelected(original)
        }}
      />
      {/* <DeleteForever
        onClick={() => {
          setVisible1(true)
          setSelected1(original)
        }}
      /> */}
  </div>
  ]

  return (
    <div className='row justify-content-center'>
      <div className='col-12 mb-2'>
        <div className='hstack gap-2 mb-2'>
          <button className = 'btn btn-outline-primary' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
          <CustomerCredit data = {selectedRows}/>
          <button className = 'btn btn-outline-success ms-auto' onClick={() => {setVisibleDebit(true)}}><Add/> Debit</button> 
          <button className = 'btn btn-outline-danger' onClick={() => {setVisibleCredit(true)}}><Add/> Credit</button>
        </div>
      </div>

      <div className='d-flex justify-content-end'>
        <SwitchInput
          endLabel = "Active"
          checked = {checked}
          color = 'primary'
          onChange = {(e) => {
            setChecked(preVal => !preVal)
          }}
        />
      </div>

      <MaterialTable
        title = 'List of Customer Accounts'
        col = {columns}
        columnVisibility = {{nic: false, address: false}}
        row = {listCustomers && checked ? listCustomers?.filter((i) => i?.active == true) : listCustomers}
        rowAction = {rowAction}
        getSelectedRows = {getSelectedRows}
      />

      <ModalBasic
        open = {visibleAdd}
        onClose = {()=> {
        setVisibleAdd(false)
        }}
        content = {<CreateCustomer/>}
      />

      <ModalBasic
        open = {visible}
        onClose = {()=> {
        setVisible(false)
        }}
        content = {<UpdateCustomer customer = {selected}/>}
      />

      {/* <ModalBasic
        open = {visible1}
        onClose = {()=> {
        setVisible1(false)
        }}
        content = {
          auth?.employee?.role === 0 ?
            <div className='d-flex justify-content-between gap-2'>
              <p>Are You sure want to? On deletation selected Customer data will be erased</p>
              <div>
                <button className='btn btn-outline-danger' disabled={submitDisable} onClick={() => {
                    handleDeleteCustomer(selected1._id)
                  }}>Delete
                </button>
              </div>
            </div>
            :
            <div>
              <p className='text-center'>You don't have Permission to Delete</p>
              <p className='text-center fs-4'>Login as Admin</p>
            </div>
            
        }
      /> */}

      <ModalBasic
        width = '80%'
        open = {visibleCredit}
        onClose = {()=> {
        setVisibleCredit(false)
        }}
        content = {<AccountsFrom data= {{inputAccounting2: '63e31abdf029ceab39dcd283'}}/>}
      />

      <ModalBasic
        width = '80%'
        open = {visibleDebit}
        onClose = {()=> {
        setVisibleDebit(false)
        }}
        content = {<AccountsFrom data= {{inputAccounting: '63e31abdf029ceab39dcd283'}}/>}
      />
    </div>
  )
}
