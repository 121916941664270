import React, {useState, useEffect, useRef} from 'react'
import Axios from 'axios'
import { toast } from 'react-hot-toast'
import moment from 'moment'

import {useGdate} from '../../../context/date'

import {GeneralNo, currency} from '../../../Functions/NoFomats'
//Due to some Error AutoComplete Components used on this module
import {TextField, Autocomplete, Button, Paper} from '@mui/material';
import {Clear} from '@mui/icons-material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCartPlus, faBarcode} from '@fortawesome/free-solid-svg-icons'

import { Text, SelectAutoComplete } from '../../../Components/Inputs/InputFields'
import MaterialTable from '../../../Components/Table/MaterialTable'

import Scanner from '../../../Components/Scan-Qr/Scanner'
import FileUpload from '../../../Components/FIleUpload/FileUpload'

export default function PurchaseForm() {
  const [gdate] = useGdate()

  // LOAD DATA
  const [listWareHouses, setListWareHouses] = useState('')
  const [listWarehouseStocks, setListWarehouseStocks] = useState('')
  const [listSuppliers, setListSuppliers] = useState('')
  const [listBanks, setListBanks] = useState('')

  const [invoiceNo, setInvoiceNo] = useState('')
  const [wareHouse, setWareHouse] = useState('')

  const [inputValue, setInputValue] = useState('') 
  const [selectProductStock, setSelectProductStock] = useState('')

  const [selectedSupplier, setSelectedSupplier] = useState()

  const [bank, setBank] = useState()

  const [quantity, setQuantity] = useState()
  const [itemPrice, setItemPrice] = useState('')

  //Mark Date should be morethan cashflow verified date
  const [checkCashflow, setCheckCashflow] = useState(false)

  const [purchaseDate] = useState(gdate?.date)
  const [items, setItems] = useState([])
  const [subTotal, setSubTotal] = useState()
  const [total, setTotal] = useState()
  const [invoice, setInvoice] = useState()

  const [payMethod] = useState([{_id:'64952f578188d2c8b9c26603', name: 'Cash'}, {_id:'63e31a93f029ceab39dcd281', name: 'Bank'}, {_id:'64acdf13722d07654e219f89', name: 'Credit'}])
  const [pay, setPay] = useState('')

  const [scannerVisible, setScannerVisible] = useState(false)
  const productFocusRef = useRef()

  const [submitDisable, setSubmitDisable] = useState(false)

  //Refresh Events
  useEffect(() => {
    loadWareHouses()
    loadSupplier()
    loadBanks()
    setQuantity(1)
    setItemPrice('0')
    setCheckCashflow(() => moment(gdate?.cashflowDate) > moment(purchaseDate) ? true : false)
  },[])

  useEffect(() => {
    loadWarehouseStocks()
    setInputValue('')
    setSelectProductStock('')
  },[wareHouse])

  useEffect(() => {
    if (selectProductStock?.productCode?.productCode !== inputValue.split('|')[0].trim()) {
        listWarehouseStocks && setSelectProductStock(listWarehouseStocks?.find((i) => i.productCode?.productCode === inputValue) ? listWarehouseStocks?.find((i) => i.productCode?.productCode === inputValue) : '')
    }
  }, [inputValue])

  useEffect(() => {
    selectProductStock && quantity && setItemPrice(Number(selectProductStock?.productCode?.cost * quantity)?.toFixed(2))
  }, [selectProductStock, quantity])

  useEffect(() => {
    setSubTotal(items?.reduce((t,i) => t = t + Number(i?.itemPrice), 0)?.toFixed(2))
    setTotal(items?.reduce((t,i) => t = t + Number(i?.itemPrice) , 0)?.toFixed(2))
  },[items])

  useEffect(() => {
    setBank()
  },[pay])


  const loadWareHouses = async() => {
    try {
      const {data} = await Axios.post('/warehouses')
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListWareHouses(data)
        setWareHouse(data?.find((i) => i?._id === '63f48039710db4fab3a62abb'))
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadSupplier = async() => {
    try {
      const {data} = await Axios.post(`/suppliers`)
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListSuppliers(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadBanks = async() => {
    try {
      const {data} = await Axios.post(`/bank-accounts`)
      if(data?.error){
        toast.error(data?.error)
      } else {
        setListBanks(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadWarehouseStocks = async() => {
    try {
      const {data} = await Axios.post(`/warehousestocks/filtercategory`, {_id: {"$ne" : '63e65efc2f840202604fbb7f'}})
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListWarehouseStocks(data.filter((i) => i?.wareHouse?._id === wareHouse?._id))
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const getBarcodeData = (data) => {
    setInputValue(data.result.decodedText)
  }

  const getPDFData = (data) => {
    if(data?.file) {
      setInvoice(data)
    } else {
      setInvoice()
    }
  }

  const productAdd = async(e) => {
    e.preventDefault()
    try {
      if (itemPrice > 0 && quantity > 0) {
        await setItems([...items, {productStock: selectProductStock, quantity, itemPrice}])
        setQuantity(1)
        setInputValue('')
        setSelectProductStock('')
        setItemPrice('')
        productFocusRef?.current?.firstChild?.focus();
      } else {
        toast.error("Final Price Required / Minimum 1 Qty")
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const acc2 = (pay) => {
    if (pay?._id === '64acdf13722d07654e219f89') {
      return {date: moment(purchaseDate).format("YYYY-MM-DD"), type: `${total >= 0 ? "credit" : "debit"}`, empStatus: {status: false}, detail:{description: `Purchase INV No: ${invoiceNo} : ${selectedSupplier?.name}`, supplier: selectedSupplier?._id}, amount: Number(total).toFixed(2) , accounting: '64acdf13722d07654e219f89'}
    } else {
      return {date: moment(purchaseDate).format("YYYY-MM-DD"), empStatus: {status: false}, detail:{description: `Purchase INV No: ${invoiceNo} : ${selectedSupplier?.name}`, bank}, amount: `${total >= 0 ? Number(-total).toFixed(2) : Number(total).toFixed(2)  }` , accounting: pay?._id}
    }
  }

  const orderSubmit = async(e) => {
    e.preventDefault()
    try {
      await setSubmitDisable(true)
      if (pay?.id === '63e31a93f029ceab39dcd281' && !bank) {
        toast.error("Bank Account Required");
        setSubmitDisable(false)
      } else {
        const {data} = await Axios.post(`/createpurchase`, {invoiceNo, supplier: selectedSupplier?._id, purchaseDate, items, total, payment: pay?._id, invoice, type: 'inventory'})
        if (data?.error) {
          toast.error(data.error)
          setSubmitDisable(false)
        } else {
          const data1 = await Axios.post(`/accdata`, {date: moment(purchaseDate).format("YYYY-MM-DD"), empStatus: {status: false}, detail:{description: `Purchase INV No: ${invoiceNo} : ${selectedSupplier?.name}`}, amount: Number(total).toFixed(2) , accounting: "63ecfbe55b36ecf83d9dd4a3"})
          const data2 = await Axios.post(`/accdata`, acc2(pay))
          if(data1?.data?.error || data2?.data?.error){
            toast.error(data1?.data?.error || data2?.data?.error)
            setSubmitDisable(false)
          } else {
            toast.success("Purchase Recorded Sucessfully")
            window.location.reload()
          }
        }
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong!")
    }
  }

  const columns = [
    {accessorKey: 'productStock.productCode.productCode', header: 'Code', size: 100},
    {accessorKey: 'productStock.productCode.product.brand.name', header: 'Brand', size: 100},
    {accessorKey: 'productStock.productCode.product.name', header: 'Item', size: 100},
    {accessorKey: 'quantity', header: 'Qty', size: 100},
    {accessorKey: 'unitCost', header: 'Unit Cost', size: 100, Cell: ({row: {original}}) => GeneralNo(original.itemPrice / original.quantity)},
    {accessorKey: 'itemPrice', header: 'Final Price', size: 100, Cell: ({row: {original}}) => currency(original.itemPrice ? original?.itemPrice : 0.00)},
    {accessorKey: 'productStock.wareHouse.name', header: 'Warehouse', size: 100},
  ]

  const rowAction = ({row: {original}}) => [
    <div className='d-flex justify-content-center'>
      <Clear
        onClick={() => {
          setItems(items?.filter(c => c?.productStock?._id !== original.productStock?._id))
          toast.success("Product Removed")
          productFocusRef?.current?.firstChild?.focus();
        }}
      />
    </div>
  ]
  
  // UI Components
  return (
    <div className='row'>
      {/* COLUMN 1 */}
      <div className='col-xxl-8 col-12'>
        {/* WAREHOUSE */}
        <div className='col-12 mb-2'>
          <SelectAutoComplete
            label = "Warehouse"
            optionsList={listWareHouses}
            option = {(i) => i.name}
            value={wareHouse}
            onChange={(event, value) => {
                setWareHouse(value)
            }}
          />
        </div>

        {/* PRODUCTS */}
        <form className='row mb-2' onSubmit={productAdd}>
          <div className='col-md-6 col-12 mb-2'>
            {scannerVisible ? <Scanner result = {getBarcodeData}/> : <button className = 'd-flex btn btn-outline-primary' onClick={() => {setScannerVisible(true)}}><FontAwesomeIcon icon={faBarcode}/></button>}
          </div>

          <div className='col-md-6 col-12'>
            <div className='col-12'>
              <div className='col-12'>
                {listWarehouseStocks && <Autocomplete
                  size='small'
                  className='mb-2'
                  value={selectProductStock?.productCode}
                  onChange={(event, value) => {
                    setSelectProductStock(value)
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue)
                  }}
                  freeSolo
                  filterSelectedOptions
                  options={listWarehouseStocks}
                  renderInput={(params) => <TextField {...params} label="Product" autoFocus ref={productFocusRef}/>}
                  getOptionLabel = {(params) => `${params?.productCode?.productCode + ' | '+ params.productCode?.product?.brand?.name + ' ' + params.productCode?.product?.name + ' | '+ new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(params?.productCode?.cost)}`}
                  noOptionsText = "No Product Found"
                />}
              </div>

              <div className='col-12'>
                <Text
                  label="Qty"
                  type="Number"
                  value = {quantity}
                  onChange={(e) => setQuantity(Number(e.target.value))}
                />
              </div>

              <div className='col-12'>
                <Text
                  label="Final Price"
                  type="Number"
                  value = {itemPrice}
                  onChange={(e) => setItemPrice(Number(e.target.value))}
                  end = "LKR"
                />
              </div>

              <div className=''>
                  <dd className='fw-bold'>Price / Qty : {selectProductStock && new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(itemPrice / quantity)}</dd>
              </div>

              <div className='col-12 d-grid'>
                {selectProductStock ? <button style={{maxHeight: '40px'}} className='btn btn-primary' type='submit'><FontAwesomeIcon icon={faCartPlus}/></button> : <button  style={{maxHeight: '40px'}} className='btn btn-dark' type='submit' disabled><FontAwesomeIcon icon={faCartPlus}/></button>}
              </div>

            </div>
          </div>
        </form>

        {/*PRODUCTS ADDED TABLE*/}
        <div className='row mb-3'>
          <div className='col-12'>
            <MaterialTable
              col = {columns}
              row = {items}
              rowAction = {rowAction}
              exportDisable = {true}
            />
          </div>
        </div>
      </div>

      {/* COLUMN 2 */}
      <div className='col-xxl-4 col-12 d-flex justify-content-center align-items-start'>
        <Paper elevation={5} className='p-5' style={{minsize: 400}}>
          <h4 className='text-center pb-3'>Purchase Summary</h4>
          <Text
            label="Invoice No"
            value = {invoiceNo}
            onChange={(e) => setInvoiceNo(e.target.value)}
          />

          <SelectAutoComplete
            label = "Supplier"
            optionsList={listSuppliers}
            option = {(i) => `${i?.name + ' | '+ i?.phoneNumber}`}
            value={selectedSupplier}
            onChange={(event, value) => {
                setSelectedSupplier(value)
            }}
          />
          <div className='d-flex justify-content-between'><p>Date : </p> <p>{purchaseDate}</p></div>
          <div className='d-flex justify-content-between'><p>No of Items : </p> <p>{items.length}</p></div>
          <div className='d-flex justify-content-between'><p>Sub Total : </p> <p>{new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(items.length > 0 ? subTotal : 0)}</p></div>

          <div>Invoice PDF File</div>
          <FileUpload submit = {getPDFData} fileType= "PDF"/>

          <hr style={{borderStyle: 'double'}}></hr>
          <div className='d-flex justify-content-between'><h5>Total : </h5> <h5>{new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(items.length > 0 ? total : 0)}</h5></div>
          {payMethod && 
          <SelectAutoComplete
            label = "Payment Method"
            optionsList={payMethod}
            option = {(i) => i.name}
            value={pay}
            onChange={(event, value) => {
                setPay(value)
            }}
          />}

          {(pay?._id === "63e31a93f029ceab39dcd281") &&
            <SelectAutoComplete
              label = "Account"
              optionsList={listBanks}
              option = {(i) => i.bank + ' | ' + i.accountNo}
              value={bank}
              onChange={(event, value) => {
                  setBank(value)
              }}
            />
          }

          <div>
            <Button className='w-100 mt-2' size='large' color="success" variant="contained" onClick={orderSubmit} disabled = {(invoiceNo && selectedSupplier && pay && items.length > 0 ? submitDisable : true) || checkCashflow}>Checkout</Button>
            {checkCashflow && <dd className='text-center fs-6 fw-bold text-danger mt-2'>Cashflow Verified on Selected date</dd>}
          </div>
        </Paper>
      </div>

    </div>
  )
}
