import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import {Add, Edit} from '@mui/icons-material/'

import MaterialTable from '../../Components/Table/MaterialTable'
import ModalBasic from '../../Components/Modal/ModalBasic'

import CreateAccounts from './CreateAccounts'
import UpdateAccounts from './UpdateAccounts'

export default function ListAccounts() {

    const [listAccounts, setListAccounts] = useState('')

    //Model
    const [visibleAdd, setVisibleAdd] = useState(false)

    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        loadAccounts()
    }, [])
    

    const loadAccounts = async() => {
        try {
            const {data} = await Axios.post('/accountings')
            if(data?.error){
                toast.error(data.error)
            } else {
                setListAccounts(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const columns = [
        { accessorKey: 'name', header: 'Accounts Name'},
        { accessorKey: 'category.name', header: 'Type of Account'},
        { accessorKey: 'description', header: 'Description'},
    ]

    const rowAction = ({row}) => [
        <div className='d-flex justify-content-center gap-2'>
            <Edit
            onClick={() => {
                setVisible(true)
                setSelected(row.original)
            }}
            />
        </div>
    ]

    return (
        <div className='row justify-content-center'>
            <div className='col-xxl-8 col-xl-10 col-lg-11 col-12 mb-3'>
                <div className='row justify-content-between mb-2'>
                    <div className='col-xl-4 col-md-6 col-12 d-flex gap-2 mb-2'>
                        <button className = 'btn btn-outline-primary w-50' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
                    </div>
                    <MaterialTable
                        title = {'All Accounts Types'}
                        col = {columns} 
                        row = {listAccounts}
                        rowAction = {rowAction}
                        exportDisable = {true}
                    />  
                </div>      
            </div>

            <ModalBasic
                open = {visibleAdd}
                onClose = {()=> {
                setVisibleAdd(false)
                }}
                content = {<CreateAccounts/>}
            />

            <ModalBasic
                open = {visible}
                onClose = {()=> {
                setVisible(false)
                }}
                content = {<UpdateAccounts data={selected}/>}
            />
        </div>
    )
}
