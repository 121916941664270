import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useGdate } from '../../../context/date'

import {currency} from '../../../Functions/NoFomats'

import {Add, Edit, CallReceived, CallMade, Check, Warning} from '@mui/icons-material/'

import { SwitchInput } from '../../../Components/Inputs/InputFields'
import MaterialTable from '../../../Components/Table/MaterialTable'
import ModalBasic from '../../../Components/Modal/ModalBasic'

import CreateCheque from './CreateCheque'
import UpdateCheque from './UpdateCheque'


export default function ListCheques() {
    const [gdate] = useGdate()

    //Model Visible Status
    const [visibleAdd, setVisibleAdd] = useState(false)

    const [visibleUpdateChq, setVisibleUpdateChq] = useState(false)
    const [selected, setSelected] = useState()

    const [listCheques, setListCheques] = useState('')
    const [checked, setChecked] = useState(true)

    useEffect(() => {
        loadCheques()
    }, [])

    const loadCheques = async() => {
        try {
            const {data} = await Axios.post('/cheques')
            if(data?.error){
                toast.error(data.error)
            } else {
                setListCheques(data)
            }
        } catch (err) {
            toast.error("Something went wrong, Try Again!")
            console.log(err)
        }
    }

    const columns = [
        {accessorKey: 'status', header: 'Type', size: 80, accessorFn: (i) => i.status, Cell : ({row: {original}}) => original.status === "credit" ? <CallReceived color='success'/> : <CallMade color='error'/>},
        {accessorKey: 'date', header: 'Date', size: 100},
        {accessorKey: 'chqCustomer.name', header: 'Customer', Cell : ({row: {original}}) => original.chqCustomer?.name, exportHead: 'Customer'},
        {accessorKey: 'chqSupplier.name', header: 'Supplier', Cell : ({row: {original}}) => original.chqSupplier?.name, exportHead: 'Supplier'},
        {accessorKey: 'chqBank.accountName', header: 'Cheque Account', Cell : ({row: {original}}) => original.chqBank?.accountName},
        {accessorKey: 'chqNo', header: 'Cheque No', size: 100},
        {accessorKey: 'chqDate', header: 'Cheque Date', size: 100},
        {accessorKey: 'chqDeposit', header: 'Release', size:100, accessorFn: (i) => i.chqDeposit, 
            Cell : ({row: {original}}) => original.chqDeposit ? <Check color='success'/> : <Warning color='warning'/>,
            export: (i) => i ? 'YES' : 'NO'
        },
        {accessorKey: 'amount', header: 'Amount', size: 100, Cell : ({row: {original}}) => currency(original.amount)},
    ]

    const rowAction = ({row}) => [
        <div className='d-flex justify-content-center gap-2'>
            <Edit
                onClick={() => {
                    setVisibleUpdateChq(true)
                    setSelected(row.original)
                }}
            />
        </div>
    ]

    return (
        <div className='row justify-content-center p-3'>
            <div className='col-12'>
                <div className='d-flex justify-content-between mb-2'>
                    <button className = 'btn btn-outline-primary' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
                    <SwitchInput
                        startLabel = "All"
                        endLabel = "Pending Release"
                        checked = {checked}
                        color = 'primary'
                        onChange = {(e) => {
                            setChecked(preVal => !preVal)
                        }}
                    />
                </div>
                <div className='col-12 mb-3'>
                    <MaterialTable
                        title = {'All Received / Given Cheques | All Cheques'}
                        col = {columns}
                        row = {listCheques && checked ? listCheques?.filter((f) => f?.chqDeposit === false) : listCheques}
                        rowSelection = {true}
                        rowAction = {rowAction}
                    />
                </div>
            </div>
            <ModalBasic
                open = {visibleAdd}
                onClose = {()=> {
                setVisibleAdd(false)
                }}
                content = {<CreateCheque/>}
            />

            <ModalBasic
                open = {visibleUpdateChq}
                onClose = {()=> {
                setVisibleUpdateChq(false)
                }}
                content = {<UpdateCheque data = {selected}/>}
            />
        </div>
        )
}
