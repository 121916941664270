import React, {useEffect, useState, useRef} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment';
import {useReactToPrint} from 'react-to-print'

import {styled, Table, TableBody, TableCell, TableHead, TableRow, Paper} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell'
import {Print} from '@mui/icons-material/'

import {useGdate} from '../../../../context/date';
import {useDk} from '../../../../context/dk'

export default function IncomeStatementDetail(props) {
  const [gdate] = useGdate()
  const [dk, setDk] = useDk()

  //Sales Income Accounts not included
  const [incomeAccounts, setIncomeAccounts] = useState('')

  //Purchase Expense Accounts not included
  const [expenseAccounts, setExpenseAccounts] = useState('')

  const [dayPumpTotal, setDayPumpTotal] = useState()
  const [listInventoryTotal, setListInventoryTotal] = useState()

  const [fuelProfit, setFuelProfit] = useState()
  const [inventoryProfit, setInventoryProfit] = useState()

  const [netProfit, setNetProfit] = useState()

  const [listFuelLoad, setListFuelLoad] = useState()
  
  const [fuelLoadsTotal, setFuelLoadsTotal] = useState()
  const [inventoryPurchaseTotal, setInventoryPurchaseTotal] = useState()

  const [fuelLoadReturns, setFuelLoadReturns] = useState()
  const [inventoryPurchaseReturns, setInventoryPurchaseReturns] = useState()

  const [dayPumpByType, setDayPumpByType] = useState()
  const [inventorySalesCost, setInventorySalesCost] = useState()

  const [inventoryReturnsCost, setInventoryReturnsCost] = useState()
  const [inventoryReturnsProfit, setInventoryReturnsProfit] = useState()

  const [lossInventory, setLossInventory] = useState()
  const [lossFuelByType, setLossFuelByType] = useState()

  const [fuelStockValue, setFuelStockValue] = useState()
  const [inventoryStockValue, setInventoryStockValue] = useState()


  //Layouts
  const [salesTableLayout, setSalesTableLayout] = useState('col-xxl-8 col-12')
  const [incomeStTableLayout, setIncomeStTableLayout] = useState('col-xxl-4 col-12')

  useEffect(() => {
    loadTotalAccounts()
    loadPurchaseByGasType()
    loadPurchaseByProducts()
    loadDayPumpTotal()
    loadProductLineTotal()
    loadOrdersReturns()
    loadDayPumpByType()
    loadPurchaseReturnsByGasType()
    loadPurchaseReturnsByProducts()
    loadLossInventory()
    loadLossFuel()
  },[gdate])

  //FuelStock value By Type - BalanceSheet
  useEffect(() => {
    setFuelStockValue(fuelStockValueByType())
  },[listFuelLoad, dayPumpByType, fuelLoadReturns, lossFuelByType])

  //INVENTORY STOCK VALUE - Blancesheet
  useEffect(() => {
    setInventoryStockValue((inventoryPurchaseTotal + inventoryReturnsCost) - (inventorySalesCost + inventoryPurchaseReturns) - lossInventory)
  },[inventoryPurchaseTotal, inventoryReturnsCost, inventorySalesCost, inventoryPurchaseReturns, lossInventory])

  //Net Profit
  useEffect(() => {
    setNetProfit( ( (incomeAccounts && incomeAccounts?.reduce((a,v) => a = a + v?.total ,0)) + fuelProfit + inventoryProfit - inventoryReturnsProfit) - (expenseAccounts && expenseAccounts?.reduce((a,v) => a = a + v?.total, 0)) )
  },[incomeAccounts, expenseAccounts, fuelProfit, inventoryProfit])

  //Send data to BalanceSheet
  useEffect(() => {
    sendIncomeStatementDetails()
  },[netProfit, fuelLoadsTotal , fuelStockValue, inventoryStockValue])

  const dateFilters = (balanceSheet) => {
    if (balanceSheet === true) {
      return {$lte: gdate?.reportEndDate}
    } else {
      return {$gte: gdate?.reportStartDate, $lte : gdate?.reportEndDate}
    }
  }

  //Income and expense accounts filtered and load
  const loadTotalAccounts = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/group-bydescription`, {date: dateFilters(props?.balanceSheet)})
      if(data?.error) {
        toast.error(data.error)
      } else {
        await setIncomeAccounts(data?.filter((i) => i?._id?.accounting[0]?.category === '63dca26006b9cdaae9c5c8f6') ? data?.filter((i) => i?._id?.accounting[0]?.category === '63dca26006b9cdaae9c5c8f6' && i?._id?.accounting[0]?._id !== "63eb82f1661aeb9f06368651") : '')
        await setExpenseAccounts(data?.filter((i) => i?._id?.accounting[0]?.category === '63dca26f06b9cdaae9c5c8fc') ? data?.filter((i) => i?._id?.accounting[0]?.category === '63dca26f06b9cdaae9c5c8fc' && i?._id?.accounting[0]?._id !== "63ecfbe55b36ecf83d9dd4a3") : '')
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  //Purchase Fuel By Type Purchases and Total
  const loadPurchaseByGasType = async() => {
    try {
        const {data} = await Axios.post(`/purchaseproductlines-gasType`, {purchaseDate: dateFilters(props?.balanceSheet)})
        if(data?.error){
            toast.error(data.error)
        } else {
          // List by Fuel Type for BALANCE SHEET
          setListFuelLoad(data)
          //Fuel Load Total Value
          setFuelLoadsTotal(data.length > 0 ? data.reduce((a,v) => a = a + v?.total, 0) : '0')
        }
    } catch (err) {
        toast.error("Something went wrong, Try Again!")
        console.log(err)
    }
  }
  
  // Purchase Inventory Purchases and Total
  const loadPurchaseByProducts = async() => {
    try {
        const {data} = await Axios.post(`/purchaseproductlines-product`, {purchaseDate: dateFilters(props?.balanceSheet), type: 'inventory'})
        if(data?.error){
            toast.error(data.error)
        } else {
          setInventoryPurchaseTotal(data.length > 0 ? data?.reduce((a,v) => a = a + v?.total, 0) : '0')
        }
    } catch (err) {
        toast.error("Something went wrong, Try Again!")
        console.log(err)
    }
  }

  // Purchase Returns By ProductType
  const loadPurchaseReturnsByGasType = async() => {
    try {
      const {data} = await Axios.post(`/purchase-return-productlines-gasType`, {returnDate: dateFilters(props?.balanceSheet)})
      if (data?.error) {
        toast?.error(data.error)
      } else {
        setFuelLoadReturns(data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const loadPurchaseReturnsByProducts = async() => {
    try {
      const {data} = await Axios.post(`/purchase-return-productlines-product`, {returnDate: dateFilters(props?.balanceSheet), "productLine.purchase.type": 'inventory'})
      if (data?.error) {
        toast?.error(data.error)
      } else {
        setInventoryPurchaseReturns(data.length > 0 ? data?.reduce((a,v) => a = a + v?.returnPrice, 0) : '0')
      }
    } catch (err) {
      console.log(err)
    }
  }

  //Gas profit, Sales cost Total load by fuel type DayPump Details GROUPBY Type, Price, Unit Cost
  const loadDayPumpTotal = async() => {
    try {
      const {data} = await Axios.post(`/daypumps/fuelsales-typepricecost`, {date: dateFilters(props?.balanceSheet)})
      if (data?.error) {
        toast?.error(data.error)
      } else {
        setDayPumpTotal(data)
        // //Day Pumps Profit
        setFuelProfit(data && data?.reduce((a,v) => a = a + (v?.liter * (v?._id?.price - v?._id?.unitCost)), 0))
      }
    } catch (err) {
      console.log(err)
    }
  }

  //Inventory Sales Profit, Sales cost Total load GROUPBY ProductCode, Product, Price, Unit Cost
  const loadProductLineTotal = async() => {
    try {
      const {data} = await Axios.post(`/productline-productcode`, {date: dateFilters(props?.balanceSheet)})
      if (data?.error) {
        toast?.error(data.error)
      } else {
        setListInventoryTotal(data)
        setInventoryProfit(data && data?.reduce((a,v) => a = a + ((v?.quantity * (v?._id?.price - v?._id?.unitCost)) - v?.discount), 0))
        await setInventorySalesCost(data?.reduce((a,v) => a + (v?._id?.unitCost * v?.quantity), 0) ? data?.reduce((a,v) => a + (v?._id?.unitCost * v?.quantity), 0) : '0' )
      }
    } catch (err) {
      console.log(err)
    }
  }

  //Inventory Sales Return Profit, Sales cost Total load
  const loadOrdersReturns = async() => {
    try {
      const {data} = await Axios.post(`/return-productline-productcode`, {returnDate: dateFilters(props?.balanceSheet)})
      if (data?.error) {
        toast?.error(data.error)
      } else {
        setInventoryReturnsCost(data ? data?.reduce((a,v) => a = a + (v?._id?.unitCost * v?.quantity), 0) : '0')
        setInventoryReturnsProfit(data && data?.reduce((a,v) => a = a + ((v?.quantity * (v?._id?.price - v?._id?.unitCost)) - v?.discount), 0))
      }
    } catch (err) {
      console.log(err)
    }
  }

  //Loss Inventory
  const loadLossInventory = async() => {
    try {
      const {data} = await Axios.post(`/stock-adjustments`, {date: dateFilters(props?.balanceSheet), type: 'adjustment'})
      if (data?.error) {
        toast?.error(data.error)
      } else {
        setLossInventory(data?.reduce((a,v) => a += v?.amount, 0))
      }
    } catch (err) {
      console.log(err)
    }
  }

  //Loss Fuel
  const loadLossFuel = async() => {
    try {
      const {data} = await Axios.post(`/tank-checks/fuelType`)
      if (data?.error) {
        toast?.error(data.error)
      } else {
        setLossFuelByType(data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  //FOR BLANCESHEET
  // Gas sales by type for calculate stock value
  const loadDayPumpByType = async() => {
    try {
      const {data} = await Axios.post(`/daypumps/fuelsales-bytype`, {date: dateFilters(props?.balanceSheet)})
      if (data?.error) {
        toast?.error(data.error)
      } else {
        setDayPumpByType(data)
      }
    } catch (err) {
      console.log(err)
    }
  }
  
  const fuelStockValueByType = () =>
    listFuelLoad?.map((i) => {
      const salesData = dayPumpByType?.find((d) => d?._id?.type === i?._id?.productType) // Daily Sales
      const returnData = fuelLoadReturns?.find((f) => f?._id?.productType === i?._id?.productType) // Purchase Returns
      const lossFuel = lossFuelByType?.find((e) => e?._id?.type === i?._id?.productType) // Loss Fuel
      return {type: i?._id?.productType, 
        stock: (i?.quantity - ((salesData ? salesData?.liter : 0) + (returnData ? returnData?.quantity : 0) + (lossFuel ? lossFuel?.lossLiters : 0))), 
        stockValue: (i?.total - ((salesData ? salesData?.costOfSales : 0) + (returnData ? returnData?.returnPrice : 0) + (lossFuel ? lossFuel?.amount : 0))), 
        sales: (salesData ? salesData?.sales : 0)}
    })

  const sendIncomeStatementDetails = async() => {
    await props.getIncomeStatementDetails && props.getIncomeStatementDetails({netProfit, fuelStockValue, inventoryStockValue})
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
  }));

  const componentRef = useRef();
  const printData = useReactToPrint({
      content : () => componentRef.current,
      documentTitle: `Detailed Income Statement - ${gdate?.reportStartDate} - ${gdate?.reportEndDate}`,
      onBeforeGetContent: async() => {
        await setSalesTableLayout('col-8')
        await setIncomeStTableLayout('col-4')
        await setDk({...dk, darkmode : false})
      },
      onAfterPrint: async() => {
        const data = localStorage.getItem("darkmode");
        if (data) {
            const parsed = JSON.parse(data);
            await setDk({...dk, darkmode: parsed});
        }
        await setSalesTableLayout('col-xxl-8 col-12')
        await setIncomeStTableLayout('col-xxl-4 col-12')
      },
  })

  return (
    <div className='row'>
      <div className='mb-2'>
        <button className = 'btn btn-warning' onClick={() => {printData()}}><Print/></button>
      </div>

      {/* Visible on larger than xxl screen */}
      <div ref={componentRef} className='p-3 col-12'>
        <p className='text-center fs-5 mb-2' style={{fontFamily: 'TirantiSolidLET'}}>{`${moment(gdate?.reportStartDate).format("MMMM Do YYYY")} - ${moment(gdate?.reportEndDate).format("MMMM Do YYYY")}`}</p>

        <div className='row'>
            <div className={salesTableLayout}>
              {/* Fuel Sales */}
              <div className='col-12 mb-3' style={{overflowX: 'auto'}}>
                <Table className='w-100' size='small' component={Paper}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className='text-uppercase'></StyledTableCell>
                      <StyledTableCell className='text-uppercase border-end'></StyledTableCell>
                      <StyledTableCell className='text-uppercase text-center border-end' colSpan={3}>UNIT</StyledTableCell>
                      <StyledTableCell className='text-uppercase text-center' colSpan={3}>TOTAL</StyledTableCell>
                    </TableRow>

                    <TableRow>
                      <StyledTableCell className='text-uppercase border-end'>Details</StyledTableCell>
                      <StyledTableCell className='text-uppercase border-end' align="right">Qty</StyledTableCell>
                      <StyledTableCell className='text-uppercase' align="right">Price</StyledTableCell>
                      <StyledTableCell className='text-uppercase' align="right">Cost</StyledTableCell>
                      <StyledTableCell className='text-uppercase border-end' align="right">Profit</StyledTableCell>
                      <StyledTableCell className='text-uppercase' align="right">Sales</StyledTableCell>
                      <StyledTableCell className='text-uppercase' align="right">Purchase</StyledTableCell>
                      <StyledTableCell className='text-uppercase' align="right">Profit</StyledTableCell>
                    </TableRow>
                  </TableHead>
              
                  <TableBody>
                    {dayPumpTotal && dayPumpTotal?.map((i, index) =>
                    <TableRow key={i?._id?.type + index}>
                      <StyledTableCell className='border-end'>{i?._id?.type}</StyledTableCell>
                      <StyledTableCell align="right" className='border-end'>{(new Intl.NumberFormat('en-US', {style: 'unit', unit: 'liter', minimumFractionDigits: 3, maximumFractionDigits: 3 }).format(i?.liter))}</StyledTableCell>
                      <StyledTableCell align="right">{(new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(i?._id?.price))}</StyledTableCell>
                      <StyledTableCell align="right">{(new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(i?._id?.unitCost)) }</StyledTableCell>
                      <StyledTableCell align="right" className='border-end'>{(new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(i?._id?.price - i?._id?.unitCost)) }</StyledTableCell>
                      <StyledTableCell align="right">{(new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(i?.liter * i?._id?.price)) }</StyledTableCell>
                      <StyledTableCell align="right">{(new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(i?.liter * i?._id?.unitCost)) }</StyledTableCell>
                      <StyledTableCell align="right">{(new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(i?.liter * (i?._id?.price - i?._id?.unitCost)))}</StyledTableCell>
                    </TableRow>
                    )}

                    <TableRow key='Total' style={{backgroundColor: 'greenyellow', color: 'black'}}>
                      <StyledTableCell className='text-dark fw-bold border-end'>Total</StyledTableCell>
                      <StyledTableCell align="right" className='text-dark fw-bold border-end'>{new Intl.NumberFormat('en-US', {style: 'unit', unit: 'liter', minimumFractionDigits: 3, maximumFractionDigits: 3 }).format(dayPumpTotal && dayPumpTotal?.reduce((a,v) => a = a + v?.liter, 0) )}</StyledTableCell>
                      <StyledTableCell align="right" className='text-dark fw-bold'></StyledTableCell>
                      <StyledTableCell align="right" className='text-dark fw-bold'></StyledTableCell>
                      <StyledTableCell align="right" className='text-dark fw-bold border-end'></StyledTableCell>
                      <StyledTableCell align="right" className='text-dark fw-bold'>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(dayPumpTotal && dayPumpTotal?.reduce((a,v) => a = a + (v?.liter * v?._id?.price), 0) )}</StyledTableCell>
                      <StyledTableCell align="right" className='text-dark fw-bold'>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(dayPumpTotal && dayPumpTotal?.reduce((a,v) => a = a + (v?.liter * v?._id?.unitCost), 0) )}</StyledTableCell>
                      <StyledTableCell align="right" className='text-dark fw-bold'>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(fuelProfit)}</StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>

              {/* Inventory Sales */}
              <div className='col-12 mb-3' style={{overflowX: 'auto'}}>
                <Table className='w-100' size='small' component={Paper}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className='text-uppercase'></StyledTableCell>
                      <StyledTableCell className='text-uppercase'></StyledTableCell>
                      <StyledTableCell className='text-uppercase border-end'></StyledTableCell>
                      <StyledTableCell className='text-uppercase text-center border-end' colSpan={3}>UNIT</StyledTableCell>
                      <StyledTableCell className='text-uppercase text-center' colSpan={4}>TOTAL</StyledTableCell>
                    </TableRow>
                    
                    <TableRow>
                      <StyledTableCell className='text-uppercase'>SKU</StyledTableCell>
                      <StyledTableCell className='text-uppercase border-end'>Details</StyledTableCell>
                      <StyledTableCell className='text-uppercase border-end' align="right">Qty</StyledTableCell>
                      <StyledTableCell className='text-uppercase' align="right">Price</StyledTableCell>
                      <StyledTableCell className='text-uppercase' align="right">Cost</StyledTableCell>
                      <StyledTableCell className='text-uppercase border-end' align="right">Profit</StyledTableCell>
                      <StyledTableCell className='text-uppercase' align="right">Sales</StyledTableCell>
                      <StyledTableCell className='text-uppercase' align="right">Purchase</StyledTableCell>
                      <StyledTableCell className='text-uppercase' align="right">Dsc</StyledTableCell>
                      <StyledTableCell className='text-uppercase' align="right">G.Profit</StyledTableCell>
                    </TableRow>
                  </TableHead>
                
                  <TableBody>
                    {listInventoryTotal && listInventoryTotal?.map((i, index) =>
                    <TableRow key={i?._id?.productCode + index}>
                      {/* Product Details */}
                      <StyledTableCell>{i?._id?.productCode}</StyledTableCell>
                      <StyledTableCell className='border-end'>{i?._id?.product}</StyledTableCell>
                      <StyledTableCell className='border-end' align="right" >{new Intl.NumberFormat().format(i?.quantity)}</StyledTableCell>
                      <StyledTableCell align="right">{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(i?._id?.price)}</StyledTableCell>
                      <StyledTableCell align="right">{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(i?._id?.unitCost)}</StyledTableCell>
                      <StyledTableCell align="right" className='border-end'>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(i?._id?.price - i?._id?.unitCost)}</StyledTableCell>
                      {/* Sum of Accounts */}
                      <StyledTableCell align="right">{new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(i?.quantity * i?._id?.price)}</StyledTableCell>
                      <StyledTableCell align="right">{new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(i?.quantity * i?._id?.unitCost)}</StyledTableCell>
                      <StyledTableCell className='text-uppercase' align="right">{new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(i?.discount)}</StyledTableCell>
                      <StyledTableCell align="right">{new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format( (i?.quantity * (i?._id?.price - i?._id?.unitCost)) - i?.discount )}</StyledTableCell>
                    </TableRow>
                    )}

                    <TableRow key='Total' style={{backgroundColor: 'greenyellow', color: 'black'}}>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell className='text-dark fw-bold border-end'>Total</StyledTableCell>
                      <StyledTableCell align="right" className='text-dark fw-bold border-end'></StyledTableCell>
                      <StyledTableCell align="right" className='text-dark fw-bold'></StyledTableCell>
                      <StyledTableCell align="right" className='text-dark fw-bold'></StyledTableCell>
                      <StyledTableCell align="right" className='text-dark fw-bold border-end'></StyledTableCell>
                      {/* Sum of Accounts */}
                      <StyledTableCell align="right" className='text-dark fw-bold'>{new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(listInventoryTotal && listInventoryTotal?.reduce((a,v) => a = a + (v?.quantity * v?._id?.price), 0) ) }</StyledTableCell>
                      <StyledTableCell align="right" className='text-dark fw-bold'>{new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(listInventoryTotal && listInventoryTotal?.reduce((a,v) => a = a + (v?.quantity * v?._id?.unitCost), 0) ) }</StyledTableCell>
                      <StyledTableCell align="right" className='text-dark fw-bold'>{new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(listInventoryTotal && listInventoryTotal?.reduce((a,v) => a = a + v?.discount, 0) )}</StyledTableCell>
                      <StyledTableCell align="right" className='text-dark fw-bold'>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(inventoryProfit)}</StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </div>

            {/* All Income and Expenses */}
            <div className={incomeStTableLayout} style={{overflowX: 'auto'}}>
            <Table className='w-100' size='small' component={Paper}>
                  <TableHead>
                    <TableRow>
                        <StyledTableCell className='text-center' colSpan={3}>INCOME STATEMENT</StyledTableCell>
                    </TableRow>

                    <TableRow>
                      <StyledTableCell className='text-uppercase'>Details</StyledTableCell>
                      <StyledTableCell className='text-uppercase' align="right">Amount</StyledTableCell>
                      <StyledTableCell className='text-uppercase' align="right">Amount</StyledTableCell>
                    </TableRow>
                  </TableHead>
              
                  <TableBody>
                    <TableRow key='fuel-profit'>
                      <StyledTableCell>Fuel Profit</StyledTableCell>
                      <StyledTableCell align="right">{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(fuelProfit)}</StyledTableCell>
                      <StyledTableCell align="right"></StyledTableCell>
                    </TableRow>

                    <TableRow key='Inventory-profit'>
                      <StyledTableCell>Inventory Profit</StyledTableCell>
                      <StyledTableCell align="right">{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(inventoryProfit)}</StyledTableCell>
                      <StyledTableCell align="right"></StyledTableCell>
                    </TableRow>

                    <TableRow key='Inventory-returns-profit'>
                      <StyledTableCell>Returns Profit Reduction(-)</StyledTableCell>
                      <StyledTableCell align="right">-{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(inventoryReturnsProfit)}</StyledTableCell>
                      <StyledTableCell align="right"></StyledTableCell>
                    </TableRow>

                    <TableRow key='other-income'>
                      <StyledTableCell className='fw-bold'>OTHER INCOMES</StyledTableCell>
                      <StyledTableCell align="right"></StyledTableCell>
                      <StyledTableCell align="right"></StyledTableCell>
                    </TableRow>

                    {incomeAccounts && incomeAccounts.map((i, index) => 
                      <TableRow key={i?._id?.accounting[0]?._id + index}>
                        <StyledTableCell className='text-capitalize'>{i?._id?.detail}</StyledTableCell>
                        <StyledTableCell align="right">{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(i?.total)}</StyledTableCell>
                        <StyledTableCell align="right"></StyledTableCell>
                      </TableRow>
                    )}

                    <TableRow key='Total-income'>
                      <StyledTableCell align="center" className='fw-bold'>Gross Profit</StyledTableCell>
                      <StyledTableCell align="right"></StyledTableCell>
                      <StyledTableCell align="right" className='border-bottom'>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format( (incomeAccounts && incomeAccounts?.reduce((a,v) => a = a + v?.total ,0)) + fuelProfit + inventoryProfit - inventoryReturnsProfit )}</StyledTableCell>
                    </TableRow>

                    <TableRow key='expenses'>
                      <StyledTableCell className='fw-bold'>EXPENSES</StyledTableCell>
                      <StyledTableCell align="right"></StyledTableCell>
                      <StyledTableCell align="right"></StyledTableCell>
                    </TableRow>

                    {expenseAccounts && expenseAccounts.map((i) => 
                      <TableRow key={i?._id?.accounting[0]?._id}>
                        <StyledTableCell className='text-capitalize'>{i?._id?.detail}</StyledTableCell>
                        <StyledTableCell align="right">{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(i?.total)}</StyledTableCell>
                        <StyledTableCell align="right"></StyledTableCell>
                      </TableRow>
                    )}

                    <TableRow key='Total-expense'>
                      <StyledTableCell align="center" className='fw-bold'>Total Expenses </StyledTableCell>
                      <StyledTableCell align="right"></StyledTableCell>
                      <StyledTableCell align="right" className='border-bottom'>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format( (expenseAccounts && expenseAccounts?.reduce((a,v) => a = a + v?.total,0)) * (-1) )}</StyledTableCell>
                    </TableRow>


                    <TableRow key='Total' style={{backgroundColor: 'greenyellow', color: 'black'}}>
                      <StyledTableCell align="right" className='text-dark fw-bold'>NET PROFIT</StyledTableCell>
                      <StyledTableCell className='text-dark fw-bold'></StyledTableCell>
                      <StyledTableCell align="right" className='text-dark fw-bold'>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(netProfit)}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
            </div>
        </div>
      </div>

    </div>
  )
}
