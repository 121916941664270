import React, {useState} from 'react'

import { useDk } from '../../context/dk';

// Import the main component
import { Viewer } from '@react-pdf-viewer/core'; // install this library

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library

// Worker
import { Worker } from '@react-pdf-viewer/core'; // install this library

import {Visibility} from '@mui/icons-material/'
import ModalBasic from '../Modal/ModalBasic'

export default function PDFViewer(props) {

    const [visible, setVisible] = useState(false)
    const [dk] = useDk()

    const [viewPdf] = useState(props?.data)

        // Create new plugin instance
        const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <div>
            <Visibility fontSize='large' className='p-2 shadow rounded-circle'
                onClick={() => 
                    setVisible(true)
                }
            />
            <ModalBasic
                open = {visible}
                width = {750}
                onClose = {()=> {
                setVisible(false)
                }}
                content = {
                    <div>
                        <h4 className='text-center'>View PDF</h4>
                        {viewPdf &&
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.10.111/build/pdf.worker.min.js">
                            <Viewer theme={dk?.darkmode ? 'dark' : 'light'} fileUrl={viewPdf} plugins={[defaultLayoutPluginInstance]} />
                            </Worker>
                        }
                    </div>
                }
            />
        </div>
    )
}
