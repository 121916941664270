import React, {useEffect, useState} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { jsPDF } from 'jspdf'; //or use your library of choice here

import { useSettings } from '../../../../context/settings'
import { useDk } from '../../../../context/dk'

import {currency, unit} from '../../../../Functions/NoFomats'

import { styled } from '@mui/material/styles';
import {Table, TableBody, TableCell, TableHead, TableRow, Paper} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';

import QrCodeGenerator from '../../../../Components/Scan-Qr/QrCodeGenerator'
import {Divider} from '@mui/material';

import CpetcoLogoImg from '../../../../Assets/images/ceypetco_logo.png'

export default function EReceipt() {
  const {id} = useParams()
  const [settings] = useSettings();
  const [dk, setDk] = useDk();

  const [order, setOrder] = useState()
  const [items, setItems] = useState()

  const [noQuantity, setNoQuantity] = useState('')
  const [subTotal, setSubTotal] = useState('')
  const [discount, setDiscount] = useState('')

  useEffect(() => {
    setDk({...dk, darkmode : false})
    loadOrders()
  },[])

  useEffect(() => {
    setSubTotal(items?.reduce((t,i) => t = t + (i?.quantity * i?.productStock?.productCode?.price), 0))
    setDiscount(items?.reduce((t,i) => t = t + Number(i?.unitDiscount * i?.quantity), 0))
    setNoQuantity(items?.reduce((t,i) => t = t + Number(i?.quantity), 0));
  },[items])

  const loadOrders = async() => {
    try {
        const {data} = await Axios.post('/orders', {_id: id})
        if(data?.error){
            toast.error(data.error)
        } else {
            setOrder(data[0])
            await loadItems(data[0]?._id)
        }
    } catch (err) {
        toast.error("Something went wrong, Try Again!")
        console.log(err)
    }
  }

  const loadItems = async(id) => {
    try {
      const {data} = await Axios.post(`/productlines`, {order: id})
      if (data?.error) {
        toast.error(data.error)
      } else {
        await setItems(data)
      }
    } catch (err) {
      toast.error("Something went wrong, Try Again!")
      console.log(err)
    }
  }

  const handleExportPDF = async() => {
      var doc = new jsPDF({
        orientation: 'p',
        format: 'a4',
      })

      var elementHTML = await document.querySelector("#print")

      doc.html(elementHTML, {
        callback: function(doc) {
            //Print the Doc
            // doc.autoPrint({variant: 'non-conform'})
            // Save the PDF
            doc.save(`Sales InvoiceNo - ${order?.invoiceNo}.pdf`);
        },
        x: 10,
        y: 10,
        width: 185, //target width in the PDF document
        windowWidth: 900 //window width in CSS pixels
      });
  };

  const paymentMethod = [{_id:'64952f578188d2c8b9c26603', name: 'Cash'}, {_id:'63e31a93f029ceab39dcd281', name: 'Bank / Card Pay'}, {_id:'63e31abdf029ceab39dcd283', name: 'Credit'}]

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
  }));

  return (
    <div className='p-3'>
      {/* <meta name="viewport" content="width=900"></meta> */}
      <button className='btn btn-outline-primary mb-3' onClick={handleExportPDF}>Print</button>
      <div id='print' className='row justify-content-center'>
        <div className='d-flex justify-content-between'>
            <div className='d-flex gap-2 align-items-center'>
                <img
                    height = '100px' 
                    src={settings?.logo ? settings?.logo?.file : CpetcoLogoImg}
                    alt='Logo'
                />
                <h4 style={{fontFamily: 'TirantiSolidLET'}}>{settings?.nameOnReceipt ? settings?.name : ''}</h4>    
            </div>
            <div>
                <dd className='text-end lh-sm' style={{fontSize: '14px'}}>Address: {settings?.address}</dd>
                <dd className='text-end lh-sm' style={{fontSize: '14px'}}>Telephone No: {settings?.phoneNumber}</dd>
                <dd className='text-end lh-sm' style={{fontSize: '14px'}}>Email: {settings?.mail}</dd>
            </div>
        </div>
                
        <hr></hr>

        <h4 className='text-center fw-bold' >SALES RECEIPT</h4>
        
        <div className='d-flex justify-content-center mb-2'>
          <div className='row justify-content-between'>
              <dd style={{fontSize: '12px'}}>INVOICE NO: {order?.invoiceNo}</dd>
              <dd style={{fontSize: '12px'}}>CUSTOMER: {order?.selectedCustomer ? order?.selectedCustomer?.name : 'Retail'}</dd>
              <dd style={{fontSize: '12px'}}>DATE: {order?.orderDate}</dd>
              
          </div>

          <div className='row text-end'>
              <dd style={{fontSize: '12px'}}>Payment: {(paymentMethod.find((i) => i._id === order?.payment?._id) ? paymentMethod.find((i) => i._id === order?.payment?._id)?.name : 'Unknown')}</dd>
              <dd style={{fontSize: '12px'}}>No.Items: {(items?.length ? items?.length : 0)}</dd>
              <dd style={{fontSize: '12px'}}>No.Qty: {new Intl.NumberFormat().format(noQuantity)}</dd>
          </div>   
        </div>

        <div className='w-100'>
            <div style={{overflowX: 'scroll'}}>
                {items && 
                    <Table className='w-100' size='small' component={Paper}>
                        <TableHead>
                            <TableRow>
                              {['#', 'Product Code', 'Product', 'Unit Price', 'Qty', 'Discount', 'Price']?.map((i) => 
                                <StyledTableCell style={{fontSize: '12px'}}>{i}</StyledTableCell>
                              )}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {items?.map((i,index) =>
                              <TableRow key={index}>
                                {[(index+1), i?.productStock?.productCode?.productCode, i?.productStock?.productCode?.product?.name, currency(i?.productStock?.productCode?.price), unit(i?.quantity, i?.productStock?.productCode?.product?.unit), currency(i?.unitDiscount * i?.quantity), currency((i?.quantity * (i?.productStock?.productCode?.price - i?.unitDiscount)))]?.map((i) => 
                                  <StyledTableCell style={{fontSize: '12px'}}>{i}</StyledTableCell>
                                )}
                              </TableRow> 
                            )}
                        </TableBody>
                    </Table>
                }
            </div>

            <div className='row m-3 pt-3'>
                <div className='col-md-6 col-12'>
                    <div className='d-flex justify-content-center'>
                        <QrCodeGenerator qrText = {String(order?.invoiceNo)} size='125' hide='true'/>
                    </div>
                </div>

                <div className='col-md-6 col-12'>
                    <div className='row'>
                        <div className='d-flex justify-content-between'>
                            <p className='lh-sm' style={{fontSize: '14px'}}>Sub Total:</p>
                            <p className='lh-sm' style={{fontSize: '14px'}}>{items?.length > 0 ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(subTotal)}` : '0.00'}</p> 
                        </div>

                        <div className='d-flex justify-content-between'>
                            <p className='lh-sm' style={{fontSize: '14px'}}>Total Discount:</p>
                            <p className='lh-sm' style={{fontSize: '14px'}}>( {discount ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(discount)}` : '0.00'} )</p> 
                        </div>

                        <div className='d-flex justify-content-between'>
                            <p className='lh-sm fw-bold' style={{fontSize: '16px'}}>Total:</p>
                            <p className='lh-sm fw-bold' style={{fontSize: '16px'}}>{items?.length > 0 ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(subTotal - discount)}` : '0.00'}</p> 
                        </div>

                        {order?.payment?._id === '64952f578188d2c8b9c26603' && <div className='d-flex justify-content-between'>
                            <p className='lh-sm' style={{fontSize: '12px'}}>Cash Received:</p>
                            <p className='lh-sm' style={{fontSize: '12px'}}>{order?.received ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(order?.received)}` : '0.00'}</p> 
                        </div>}

                        {order?.payment?._id === '64952f578188d2c8b9c26603' && <div className='d-flex justify-content-between'>
                            <p className='lh-sm' style={{fontSize: '12px'}}>Balance:</p>
                            <p className='lh-sm' style={{fontSize: '12px'}}>{order?.received && order?.total ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(order?.received - order?.total)}` : '0.00'}</p> 
                        </div>}
                    </div>
                </div>
            </div>
        </div>

        <h6 className='text-center p-1'>Thanks, Come Again</h6>
        <Divider>*** END ***</Divider>
      </div>
    </div>
  )
}
