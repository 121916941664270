import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-hot-toast';

import { useGdate } from '../../context/date'

import { GeneralNo, unit, currency } from '../../Functions/NoFomats';

import { Text } from '../../Components/Inputs/InputFields';

import {DeleteForever, Check} from '@mui/icons-material/'

import MaterialTable from '../../Components/Table/MaterialTable'
import ModalBasic from '../../Components/Modal/ModalBasic'
import LoadingButton from '@mui/lab/LoadingButton'

export default function EmpAccountsSummary() {
    const {id}  = useParams();
    const [searchParams] = useSearchParams()

    const [gdate] = useGdate()
    const navigate = useNavigate();
    
    const [totalAccounts, setTotalAccounts] = useState()
    const [fuelUpDetails, setFuelUpDetails] = useState('')

    const [employee] = useState(id)
    const [date] = useState(gdate?.date)
    const [cash, setCash] = useState('0')
    const [bank, setBank] = useState('0')
    const [bill, setBill] = useState('0')
    const [drawings, setDrawings] = useState('0')
    const [expenses, setExpenses] = useState('0')
    const [salary, setSalary] = useState('0')

    const [allEntires, setAllEntires] = useState('')

    const [type, setType] = useState('')
    const [missExpenses, setMissExpenses] = useState(0)

    const [rowData, setRowData] = useState('')

    const [visiblePumpModel, setVisiblePumpModel] = useState(false)
    const [selectedDeletePump, setSelectedDeletePump] = useState(null)
    const [disableDayPumpSubmit, setDisableDayPumpSubmit] = useState(false)

    const [visibleDayEntryModel, setVisibleDayEntryModel] = useState(false)
    const [selectedDayEntry, setSelectedDayEntry] = useState(null)
    const [disableDayEntrySubmit, setDisableDayEntrySubmit] = useState(false)

    const [subtn, setSubtn] = useState(true)
    const [exists, setExists] = useState('')

    //Load Day Pump & Load Account Data
    useEffect(() => {
        loadDayPump()
        loadAllEntries()
        loadAccountsData()
    },[])

    //Update on Date Effect
    useEffect(() => {
        loadDayPump()
        loadAccountsData()
        loadDayEmployee()
        loadfuelUpFilter()
    }, [date])

    //Calculate Total Accounts related to Employee
    useEffect(() => {
        setTotalAccounts(Number(cash) + Number(bank) + Number(bill) + Number(drawings) + Number(expenses) + Number(salary) + Number(missExpenses))
    }, [cash, bank, bill, drawings, expenses, salary, missExpenses])

    //Submit Status Check
    useEffect(() => {
        rowData.length > 0 && (((rowData?.reduce((a,v) =>  a = a + v.inCome , 0 )?.toFixed(2)) === totalAccounts?.toFixed(2)) ? setSubtn(false) : setSubtn(true) )
    }, [rowData, totalAccounts])

    //Check MissExpense account Type Credit / Debit
    useEffect(() => {
        setType(() => 
        missExpenses >= 0 ? "credit" : "debit"
      )
    },[missExpenses])

    const loadDayEmployee = async() => {
        try {
            const {data} = await Axios.post(`/dayemployees/filter`, {employee, date})
            if(data?.error) {
                toast.error(data.error)
            } else {
                setExists(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const loadAccountsData = async() => {
        try {
            const {data} = await Axios.post(`/accdatas/filteremployeeaccount`, {employee, date})
            if(data?.error){
                toast.log(data.error)
            } else {
                await setCash(data.find((i) => i._id.accounting === '64952f578188d2c8b9c26603') ? `${data.find((i) => i._id.accounting === '64952f578188d2c8b9c26603').total?.toFixed(2)}` : '0.00' )
                await setBank(data.find((i) => i._id.accounting === '63e31a93f029ceab39dcd281') ? `${data.find((i) => i._id.accounting === '63e31a93f029ceab39dcd281').total?.toFixed(2)}` : '0.00' )
                await setBill(data.find((i) => i._id.accounting === '63e31abdf029ceab39dcd283') ? `${data.find((i) => i._id.accounting === '63e31abdf029ceab39dcd283').total?.toFixed(2)}` : '0.00' )
                await setDrawings(data.find((i) => i._id.accounting === '63e31af4f029ceab39dcd28f') ? `${(-data.find((i) => i._id.accounting === '63e31af4f029ceab39dcd28f').total)?.toFixed(2)}` : '0.00' )
                await setExpenses(data.find((i) => i._id.accounting === '63e31b29f029ceab39dcd291') ? `${data.find((i) => i._id.accounting === '63e31b29f029ceab39dcd291').total?.toFixed(2)}` : '0.00' )
                await setSalary(data.find((i) => i._id.accounting === '64cb525518dca625f12ce5bf') ? `${data.find((i) => i._id.accounting === '64cb525518dca625f12ce5bf').total?.toFixed(2)}` : '0.00' )
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const loadAllEntries = async() => {
        try {
            const {data} = await Axios.post(`/accdatas`, {"empStatus.status": true, "empStatus.employee": employee, date})
            if(data?.error){
                toast.log(data.error)
            } else {
                setAllEntires(data?.filter((i) => i?.accounting?._id !== '63eb82f1661aeb9f06368651'))
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const loadfuelUpFilter = async() => {
        try {
         const {data} = await Axios.post(`/fillupsfilterbyemployee`, {date})
         if (data?.error) {
            toast.error(data.error)
         } else {
            setFuelUpDetails(data.find((i) => i?._id?.employee === id) ? data.find((i) => i?._id?.employee === id) : '')
         }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")    
        }
    }

    const loadDayPump = async() => {
        try {
            const {data} = await Axios.post(`/daypumps/byemployee`, {employee, date})
            if (data?.error){
                toast.error(data.error)
            } else {
                setRowData(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const checkMissExpense = (missExpense) => {
        if (missExpense !== 0) {
            return ([
                {date, type : type, empStatus: {status: true, employee}, detail:{description: "Miscellaneous Expenses"}, amount: Number(missExpense).toFixed(2) , accounting: "63e31b29f029ceab39dcd291"},
                {date, type : "credit", empStatus: {status: true, employee}, detail:{description: "Fuel Day Sales By Employee"}, amount: Number(totalAccounts).toFixed(2) , accounting: "63eb82f1661aeb9f06368651"}
            ])
        } else {
            return ([
                {date, type : "credit", empStatus: {status: true, employee}, detail:{description: "Fuel Day Sales By Employee"}, amount: Number(totalAccounts).toFixed(2) , accounting: "63eb82f1661aeb9f06368651"}
            ])
        }
    }

    const handleDeleteDayPump = async(selected) => {
        try {
            await setDisableDayPumpSubmit(true)
            const {data} = await Axios.put(`/warehousestock/${selected?.pump?.wareHouseStock?._id}`, {
                $inc: { 'stock':  +(Number(selected?.soldQuantity) + Number(selected?.expenseL) + Number(selected?.drawingsL) +  Number(selected?.testRefill === true ? 0 : selected?.testing)), 'sold': -(Number(selected?.soldQuantity) + Number(selected?.expenseL) + Number(selected?.drawingsL) +  Number(selected?.testRefill === true ? 0 : selected?.testing))}
            });
            if(data?.error){
              toast.error(data.error)
              setDisableDayPumpSubmit(false)
            } else {
                const {data} = await Axios.delete(`/daypump/${selected._id}`)
                if(data?.error) {
                    toast.error(data.error)
                    setDisableDayPumpSubmit(false)
                } else if(selected?.expenseL || selected?.drawingsL) {
                    const {data} = await Axios.post(`/accdatas/removemany`, {"refId": `dp-${selected?._id}-${selected?.date}-${id}` , "empStatus.status": false, date: selected?.date})
                    if (data?.error) {
                        toast.error(data?.error)
                    } else {
                        toast.success("Day Pump & Expenses Deleted")
                        window.location.reload()
                    }
                } else {
                    toast.success("Day Pump Deleted")
                    window.location.reload()
                }
            }
          } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
          }
    }

    const handleDeleteDayEntry = async(selected) => {
        try {
            await setDisableDayEntrySubmit(true)
            const {data} = await Axios.post(`/accdatas/removemany`, {_id: selected?._id})
            if(data?.error){
              toast.error(data.error)
              setDisableDayEntrySubmit(false)
            } else {
                toast.success("Day Entry Record Record Deleted")
                window.location.reload()
            }
          } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
          }
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        try {
            await setSubtn(true)
            const {data} = await Axios.post(`/accdataBulk`, checkMissExpense(missExpenses))
            if (data?.error) {
                toast.error(data?.error)
                setSubtn(false)
            } else {
                const {data} = await Axios.post(`/dayemployee`, {employee, date, pumpList: rowData, cash, bank, bill, drawings, expenses: String((Number(expenses) + Number(missExpenses)).toFixed(2)), salary})
                if (data?.error) {
                    toast.error(data.error)
                    setSubtn(false)
                } else {
                    toast.success("Employee Day Accounts Saved")
                    navigate('/dashboard/user/employee/card')
                }
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const columnDayPump = [
        {accessorKey: 'pump.name', header: 'Name', size: 100},
        {accessorKey: 'startReading', header: 'Start Reading', size: 100, Cell: ({row: {original}}) => GeneralNo(original.startReading)},
        {accessorKey: 'endReading', header: 'End Reading', size: 100, Cell: ({row: {original}}) => GeneralNo(original.endReading)},
        {accessorKey: 'soldQuantity', header: 'Sold L', size: 100, Cell: ({row: {original}}) => unit(original.soldQuantity, 'L')},
        {accessorKey: 'testing', header: 'Test', size: 100, Cell: ({row: {original}}) => unit(original.testing, 'L')},
        {accessorKey: 'other', header: 'Other', size: 100, Cell: ({row: {original}}) => unit((original.expenseL + original.drawingsL), 'L')},
        {accessorKey: 'inCome', header: 'In Come', size: 100, Cell: ({row: {original}}) => currency(original.inCome)},
    ]

    const rowActionDayPump = ({row}) => [
        <div className='d-flex justify-content-center'>
            {exists?.length > 0 ? <Check color='success'/>
            :
            <DeleteForever
                onClick={() => {
                setVisiblePumpModel(true)
                setSelectedDeletePump(row.original)
                }}
            />}
        </div>
    ]

    const columnEntries = [
        {accessorKey: 'accounting.name', header: 'Accounts', size: 100, Cell: ({row: {original}}) => original.accounting?.name?.toUpperCase()},
        {accessorKey: 'detail', header: 'Detail', size: 100, Cell: ({row: {original}}) => {
            switch (original.accounting?._id) {
                case '63e31abdf029ceab39dcd283':
                    return original.detail?.customer?.name
            case '63e31a93f029ceab39dcd281':
                return (original.detail?.bank?.bank + ' - ' + original.detail?.bank?.accountName)
            default:
                return ''
                break;
            }}
        },
        {accessorKey: 'detail.description', header: 'Description'},
        {accessorKey: 'amount', header: 'Amount', size: 100, Cell: ({row: {original}}) => currency(original.amount)},
    ]

    const rowActionEntries = ({row}) => [
        <div className='d-flex justify-content-center'>
            {exists?.length > 0 ? <Check color='success'/>
            :
            <DeleteForever fontSize='large' className='p-2 shadow rounded-circle'
                onClick={() => {
                setVisibleDayEntryModel(true)
                setSelectedDayEntry(row.original)
                }}
            />}
        </div>
    ]

  return (
    <div className='p-4'>

        <div className='row'>
            <dd className='text-center'>{date} - {searchParams.get('name')}</dd>
            {/* Top Level 1st Column */}
            <div className='col-lg-8 col-12'>

                {/* Accounts Details */}
                <div className='row'>
                    {/* 1st Column */}
                    <div className='col-xl-3 col-sm-6 col-12'>
                        <Text
                            label="Cash" 
                            value = {cash}
                            readOnly = "true"
                            end = "LKR"
                        />
                        <Text
                            label="Bank" 
                            value = {bank}
                            readOnly = "true"
                            end = 'LKR'
                        />
                    </div>
                    {/* 2nd Column */}
                    <div className='col-xl-3 col-sm-6 col-12'>
                        <Text
                            label="Debts / Bill" 
                            value = {bill}
                            readOnly = "true"
                            end = "LKR"
                        />
                        <Text
                            label="Drawings" 
                            value = {drawings}
                            readOnly = "true"
                            end = 'LKR'
                        />
                    </div>

                    {/* 3rd Column */}
                    <div className='col-xl-3 col-sm-6 col-12'>
                        <Text
                            label="Salary" 
                            value = {salary}
                            readOnly = 'true'
                            end = "LKR"
                        />
                        <Text
                            label="Expenses" 
                            value = {expenses}
                            readOnly = 'true'
                            end = "LKR"
                        />
                    </div>

                    {/* 4th Column */}
                    <div className='col-xl-3 col-sm-6 col-12'>
                        <Text
                            label="Miscellaneous Expenses" 
                            value = {missExpenses}
                            type = "number"
                            onChange={(e) => setMissExpenses(e.target.value)}
                            end = "LKR"
                        />
                    </div>

                {/* 3rd Row Pump Details Table*/}
                <div className='col-12 mt-2'>
                    <MaterialTable
                        title = 'Day Pump List'
                        col = {columnDayPump}
                        row = {rowData}
                        rowAction = {rowActionDayPump}
                        exportDisable = {true}
                    />
                </div>

                {/* 4th Row All Enties Details Table*/}
                <div className='col-12 mt-2'>
                    <MaterialTable
                        title = 'All Entires'
                        col = {columnEntries}
                        row = {allEntires}
                        rowAction = {rowActionEntries}
                        exportDisable = {true}
                    />
                </div>

            </div>

            </div>

            {/* Top Level 2nd Column */}
            <div className='col-lg-4 col-12' style={{minHeight : "100%"}}>
                <div className='border p-3'>
                    <div className='d-flex justify-content-between'>
                        <dd>Daily Sales : </dd>
                        <dd>{new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(rowData ? rowData?.reduce((a,v) =>  a = a + v.inCome , 0 ) : 0)}</dd>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <dd>Fill Up Amount : </dd>
                        <dd>{new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(fuelUpDetails ? fuelUpDetails?.amount : 0)}</dd>
                    </div>
                    <div className='d-flex justify-content-between fw-bold'>
                        <dd>Sales Differance : </dd>
                        <dd style={ rowData && fuelUpDetails && (rowData?.reduce((a,v) =>  a = a + v.inCome , 0 ) - fuelUpDetails?.amount) >= 0 ? {color: 'greenyellow'} : {color: 'red'}}>{new Intl.NumberFormat().format(fuelUpDetails && rowData ? (rowData?.reduce((a,v) =>  a = a + v.inCome , 0 ) - fuelUpDetails?.amount) : 0)}</dd>
                    </div>
                    <hr></hr>
                    <div className='d-flex justify-content-between'>
                        <dd>Total Points : </dd>
                        <dd>{fuelUpDetails ? new Intl.NumberFormat().format(fuelUpDetails?.points) : `0.00`}</dd>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <dd>Total Redeem : </dd>
                        <dd>{fuelUpDetails ? new Intl.NumberFormat().format(fuelUpDetails?.redeem): '0.00'}</dd>
                    </div>

                </div>

                <div className='row justify-content-center text-center mt-3'>
                    <h6 className='row justify-content-center text-center'>Pump List Income</h6>
                    <h6 className='row justify-content-center fw-bold'>{new Intl.NumberFormat().format(rowData ? rowData?.reduce((a,v) =>  a = a + v.inCome , 0 ) : 0)}</h6>
                </div>

                <div className='row justify-content-center text-center'>
                    <h6 className='row justify-content-center text-center'>Accounts Deposits</h6>
                    <h6 className='row justify-content-center fw-bold'>{new Intl.NumberFormat().format(totalAccounts ? totalAccounts : 0)}</h6>
                </div>

                <div>
                    {rowData && <h6 className='row justify-content-center text-center' style={(rowData?.reduce((a,v) =>  a = a + v.inCome , 0 ).toFixed(2) === totalAccounts.toFixed(2)) ? {color: 'greenyellow'} : {color: 'red'} }>Accounts Differance: <br/> {new Intl.NumberFormat().format((rowData?.reduce((a,v) =>  a = a + v.inCome , 0 ).toFixed(2) - totalAccounts.toFixed(2)))}</h6>}
                </div>

                {/* Submit Button */}
                <div className='p-2 d-grid'>
                    {exists?.length > 0 ? <LoadingButton variant="contained" disabled> <span>Already Saved</span> </LoadingButton> : <LoadingButton onClick={handleSubmit} loading={subtn} variant="contained"> <span>SUBMIT</span> </LoadingButton>}
                </div>
            </div>

            {/* Model for delete Day pump */}
            <ModalBasic
                open = {visiblePumpModel}
                onClose = {()=> {
                setVisiblePumpModel(false)
                }}
                content = {
                    <div>
                        <p>Do you want to Delete Pump: {selectedDeletePump?.pump?.name}</p>
                        <div className='d-flex justify-content-end'>
                            <button className='btn btn-outline-danger' disabled = {disableDayPumpSubmit} onClick={() => {
                                handleDeleteDayPump(selectedDeletePump)
                                }}>Delete
                            </button>
                        </div>

                    </div>     
                }
            />

            {/* Model for delete Day Entries */}
            <ModalBasic
                open = {visibleDayEntryModel}
                onClose = {()=> {
                setVisibleDayEntryModel(false)
                }}
                content = {
                    <div>
                        <p>Do you want to Delete Accounts: {selectedDayEntry?.accounting?.name?.toUpperCase()} <br/> Amount: {new Intl.NumberFormat().format(selectedDayEntry?.amount)}</p>
                        <div className='d-flex justify-content-end'>
                            <button className='btn btn-outline-danger' disabled = {disableDayEntrySubmit} onClick={() => {
                                handleDeleteDayEntry(selectedDayEntry)
                                }}>Delete
                            </button>
                        </div>

                    </div>     
                }
            />

        </div>
    </div>
  )
}
