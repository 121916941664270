import React from 'react'
import {Routes, Route} from "react-router-dom"
import { Layout } from 'antd'

import { useDk } from '../context/dk'

//Roles Permission Access Levels
import PrivateRoute from './PrivateRoute'
import UserRoute from './UserRoute'
import AdminRoute from './AdminRoute'

//All Level Access
import Start from '../Pages/Start'
import LoginPage from '../Pages/Auth/Login' //No SB Option
import Developer from '../Pages/Developer' //No SB Option
import PageNotFound from '../Pages/PageNotFound' //No SB Option
import EReceipt from '../Pages/Inventory/Sales/Receipts/EReceipt'

//EMPLOYEE Level Access
import Home from '../Pages/Home'
import Profile from '../Pages/Employee/Profile' //No SB Option

import PumpsCard from '../Pages/Pump/PumpsCard'

import ListFuelUps from '../Pages/FillUps/FuelUps/ListFuelUps'
//POS
import SalesForm from '../Pages/Inventory/Sales/SalesForm'
import ReturnsForm from '../Pages/Inventory/Sales/ReturnsForm'


//USER Level Access
import Dashboard from '../Pages/Dashboard/Dashboard'
import QuickPanel from '../Pages/Dashboard/QuickPanel/QuickPanel'
import GasDashboard from '../Pages/GasDashboard'

import EmployeeCard from '../Pages/Employee/EmployeeCard'
import EmpAccForm from '../Pages/Accounts/EmpAccForm' //No SB Option
import EmpAccountsSummary from '../Pages/Employee/EmpAccountsSummary' //No SB Option
import ListEmployee from '../Pages/Employee/ListEmployee'
import ListDayEmployee from '../Pages/Employee/ListDayEmployee'
import Attendance from '../Pages/Employee/Attendances/Attendance'
import EmpAttendanceDetails from '../Pages/Employee/Attendances/EmpAttendanceDetails' //No SB Option

import ListCustomers from '../Pages/Customer/ListCustomers'
import CustomerOrders from '../Pages/Customer/GovOrders/CustomerOrders'
import ListGovOrders from '../Pages/Customer/GovOrders/ListGovOrder'

import TankCard from '../Pages/Tank/TankCard'
import TankStockForm from '../Pages/Tank/StockUpdateForm' //No SB Option
import FindDepth from '../Pages/Tank/Depths/DepCheck'
import AllLoads from '../Pages/Tank/AllLoads'
import AllDepths from '../Pages/Tank/Depths/AllDepths'
import ListDep from '../Pages/Tank/Depths/ListDep' //No SB Option
import ListTankCheck from '../Pages/Tank/TankChecks/ListTankCheck' //No SB Option
import TankCheck from '../Pages/Tank/TankChecks/TankCheck' //No SB Option

import ListDayPump from '../Pages/Pump/ListdayPump'
import FuelSales from '../Pages/Pump/FuelSales'

import PointsCustomers from '../Pages/FillUps/PointsCustomer/ListPointsCustomers'
import ListPCVehicles from '../Pages/FillUps/Vehicles/ListVehicles'
//POS
import ListOrders from '../Pages/Inventory/Sales/Orders/ListOrders'
import ListOrderReturns from '../Pages/Inventory/Sales/OrderReturns/ListOrderReturns'
import PurchaseForm from '../Pages/Inventory/Purchase/PurchaseForm'
import ListPurchase from '../Pages/Inventory/Purchase/Purchases/ListPurchase'
import PurchaseDetails from '../Pages/Inventory/Purchase/Purchases/PurchaseDetails' //No SB Option
import PurchaseReturnForm from '../Pages/Inventory/Purchase/PurchaseReturnForm'
import ListPurchaseReturns from '../Pages/Inventory/Purchase/PurchaseReturns/ListPurchaseReturns'
import PurchaseReturnDetails from '../Pages/Inventory/Purchase/PurchaseReturns/PurchaseReturnDetails' //No SB Option
import ListStock from '../Pages/Inventory/ListStock'

import ListEmployeeAccounts from '../Pages/Employee/ListEmployeeAccounts' //No SB Option
import ListCustomerAccounts from '../Pages/Customer/ListCustomerAccounts' //No SB Option
import AccountsDetails from '../Pages/Accounts/DoubleEntires/AccountsDetails' //No SB Option
import AccountsForm from '../Pages/Accounts/DoubleEntires/AccountsForm'
import AllDoubleEntries from '../Pages/Accounts/DoubleEntires/AllDoubleEntries' //No SB Option
import DailyCashFlow from '../Pages/Accounts/CashFlow/DailyCashFlow'
import ListBankAccounts from '../Pages/Accounts/Bank/ListBankAccounts'
import BankAccountsDetails from '../Pages/Accounts/Bank/BankAccountsDetails' //No SB Option
import ListCheques from '../Pages/Accounts/Cheques/ListCheques'
import IncomeStatementDetail from '../Pages/Accounts/FinancialYear/IncomeStatements/IncomeStatementDetail' //No SB Option
import FinancialStatementDetail from '../Pages/Accounts/FinancialYear/FinancialStatements/FinancialStatementDetail' //No SB Option


//ADMIN Level Access
import ListSuppliers from '../Pages/Supplier/ListSuppliers'

import ListTank from '../Pages/Tank/ListTank'
import ListFuelType from '../Pages/Tank/FuelType/ListFuelType'

import ListBooth from '../Pages/Booth/ListBooth'

import ListPump from '../Pages/Pump/ListPump'

import ListPointsCategories from '../Pages/FillUps/PointsCategory/ListPointsCategories'
//POS
import ListBrands from '../Pages/Inventory/Brand/ListBrand' //No SB Option
import ListProducts from '../Pages/Inventory/ListProducts'
import ListStockAdjustment from '../Pages/Inventory/StockAdjustment/ListStockAdjustment'
import WareHouse from '../Pages/Inventory/WareHouse/WareHouse'

import ListSupplierAccounts from '../Pages/Supplier/ListSupplierAccounts' //No SB Option
import ListAccounts from '../Pages/Accounts/ListAccounts'

import Settings from '../Pages/Settings'

export default function RoutesPage() {
  const [dk] = useDk()
  const {Content} = Layout;
      
  return (
    <Content
      style={{
        height: '100%',
      }}
    >
      <div style={{padding: 24, height: '100%', color: (dk.darkmode ? 'white' : '#121212')}}>
        <Routes>
            <Route path="/" element={<Start/>}/>
            <Route path="/login" element={<LoginPage/>}/>
            <Route path="/developer" element={<Developer/>}/>
            <Route path="/e-receipt/:id" element={<EReceipt/>}/>
            <Route path="*" element={<PageNotFound/>} replace/>

            {/* EMPLOYEE ACCESS */}
            <Route path="/dashboard" element={<PrivateRoute/>}>
              <Route path="profile" element={<Profile/>}/>
              <Route path="" element={<Home/>}/>

              <Route path="pump/card" element={<PumpsCard/>}/>

              <Route path="fillup/fill" element={<ListFuelUps/>}/>

              <Route path="inventory/sale" element={<SalesForm/>}/>
              <Route path="inventory/sale-return" element={<ReturnsForm/>}/>
            </Route>

            {/* USER ACCESS  */}
            <Route path="/dashboard/user" element={<UserRoute/>}>
              <Route path="" element={<Dashboard/>}/>
              <Route path="quick-panel" element={<QuickPanel/>}/>
              <Route path="gas-dashboard" element={<GasDashboard/>}/>


              <Route path="employee/card" element={<EmployeeCard/>}/>
              <Route path="employee/day-entry/:id" element={<EmpAccForm/>}/>
              <Route path="employee/:id" element={<EmpAccountsSummary/>}/>
              <Route path="employee/list" element={<ListEmployee/>}/>
              <Route path="employee/listdayemployee" element={<ListDayEmployee/>}/>
              <Route path="employee/attendance" element={<Attendance/>}/>
              <Route path="employee/attendance/:id" element={<EmpAttendanceDetails/>}/>

              <Route path="customer/list" element={<ListCustomers/>}/>
              <Route path="customer-orders/:id" element={<CustomerOrders/>}/>
              <Route path="customers/list-govorders" element={<ListGovOrders/>}/>

              <Route path="supplier/list" element={<ListSuppliers/>}/>

              <Route path="tank/card" element={<TankCard/>}/>
              <Route path="tank/purchase/:id" element={<TankStockForm/>}/>
              <Route path="tank/find-depth" element={<FindDepth/>}/>
              <Route path="tank/all-loads" element={<AllLoads/>}/>
              <Route path="tank/all-depths" element={<AllDepths/>}/>
              <Route path="tank/depthlist/:id" element={<ListDep/>}/>
              <Route path="tank/List-tank-check/:id" element={<ListTankCheck/>}/>
              <Route path="tank/tank-check/:id" element={<TankCheck/>}/>

              <Route path="pump/dayPump" element={<ListDayPump/>}/>
              <Route path="pump/fuel-sales" element={<FuelSales/>}/>

              <Route path="fillup/pointscustomer" element={<PointsCustomers/>}/>
              <Route path="fillup/vehicles" element={<ListPCVehicles/>}/>

              <Route path="inventory/sales" element={<ListOrders/>}/>
              <Route path="inventory/sale-returns" element={<ListOrderReturns/>}/>
              <Route path="inventory/purchase" element={<PurchaseForm/>}/>
              <Route path="inventory/purchases" element={<ListPurchase/>}/>
              <Route path="inventory/purchase-report/:id" element={<PurchaseDetails/>}/>
              <Route path="inventory/purchase-return" element={<PurchaseReturnForm/>}/>
              <Route path="inventory/purchase-returns" element={<ListPurchaseReturns/>}/>
              <Route path="inventory/purchase-return-report/:id" element={<PurchaseReturnDetails/>}/>
              <Route path="inventory/stocks" element={<ListStock/>}/>
              <Route path="inventory/stock-adjustments" element={<ListStockAdjustment/>}/>

              <Route path="employee-accounts/:id" element={<ListEmployeeAccounts/>}/>
              <Route path="customer-accounts/:id" element={<ListCustomerAccounts/>}/>
              <Route path="supplier-accounts/:id" element={<ListSupplierAccounts/>}/>
              <Route path="accounts/:id" element={<AccountsDetails/>}/>
              <Route path="accounts/entry" element={<AccountsForm/>}/>
              <Route path="accounts/alldoubleentries" element={<AllDoubleEntries/>}/>
              <Route path="accounts/daily-cashflow" element={<DailyCashFlow/>}/>
              <Route path="accounts/bank-accounts" element={<ListBankAccounts/>}/>
              <Route path="bank-accounts/:id" element={<BankAccountsDetails/>}/>
              <Route path="accounts/cheques" element={<ListCheques/>}/>
              <Route path="accounts/incomeStatement-Detail" element={<IncomeStatementDetail/>}/>
              <Route path="accounts/financialStatement-Detail" element={<FinancialStatementDetail/>}/>

            </Route>

            {/* ADMIN ACCESS  */}
            <Route path="/dashboard/user/admin" element={<AdminRoute/>}>

              <Route path="tank/list" element={<ListTank/>}/>
              <Route path="fueltype" element={<ListFuelType/>}/>

              <Route path="booth/list" element={<ListBooth/>}/>
              <Route path="pump/list" element={<ListPump/>}/>
              
              <Route path="inventory/list" element={<ListProducts/>}/>
              <Route path="inventory/brands" element={<ListBrands/>}/>
              <Route path="inventory/warehouse" element={<WareHouse/>}/>

              <Route path="fillup/pointsCategory" element={<ListPointsCategories/>}/>

              <Route path="accounts/list" element={<ListAccounts/>}/>

              <Route path="settings" element={<Settings/>}/>
            </Route>
        </Routes>
      </div>
    </Content>
  )
}
