import React, {useState} from 'react'
import{ Modal, Box } from '@mui/material/';
import {useDk} from '../../context/dk'

export default function ModalBasic(props) {
  const [dk] = useDk();
  
  return (
    <div>
        <Modal
        open={props.open}
        onClose={props.onClose}
        >
          <Box sx={{
            borderRadius: '10px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: props.width || 400,
            maxHeight: window.screen.height * 0.8,
            // bgcolor: 'background.paper',
            boxShadow: '10',
            backgroundImage: `${dk.darkmode ? 'linear-gradient(122deg, rgba(70,70,50,0.7) 0%, rgba(50,70,80,0.5) 70%, rgba(70,40,70,0.7) 100%)' : 'linear-gradient(122deg, rgba(150,150,120,0.7) 0%, rgba(200,180,190,0.75) 60%, rgba(170,100,170,0.4) 100%)'}`, 
            backdropFilter: 'blur(8px)',
            p: 3,
            overflowY: 'auto'
            }}
          >
            {props.content}
          </Box>
        </Modal>
    </div>
  )
}



