import React, {useState, useEffect} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import { useAuth } from '../../context/auth';

import {Text} from '../../Components/Inputs/InputFields'

export default function Profile() {

    const [auth] = useAuth();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [nic, setNic] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('')
    const [comparePassword, setComparePassword] = useState('')

    const [submitDisable, setSubmitDisable] = useState(false)


    useEffect(() => {
        loadEmpData()
    }, [auth?.employee])

    const loadEmpData = async() => {
        try {
            const {data} = await Axios.post(`/employee/${auth?.employee?._id}`)
            if (data?.error) {
                toast.error(data.error)
            } else {
                setName(data?.name)
                setEmail(data?.email)
                setAddress(data?.address)
                setNic(data?.nic)
                setPhoneNumber(data?.phoneNumber)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setSubmitDisable(true)
            if (password.trim() && password === comparePassword) {
                const {data} = await Axios.put(`/employee/${auth?.employee?._id}`, {name, email, address, nic, phoneNumber, password});
                if(data?.error) {
                    toast.error(data.error);
                    setSubmitDisable(false)
                } else {
                    toast.success("Employee sucessfully Updated");
                    window.location.reload()
                }
            } else {
                const {data} = await Axios.put(`/employee/${auth?.employee?._id}`, {name, email, address, nic, phoneNumber});
                if(data?.error) {
                    toast.error(data.error);
                    setSubmitDisable(false)
                } else {
                    toast.success("Employee sucessfully Updated");
                    window.location.reload()
                }
            }
        } catch (err) {
        toast.error("Employee Update Failed Try Again");
        
        }
    }

  return (
    <div className='row justify-content-center'>
        <div className='col-lg-4 col-md-6 col-sm-12 shadow p-3' style={{borderRadius: '5px', backdropFilter: 'blur(10px)'}}>
            <h3 className='col text-center'>My Profile</h3>
            <div className='container mt-3'>
                <form onSubmit={handleSubmit}>
                    <Text
                        label="Name"
                        value = {name}
                        onChange={(e) => setName(e.target.value)} 
                    />
                    <Text
                        label="Email"
                        type="email"
                        value = {email}
                        onChange={(e) => setEmail(e.target.value)} 
                    />

                    <Text
                        label="Address"
                        value = {address}
                        onChange={(e) => setAddress(e.target.value)} 
                    />
                    <Text
                        label="NIC Number"
                        type = "Number"
                        value = {nic}
                        onChange={(e) => setNic(e.target.value)} 
                    />
                    <Text
                        label="Phone Number"
                        type ="Number"
                        value = {phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)} 
                    />
                    
                    <Text
                        label="Password"
                        type= "password"
                        value = {password}
                        onChange={(e) => setPassword(e.target.value)} 
                    />

                    <Text
                        label="Confirm Password"
                        type = "password"
                        value = {comparePassword}
                        onChange={(e) => setComparePassword(e.target.value)} 
                    />
                    <div class="d-grid gap-2">
                        <button className = 'btn btn-primary' type = 'submit' disabled= {submitDisable}>
                            UPDATE
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  )
}
