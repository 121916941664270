import React, {useState,useEffect} from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useSettings } from '../../context/settings'
import { useGdate } from '../../context/date'

import {currency, unit} from '../../Functions/NoFomats'

import {Edit, Visibility} from '@mui/icons-material/'

import MaterialTable from '../../Components/Table/MaterialTable'
import ModalBasic from '../../Components/Modal/ModalBasic'

import UpdateCustomerAccounts from './UpdateCustomerAccounts'

import SalesReceipt from '../Inventory/Sales/Receipts/SalesReceipt';
import SalesReceiptV2 from '../Inventory/Sales/Receipts/SalesReceiptV2';

export default function ListCustomerAccounts() {
    const {id} = useParams();
    const [searchParams] = useSearchParams()

    const [settings] = useSettings()
    const [gdate] = useGdate()

    const [allData, setAllData] = useState('')
    const [listAccData, setListAccData] = useState('')

    const [items, setItems] = useState('')

    //Model
    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState(null)

    const [visibleDetails, setVisibleDetails] = useState(false)
    const [selectedDetails, setSelectedDetails] = useState('')

    useEffect(() => {
        loadAccData()
      },[id])

    useEffect(() => {
        allData && filterDataByDate()
    },[allData, gdate?.reportStartDate, gdate?.reportEndDate])

    const govCustomers  = searchParams.get('type') === 'government' ?
    [
        { accessorKey: 'detail.govOrder.govOrder', header: 'Gov Order', size: 100},
    ]: []

    const loadAccData = async() => {
        try {
            const {data} = await Axios.post(`/accdatas`, {accounting: "63e31abdf029ceab39dcd283", "detail.customer": id})
            if(data?.error){
                toast.error(data.error)
            } else {
                var effects = 0
                await setAllData(data?.map((i) => {
                    effects += i?.amount
                    var debit = ''
                    var credit = ''
                    i.amount > 0 ? debit = i?.amount : credit = i?.amount
                    return {...i, debit, credit, effects: effects}
                }))
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const filterDataByDate = async() => {
        try {
            setListAccData(allData?.filter((i) => i?.date >= gdate?.reportStartDate && i?.date <= gdate?.reportEndDate))
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const loadOrders = async(no) => {
        try {
            const {data} = await Axios.post('/orders', {invoiceNo: no})
            if(data?.error){
                toast.error(data.error)
            } else {
                setSelectedDetails(data[0])
                loadItems(data[0]?._id)
            }
        } catch (err) {
            toast.error("Something went wrong, Try Again!")
            console.log(err)
        }
    }
  
    const loadItems = async(id) => {
      try {
        const {data} = await Axios.post(`/productlines`, {order: id})
        if (data?.error) {
          toast.error(data.error)
        } else {
            await setItems(data)
            selectedDetails && items && setVisibleDetails(true)
        }
      } catch (err) {
        toast.error("Something went wrong, Try Again!")
        console.log(err)
      }
    }

    const columns = [
        { accessorKey: 'empStatus.employee.name', header: 'Employee', size: 100},
        { accessorKey: 'date', header: 'Date', size: 100},
        { accessorKey: 'detail.description', header: 'Description', size: 100},
        { accessorKey: 'detail.billNo', header: 'Bill No', size: 100},
        { accessorKey: 'detail.comment', header: 'Comment', size: 100},
        { accessorKey: 'detail.fuel.fuelType.fuelType', header: 'Fuel Type', size: 150},
        { accessorKey: 'detail.fuel.liter', header: 'Liters', size: 100, Cell: ({row: {original}}) => original.detail?.fuel?.liter && unit(original.detail?.fuel?.liter, 'L')},
        ...govCustomers,
        {accessorKey: 'debit', header: 'Debit', size: 100, Cell: ({row: {original}}) => original.debit && currency(original.debit, true), export: (i) => i && currency(i, true), exportHead: 'Debit',},
        {accessorKey: 'credit', header: 'Credit', size: 100, Cell: ({row: {original}}) => original.credit && currency(original.credit, true), export: (i) => i && currency(i, true), exportHead: 'Credit',},
        {accessorKey: 'effects', header: 'Effects', size: 100, Cell: ({row: {original}}) => currency(original.effects), export: (i) => currency(i)}
    ]

    const rowAction = ({row}) => [
        <div className='d-flex gap-2 justify-content-center'>
            {searchParams.get('type') === 'government' && row.original.amount > 0 && 
            <Edit
                onClick={() => {
                    setVisible(true)
                    setSelected(row.original)
                }}
            />}
            {row.original.detail?.invoiceNo && <Visibility
                onClick={() => {
                    loadOrders(row.original.detail?.invoiceNo)
                }}
            />}
        </div>
    ]
    
    return (
        <div className='row justify-content-center'>
            <div className='col-12 mb-3'>
                <dd>Total Cheques Given : {currency(searchParams.get('chequeTotal'))}</dd>
                <MaterialTable
                    title = {listAccData && 'Customer ' + searchParams.get('name')?.toUpperCase() + ' | ' + gdate?.reportStartDate + ' - ' + gdate?.reportEndDate}
                    col = {columns} 
                    row = {listAccData}
                    rowAction = {rowAction}
                />
            </div>

            <ModalBasic
                open = {visible}
                onClose = {()=> {
                    setVisible(false)
                }}
                content = {<UpdateCustomerAccounts data = {selected}/>}
            />

            <ModalBasic
                width = {settings.billReceipt === 'invoice' ? '1000px' : ''}
                open = {visibleDetails}
                onClose = {()=> {
                setVisibleDetails(false)
                }}
                content = {settings.billReceipt === 'invoice' ?
                    <SalesReceiptV2 order = {{invoiceNo: selectedDetails?.invoiceNo, selectedCustomer: selectedDetails?.customer, orderDate: selectedDetails?.orderDate, items, total: selectedDetails?.total, payment: selectedDetails?.payment?._id, received: selectedDetails?.received, printedDate: selectedDetails?.createdAt}}/>
                    :
                    <SalesReceipt order = {{invoiceNo: selectedDetails?.invoiceNo, selectedCustomer: selectedDetails?.customer, orderDate: selectedDetails?.orderDate, items, total: selectedDetails?.total, payment: selectedDetails?.payment?._id, received: selectedDetails?.received, printedDate: selectedDetails?.createdAt}}/>
                }
            />
        </div>
    )
}
