import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment'

import { useGdate } from '../../../context/date'

import { Text, SelectAutoComplete } from '../../../Components/Inputs/InputFields'

export default function StockManage() {

  const [gdate] = useGdate()

  const [listProductCodes, setListProductCodes] = useState()
  const [listStock, setListStock] = useState()
  const [noAdjustment, setNoAdjustment] = useState('')

  const [type, setType] = useState('exchange')
  const [selectedProductCode, setSelectedProductCode] = useState()
  const [wareHouseStock, setWareHouseStock] = useState()
  const [productStock, setProductStock] = useState()
  const [updateStock, setUpdateStock] = useState('')

  //Mark Date should be morethan cashflow verified date
  const [checkCashflow, setCheckCashflow] = useState(false)

  const [date] = useState(gdate?.date)
  const [amount, setAmount] = useState('')

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    loadProductCodeList()
    setCheckCashflow(() => moment(gdate?.cashflowDate) > moment(date) ? true : false)
  },[])

  useEffect(() => {
    loadStocksList(selectedProductCode)
  },[selectedProductCode])

  useEffect(() => {
    setWareHouseStock()
    loadAdjustment()
  },[type])

  const loadProductCodeList = async() => {
    try {
      const {data} = await Axios.post('/productcodes')
      if (data?.error) {
        toast.error(data.error)
      } else {
        await setListProductCodes(data.filter((i) => i.product?.category?._id !== "63e65efc2f840202604fbb7f"))
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }


  const loadStocksList = async(selectedProductCode) => {
    try {
        const {data} = await Axios.post('/warehousestocks/filterbyproduct', {id : selectedProductCode?._id})
        if (data?.error) {
            toast.error(data.error)
        } else {
            await setListStock(data)
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  const loadAdjustment = async() => {
    try {
      const {data} = await Axios.post('/stock-adjustments/adjustment-no')
      if(data?.error){
        toast.error(data.error)
      } else {
        setNoAdjustment(data)
      }
    } catch (err) {
      toast.error("Something went wrong, Try Again!")
      console.log(err)
    }
  }


  const handleAdjustment = async(e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post(`/stock-adjustment`, {refNo: noAdjustment, type, date, wareHouseStock: productStock, eWareHouseStock: wareHouseStock, quantity: updateStock, amount})
      if (data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        if (type === 'exchange') {
          const {data} = await Axios.post(`/warehousestock/exchange`, [
            {_id: productStock._id, stock: Number(updateStock).toFixed(3)},
            {_id: wareHouseStock?._id, stock: Number(-updateStock).toFixed(3)}
          ]);
          if(data?.error) {
            toast.error(data.error)
            setSubmitDisable(false)
          } else {
            toast.success("Product Stock Exchanged")
            window.location.reload()
          }
        } else {
          const {data} = await Axios.post(`/accdatabulk`, [
            {date, empStatus: {status: false}, detail: {description: `Ref.No : ${noAdjustment } - Adjustment of ${updateStock} ${productStock?.productCode?.product?.unit}`}, amount : `${updateStock >= 0 ? Number(amount)?.toFixed(2) : Number(-amount)?.toFixed(2)}`, accounting: '63ecfbe55b36ecf83d9dd4a3', refId : `Stockadj-${date}-${noAdjustment}`},
            {date, empStatus: {status: false}, detail: {description: `Ref.No : ${noAdjustment} - Adjustment of ${updateStock} ${productStock?.productCode?.product?.unit}`}, amount : `${updateStock < 0 ? Number(amount)?.toFixed(2) : Number(-amount)?.toFixed(2)}`, accounting: '64eec83c972323a31bc16e3d', refId : `Stockadj-${date}-${noAdjustment}`}
          ])
          if (data?.error) {
            toast.error(data?.error)
            setSubmitDisable(false)
          } else {
            const {data} = await Axios.put(`/warehousestock/${productStock._id}`, { $inc: { 'stock': +(Number(updateStock).toFixed(3)) } })
            if(data?.error) {
              toast.error(data.error)
              setSubmitDisable(false)
            } else {
              toast.success("Product Stock Adjusted")
              window.location.reload()
            }
          }
        }
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  return (
    <div className='p-3'>
      <h5 className='text-center text-capitalize'>Stock Adjustment Form</h5>

      <form onSubmit={handleAdjustment}>
        <dd className='text-center'>{date}</dd>

        <SelectAutoComplete
          label = "Type"
          optionsList={['exchange', 'adjustment']}
          option = {(i) => i}
          value={type}
          onChange={(event, value) => {
              setType(value)
          }}
        />

        <SelectAutoComplete
          label = "Product Codes | Brand | Product"
          notFoundLabel = "Product Code"
          focus = {true}
          optionsList={listProductCodes}
          option = {(i) => `${i?.productCode + ' | '+ i.product?.brand?.name + ' ' + i.product?.name}`}
          value={selectedProductCode}
          onChange={(event, value) => {
              setSelectedProductCode(value)
          }}
        />

        {type === 'exchange' && 
        <SelectAutoComplete
          label = "From Warehouse"
          optionsList={listStock}
          option = {(i) => (i.wareHouse?.name + ' | ' + new Intl.NumberFormat().format(i?.stock) + ' ' + i.productCode?.product?.unit)}
          value={wareHouseStock}
          onChange={(event, value) => {
              setWareHouseStock(value)
          }}
        />

        }

        <SelectAutoComplete
          label = "To / Warehouse"
          optionsList={listStock}
          option = {(i) => (i.wareHouse?.name + ' | ' + new Intl.NumberFormat().format(i?.stock) + ' ' + i.productCode?.product?.unit )}
          value={productStock}
          onChange={(event, value) => {
              setProductStock(value)
          }}
        />


        {wareHouseStock && productStock && wareHouseStock?._id === productStock?._id ? <dd className='text-center text-danger'>You Selected Same Warehouse</dd> : ''}

        <Text
          label = {`Stock in ${productStock?.productCode?.product.unit ? productStock?.productCode?.product.unit : ''}`}
          type = "Number"
          value = {updateStock}
          onChange={(e) => {
            setUpdateStock(e.target.value)
            setAmount(Math.abs(productStock?.productCode?.cost * e.target.value)?.toFixed(2))
          }}
        />

        {type === 'adjustment' && <Text
          label = "Value"
          type = "Number"
          value = {amount}
          onChange={(e) => {
            setAmount(e.target.value)
          }}
          end = "LKR"
        />}

        <div className='d-flex justify-content-between p-2'>
          <p>Stock After Update</p>
          <p>{new Intl.NumberFormat().format((productStock?.stock + Number(updateStock))) + ' ' + productStock?.productCode?.product?.unit}</p>
        </div>

        <div className="d-grid gap-2">
            <button className='btn btn-primary mt-3' disabled={(wareHouseStock?._id !== productStock?._id && (type === 'exchange' ? (wareHouseStock?.stock >= updateStock ? true : false) : true) && updateStock && amount ? submitDisable : true) || checkCashflow}>{type === 'exchange' ? "EXCHANGE" : "ADJUSTMENT"}</button>
            {checkCashflow && <dd className='text-center fs-6 fw-bold text-danger mt-2'>Cashflow Verified on Selected date</dd>}
        </div>
      </form>
  </div>
  )
}
