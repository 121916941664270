import React, {useState} from 'react'
import { FormControl, InputLabel, MenuItem, Select, Autocomplete, Switch, TextField, InputAdornment, Stack, Typography } from '@mui/material/'

export function Text(props) {
    const InputProps = {
        readOnly: (props.readOnly === 'true' ? true : false),
        startAdornment: (props.start && <InputAdornment position="start">{props.start}</InputAdornment>),
        endAdornment: (props.end && <InputAdornment position="end">{props.end}</InputAdornment>)
    }
  return (
    <TextField
      key = {props.label}
      className = {props.className ? props.className : 'mb-2'}
      label= {props.label} 
      fullWidth
      size = {props.size ? props.size : 'small'} 
      defaultValue = {props.defaultValue}
      value = {props.value}
      onChange = {props.onChange}
      type = {props.type}
      autoFocus = {props.focus}
      InputProps={InputProps}
    />
  )
}

export  function SelectAutoComplete(props) {
  const [inputValue, setInputValue] = useState('')
  return (
    <div className='w-100'>
      {props.optionsList && <Autocomplete
        size='small'
        fullWidth
        className='mb-2'
        value={props.value}
        onChange={props.onChange}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        options={props.optionsList}
        renderInput={(params) => <TextField {...params} label={props.label} focused={props.focused} color={props.color} autoFocus = {props.focus ? props.focus : false} ref={props.ref}/>}
        getOptionLabel = {props.option}
        noOptionsText = {`${props.notFoundLabel || props.label} Not Found`}
        freeSolo = {props.freeSolo ? props.freeSolo : false}
        filterSelectedOptions = {props.filterSelectedOptions ? props.filterSelectedOptions : false}
      />}
    </div>
  )
}

export function SwitchInput(props) {
  return (
    <Stack key={props.startLabel + props.endLabel} className='justify-content-center mb-2' direction="row" alignItems="center">
      <Typography>{props.startLabel}</Typography>
      <Switch checked={props.checked} onChange={props.onChange} color = {props.color ? props.color : 'default'}/>
      <Typography>{props.endLabel}</Typography>
    </Stack>
  )
}





