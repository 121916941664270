import React, {useEffect, useState} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { Text, SelectAutoComplete } from '../../Components/Inputs/InputFields';

import {Money, AccountBalance, AccountBalanceWallet, Lock, Receipt, CreditCard, PanTool, Payments} from '@mui/icons-material/';

export default function CreateAccounts() {

    const [listAccountsCategory, setlistAccountsCategory] = useState('')
    const iconList = [{id: 'money', icon: <Money/>}, {id: 'locker', icon: <Lock/>}, {id: 'bank', icon: <AccountBalance/>}, {id: 'card', icon: <CreditCard/>}, {id: 'receipt', icon: <Receipt/>}, {id: 'drawings', icon: <PanTool/>}, {id: 'payments', icon: <Payments/>}, {id: 'equity', icon: <AccountBalanceWallet/>}]

    const [name, setName] = useState()
    const [category, setCategory] = useState()
    const [description, setDescription] = useState()
    const [icon, setIcon] = useState()

    const [submitDisable, setSubmitDisable] = useState(false)


    useEffect(() => {
        loadAccountsCategories()
    },[])

    const loadAccountsCategories = async() => {
        try {
            const {data} = await Axios.post(`/acccategories`)
            if (data?.error) {
                toast.error(data.error)
            } else {
                setlistAccountsCategory(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        try {
            await setSubmitDisable(true)
            const {data} = await Axios.post(`/accounting`, {name, category, description, icon})
            if(data?.error) {
                toast.error(data.error)
                setSubmitDisable(false)
            } else {
                toast.success("Accounts Added Successfully")
                window.location.reload()
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    return (
        <div className='p-3'>
            <h5 className='text-center text-capitalize'>Bank Accounts</h5>
            <form onSubmit={handleSubmit}>

                <Text
                    label="Account Name" 
                    value = {name}
                    onChange={(e) => setName(e.target.value)}
                />

                <SelectAutoComplete
                    label = "Account Type"
                    optionsList={listAccountsCategory}
                    option = {(i) => i.name}
                    value={category}
                    onChange={(event, value) => {
                        setCategory(value)
                    }}
                />

                <Text
                    label="Description" 
                    value = {description}
                    onChange={(e) => setDescription(e.target.value)}
                />

                <SelectAutoComplete
                    label = "Icon"
                    optionsList={iconList?.map((i) => i?.id)}
                    option = {(i) => i}
                    value={icon}
                    onChange={(event, value) => {
                        setIcon(value)
                    }}
                />

                {icon && <div className='text-center mt-3'>{iconList?.find((i) => i?.id === icon)?.icon}</div>}

                <div className="d-grid gap-2">
                <button className='btn btn-primary mt-3' type='submit' disabled={submitDisable}>ADD ACCOUNTS</button>
                </div>

            </form>
        </div>
  )
}
