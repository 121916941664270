import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment';

import { useGdate } from '../../../context/date'

import { Text, SelectAutoComplete } from '../../../Components/Inputs/InputFields';

export default function AccountsForm(props) {
  const accData = props.data
  const [gdate] = useGdate()

  console.log(props.data)

  const [listAccountings, setListAccountings] = useState('')
  const [accDataLength, setAccDataLength] = useState('')
  const [listBanks, setListBanks] = useState('')
  const [listCustomers, setListCustomers] = useState('')
  const [listSuppliers, setListSuppliers] = useState('')
  const [listEmployeeSalaryArrears, setListEmployeeSalaryArrears] = useState('')

  //Mark Date should be morethan cashflow verified date
  const [checkCashflow, setCheckCashflow] = useState(false)

  const [date] = useState(gdate?.date)
  const [enteredAmount, setEnteredAmount] = useState('')
  
  //Accounts form 1
  const [accounting, setAccounting] = useState('')
  const [amount, setAmount] = useState('')
  const [description, setDescription] = useState('')

  const [bank, setBank] = useState()
  const [customer, setCustomer] = useState()
  const [supplier, setSupplier] = useState()
  const [employee, setEmployee] = useState()

  //Accounts form 2
  const [accounting2, setAccounting2] = useState('')
  const [amount2, setAmount2] = useState('')
  const [description2, setDescription2] = useState('')

  const [bank2, setBank2] = useState()
  const [customer2, setCustomer2] = useState()
  const [supplier2, setSupplier2] = useState()
  const [employee2, setEmployee2] = useState()

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    loadAccounts()
    loadAccLength()
    loadBanksTotal()
    loadEmployeesTotal()
    loadEmployeesTotal()
    loadCustomersTotal()
    loadSuppliersTotal()
    setCheckCashflow(() => moment(gdate?.cashflowDate) > moment(date) ? true : false)
  }, [])

  useEffect(() => {
    listAccountings && setAccounting(listAccountings?.find((i) => i?._id === accData?.inputAccounting) && listAccountings?.find((i) => i?._id === accData?.inputAccounting))
    listAccountings && setAccounting2(listAccountings?.find((i) => i?._id === accData?.inputAccounting2) && listAccountings?.find((i) => i?._id === accData?.inputAccounting2))
    
  }, [listAccountings])

  useEffect(() => {
    setCustomer()
    setSupplier()
    setEmployee()

  }, [accounting])

  useEffect(() => {
    setCustomer2()
    setSupplier2()
    setEmployee2()

  }, [accounting2])

  useEffect(() => {
    if (accounting?.category?.type) {
      setAmount(enteredAmount) 
    } else {
      setAmount(-enteredAmount) 
    }

    if (!accounting2?.category?.type) {
      setAmount2(enteredAmount) 
    } else {
      setAmount2(-enteredAmount) 
    }
  },[enteredAmount, accounting, accounting2])
  

  const loadAccounts = async() => {
    try {
      const {data} = await Axios.post(`/accountings`)
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListAccountings(data?.filter((i) => i?.deDisable !== true))
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadAccLength = async() => {
    try {
      const {data} = await Axios.get(`/accdata-count`)
      if (data?.error) {
        toast.error(data.error)
      } else {
        setAccDataLength(data + 1)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadBanksTotal = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/banktotal`, {  $expr : { $eq: [ '$accounting' , { $toObjectId: '63e31a93f029ceab39dcd281' } ] }, "detail.bank" : { $exists: true} })
      if(data?.error){
        toast.error(data?.error)
      } else {
        setListBanks(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadEmployeesTotal = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/employeetotal`, {  $expr : { $eq: [ '$accounting' , { $toObjectId: '6515831c1a89e4fc7010012a' } ] }, "empStatus.employee" : { $exists: true} })
      if(data?.error){
        toast.error(data?.error)
      } else {
        setListEmployeeSalaryArrears(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadCustomersTotal = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/customertotal`,  {  $expr : { $eq: [ '$accounting' , { $toObjectId: '63e31abdf029ceab39dcd283' } ] }, "detail.customer" : { $exists: true} } )
      if(data?.error){
        toast.error(data?.error)
      } else {
        setListCustomers(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadSuppliersTotal = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/suppliertotal`, {  $expr : { $eq: [ '$accounting' , { $toObjectId: '64acdf13722d07654e219f89' } ] }, "detail.supplier" : { $exists: true} })
      if(data?.error){
        toast.error(data?.error)
      } else {
        setListSuppliers(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const doubleEntryData = () => {
    return [
      {date, empStatus: {status: false, employee}, detail: {description, customer, bank, supplier}, amount: Number(amount)?.toFixed(2), accounting, refId : `de-${moment(new Date()).format("YYYYMMDDHHmmss")}-${accDataLength}-${enteredAmount}`},
      {date, empStatus: {status: false, employee: employee2}, detail: {description: description2, customer: customer2, bank: bank2, supplier: supplier2}, amount: Number(amount2)?.toFixed(2), accounting: accounting2, refId : `de-${moment(new Date()).format("YYYYMMDDHHmmss")}-${accDataLength}-${enteredAmount}`}
    ]
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    try {
      await setSubmitDisable(true)
      if (checkCashflow) {
        toast.error(`Already ${date} Cashflow Verified`)
        setSubmitDisable(false)
      } else {
        const {data} = await Axios.post(`/accdatabulk`, doubleEntryData())
        if(data?.error){
          toast.error(data?.error)
          setSubmitDisable(false)
        } else {
          toast.success("Accounts Data Saved Sucessfully")
          window.location.reload()
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className='p-3'>
      <h5 className='text-center mb-3'>ACCOUNTS DOUBLE ENTRY</h5>
      <dd className='text-center'>{date}</dd>
      <form onSubmit={handleSubmit}>
        <div>
          {/* Accounts Form 1 */}
          <div className='row justify-content-center'>
              {/* ACCOUNTS */}
              <div className='col-xl-4 col-lg-6 col-md-8 col-12'>
                {!accData?.inputAccounting && 
                <SelectAutoComplete
                  label = "Accounts"
                  focus = {true}
                  optionsList={listAccountings}
                  option = {(i) => `${i.name?.toUpperCase() + ' | Type: '+ i.category?.name}`}
                  value={accounting}
                  onChange={(event, value) => {
                      setAccounting(value)
                  }}
                />}
                {listAccountings && accData?.inputAccounting && <h6 className='text-center' >{accounting?.name?.toUpperCase() + ' | ' + accounting?.category?.name}</h6>}
              </div>

              {/* TABLE */}
              <div className='row mb-3 border-top'>
                <div className='col-lg-6 col-11 p-3 border-end'>
                  <dd>{accounting?.category?.type ? 'Increase' : 'Decrease'}</dd>

                  <div className='row'>
                    <div className='col-md-8 col-12'>
                      {/* BANK */}
                      {accounting?._id === '63e31a93f029ceab39dcd281' &&
                      <SelectAutoComplete
                        label = "Bank"
                        optionsList={listBanks}
                        option = {(i) => `${i._id.accountName} | *** ${i._id.accountNo?.slice((i._id.accountNo.length - 4))} | ${new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(i.total)}`}
                        value={bank?._id?._id}
                        onChange={(event, value) => {
                            setBank(value?._id)
                            setEnteredAmount(Math.abs(value?.total))
                        }}
                      />}
                      
                      {/* DEBTS */}
                      {accounting?._id === '63e31abdf029ceab39dcd283' && 
                      <SelectAutoComplete
                        label = "Customer"
                        optionsList={listCustomers}
                        option = {(i) => `${i._id?.name} | ${i._id?.phoneNumber} | ${new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(i.total)}`}
                        value={customer?._id?._id}
                        onChange={(event, value) => {
                            setCustomer(value?._id)
                            setEnteredAmount(Math.abs(value?.total))
                        }}
                      />}

                      {/* SUPPLIERS */}
                      {accounting?._id === '64acdf13722d07654e219f89' &&
                      <SelectAutoComplete
                        label = "Supplier"
                        optionsList={listSuppliers}
                        option = {(i) => `${i._id?.name} | ${i._id?.company} | ${new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(i.total)}`}
                        value={supplier?._id?._id}
                        onChange={(event, value) => {
                            setSupplier(value?._id)
                            setEnteredAmount(Math.abs(value?.total))
                        }}
                      />}

                      {/* SALARY*/}
                      {accounting?._id === '64cb525518dca625f12ce5bf' && 
                        <p>Pay Employee Arrears By Select SALARY ARREARS Accounts</p>
                      }

                      {/* SALARY ARREARS*/}
                      {accounting?._id === '6515831c1a89e4fc7010012a' && 
                        <SelectAutoComplete
                          label = "Employee"
                          optionsList={listEmployeeSalaryArrears}
                          option = {(i) => `${i._id.name} | ${new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(i.total)}`}
                          value={employee?._id?._id}
                          onChange={(event, value) => {
                            setEmployee(value?._id)
                            setEnteredAmount(Math.abs(value?.total))
                            setDescription(`Salary Arrears Paid`)
                          }}
                        />
                      }

                      <Text
                        label = "Description"
                        value = {description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>

                    <div className='col-md-4 col-12'>
                      <p className='text-end fs-5'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(amount ? amount : '0.00')}</p>
                    </div>
                  </div>

                </div>
              </div>
          </div>

          {/* Accounts Form 2 */}
          <div className='row justify-content-center'>
            {/* ACCOUNTS */}
            <div className='col-xl-4 col-lg-6 col-md-8 col-12'>
                {!accData?.inputAccounting2 && 
                <SelectAutoComplete
                  label = "Accounts"
                  optionsList={listAccountings}
                  option = {(i) => `${i.name?.toUpperCase() + ' | Type: '+ i.category?.name}`}
                  value={accounting2}
                  onChange={(event, value) => {
                      setAccounting2(value)
                  }}
                />}
                {listAccountings && accData?.inputAccounting2 && <h6 className='text-center' >{accounting2?.name?.toUpperCase() + ' | ' + accounting2?.category?.name}</h6>}
            </div>

            {/* TABLE */}
            <div className='row mb-3 border-top'>
              <div className='col-lg-6 col-1'></div>

              <div className='col-lg-6 col-11 p-3 border-start'>
                <dd>{!accounting2?.category?.type ? 'Increase' : 'Decrease'}</dd>

                <div className='row'>
                  <div className='col-md-8 col-12'>
                    {/* BANK */}
                    {accounting2?._id === '63e31a93f029ceab39dcd281' &&
                      <SelectAutoComplete
                        label = "Bank"
                        optionsList={listBanks}
                        option = {(i) => `${i._id?.accountName} | ***${i._id?.accountNo?.slice(i._id?.accountNo?.length -4)} | ${new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(i.total)}`}
                        value={bank2?._id?._id}
                        onChange={(event, value) => {
                            setBank2(value?._id)
                            setEnteredAmount(Math.abs(value?.total))
                        }}
                      />
                    }

                    {/* DEBTS */}
                    {accounting2?._id === '63e31abdf029ceab39dcd283' &&
                    <SelectAutoComplete
                      label = "Customer"
                      optionsList={listCustomers}
                      option = {(i) => `${i._id?.name} | ${i._id?.phoneNumber} | ${new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(i.total)}`}
                      value={customer2?._id?._id}
                      onChange={(event, value) => {
                          setCustomer2(value?._id)
                          setEnteredAmount(Math.abs(value?.total))
                      }}
                    />}

                    {/* SUPPLIERS */}
                    {listSuppliers && accounting2?._id === '64acdf13722d07654e219f89' && 
                    <SelectAutoComplete
                      label = "Supplier"
                      optionsList={listSuppliers}
                      option = {(i) => `${i._id?.name} | ${i._id?.company} | ${new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(i.total)}`}
                      value={supplier2?._id?._id}
                      onChange={(event, value) => {
                          setSupplier2(value?._id)
                          setEnteredAmount(Math.abs(value?.total))
                      }}
                    />}

                    {/* SALARY*/}
                    {accounting2?._id === '64cb525518dca625f12ce5bf' && 
                      <p>Pay Employee Arrears By Select SALARY ARREARS Accounts</p>
                    }

                    {/* SALARY ARREARS*/}
                    {accounting2?._id === '6515831c1a89e4fc7010012a' && 
                      <SelectAutoComplete
                        label = "Employee"
                        optionsList={listEmployeeSalaryArrears}
                        option = {(i) => `${i._id.name} ${new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(i.total)}`}
                        value={employee2?._id?._id}
                        onChange={(event, value) => {
                            setEmployee2(value?._id)
                            setEnteredAmount(Math.abs(value?.total))
                            setDescription2(`Salary Arrears Added`)
                        }}
                      />
                    }

                    <Text
                      label = "Description"
                      value = {description2}
                      onChange={(e) => setDescription2(e.target.value)}
                    />
                  </div>

                  <div className='col-md-4 col-12'>
                    <p className='text-end fs-5'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(amount2 ? amount2 : '0.00')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row justify-content-center border p-3'>
          <div className='col-lg-3 col-md-6 col-sm-8 col-12'>
            <Text
              label="Amount" 
              value = {enteredAmount}
              type = "number"
              onChange={(e) => setEnteredAmount(e.target.value)}
              start = 'LKR'
            />
          </div>

          <div className="col-lg-3 col-md-6 col-sm-8 col-12 d-grid gap-2">
            <button className='btn btn-primary' style={{maxHeight: '40px'}} type='submit' onClick={handleSubmit} disabled={submitDisable || checkCashflow}>Submit</button>
            {checkCashflow && <dd className='text-center fs-6 fw-bold text-danger mt-2'>Cashflow Verified on Selected date</dd>}
          </div>
        </div>
      </form>
    </div>
  )
}
