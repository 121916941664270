import React, {useState,useEffect} from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useGdate } from '../../../context/date'

import {currency} from '../../../Functions/NoFomats'

import MaterialTable from '../../../Components/Table/MaterialTable'

export default function ListAccounts() {
    const {id} = useParams();
    const [searchParams] = useSearchParams()
    const [gdate] = useGdate()

    const [allData, setAllData] = useState('')
    const [listAccData, setListAccData] = useState('')

    useEffect(() => {
        loadAccData()
      },[id])

    useEffect(() => {
        allData && filterDataByDate()
      },[allData, gdate?.reportStartDate, gdate?.reportEndDate])
    
    const loadAccData = async() => {
        try {
            const {data} = await Axios.post(`/accdatas`, {accounting: id})
            if(data?.error){
                toast.error(data.error)
            } else {
                var effects = 0
                await setAllData(data?.map((i) => {
                    var debit = ''
                    var credit = ''
                    if ((i.accounting?.category?.type && i?.amount > 0) || (!i.accounting?.category?.type && i?.amount < 0)) {
                        debit = Math.abs(i?.amount)
                    } else if ((!i.accounting?.category?.type && i?.amount > 0) || (i.accounting?.category?.type && i?.amount < 0)) {
                        credit = Math.abs(i?.amount)
                    }
                    effects += i?.amount
                    return {...i, debit, credit, effects}
                }))
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const filterDataByDate = async() => {
        try {
            setListAccData(allData?.filter((i) => i?.date >= gdate?.reportStartDate && i?.date <= gdate?.reportEndDate))
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const dateEmpDes = [
        { accessorKey: 'date', header: 'Date', size: 100},
        { accessorKey: 'empStatus.employee.name', header: 'Employee', size: 100, Cell: ({row: {original}}) => original.empStatus?.employee?.name},
        { accessorKey: 'detail.description', header: 'Description', size: 100, Cell: ({row: {original}}) => original.detail?.description},
    ]

    const dateDes = [
        { accessorKey: 'date', header: 'Date', size: 100},
        { accessorKey: 'detail.description', header: 'Description', size: 100, Cell: ({row: {original}}) => original.detail?.description}
    ]

    const amountDetailsCol = [
        { accessorKey: 'debit', header: 'Debit', Cell: ({row: {original}}) => original.debit ? currency(original.debit) : '', export: (i) => i ? currency(i) : ''},
        { accessorKey: 'credit', header: 'Credit', Cell: ({row: {original}}) => original.credit ? currency(original.credit) : '', export: (i) => i ? currency(i) : ''},
        { accessorKey: 'effects', header: 'Effects', size: 100, Cell: ({row: {original}}) => currency(original.effects), export: (i) => currency(i)}
    ]

    const allAccounts = [
        ...dateEmpDes,
        ...amountDetailsCol
    ]

    const bankAccounts = [
        ...dateEmpDes,
        { accessorKey: 'detail.bank.accountName', header: 'Account', size: 100, Cell: ({row: {original}}) => original.detail?.bank?.accountName},
        { accessorKey: 'detail.cheque.chqDate', header: 'Cheque Date', size: 100, Cell: ({row: {original}}) => original.detail?.cheque?.chqDate},
        { accessorKey: 'detail.cheque.chqNo', header: 'Cheque No', size: 100, Cell: ({row: {original}}) => original.detail?.cheque?.chqNo},
        ...amountDetailsCol
    ]

    const billAccounts = [
        ...dateEmpDes,
        { accessorKey: 'detail.customer.name', header: 'Customer', size: 100, Cell: ({row: {original}}) => original.detail?.customer?.name},
        { accessorKey: 'detail.billNo', header: 'Bill No', size: 100, Cell: ({row: {original}}) => original.detail?.billNo},
        { accessorKey: 'detail.vehicleNo', header: 'Vehicle No', size: 100, Cell: ({row: {original}}) => original.detail?.vehicleNo},
        { accessorKey: 'detail.order', header: 'Gov Order', size: 100, Cell: ({row: {original}}) => original.detail?.order},
        ...amountDetailsCol
    ]

    const chequeAccounts = [
        ...dateDes,
        { accessorKey: 'detail.customer.name', header: 'Customer', size: 100, Cell: ({row: {original}}) => original.detail?.customer?.name},
        { accessorKey: 'detail.cheque.chqDate', header: 'Cheque Date', size: 100, Cell: ({row: {original}}) => original.detail?.cheque?.chqDate},
        { accessorKey: 'detail.cheque.chqNo', header: 'Cheque No', size: 100, Cell: ({row: {original}}) => original.detail?.cheque?.chqNo},
        ...amountDetailsCol
    ]

    const creditorAccounts = [
        ...dateDes,
        { accessorKey: 'detail.supplier.name', header: 'Supplier', size: 100, Cell: ({row: {original}}) => original.detail?.supplier?.name},
        ...amountDetailsCol
    ]
    
    const selectedAccounts = (id) => {
        switch (id) {
            case '63e31abdf029ceab39dcd283':
                return billAccounts
                break
            case '63e31a93f029ceab39dcd281':
                return bankAccounts
                break
            case '64fda2c5c969962bfbcee3eb':
                return chequeAccounts
                break
            case '64acdf13722d07654e219f89':
                return creditorAccounts
                break
            default:
                return allAccounts
        }
    }

    return (
        <div className='row justify-content-center'>
            <div className='col-12 mb-3'>
                <MaterialTable
                    title = {searchParams.get('accounts')?.toUpperCase() + ' Transactions | ' + gdate?.reportStartDate + ' - ' + gdate?.reportEndDate}
                    col = {selectedAccounts(id)}
                    row = {listAccData}
                    columnVisibility = {id === '63e31a93f029ceab39dcd281' ? 
                    {
                        'detail.cheque.chqDate': false,
                        'detail.cheque.chqNo': false,
                    }: 
                    {
                        'detail.order': false

                    }}
                />
            </div>
        </div>
    )
}
