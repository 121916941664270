import React, {useState, useEffect} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import { useAuth } from '../../context/auth'
import { useGdate } from '../../context/date'

import {Check, Clear} from '@mui/icons-material'

import {Text, SelectAutoComplete} from '../../Components/Inputs/InputFields'

export default function CreateEmployee() {
  const [auth] = useAuth()
  const [gdate] = useGdate()

  const [rolesArray, setRolesArray] = useState([{id: 2, role:"Employee"}])

  const [date] = useState(gdate?.date)
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [nic, setNic] = useState()
  const [role, setRole] = useState()
  const [designation, setDesignation] = useState()
  const [password, setPassword] = useState()
  const [comparePassword, setComparePassword] = useState()
  const [address, setAddress] = useState()
  const [phoneNumber, setPhoneNumber] = useState()

  const [type, setType] = useState()
  const [payment, setPayment] = useState()

  const [amount, setAmount] = useState()

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    setRolesArray(roles())
  },[auth?.employee])

  const roles = () => {
    switch (auth?.employee?.role) {
      case 0:
        return [{id: 0, role:"Admin"},{id: 1, role:"User"},{id: 2, role:"Employee"}]
        break
      default:
        return [{id: 2, role:"Employee"}]
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
      if (amount && (password === comparePassword)) {
        const {data} = await Axios.post(`/register`, {
          name, email, nic, role, designation, password, address, phoneNumber, payment: {type, amount: Number(payment)?.toFixed(2)}
        })
        if(data?.error) {
          toast.error(data.error);
          setSubmitDisable(false)
        } else {
          const data1 = await Axios.post(`/accdatabulk`, [
            {date, empStatus: {status: false}, detail:{description: `Employee: ${name} Opening Salary Balance`}, amount: (amount ? amount : 0), accounting: "64cb525518dca625f12ce5bf", refId: `de-employee-${data?.employee?._id}-${date}-Opening Balance`},
            {date, empStatus: {status: false, employee: data?.employee?._id}, detail:{description: `Employee: ${name} Opening Salary Arrears`}, amount: (amount ? amount : 0), accounting: '6515831c1a89e4fc7010012a', refId: `de-employee-${data?.employee?._id}-${date}-Opening Balance`}
          ])
          if(data1?.data?.error){
            toast.error(data.error)
            setSubmitDisable(false)
          } else {
            toast.success("Employee Added");
            window.location.reload()
          }
        }
      } else {
        toast.error("Password Miss Match / Amount")
        setSubmitDisable(false)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
      
    }
  }
  
  return (
    <div className='p-3'>
      <h4 className='text-center mb-3'>Create Employee</h4>
      <form onSubmit={handleSubmit}>
        <Text
          label="Name"
          value = {name}
          onChange={(e) => setName(e.target.value)}
          focus = 'true' 
        />
        <Text
          label="Email / Username"
          value = {email}
          onChange={(e) => setEmail(e.target.value)} 
        />

        <Text
          label="NIC Number"
          value = {nic}
          onChange={(e) => setNic(e.target.value)}
          end = {nic?.length > 0 ? (nic.length === 10 || nic.length === 12 ? <Check color='success' /> : <Clear color='error'/>) : ''}
        />

        <SelectAutoComplete
          label = "Role"
          optionsList={rolesArray}
          option = {(i) => i.role}
          value={role}
          onChange={(event, value) => {
              setRole(value.id)
          }}
        />

        <Text
          label="Designation"
          value = {designation}
          onChange={(e) => setDesignation(e.target.value)}
        />

        <Text
          label="Password"
          type= "password"
          value = {password}
          onChange={(e) => setPassword(e.target.value)} 
        />

        <Text
          label="Confirm Password"
          type = "password"
          value = {comparePassword}
          onChange={(e) => setComparePassword(e.target.value)}
          end = {comparePassword?.trim() > 0 ? (password === comparePassword ? <Check color='success' /> : <Clear color='error'/>) : ''}
        />

        <Text
          label="Address"
          value = {address}
          onChange={(e) => setAddress(e.target.value)} 
        />

        <Text
          label="Phone Number"
          type ="Number"
          value = {phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)} 
          end = {phoneNumber?.length > 0 ? (phoneNumber.length === 10 ? <Check color='success' /> : <Clear color='error'/>) : ''}
        />

        <SelectAutoComplete
          label = "Payment Type"
          optionsList={['hours', 'day', 'month']}
          option = {(i) => i}
          value={type}
          onChange={(event, value) => {
              setType(value)
          }}
        />

        <Text
          label="Payment"
          type= "Number"
          value = {payment}
          onChange={(e) => setPayment(e.target.value)}
          end = "LKR"
        />

        <Text
          label="Salary Arrears"
          type ="Number"
          value = {amount}
          onChange={(e) => setAmount(e.target.value)} 
          end = {amount ? (amount < 0 ? "Cr" : "Dr") : ''}
        />

        <div class="d-grid gap-2">
          <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
            REGISTER
          </button>
        </div>
        
      </form>
    </div>
  )
}
