import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment'

import {unit, GeneralNo} from '../../Functions/NoFomats'

import MaterialTable from '../../Components/Table/MaterialTable'

export default function StockList(props) {
    const tank = props.data

    const [listLoads, setListLoads] = useState('')
    //Model

    useEffect(() => {
        loadStockLoad()
    }, [tank])
    

  const loadStockLoad = async() => {
      try {
          const {data} = await Axios.post(`/stockloads`, {"wareHouseStock._id": tank?._id})
          if(data?.error){
              toast.error(data.error)
          } else {
            setListLoads(data?.map(i => ({...i, load: i?.quantity/ i?.wareHouseStock?.productCode?.product?.type?.loadLiter})))
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  const columns = [
    { accessorKey: 'purchase.purchaseDate', header: 'Date', size: 100},
    { accessorKey: 'purchase.invoiceNo', header: 'Invoice No', size: 100},
    { accessorKey: 'quantity', header: 'Litters', size: 100, Cell: ({row: {original}}) => unit(original.quantity, 'L'),  export: (i) => unit(i, 'L')},
    { accessorKey: 'load', header: 'Load', size: 100, Cell : ({row: {original}}) => GeneralNo(original.load), export: (i) => GeneralNo(i)},
    { accessorKey: 'createdAt', header: 'Marked On', size: 180, Cell : ({row: {original}}) => moment(original.createdAt).format("YYYY-MM-DD hh:mm: A"), export : (i) => moment(i).format("YYYY-MM-DD hh:mm: A")},
  ]
  
  return (
    <div className='row justify-content-center mt-5'>
      <div className='col-lg-10 col-12'>
        <MaterialTable
          title = {'Tank ' + tank?.productCode?.product?.name + ' - Purchase Stocks List'}
          col = {columns}
          row = {listLoads}
        />
      </div>
    </div>
    )
}
