import React, {useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import {useAuth} from '../../context/auth'

import {currency} from '../../Functions/NoFomats'

import MaterialTable from '../../Components/Table/MaterialTable'
import ModalBasic from '../../Components/Modal/ModalBasic'

import {Add, Edit} from '@mui/icons-material/'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBarcode} from '@fortawesome/free-solid-svg-icons'

import Category from './Category/Category'
import CreateProduct from './CreateProduct'
import UpdateProduct from './UpdateProduct'

import CreateProductCode from './ProductCodes/CreateProductCode'
import UpdateProductCode from './ProductCodes/UpdateProductCode'


export default function ListProducts() {

  const navigate = useNavigate()
  const [auth] = useAuth()

  const [listProducts, setListProducts] = useState('')

  //Model
  const [visibleAddCategory, setVisibleAddCategory] = useState(false)

  const [visibleAddProduct, setVisibleAddProduct] = useState(false)

  const [visibleUpdateProduct, setVisibleUpdateProduct] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)

  const [visibleAddProductCode, setVisibleAddProductCode] = useState(false)

  const [visibleUpdateProductCode, setVisibleUpdateProductCode] = useState(false)
  const [selectedProductCode, setSelectedProductCode] = useState(null)


  useEffect(() => {
      loadProductCodes()
  }, [])

  const loadProductCodes = async() => {
    try {
        const {data} = await Axios.post('/productcodes')
        if(data?.error){
            toast.error(data.error)
        } else {
          setListProducts(data.filter((i) => i.product?.category?._id !== '63e65efc2f840202604fbb7f'))
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  const columns = [
    {accessorKey: 'product.name', header: 'Name', size:100, Cell: ({row: {original}}) => 
      <div className='d-flex align-items-center gap-3'>
        <Edit fontSize='small'
          onClick={() => {
            setVisibleUpdateProduct(true)
            setSelectedProduct(original.product)
          }}
        />
        <FontAwesomeIcon icon={faBarcode}
          onClick={() => {
            setVisibleAddProductCode(true)
            setSelectedProduct(original.product)
          }}
        />
      </div>
    },
    {accessorKey: 'product.category.name', header: 'Category', size: 100, exportHead: 'Category'},
    {accessorKey: 'product.brand.name', header: 'Brand', size: 100, exportHead: 'Brand'},
    {accessorKey: 'product.unit', header: 'Unit', size: 100},
    {accessorKey: 'productCode', header: 'Product Code'},
    {accessorKey: 'cost', header: 'Cost', size: 100, Cell: ({row: {original}}) => currency(original.cost)},
    {accessorKey: 'price', header: 'Price', size: 100, Cell: ({row: {original}}) => currency(original.price)},
  ]

  const rowAction = ({row: {original}}) => [
    <div className='d-flex justify-content-center gap-2'>
      <Edit
        onClick={() => {
          setVisibleUpdateProductCode(true)
          setSelectedProductCode(original)
        }}
      />
    </div>
  ]

  return (
    <div className='row justify-content-center'>
      <div className='col-lg-12 col-12 mb-3'>
        {auth?.employee?.role === 0 &&
          <div className='d-flex gap-2 mb-2'>
            <button className = 'btn btn-outline-primary' onClick={() => {setVisibleAddProduct(true)}}><Add/> PRODUCT</button>
            <button className = 'btn btn-outline-primary' onClick={() => {navigate(`/dashboard/user/admin/inventory/brands`)}}>BRAND</button>
            <button className = 'btn btn-outline-primary' onClick={() => {setVisibleAddCategory(true)}}><Add/> CATEGORY</button>
          </div>
        }
        <MaterialTable
          title = "List of Products"
          col = {columns}
          row = {listProducts}
          rowAction = {rowAction}
          groupingColumns = {['product.name']}
        />

        {/* CATEGORY */}
        <ModalBasic
          open = {visibleAddCategory}
          onClose = {()=> {
          setVisibleAddCategory(false)
          }}
          content = {<Category/>}
        />

        {/* CREATE PRODUCT */}
        <ModalBasic
          open = {visibleAddProduct}
          onClose = {()=> {
          setVisibleAddProduct(false)
          }}
          content = {<CreateProduct/>}
        />

        {/* UPDATE PRODUCT */}
        <ModalBasic
          open = {visibleUpdateProduct}
          onClose = {()=> {
          setVisibleUpdateProduct(false)
          }}
          content = {auth?.employee?.role === 0 ? <UpdateProduct data={selectedProduct}/> :               
            <div className='d-flex justify-content-center w-100'>
              <p>You don't have Permission to Edit</p>
            </div>}
        />

        {/* ADD PRODUCTCODE */}
        <ModalBasic
          open = {visibleAddProductCode}
          onClose = {()=> {
          setVisibleAddProductCode(false)
          }}
          content = {<CreateProductCode productData={selectedProduct}/>}
        />

        <ModalBasic
          open = {visibleUpdateProductCode}
          onClose = {()=> {
          setVisibleUpdateProductCode(false)
          }}
          content = {<UpdateProductCode productCodeData={selectedProductCode}/>}
        />
      </div>
    </div>
  )
}