export function GeneralNo(val) { 
    var convertedAmount = 0
    convertedAmount = new Intl.NumberFormat().format(val)
    return convertedAmount
}

export function currency(val, abs) { 
    const convertedAmount = new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(abs ? Math.abs(val): val)
    return convertedAmount
}

export function unit(val, unit) {
    switch (unit) {
        case 'QTY':
            return new Intl.NumberFormat().format(val) + ' QTY';
        case 'L':
            return new Intl.NumberFormat('en-us', {style: 'unit', unit: 'liter'}).format(val);
        case 'Kg':
            return new Intl.NumberFormat('en-us', {style: 'unit', unit: 'kilogram'}).format(val);
        default:
            return new Intl.NumberFormat().format(val);
    }
}