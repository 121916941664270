import React, {useState, useEffect} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import {Text, SelectAutoComplete} from '../../Components/Inputs/InputFields'

export default function CreateProduct() {

  const [listCategory, setListCategory] = useState()
  const [listBrand, setListBrand] = useState()
  const [listWarehouses, setListWarehouses] = useState()

  const [name, setName] = useState("")
  const [description, setDescription] = useState('')
  const [category, setCategory] = useState()
  const [brand, setBrand] = useState()
  const [unit, setUnit] = useState()

  const listUnit = ['L', 'QTY', 'Kg']

  const [productCode, setProductCode] = useState()
  const [cost, setCost] = useState()
  const [price, setPrice] = useState()

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    loadCategories()
    loadBrands()
    loadWareHouses()
  }, [])

  const loadCategories = async() => {
    try {
      const {data} = await Axios.post('/categories')
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListCategory(data.filter((i) => i._id !== '63e65efc2f840202604fbb7f')) 
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadBrands = async() => {
    try {
      const {data} = await Axios.post('/brands')
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListBrand(data) 
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadWareHouses = async() => {
    try {
      const {data} = await Axios.post('/warehouses')
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListWarehouses(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const productObject = (i) => {
    try {
      if (i) {
        return {name, description, brand, category, unit}
      } else {
        return {name, description, category, unit}
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post(`/product`, productObject(brand));
      if(data?.error) {
        toast.error(data.error);
        setSubmitDisable(false)
      } else if (productCode) {
        const {_id} = data;
        try {
          const data1 = await Axios.post('/productcode', {productCode, product: _id, cost, price})
          if(data1?.data?.error) {
            toast.error(data1.data.error)
            setSubmitDisable(false)
          } else {
            const data2 = await Axios.post(`/warehousestockmany`, listWarehouses.map((i) => ({wareHouse: i._id, productCode: data1?.data?._id})))
            if (data2?.data?.error) {
              toast.error(data2.data.error)
              setSubmitDisable(false)
            } else {
              toast.success('Product Added with Product Code')
              window.location.reload()   
            }
          }
        } catch (err) {
          toast.error("System error, Try Again!")
          setSubmitDisable(false)
        }
      } else {
        toast.success('Product Added')
        window.location.reload()   
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong, Try Again!")
      
    }
  }

  return (
    <div className='p-3'>
      <h4 className='text-center mb-3'>Add Product</h4>
      
      <form onSubmit={handleSubmit}>
        <Text
          label="Product Name"
          value = {name}
          onChange={(e) => setName(e.target.value)}
          focus = 'true'
        />

        <SelectAutoComplete
          label = "Category"
          optionsList={listCategory}
          option = {(i) => i.name}
          value={category}
          onChange={(event, value) => {
              setCategory(value)
          }}
        />

        <SelectAutoComplete
          label = "Brand"
          optionsList={listBrand}
          option = {(i) => i.name}
          value={brand}
          onChange={(event, value) => {
              setBrand(value)
          }}
        />

        <Text
          label="Description"
          value = {description}
          onChange={(e) => setDescription(e.target.value)} 
        />

        <SelectAutoComplete
          label = "Unit"
          optionsList={listUnit}
          option = {(i) => i}
          value={unit}
          onChange={(event, value) => {
              setUnit(value)
          }}
        />

        <dd className='text-center'>Product Code Details</dd>
        <dd>If you not enter productcode details, only product will be added</dd>
        <Text
          label="Product Code"
          value = {productCode}
          onChange={(e) => setProductCode(e.target.value)} 
        />

        <Text
          label="Cost"
          value = {cost}
          onChange={(e) => setCost(e.target.value)} 
        />

        <Text
          label="Price"
          value = {price}
          onChange={(e) => setPrice(e.target.value)} 
        />

        <div class="d-grid gap-2">
          <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
            ADD PRODUCT
          </button>
        </div>
      </form>
    </div>
  )
}
