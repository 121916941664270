import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { useNavigate, createSearchParams } from 'react-router-dom'
import {toast} from 'react-hot-toast'

import { useGdate } from '../../context/date'

import {PropaneTank, Height, Check, LocalShipping, WarningAmber} from '@mui/icons-material/'
import { Card } from 'antd'

import ModalBasic from '../../Components/Modal/ModalBasic'

import DepCheck from './Depths/DepCheck'

import TankImg from '../../Assets/images/Tank-for-Card.png'

export default function TankCard() {
  const navigate = useNavigate()

  const [gdate] = useGdate()

  const [loading, setLoading] = useState(true);

  const [tankslist, setTankslist] = useState('')
  const [depList, setDepList] = useState('')

  const [visible1, setVisible1] = useState(false);
  const [selected1, setSelected1] = useState(null);

  useEffect(() => {
    loadTanks()
    loadDepths()
  }, [])

  const loadTanks = async() => {
    try {
      const {data} = await Axios.post('/warehousestocks/filtercategory', {_id: "63e65efc2f840202604fbb7f"})
      if (data?.error){
        toast.error(data?.error)
      } else {
        setTankslist(data)
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadDepths = async() => {
    try {
      const {data} = await Axios.post('/product-depths')
      if (data?.error){
        toast.error(data?.error)
      } else {
        setDepList(data)
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }
  
  return (
    <div className='row' style={{borderRadius: '5px', backdropFilter: 'blur(10px)'}}>
      {tankslist?.length === 0 ? <h2 className='col text-center'>There is no tank to Show</h2> : ''}
      {tankslist && depList && tankslist?.map((i) => (
        <div key={i._id} className='col-xl-3 col-lg-4 col-md-6 col-sm-12 p-2'> 
          <Card
            hoverable
            className='align-items-center'
            style={{
              backgroundImage: 'linear-gradient(90deg, rgba(15,54,100,1) 0%, rgba(45,54,50,1) 70%)',
              backgroundSize: 'cover',
            }}
            loading={loading}
          >
            <div className='position-relative'>
              <div className='position-absolute'>
                <img
                  style={{opacity:0.25, height: '120px', zIndex: 'inherit'}}
                  alt="Petrol Tank"
                  src={TankImg}
                />
              </div>

              <div className='d-flex justify-content-between'>
                <div className='position-relative'>
                  <div className='d-flex gap-1'>
                    <LocalShipping fontSize='large' className='p-2 shadow rounded-circle' 
                      style={{backgroundColor: 'rgba(250,250,250,0.7)'}}
                      onClick={()=> {
                        navigate({
                          pathname: `/dashboard/user/tank/purchase/${i?._id}`,
                          search: `?${createSearchParams({
                            name: i?.productCode?.product?.name
                          })}`
                        })
                        
                      }}
                    />
                    <Height fontSize='large' className='p-2 shadow rounded-circle' 
                      style={{backgroundColor: 'rgba(250,250,250,0.7)'}}
                      onClick={()=> {
                          setVisible1(true)
                          setSelected1(i)
                      }}
                    />

                    <PropaneTank fontSize='large' className='p-2 shadow rounded-circle' 
                      style={{backgroundColor: 'rgba(250,250,250,0.7)'}}
                      onClick={()=> {
                        navigate({
                          pathname: `/dashboard/user/tank/list-tank-check/${i?._id}`,
                          search: `?${createSearchParams({
                            name: i?.productCode?.product.name
                          })}`
                        })
                      }}
                    />
                  </div>
                </div>

                <div style={{width: '100%'}}>
                  <div className='text-end'>
                    <dd className='text-capitalize fs-6' style={{color: 'white'}}>Stock: <strong>{new Intl.NumberFormat().format(i?.stock)}L</strong></dd>
                  </div>
                </div>
              </div>

              <div style={{width: '100%'}}>
                <div className='text-end'>
                  <li className='text-capitalize fs-6' style={{color: 'white'}}>Depth: <strong>{new Intl.NumberFormat().format( depList?.find((d) => i?.productCode?.product?._id === d?.product?._id && gdate?.date === d?.date) ? depList?.find((d) => i?.productCode?.product?._id === d?.product?._id  && gdate?.date === d?.date)?.dep : 0 )} | {new Intl.NumberFormat().format( depList?.find((d) => i?.productCode?.product?._id === d?.product?._id  && gdate?.date === d?.date) ? depList?.find((d) => i?.productCode?.product?._id === d?.product?._id  && gdate?.date === d?.date)?.volume : 0 ) }L</strong></li>
                  <li className='text-capitalize fs-6 fw-bold' style={((depList?.find((d) => i?.productCode?.product?._id === d?.product?._id) ? depList?.find((d) => i?.productCode?.product?._id === d?.product?._id)?.volume : 0) - i.stock) > 0 ? {color: 'greenyellow'} : {color: 'red'}}>Dep Dif: {new Intl.NumberFormat().format( (depList?.find((d) => i?.productCode?.product?._id === d?.product?._id) ? depList?.find((d) => i?.productCode?.product?._id === d?.product?._id)?.volume : 0) - i.stock )}L</li>
                </div>
              </div>
            </div>

            <div className='d-flex justify-content-between pt-1'>
              <dd className='text-capitalize fs-6' style={{color: 'white'}}>Capacity: <strong>{new Intl.NumberFormat().format(i.productCode?.product?.capacity)}G | {new Intl.NumberFormat().format(Math.round(i.productCode?.product?.capacity * 4.865))}L</strong></dd>
              {depList?.find((d) => i?.productCode?.product?._id === d?.product?._id && gdate?.date === d?.date) ? <Check className='bg-dark p-1' fontSize='large' color= "success"/> : <WarningAmber className='bg-dark p-1' fontSize='large' color= "warning"/>}
            </div>

            <div className='border border-dark p-2 mt-2' style={{backgroundImage: 'linear-gradient(122deg, rgba(43,23,55,0.79) 0%, rgba(55,05,90,0.6) 50%, rgba(43,23,55,0.79) 100%)', backdropFilter: 'blur(3px)', borderRadius: '5px'}}>
              <div className='text-uppercase text-center justify-content-center fs-6 fw-bold' style={{color: 'white'}}>{i.productCode?.product.name}</div>
            </div>

          </Card>
        </div>
        ))}

        <ModalBasic
          open = {visible1}
          onClose = {()=> {
            setVisible1(false)
          }}
          content = {
            <DepCheck
            warehouseStock = {selected1}
            />
          }
        />
      </div>
  )
}