import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useGdate } from '../../../context/date'

import { Text, SelectAutoComplete } from '../../../Components/Inputs/InputFields';

export default function UpdateCheque(props) {
    const [chqData, setChqData] = useState(props.data)
    const [gdate] = useGdate()

    //QUICK PANEL DATA
    const [listCheques, setListCheques] = useState()

    const [listAccountings, setListAccountings] = useState()
    const [listBanks, setListBanks] = useState()
    const [listCustomers, setListCustomers] = useState()
    const [listSuppliers, setListSuppliers] = useState()

    const [date] = useState(gdate.date)
    const [status, setStatus] = useState()
    const [chqNo, setChqNo] = useState()
    const [description, setDescription] = useState()
    const [description2, setDescription2] = useState()
    const [amount, setAmount] = useState()

    const [accounting, setAccounting] = useState()
    const [customer, setCustomer] = useState()
    const [supplier, setSupplier] = useState()
    const [bank, setBank] = useState()

    const [submitDisable, setSubmitDisable] = useState(false)

    useEffect(() => {
      !chqData && loadCheques()
    },[])


    useEffect(() => {
      setStatus(chqData?.status)
      setChqNo(chqData?.chqNo)
      setAmount(chqData?.amount)
      chqData?.status === 'credit' && loadAccounts()
      chqData?.status === 'debit' && setCustomer(chqData?.chqCustomer)
      chqData?.status === 'debit' && setSupplier(chqData?.chqSupplier)
      chqData?.status === 'debit' && setBank(chqData?.chqBank)
  }, [chqData])

    useEffect(() => {
        accounting?._id === '63e31a93f029ceab39dcd281' && loadBanks()
        accounting?._id === '63e31abdf029ceab39dcd283' && loadCustomers()
        accounting?._id === '64acdf13722d07654e219f89' && loadSuppliers()
    },[accounting])

    const loadCheques = async() => {
      try {
          const {data} = await Axios.post('/cheques', {chqDate: date})
          if(data?.error){
              toast.error(data.error)
          } else {
              setListCheques(data?.filter((i) => i?.chqDeposit === false))
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
    }


    const loadAccounts = async() => {
        try {
          const {data} = await Axios.post(`/accountings`)
          if (data?.error) {
            toast.error(data.error)
          } else {
            setListAccountings(data?.filter((i) => i?.deDisable !== true))
          }
        } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
        }
    }

    const loadBanks = async() => {
        try {
          const {data} = await Axios.post(`/bank-accounts`)
          if(data?.error){
            toast.error(data?.error)
          } else {
            setListBanks(data)
          }
        } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
        }
    }

    const loadCustomers = async() => {
        try {
          const {data} = await Axios.post(`/customers`)
          if(data?.error){
            toast.error(data?.error)
          } else {
            setListCustomers(data)
          }
        } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
        }
    }
    
    const loadSuppliers = async() => {
        try {
          const {data} = await Axios.post(`/suppliers`)
          if(data?.error){
            toast.error(data?.error)
          } else {
            setListSuppliers(data)
          }
        } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
        }
    }
    
    const handleSubmit = async(e) => {
        e.preventDefault()
        try {
          setSubmitDisable(true)
            //Status change for both received and given cheque
            const {data} = await Axios.put(`/cheque/${chqData?._id}`, {chqDeposit: true})
            if (data?.error) {
                toast.error("Cannot update Cheque Details")
                setSubmitDisable(false)
            } else if (status === 'credit') {
                const {data} = await Axios.post(`/accdatabulk`, [
                    //Cheque Deposits to bank and transfer to other accounts
                    {date, empStatus: {status: false}, detail: {description, bank, customer, supplier}, accounting: accounting?._id, amount: Number(accounting?.category?.type ? amount : -amount)?.toFixed(2), refId : `cheque-${chqData?._id}-${chqData?.chqNo}-${chqData?.chqDate}-${amount}`},
                    {date, empStatus: {status: false}, detail: {description: description2, cheque: chqData, customer: chqData?.chqCustomer}, accounting: '64fda2c5c969962bfbcee3eb', amount: Number(-amount)?.toFixed(2), refId : `cheque-${chqData?._id}-${chqData?.chqNo}-${chqData?.chqDate}-${amount}`}
                ])
                if (data?.error) {
                    toast.error("Somehing went wrong, Try Again!")
                    setSubmitDisable(false)
                } else {
                    toast.success("Cheque & Entries Updated")
                    window.location.reload()
                }
            } else if (status === 'debit') {
              const {data} = await Axios.post(`/accdatabulk`, [
                //Cheque Deposits to bank and transfer to other accounts
                {date, empStatus: {status: false}, detail: {description, customer, supplier}, accounting: chqData?.chqAccounting?._id, amount: Number(chqData?.chqAccounting?.category?.type ? amount : -amount)?.toFixed(2), refId : `cheque-${chqData?._id}-${chqData?.chqNo}-${chqData?.chqDate}-${amount}`},
                {date, empStatus: {status: false}, detail: {description: description2, cheque: chqData, bank}, accounting: '63e31a93f029ceab39dcd281', amount: Number(-amount)?.toFixed(2), refId : `cheque-${chqData?._id}-${chqData?.chqNo}-${chqData?.chqDate}-${amount}`}
              ])
              if (data?.error) {
                  toast.error("Somehing went wrong, Try Again!")
                  setSubmitDisable(false)
              } else {
                  toast.success("Cheque & Entries Updated")
                  window.location.reload()
              }
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
    }

    return (
        <div className='p-3'>
            <h5 className='text-center text-capitalize'>Release {status} Cheque</h5>
            {chqData?.chqCustomer && <dd className='text-center'>{chqData?.chqCustomer?.name}</dd>}
            {chqData?.chqSupplier && <dd className='text-center'>{chqData?.chqSupplier?.name}</dd>}
            <dd className='text-center'>{date}</dd>

            <SelectAutoComplete
              label = "Cheques"
              optionsList={listCheques}
              option = {(i) => `${i.status?.toUpperCase() + ' | '+ i.chqNo + ' | '+ new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(i.amount)}`}
              value={chqData}
              onChange={(event, value) => {
                  setChqData(value)
              }}
            />

            <form onSubmit={handleSubmit}>
                {status === 'credit' && <SelectAutoComplete
                    label = "Accounts"
                    optionsList={listAccountings}
                    option = {(i) => `${i.name?.toUpperCase() + ' | Type: '+ i.category?.name}`}
                    value={accounting}
                    onChange={(event, value) => {
                        setAccounting(value)
                    }}
                />}

                {accounting?._id === '63e31a93f029ceab39dcd281' &&
                    <SelectAutoComplete
                        label = "Deposit Bank"
                        optionsList={listBanks}
                        option = {(i) => `${i.bank + ' | '+ i.accountName + ' | '+ i.accountNo}`}
                        value={bank}
                        onChange={(event, value) => {
                            setBank(value)
                        }}
                    />
                }

                {accounting?._id === '63e31abdf029ceab39dcd283' &&
                    <SelectAutoComplete
                        label = "Customer"
                        optionsList={listCustomers}
                        option = {(i) => i?.name + ' | ' + i?.phoneNumber}
                        value={customer}
                        onChange={(event, value) => {
                            setCustomer(value)
                        }}
                    />
                }

                {accounting?._id === '64acdf13722d07654e219f89' &&
                    <SelectAutoComplete
                        label = "Supplier"
                        optionsList={listSuppliers}
                        option = {(i) => `${i.name + ' | '+ i.company}`}
                        value={supplier}
                        onChange={(event, value) => {
                            setSupplier(value)
                        }}
                    />
                }

                {(accounting || chqData?.chqAccounting) &&
                <div>
                  <Text
                    label = {`${accounting ? accounting?.name : chqData?.chqAccounting?.name} Accounts Description`} 
                    value = {description}
                    onChange={(e) => setDescription(e.target.value)}
                  />

                  <Text
                    label = {`${accounting ? 'Cheque' : 'Bank'} Accounts Description`}
                    value = {description2}
                    onChange={(e) => setDescription2(e.target.value)}
                  /> 
                </div>
                }

                <div className='d-flex justify-content-between'>
                  <dd>Cheque No :</dd>
                  <dd>{chqNo}</dd>
                </div>

                <div className='d-flex justify-content-between'>
                  <dd>Amount :</dd>
                  <dd>{new Intl.NumberFormat('en-us', {style: 'currency', currency: 'LKR'}).format(amount)}</dd>
                </div>

                <div className="d-grid gap-2">
                <button className='btn btn-primary mt-3' name="submitButton" type='submit' disabled = {submitDisable}>REALSE CHEQUE</button>
                </div>

            </form>
        </div>
    )
}
