import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment'

import { useAuth } from '../../../context/auth'
import { useGdate } from '../../../context/date'

import {currency} from '../../../Functions/NoFomats'

import MaterialTable from '../../../Components/Table/MaterialTable'
import ModalBasic from '../../../Components/Modal/ModalBasic'

import {Add, Check, DeleteForever} from '@mui/icons-material/'

import CreateFuelUps from './CreateFuelUps'

export default function ListFuelUps() {
  const [auth] = useAuth();
  const [gdate] = useGdate();

  const [listFuelUps, setListFuelUps] = useState('')


  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)

  const [selectedDelete, setSelectedDelete] = useState('')
  const [visibleDelete, setVisibleDelete] = useState(false)

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
      loadFuelUps()
  }, [])
  
  const loadFuelUps = async() => {
      try {
          if(auth?.employee?.role <= 1) {
            const {data} = await Axios.post('/fillups', {date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
            if(data?.error){
                toast.error(data.error)
            } else {
                setListFuelUps(data)
            }
          } else {
            const {data} = await Axios.post('/fillups', {employee: auth?.employee?._id, date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
            if(data?.error){
                toast.error(data.error)
            } else {
                setListFuelUps(data)
            }
          }
      } catch (err) {
        console.log(err)
          toast.error("Something went wrong, Try Again!")
      }
  }
  
  const handleDeletefuelUp = async(id) => {
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.delete(`/fillup/${id}`)
      if (data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        toast.success('Fill Up Records Deleted Successfully')
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const columns = [
    {accessorKey: 'vehicle', header: 'Vehicle', size: 120},
    { accessorKey: 'amount', header: 'Amount', size: 125, Cell: ({row: {original}}) => currency(original.amount)},
    { accessorKey: 'points', header: 'Points', size: 100, Cell: ({row: {original}}) => currency(original.points)},
    { accessorKey: 'redeem', header: 'Redeem', size: 100, Cell: ({row: {original}}) => currency(original.redeem)},
    { accessorKey: 'paid', header: 'Paid', size: 125, Cell: ({row: {original}}) => currency(original.amount - original.redeem)},
    { accessorKey: 'employee', header: 'Employee', size: 150, Cell: ({row: {original}}) => original.employee?.name},
    { accessorKey: 'date', header: 'Date', size: 180, Cell: ({row: {original}}) => moment(original.createdAt).format("YYYY-MM-DD hh:mm A")},
  ]

  const rowAction = ({row}) => [
    <div className='d-flex justify-content-center'>
      {moment(row.original.date) < moment(gdate?.cashflowDate) ? <Check color='success'/>
      :
      <DeleteForever
        onClick={() => {
          setVisibleDelete(true)
          setSelectedDelete(row.original)
        }}
      />}
    </div>
  ]
  
  return (
    <div className='row justify-content-center'>
      <div className='col-lg-10 col-12 mb-3'>
        <div className='row justify-content-between mb-2'>
            <div className='col-xxl-3 col-xl-4 col-md-6 col-12 d-flex gap-2 mb-2'>
                <button className = 'btn btn-outline-primary w-50' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
            </div>
        </div>

        <MaterialTable
          title = {'Fillup Details | ' + gdate?.reportStartDate + ' - ' + gdate?.reportEndDate}
          col = {columns}
          row = {listFuelUps}
          rowAction = {rowAction}
          exportDisable = {true}
        />

        <ModalBasic
          open = {visibleAdd}
          onClose = {()=> {
          setVisibleAdd(false)
          }}
          content = {<CreateFuelUps/>}
        />

        <ModalBasic
          open = {visibleDelete}
          onClose = {()=> {
          setVisibleDelete(false)
          }}
          content = {
            auth?.employee?.role < 2 ?
              <div className='d-flex justify-content-between'>
                <p>Are You sure want to?</p>
                <button className = 'btn btn-danger' disabled={submitDisable} onClick={() => {
                    handleDeletefuelUp(selectedDelete._id)
                  }}>Delete
                </button>
              </div>
              :
              <div className='d-flex justify-content-center w-100'>
                <p>You don't have Permission to Delete</p>
              </div>
          }
        />
      </div>
    </div>
  )
}
