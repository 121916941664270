import React, {useEffect, useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"
import moment from 'moment'

import { useGdate } from '../../../context/date'

import {Text, SelectAutoComplete} from '../../../Components/Inputs/InputFields'

export default function CreateGovOrder() {

    const [gdate] = useGdate()

    //Mark Date should be morethan cashflow verified date
    const [checkCashflow, setCheckCashflow] = useState(false)

    const [listGovCustomers, setListGovCustomers] = useState('')

    const [customer, setCustomer] = useState()
    const [govOrder, setGovOrder] = useState('')
    const [status] = useState('receive')
    const [receiveDate, setReceiveDate] = useState(gdate?.date)
    const [amount, setAmount] = useState('')

    const [inputCustomer, setInputCustomer] = useState()

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    loadGovCustomers()
    setCheckCashflow(() => moment(gdate?.cashflowDate) > moment(receiveDate) ? true : false)
  },[])

  const loadGovCustomers = async() => {
    try {
        const {data} = await Axios.post('/customers')
        if (data?.error) {
            toast.error(data.error)
        } else {
            setListGovCustomers(data?.filter((i) => i?.type === 'government'))
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
      if (moment(receiveDate) < moment(gdate?.cashflowDate)) {
        toast.error(`Cashflow Verified on ${receiveDate}`)
      } else {
        const {data} = await Axios.post(`/govOrder`, {customer, govOrder, status, receiveDate, amount});
        if(data?.error) {
          toast.error(data.error);
          setSubmitDisable(false)
        } else {
          toast.success("Government Order Recorded");
          window.location.reload()
        }
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  return (
    <div className='p-3'>
        <h5 className='text-center mb-3'>Add Government Order</h5>
        <form onSubmit={handleSubmit}>
            <SelectAutoComplete
              label = "Customer"
              focus = {true}
              optionsList={listGovCustomers}
              option = {(i) => `${i.name + ' | '+ i.phoneNumber}`}
              value={customer}
              onChange={(event, value) => {
                  setCustomer(value)
              }}
            />
            <Text
                label="Government Order No"
                value = {govOrder}
                onChange={(e) => setGovOrder(e.target.value)}
            />
            <Text
                label="Received Date"
                type = 'date'
                value = {receiveDate}
                onChange={(e) => setReceiveDate(e.target.value)}
            />
            <Text
                label="Amount"
                type = "Number"
                value = {amount}
                onChange={(e) => setAmount(e.target.value)}
                end = "LKR"
            />

        <div class="d-grid gap-2">
          <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable || checkCashflow}>SUBMIT ORDER</button>
          {checkCashflow && <dd className='text-center fs-6 fw-bold text-danger mt-2'>Cashflow Verified on Selected date</dd>}

        </div>
      </form>
    </div>
  )
}
