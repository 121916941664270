import React, {useEffect, useState} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import BarChart from '../Components/Charts/BarChart'

export default function GasDashboard(props) {
  const [tankData, setTankData] = useState('')

  useEffect(() => {
    loadTanksData()
  },[])

  const loadTanksData = async() => {
    try {
        const {data} = await Axios.post('/warehousestocks/filtercategory', {_id: "63e65efc2f840202604fbb7f"})
        if(data?.error) {
          toast.error(data.error)
        } else{
          setTankData(data)
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
}

  return (
    <div className='row justify-content-center mt-3'>
      {props.noTitle ? '' : <h5 className='text-center'>Fuel Stocks</h5>}
      
      <div className='col-12'>
        {tankData && <BarChart data={tankData}/>}
      </div>
    </div>
  )
}
