import React from 'react'
import {Text} from '../../../Components/Inputs/InputFields'

export default function CategoryForm(props) {
  return (
    <div className='p-3'>
    <h5 className='row justify-content-center p-2'>{props.title}</h5>
    <form onSubmit={props.handleSubmit}>
      <Text
        label = "Category"
        value = {props.value}
        onChange = {props.onChange} 
        autoFocus = "true"
      />
      <div className="d-flex justify-content-between">
        <button className='btn btn-primary mt-3'>{props.btntxt || "SUBMIT"}</button>
        {props.handleDelete && 
        (<button className='btn btn-danger mt-3' onClick={props.handleDelete} disabled={props?.btnStatus}>DELETE</button>
        )}
      </div>
    </form>
  </div>
  )
}
