import React, {useState, useEffect} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import {Text, SelectAutoComplete} from '../../Components/Inputs/InputFields'

export default function UpdateProduct(props) {
    const productData = props.data;

    const [listCategory, setListCategory] = useState()
    const [listBrand, setListBrand] = useState()

    const [name, setName] = useState(productData?.name)
    const [category, setCategory] = useState(productData?.category)
    const [brand, setBrand] = useState(productData?.brand)
    const [description, setDescription] = useState(productData?.description)
    const [unit, setUnit] = useState(productData?.unit)

    const listUnit = ['L', 'QTY', 'Kg']

    const [submitDisable, setSubmitDisable] = useState(false)

    useEffect(() => {
        loadCategories()
        loadBrands()
    }, [])

    const loadCategories = async() => {
        try {
        const {data} = await Axios.post('/categories')
        if (data?.error) {
            toast.error(data.error)
        } else {
            setListCategory(data.filter((i) => i._id !== '63e65efc2f840202604fbb7f'))
        }
        } catch (err) {
        console.log(err)
        }
    }

    const loadBrands = async() => {
        try {
          const {data} = await Axios.post('/brands')
          if (data?.error) {
            toast.error(data.error)
          } else {
            setListBrand(data) 
          }
        } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
        }
    }

    const productObject = (i) => {
        try {
            if (i) {
                return {...productData, name, description, brand, category, unit}
            } else {
                return {...productData, name, description, category, unit}
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await setSubmitDisable(true)
            const {data} = await Axios.put(`/product/${productData?._id}`, productObject(brand));
            if(data?.error) {
                toast.error(data.error);
                setSubmitDisable(false)
            } else {
                toast.success('Product Updated')
                window.location.reload()
            }
        } catch (err) {
            console.log(err);
            toast.error("Something went wrong, Try Again!")
        }
    }
    
    return (
        <div className='p-3'>
        <h4 className='text-center mb-3'>Add Product</h4>
            <form onSubmit={handleSubmit}>
                <Text
                    label="Product Name"
                    value = {name}
                    onChange={(e) => setName(e.target.value)}
                    focus = {true}
                />

                <SelectAutoComplete
                    label = "Category"
                    optionsList={listCategory}
                    option = {(i) => i.name}
                    value={category}
                    onChange={(event, value) => {
                        setCategory(value)
                    }}
                />

                <SelectAutoComplete
                    label = "Brand"
                    optionsList={listBrand}
                    option = {(i) => i.name}
                    value={brand}
                    onChange={(event, value) => {
                        setBrand(value)
                    }}
                />

                <Text
                label="Description"
                value = {description}
                onChange={(e) => setDescription(e.target.value)} 
                />

                <SelectAutoComplete
                    label = "Unit"
                    optionsList={listUnit}
                    option = {(i) => i}
                    value={unit}
                    onChange={(event, value) => {
                        setUnit(value)
                    }}
                />


                <div class="d-grid gap-2">
                <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
                    UPDATE PRODUCT
                </button>
                </div>
            </form>
        </div>
    )
}
